import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface PackageDelete {
  success: boolean;
}

export interface PackageDeleteData {
  createPackage: PackageDelete | null
}

export interface PackageDeleteVariables {
  id: number;
  accountId: string;
  procoreProjectServerId: number;
}

export const useDeletePackage = createMutationHook<PackageDeleteData, PackageDeleteVariables>(gql`
mutation DeletePackage($id: ID!, $accountId: ID!, $procoreProjectServerId: ID!) {
  deletePackage(input: { id: $id, accountId: $accountId, procoreProjectServerId: $procoreProjectServerId }) {
    success
  }
}
`);