import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';
import { LogRequirementItem } from '../../types';

export interface CreateProcoreProjectFile {
  success: boolean;
  logRequirementItem: LogRequirementItem;
}

export interface CreateProcoreProjectFileData {
  createProcoreProjectFile: CreateProcoreProjectFile
}

export interface CreateProcoreProjectFileVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logRequirementId: number;
  filename: string;
  uploadUuid: string;
}

export const useCreateProcoreProjectFile = createMutationHook<CreateProcoreProjectFileData, CreateProcoreProjectFileVariables>(gql`
mutation CreateProcoreProjectFile($accountId: ID!, $procoreProjectServerId: ID!, $closeoutLogId: ID!, $logRequirementId: ID!, $filename: String!, $uploadUuid: String!) {
  createProcoreProjectFile(input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, logRequirementId: $logRequirementId, filename: $filename, uploadUuid: $uploadUuid }) {
    success
    logRequirementItem {
      id
      recordType
      recordServerId
      engineName
      payload
    }
  }
}
`);