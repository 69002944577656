import React from 'react';
import { Search, SearchCloseIcon, SearchCloseIconButton, SearchIconWrapper, StyledInputBase } from './package/styles';
import SearchIcon from '@mui/icons-material/Search';

interface IDynamicSearchInputProps {
  disabled: boolean;
  searchValue: string;
  onSearchValueChanged: (value: string) => void;
}

export const DynamicSearchInput = ({
  searchValue,
  disabled,
  onSearchValueChanged,
}: IDynamicSearchInputProps): JSX.Element => {

  const [focusSearch, setFocusSearch] = React.useState(false);
  const inputBaseRef = React.useRef(null);

  return (
    <Search sx={{
      borderRadius: '50vh'
    }}>
      <SearchIconWrapper disabled={disabled}>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        disabled={disabled}
        isopen={(focusSearch || Boolean((searchValue || '').trim())).toString()}
        inputRef={inputBaseRef}
        fullWidth={true}
        placeholder="Search..."
        value={searchValue}
        onKeyDown={(evt) => {
          if (evt.key.toLowerCase() === "enter") {
            inputBaseRef?.current?.blur();
          }
        }}
        endAdornment={
          (((searchValue || '').trim())) ? (
            <SearchCloseIconButton
              sx={{
                marginRight: '10px',
              }}
              size={'small'}
              onClick={() => {
                onSearchValueChanged('');
              }}
              style={{ visibility: ((searchValue || '').trim()) ? 'visible' : 'hidden' }}>
              <SearchCloseIcon />
            </SearchCloseIconButton>
          ) : null
        }
        onChange={(evt) => {
          onSearchValueChanged(evt.target.value)
        }}
        onFocus={() => { setFocusSearch(true)}}
        onBlur={() => {
          setFocusSearch(false);
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </Search>
  );
};
