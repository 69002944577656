import { gql } from "@apollo/client";
import { FilterOptionInput } from "../../types";
import { ProcoreDrawing } from "../../types/drawing";
import { SubmittalResponse } from "../../types/submittal";

export type ProcoreFilterDrawingData = {
  drawings: ProcoreDrawing[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  };
  submittalResponses: SubmittalResponse[];
};

export type ProcoreDrawingVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterDrawingVariables = ProcoreDrawingVariables & {
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const DrawingRevisionFragment = gql`
fragment DrawingRevisionFragment on ProcoreDrawingRevision {
  id
  procoreServerId
  formattedTitle
  drawingAreaName
  disciplineName
  drawingSetName
}
`;

export const filterProcoreDrawingsQuery = gql`
query FilterProcoreDrawings($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  drawings(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...DrawingRevisionFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }

  submittalResponses(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId) {
    id
    name
    considered
  }
}
${DrawingRevisionFragment}
`;

export const fetchPaginatedProcoreDrawingsQuery = gql`
query FilterPaginatedProcoreDrawings($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  drawings(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...DrawingRevisionFragment
  }
}
${DrawingRevisionFragment}
`;