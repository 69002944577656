import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogRequirementTrade } from "../../../types";

export interface LogRequirementTradeUpdate {
  logRequirementTrade?: LogRequirementTrade;
  success: boolean;
}

export interface LogRequirementTradeUpdateData {
  updateLogRequirementTrade: LogRequirementTradeUpdate | null;
}

export interface LogRequirementTradeUpdateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  id: number;
  name: string;
}

export const useUpdateLogRequirementTrade = createMutationHook<
  LogRequirementTradeUpdateData,
  LogRequirementTradeUpdateVariables
>(gql`
  mutation UpdateLogRequirementTrade(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $id: ID!,
    $name: String!
  ) {
    updateLogRequirementTrade(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, id: $id, name: $name }
    ) {
      logRequirementTrade {
        id
        name
      }
      success
    }
  }
`);
