import React from "react";
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  styled,
} from "@mui/material";
import { DocumentFolderPaginatedSelection } from "./DocumentFolderPaginatedSelection";
import { NewPackageExtractOptions } from "../../contexts/NewPackageContext";

export const FeedbackDialog = styled(Dialog)<DialogProps>(() => {
  return {
    '& .MuiDialog-paper': {
      width: "575px",
      display: 'flex',
    }
  }
});

export const ModalDialogTitle = styled(DialogTitle)<DialogTitleProps>(() => {
  return {
    padding: '20px 32px 20px 32px'
  }
});

const SelectDocumentFolderModal = (props: {
  handleOnClose: () => void;
  runExtract: (options: NewPackageExtractOptions) => void;
  open: boolean;
}): JSX.Element => {
  const { handleOnClose, open, runExtract } = props;
  return (
    <FeedbackDialog
      maxWidth={"sm"}
      onClose={() => handleOnClose()}
      aria-labelledby="help-dialog-title"
      open={open}
    >
      <ModalDialogTitle>
        {"Extract To..."}
      </ModalDialogTitle>
      <DialogContent
        dividers={false}
        style={{
          padding: '0px',
          display: 'flex',
          flex: 1,
          flexDirection: 'column' }}
      >
        <DocumentFolderPaginatedSelection handleOnClose={handleOnClose} runExtract={runExtract} />
      </DialogContent>
    </FeedbackDialog>
  );
};

export default SelectDocumentFolderModal;
