import React, { KeyboardEvent, ChangeEvent, useState } from "react";
import { ButtonBaseProps, ListItemButton, ListItemText, styled } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Typography,
  Popper,
  ButtonBase,
} from "@mui/material";
import { Colors, ExtractsCheckbox } from "../../styles";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { CustomInputBase } from "./FilterAutocomplete";

type OtherAddFilterButtonProps = {
  open: boolean;
};

const AddFilterButton = styled(ButtonBase)<ButtonBaseProps & OtherAddFilterButtonProps>(({ theme, open }) => {
  return {
    fontSize: 16,
    textAlign: "left",
    padding: "10px 12px",
    fontWeight: 500,
    border: open ? "1px solid #80BDFF" : "1px solid transparent",
    borderRadius: 6,
    background: '#F2F5F7',
    boxShadow: open ? "0px 0px 0px 3px rgba(0, 123, 255, 0.25)" : 'none',
    "&:hover": {
      background: "#EDF2F5",
      border: "1px solid transparent",
    },
    "&:focus": {
      borderRadius: 6,
      border: "1px solid #80BDFF",
      boxShadow: "0px 0px 0px 3px rgba(0, 123, 255, 0.25)",
    },
    "& span": {
      marginRight: "6px",
    },
    "& svg": {
      width: 16,
      height: 16,
    },
  }
});

export const AddFilterAutocomplete = (props: {
  onClose: () => void;
  onChange: (values: string[]) => void;
  options: string[];
  selectedOptions: string[];
  filterLabel: string;
  popperContainer?: Element;
}): JSX.Element => {
  const {
    filterLabel,
    options,
    onChange,
    onClose,
    selectedOptions,
    popperContainer
  } = props;

  const inputRef = React.useRef(null);
  const [focusedTag, setFocusedTag] = useState(-1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inputValue, setInputValue] = useState("");
  const buttonRef = React.useRef(null);
  const containerRef = React.useRef(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      event.stopPropagation();

      if (filteredOptions.length > 0) {
        setFocusedTag(Math.max(0, focusedTag - 1));
      } else {
        setFocusedTag(-1);
      }
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      event.stopPropagation();
      if (filteredOptions.length > 0) {
        setFocusedTag(Math.min(filteredOptions.length - 1, focusedTag + 1));
      } else {
        setFocusedTag(-1);
      }
    } else if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();

      console.log('handle on click and close')
    } else if (event.key == "Escape") {
      handleOnClose();
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setInputValue(value);
  };

  const handleOnClose = () => {
    if (open) {
      setFocusedTag(-1);

      setInputValue("");

      if (anchorEl) {
        anchorEl.focus();
      }
      setAnchorEl(null);

      onClose();
    }
  };

  const handleOnChange = (values: string[]) => {
    setAnchorEl(null);

    onChange(values);
  }

  const getFilteredOptions = (): {
    match: string[];
    unmatch: string[];
  } => {
    const trimmedValue = inputValue.trim().toLowerCase();

    if (trimmedValue) {
      const match = [];
      const unmatch = [];

      options.forEach((option) => {
        const matches = (option || "")
          .trim()
          .toLowerCase()
          .includes(trimmedValue);

        if (matches) {
          match.push(option);
        } else {
          unmatch.push(option);
        }
      });

      return { match: match, unmatch: unmatch };
    } else {
      return { match: options, unmatch: [] };
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-options-popover" : undefined;
  const { match: filteredOptions } = getFilteredOptions();

  function renderRow(props: ListChildComponentProps<string>) {
    const { index, style } = props;

    const selectedOption: string = filteredOptions[index];

    const checkFilterValue = selectedOptions.find(
      (v) => selectedOption === v
    );
    const checked = checkFilterValue ? true : false;

    return (
      <div style={style}>
        <ListItemButton
          sx={{
            padding: 0,
            color: Colors.darkerGray,
            fontSize: "14px",
            backgroundColor:
              index === focusedTag ? "rgba(0, 0, 0, 0.04)" : "none",
          }}
          key={`filter-list-item-${index}`}
          onClick={(evt) => {
            evt.stopPropagation();

            if (checked) {
              handleOnChange([
                ...selectedOptions.filter(option => option !== selectedOption)
              ])
            } else {
              handleOnChange([
                ...selectedOptions,
                selectedOption
              ])
            }
          }}
        >
          <ExtractsCheckbox
            color="default"
            checked={checked}
            onClick={(evt) => {
              evt.stopPropagation();

              if (checked) {
                handleOnChange([
                  ...selectedOptions.filter(option => option !== selectedOption)
                ])
              } else {
                handleOnChange([
                  ...selectedOptions,
                  selectedOption
                ])
              }
            }}
          />
          <ListItemText
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            disableTypography={true}
            key={`filter-list-item-text-${index}`}
          >
            {selectedOption}
          </ListItemText>
        </ListItemButton>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Box
        ref={containerRef}
        display="block"
        position="relative"
        width={"fit-content"}
        style={{ marginRight: "16px", marginLeft: '16px' }}
      >
        <AddFilterButton
          disableRipple
          open={open}
          aria-describedby={id}
          ref={buttonRef}
          onClick={handleClick}
        >
          <span>
            {selectedOptions.length > 0
              ? `${filterLabel} (${selectedOptions.length})`
              : filterLabel}
          </span>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </AddFilterButton>
      </Box>

      {open && (
        <ClickAwayListener onClickAway={handleOnClose}>
          <Popper
            container={popperContainer ? popperContainer : document.body}
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
          >
            <Box
              display={"flex"}
              sx={{
                boxShadow:
                  "0px 1px 10px rgba(26, 32, 36, 0.06), 0px 4px 5px rgba(26, 32, 36, 0.07), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)",
                borderRadius: "6px",
                minWidth: 300,
                zIndex: 1,
                fontSize: 13,
                color: "#586069",
                backgroundColor: Colors.white,
              }}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <CustomInputBase
                  inputRef={inputRef}
                  fullWidth={true}
                  onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                  autoFocus={true}
                />
                {filteredOptions.length > 0 && (
                  <FixedSizeList
                    height={Math.min(200, filteredOptions.length * 42)}
                    width={300}
                    itemCount={filteredOptions.length}
                    itemSize={42}
                  >
                    {renderRow}
                  </FixedSizeList>
                )}
                {filteredOptions.length <= 0 && (
                  <Box padding={"10px"}>
                    <Typography
                      fontSize="12px"
                      color={Colors.darkishGray}
                      paddingY="8px"
                    >
                      {"No items match your search terms."}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </React.Fragment>
  );
};
