import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { CloseoutLog } from "../../../types";

export interface CloseoutLogUpdate {
  closeoutLog?: CloseoutLog;
  success: boolean;
}

export interface CloseoutLogUpdateData {
  updateCloseoutLog: CloseoutLogUpdate;
}

export interface CloseoutLogUpdateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  attributes: Record<string, any>;
}

export const useUpdateCloseoutLog = createMutationHook<
CloseoutLogUpdateData,
CloseoutLogUpdateVariables
>(gql`
  mutation UpdateCloseoutLog(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $attributes: JSON!
  ) {
    updateCloseoutLog(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, attributes: $attributes }
    ) {
      closeoutLog {
        id
        name
        substantialCompletionDate
      }
      success
    }
  }
`);
