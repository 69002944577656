import {
  ApolloError,
  MutationTuple,
  QueryResult,
  QueryTuple,
  useLazyQuery,
  useMutation,
  useQuery,
  WatchQueryFetchPolicy
} from '@apollo/client';
import { DocumentNode } from 'graphql';

export function createQueryHook<TData, TVariables>(
  query: DocumentNode
): (variables: TVariables, skip?: boolean, fetchPolicy?: WatchQueryFetchPolicy) => QueryResult<TData, TVariables> {
  return function(variables, skip = false, fetchPolicy = 'cache-first') {
    return useQuery(query, { variables, fetchPolicy: fetchPolicy, skip: skip });
  };
}

export function createLazyQueryHook<TData, TVariables>(
  query: DocumentNode,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first'
): (variables: TVariables) => QueryTuple<TData, TVariables> {
  return function(variables) {
    return useLazyQuery(query, { variables, fetchPolicy: fetchPolicy });
  };
}

export function createMutationHook<TData, TVariables>(
  mutation: DocumentNode
): (
  variables: TVariables,
  onCompleted?: (data: TData) => void,
  onError?: (error: ApolloError) => void
) => MutationTuple<TData, TVariables> {
  return function(variables) {
    return useMutation(mutation, {
      variables,
    });
  };
}