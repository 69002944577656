import {
  NewAutomationStateContext,
  newAutomationInitialState,
  newAutomationReducer,
} from "./EditAutomationContext";
import React from "react";
import { useReducer } from "react";

export const NewAutomationContext =
  React.createContext<NewAutomationStateContext>({
    dispatch: () => undefined,
    state: newAutomationInitialState,
  });

export function NewAutomationContextProvider(props: {
  children: React.ReactNode;
}): JSX.Element {
  const [state, dispatch] = useReducer(
    newAutomationReducer,
    newAutomationInitialState
  );

  return (
    <NewAutomationContext.Provider value={{ dispatch, state }}>
      {props.children}
    </NewAutomationContext.Provider>
  );
}
