import React from 'react';
import { Popover, Box, PopoverProps } from '@mui/material';
import { Button } from './shared/Button';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { ManageUsersFilterRow } from './ManageUsersFilterRow';
import { ProcoreToolFilter } from '../contexts/NewPackageContext';
import { SelectFilterValue } from '../types/submittal';

interface IManageUsersFilterPopoverProps {
  allFilters: ProcoreToolFilter[];
  selectedFilterValues: Record<string, SelectFilterValue[]>;
  onResetFilters: () => void;
  onRemoveFilter: (key: string) => void;
  onChangeSelectedFilter: (oldKey: string, newKey: string) => void;
  onUpdateFilterValues: (key: string, values: SelectFilterValue[]) => void;
  onAppendNewFilter: (key: string) => void;
  onClose: () => void;
}

export const ManageUsersFilterPopover = ({
  allFilters,
  selectedFilterValues,
  onClose,
  onResetFilters,
  onRemoveFilter,
  onChangeSelectedFilter,
  onUpdateFilterValues,
  onAppendNewFilter,
  ...props
}: PopoverProps & IManageUsersFilterPopoverProps): JSX.Element => {
  // Starts with full list of filter fields and narrows down only to fields that aren't in filters yet
  const unusedFilterFields = Object.keys(selectedFilterValues).reduce((acc, nextItem) => {
    return acc.filter((item) => item.key !== nextItem);
  }, allFilters);

  const handleAppendItem = () => {
    if (unusedFilterFields.length === 0) { return; }

    onAppendNewFilter(unusedFilterFields[0].key);
  };

  return (
    <Popover {...props} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '20px 4px 8px 0px',
          width: '540px',
        }}
      >
        {Object.keys(selectedFilterValues).map((filterKey, i) => {
          return (
            <ManageUsersFilterRow
              key={`filter-row-${i}`}
              onRemoveFilter={onRemoveFilter}
              onChangeSelectedFilter={onChangeSelectedFilter}
              onUpdateFilterValues={onUpdateFilterValues}
              unusedFilterKeys={unusedFilterFields.map((item) => item.key)}
              filters={allFilters}
              filter={allFilters.find((filter) => filter.key === filterKey)}
              values={selectedFilterValues[filterKey]}
            />
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '4px 16px 12px 16px',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '8px',
        }}
      >
        <Button
          size="small"
          onClick={handleAppendItem}
          disabled={unusedFilterFields.length === 0}
          startIcon={
            <AddCircleOutline
              sx={{ '&.MuiSvgIcon-root': { fontSize: '16px' } }}
            />
          }
        >
          Add Filter
        </Button>
        <Button
          size="small"
          color="error"
          onClick={onResetFilters}
          startIcon={
            <DeleteOutline sx={{ '&.MuiSvgIcon-root': { fontSize: '16px' } }} />
          }
        >
          Clear All
        </Button>
      </Box>
    </Popover>
  );
};
