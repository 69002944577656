import {
  ListItemText,
  ListItem,
  Divider,
  Box,
  Typography,
  MenuItem,
  ListItemIcon,
  IconButton,
  SelectChangeEvent,
  ListItemIconProps,
  styled,
  IconButtonProps,
  ListItemTextProps,
  ListItemButton,
} from "@mui/material";
import React from "react";
import { NewPackageContext } from "../../contexts/NewPackageContextProvider";
import Loading from "../shared/Loading";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { AddFilterSelect, PrimarySelectInput } from "./styles";
import { Colors } from "../../styles";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  transformNodesToHierarchy,
  transformProcoreItemsToNodes,
  Node,
  flatten,
} from "../../utils/multi-tier-grouping";
import { BaseProcoreType, ProcoreToolGroupings } from "../../types";
import { groupingLabelToAttribute } from "../../utils/utils";

const GroupingListItemIcon = styled(ListItemIcon)<ListItemIconProps>(() => {
  return {
    minWidth: "unset",
    marginRight: "12px",
  }
});

const CheckedIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    height: '18px',
    width: '18px',
    background: '#007BFF',
    color: 'white',
    borderRadius: '2px',
    marginRight: '8px',
    padding: 0,
    '&:hover': {
      background: '#007BFF'
    }
  };
});

const UncheckedIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    height: '18px',
    width: '18px',
    background: 'white',
    border: '2px solid #CED4DA',
    marginRight: '8px',
    borderRadius: '2px',
    padding: 0,
    '&:hover': {
      background: 'white'
    }
  };
});

export interface GroupByOptionsProps {
  groupings: ProcoreToolGroupings[];
  selectedGroupings: string[];
  setSelectedGroupings: (selectedGroupings: string[]) => void;
}

const GroupByOptions = ({
  groupings,
  selectedGroupings,
  setSelectedGroupings,
}: GroupByOptionsProps) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value: newGroupings },
    } = event;

    setSelectedGroupings(newGroupings as string[]);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      margin={2}
    >
      <AddFilterSelect
        value={selectedGroupings}
        onChange={handleChange}
        multiple
        autoWidth={true}
        input={<PrimarySelectInput />}
        displayEmpty={true}
        disabled={groupings.length === 0}
        renderValue={(value: string[]) =>
          value.length === 0
            ? "Group Items By"
            : `Group Items By (${value.length})`
        }
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
      >
        {groupings.map((group) => {
          const indexOf = selectedGroupings.indexOf(group.attribute);
          const exists = indexOf !== -1;

          if (exists) {
            return (
              <MenuItem
                key={`grouping-menu-item-${group.label}`}
                value={group.attribute}
              >
                <CheckedIconButton
                  key={`grouping-icon-button-${group.label}`}
                  disableFocusRipple={true}
                  disableRipple={true}>
                  <Typography variant="body2">{indexOf + 1}</Typography>
                </CheckedIconButton>
                <ListItemText primary={group.label} />
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                key={`grouping-menu-item-${group.label}`}
                value={group.attribute}
              >
                <UncheckedIconButton
                  key={`grouping-icon-button-${group.label}`}
                  disableFocusRipple={true}
                  disableRipple={true}>
                </UncheckedIconButton>
                <ListItemText primary={group.label} />
              </MenuItem>
            );
          }
        })}
      </AddFilterSelect>
    </Box>
  );
};

const CustomListItemText = styled(ListItemText)<ListItemTextProps>(() => {
  return {
    color: Colors.darkGray,
    fontSize: "1.125em",
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
});

const Organize = (props: { toolTabIndex: number }): JSX.Element => {
  const { toolTabIndex } = props;
  const { state, dispatch } = React.useContext(NewPackageContext);
  const toolTabData = state.toolTabs[toolTabIndex];
  const [selectedGroupings, setSelectedGroupings] = React.useState<string[]>(
    toolTabData.selectedGroupings.map((g) => g.attribute)
  );
  const [openCollapse, setOpenCollapse] = React.useState<
    Record<string, boolean>
  >({});

  const dispatchSetSelectedGroupings = (selectedGroupings: string[]) => {
    const newSelectingGroupings: ProcoreToolGroupings[] = selectedGroupings.map(groupingAttribute => {
      return toolTabData.groupings.find(g => g.attribute === groupingAttribute);
    }).filter(g => g !== undefined);

    dispatch({
      type: "SET_SELECTED_GROUPINGS",
      value: {
        toolTabIndex: toolTabIndex,
        selectedGroupings: newSelectingGroupings,
      },
    });
  };

  const handleCategoryClick = (key: string) => {
    const open = openCollapse[key] || false;

    setOpenCollapse({
      ...openCollapse,
      [key]: !open,
    });
  };

  const handleSetSelectedGroupings = (items: string[]) => {
    setSelectedGroupings(items);
    dispatchSetSelectedGroupings(items);
  };

  function renderUngroupedRowItem(props: ListChildComponentProps<BaseProcoreType[]>) {
    const { index, style, data } = props;

    const procoreItem = data[index] as BaseProcoreType;

    return (
      <div style={style}>
        <ListItem key={`organize-list-item-${procoreItem.id}`}>
          <CustomListItemText
            disableTypography={true}
            key={`organize-list-item-text-${procoreItem.id}`}
          >
            {procoreItem.formattedTitle}
          </CustomListItemText>
        </ListItem>
        <Divider key={`organize-list-item-divider-${procoreItem.id}`} />
      </div>
    );
  }

  function renderGroupedRowItem(props: ListChildComponentProps<Node[]>) {
    const { index, style, data } = props;

    const node = data[index] as Node;

    switch (node.type) {
      case 'node':
        return (
          <div style={style}>
            <ListItemButton
              style={{ paddingLeft: `${node.level * 16}px` }}
              key={`grouping-category-${node.id}`}
              onClick={() => handleCategoryClick(node.id)}
            >
              <GroupingListItemIcon>
                <FolderOpenIcon htmlColor="#1A2024" />
              </GroupingListItemIcon>
              <ListItemText primary={node.label} />
              {openCollapse[node.id] || false ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Divider key={`grouping-list-item-divider-${node.id}`} />
          </div>
        );

      case 'procore_item':
        return (
          <div style={style}>
            <ListItem
              style={{ paddingLeft: `${node.level * 16}px` }}
              key={`organize-list-item-${node.id}`}>
              <CustomListItemText
                disableTypography={true}
                key={`organize-list-item-text-${node.id}`}
              >
                {node.label}
              </CustomListItemText>
            </ListItem>
            <Divider key={`organize-list-item-divider-${node.id}`} />
          </div>
        );
    }
  }

  const getFixedSizeListData = (): { itemData: BaseProcoreType[] | Node[], itemCount: number } => {
    if (selectedGroupings.length === 0) {
      return {
        itemData: toolTabData.procoreItems,
        itemCount: toolTabData.procoreItems.length
      };
    } else {
      const { nodes, procoreItemMap } = transformProcoreItemsToNodes(
        toolTabData.procoreItems,
        selectedGroupings.map((grouping) => {
          return groupingLabelToAttribute(toolTabData.selectedGroupings, toolTabData.procoreTool.engineName, grouping);
        })
      );
      const tree = transformNodesToHierarchy(nodes, procoreItemMap, undefined);
      const flatResult = flatten(tree).filter(node => {
        if (node.parentId === undefined) { return true; }

        return openCollapse[node.parentId as string] || false;
      });

      return { itemData: flatResult, itemCount: flatResult.length };
    }
  }

  const renderItems = (): JSX.Element => {
    const { itemData, itemCount } = getFixedSizeListData();
    const renderRow = selectedGroupings.length === 0 ? renderUngroupedRowItem : renderGroupedRowItem;

    return (
      <div style={{ display: "flex", height: "100%", width: "100%" }}>
        <div style={{ flex: "1 1 1px" }}>
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemData={itemData}
                itemCount={itemCount}
                itemSize={46}
              >
                {renderRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }

  return (
    <Box
      style={{
        border: "solid 1px #E4EBF0",
        borderRadius: "6px",
      }}
      display="flex"
      flexDirection="column"
      height={1}
      width={1}
    >
      <GroupByOptions
        groupings={toolTabData.groupings}
        selectedGroupings={selectedGroupings}
        setSelectedGroupings={handleSetSelectedGroupings}
      />
      <Divider />
      {toolTabData.loading ? (
          <Loading loadingLabel={"Loading items..."} />
        ) : renderItems()
      }
    </Box>
  );
};

export default Organize;
