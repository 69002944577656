import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface TemplateLogRequirementDeleteData {
  deleteTemplateLogRequirements: {
    success: boolean;
  }
}

export interface TemplateLogRequirementDeleteVariables {
  accountId: string;
  templateCloseoutLogId: number;
  templateLogRequirementIds: number[];
}

export const useDeleteTemplateLogRequirements = createMutationHook<
  TemplateLogRequirementDeleteData,
  TemplateLogRequirementDeleteVariables
>(gql`
  mutation DeleteTemplateLogRequirements(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $templateLogRequirementIds: [ID!]!
  ) {
    deleteTemplateLogRequirements(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, templateLogRequirementIds: $templateLogRequirementIds }
    ) {
      success
    }
  }
`);
