import { gql } from "@apollo/client";
import { ProcoreToolFilter } from "../../contexts/NewPackageContext";
import { FilterOptionInput, ProcoreToolGroupings } from "../../types";
import { ProcoreChangeEvent } from "../../types/change_event";

export type ProcoreChangeEventFilterData = {
  changeEventFilters: ProcoreToolFilter[];
  changeEventDefaultFilters: ProcoreToolFilter[];
  changeEventGroupings: ProcoreToolGroupings[];
};

export type ProcoreFilterChangeEventData = {
  changeEvents: ProcoreChangeEvent[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreChangeEventVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterChangeEventVariables = ProcoreChangeEventVariables & {
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const ChangeEventFragment = gql`
fragment ChangeEventFragment on ProcoreChangeEvent {
  id
  procoreServerId
  formattedTitle
  changeReason
  changeType
  number
  description
}
`;

export const filterProcoreChangeEventsQuery = gql`
query FilterProcoreChangeEvents($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  changeEvents(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ChangeEventFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${ChangeEventFragment}
`;

export const fetchPaginatedProcoreChangeEventsQuery = gql`
query FilterPaginatedProcoreChangeEvents($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  changeEvents(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ChangeEventFragment
  }
}
${ChangeEventFragment}
`;