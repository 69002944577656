import React from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  IconButton,
} from '@mui/material';
import AddLink from '@mui/icons-material/AddLink';
import { Typography } from './shared/Typography';
import { Colors, StyledDataGridPremium, StyledVerticalTab, StyledVerticalTabs, VertGrayDivider } from '../styles';
import { BodyCardHeaderBar } from './shared/BodyCardHeaderBar';
import { Button } from './shared/Button';
import { TabContext, TabPanel } from '@mui/lab';
import { FormattedModalItem, columnsFor, formatPayload, rowsFor } from './view-linked-log-requirements-helper';
import { useDeleteLogRequirementItems } from '../graphql/mutations/closeout/DeleteLogRequirementItems';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';
import { CLOSEOUT_TOOL_ORDER, isEmpty } from '../utils/utils';
import { CustomFooterStatusComponent } from './CustomFooterStatusComponent';
import { gridClasses } from '@mui/x-data-grid-premium';
import { ViewLinkedItemsEmptyState } from './ViewLinkedItemsEmptyState';
import UploadFile from '@mui/icons-material/UploadFile';
import Close from '@mui/icons-material/Close';

interface IViewLinkedLogRequirementItemsModal {
  accountId: string,
  procoreResourceBaseUrl: string,
  procoreProjectServerId: number,
  closeoutLogId: number,
  logRequirementId: number | undefined,
  onClickAddLinkedItems: () => void;
  onClickUploadFiles: (logRequirementId) => void;
  closeModal: () => void;
  isOpen: boolean;
}

export const ViewLinkedLogRequirementItemsModal = ({
  accountId,
  procoreResourceBaseUrl,
  procoreProjectServerId,
  closeoutLogId,
  logRequirementId,
  closeModal,
  isOpen,
  onClickAddLinkedItems,
  onClickUploadFiles
}: IViewLinkedLogRequirementItemsModal): JSX.Element => {
  const { state, dispatch } = React.useContext(CloseoutLogContext);
  const [currentToolTitle, setCurrentToolTitle] = React.useState<string>('');
  const [currentItem, setCurrentItem] = React.useState<{ isConfirmModalOpen: boolean, id: number | undefined, title: string | undefined }>({ isConfirmModalOpen: false, id: undefined, title: undefined });

  const logRequirementItemsByProcoreTool = React.useMemo(() => {
    if (logRequirementId === undefined) return {};
    return [...(state.logRequirementItems[logRequirementId] || [])]
      .sort((a, b) => {
        return CLOSEOUT_TOOL_ORDER.indexOf(a.engineName) - CLOSEOUT_TOOL_ORDER.indexOf(b.engineName)
      })
      .reduce((acc, nextLogRequirementItem) => {
      const procoreTool = state.engineNameToProcoreTool[nextLogRequirementItem.engineName];

      acc[procoreTool.title] ||= [];


      acc[procoreTool.title].push({
        id: nextLogRequirementItem.id,
        recordType: nextLogRequirementItem.recordType,
        recordServerId: nextLogRequirementItem.recordServerId,
        payload: formatPayload(nextLogRequirementItem.engineName, nextLogRequirementItem.payload),
        procoreTool: procoreTool,
      } as FormattedModalItem);

      return acc;
    }, {})
  }, [logRequirementId, state.logRequirementItems]);

  const procoreToolTitles = React.useMemo(() => {
    return logRequirementItemsByProcoreTool ? Object.keys(logRequirementItemsByProcoreTool) : []
  }, [logRequirementItemsByProcoreTool]);

  const [ deleteLogRequirementItems, { loading: loadingDeleteRequirementItems } ] = useDeleteLogRequirementItems({
    accountId: accountId,
    procoreProjectServerId: procoreProjectServerId,
    closeoutLogId: closeoutLogId,
    logRequirementId: logRequirementId,
    ids: [],
  });

  React.useEffect(() => {
    if (procoreToolTitles.length > 0 && isEmpty(currentToolTitle)) {
      setCurrentToolTitle(procoreToolTitles[0]);
    } else if (procoreToolTitles.length > 0 && !isEmpty(currentToolTitle) && !procoreToolTitles.includes(currentToolTitle)) {
      setCurrentToolTitle(procoreToolTitles[0]);
    } else if (procoreToolTitles.length === 0 && !isEmpty(currentToolTitle)) {
      setCurrentToolTitle('');
    }
  }, [procoreToolTitles]);

  const resetConfirmationDialogModal = () => setCurrentItem({ isConfirmModalOpen: false, id: undefined, title: undefined });

  const slots = React.useMemo(() => {
    return {
      footer: CustomFooterStatusComponent,
    }
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        disableScrollLock
        maxWidth="xs"
        open={currentItem.isConfirmModalOpen}
        onClose={() => resetConfirmationDialogModal()}
      >
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
        >
          <Typography typestyle="xl">Confirm Action</Typography>
          {currentItem.id && (
            <Typography>{`Are you sure you want to unlink ${currentItem.title} from this requirement?`}</Typography>
          )}
          <Box display="flex" justifyContent="end" gap="8px">
            <Button
              disabled={loadingDeleteRequirementItems}
              variant="outlined"
              buttonborderstyle="pill"
              onClick={() => resetConfirmationDialogModal()}
            >
              Cancel
            </Button>
            <Button
              disabled={loadingDeleteRequirementItems}
              variant="contained"
              buttonborderstyle="pill"
              onClick={() => {
                deleteLogRequirementItems({
                  variables: {
                    accountId: accountId,
                    procoreProjectServerId: procoreProjectServerId,
                    closeoutLogId: closeoutLogId,
                    logRequirementId: logRequirementId,
                    ids: [currentItem.id],
                  }
                }).then(( { data }) => {
                  if (data.deleteLogRequirementItems.success) {
                    dispatch({
                      type: 'REMOVE_LOG_REQUIREMENT_ITEMS',
                      value: {
                        logRequirementId: logRequirementId,
                        logRequirementItemIds: [currentItem.id]
                      }
                    });
                    resetConfirmationDialogModal();
                  }
                })
              }}
            >
              Remove Item
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog fullWidth disableScrollLock maxWidth="lg" open={isOpen} onClose={closeModal}>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '700px',
            padding: 0
          }}
        >
          <BodyCardHeaderBar title="Linked Items">
            <Box display="flex" gap="12px" alignItems='center'>
              <Box display="flex" gap="8px" alignItems='center'>
                <Button
                  variant="outlined"
                  onClick={onClickAddLinkedItems}
                  buttonborderstyle="pill"
                  startIcon={<AddLink fontSize='small'/>}
                >
                  Link Items from Procore
                </Button>
                <Button onClick={() => onClickUploadFiles(logRequirementId)} variant="outlined" buttonborderstyle="pill" startIcon={<UploadFile fontSize='small' />}>
                  Upload Files
                </Button>
              </Box>
              <VertGrayDivider orientation="vertical" sx={{height: '24px', paddingLeft: '8px'}}/>
              <IconButton onClick={closeModal}>
                <Close sx={{fontSize: '18px'}}/>
              </IconButton>
            </Box>
          </BodyCardHeaderBar>
          {Object.keys(logRequirementItemsByProcoreTool).length === 0 ? (
            <Box
              display="flex"
              padding="0px 28px 32px 28px"
              height="100%"
              width="100%"
            >
              <Box
                display="flex"
                borderRadius="8px"
                sx={{
                  border: `1px solid ${Colors.mediumLightGray}`,
                  overflow: 'hidden',
                  height: '100%',
                  width: '100%',
                }}
              >
                <ViewLinkedItemsEmptyState />
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              padding="0 28px 28px 28px"
              gap="20px"
              flex="1 1 1px"
              overflow={'auto'}
            >
              <TabContext value={currentToolTitle}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="14px"
                  sx={{
                    height: '100%',
                  }}
                >
                  <Typography color={Colors.darkishGray} typestyle="xs bold">
                    Tools
                  </Typography>
                  <StyledVerticalTabs
                    orientation="vertical"
                    value={currentToolTitle}
                    onChange={(_evt, value) => setCurrentToolTitle(value)}
                    sx={{ width: 120 }}
                  >
                  {procoreToolTitles.length <= 0 && <StyledVerticalTab value="" label="" />}
                    {procoreToolTitles.map((procoreToolTitle, index) => {
                      return (
                        <StyledVerticalTab key={`requirement-item-tool-tab-${index}`} value={procoreToolTitle} label={`${procoreToolTitle} (${((logRequirementItemsByProcoreTool || {})[procoreToolTitle] || []).length})`} />
                      );
                    })}
                  </StyledVerticalTabs>
                </Box>
              {
                procoreToolTitles.map((procoreToolTitle, index) => {
                  const rows = rowsFor(logRequirementItemsByProcoreTool[procoreToolTitle] || []);
                  return (
                    <TabPanel key={`requirement-items-tab-panel-${index}`} value={procoreToolTitle} sx={{
                      display: currentToolTitle === procoreToolTitle ? 'flex' : 'none',
                      width: '100%',
                      height: '100%',
                      padding: 0,
                      flexDirection: 'column',
                      overflow: 'auto',
                    }}>
                      <Box display="flex" flexDirection="column" flex={"1 1 1px"} overflow={"hidden"}>
                        <StyledDataGridPremium
                          hideFooter={false}
                          hideFooterRowCount={true}
                          hideFooterSelectedRowCount={true}
                          hideFooterPagination={true}
                          density='compact'
                          slots={slots}
                          slotProps={{
                            footer: { itemCount: rows.length },
                          }}
                          pinnedColumns={{ right: ['actions'] }}
                          disableColumnMenu={true}
                          getRowId={(row) => row.id}
                          disableRowSelectionOnClick={true}
                          columns={columnsFor(
                            logRequirementItemsByProcoreTool[procoreToolTitle] || [],
                            (engineName, recordServerId, query) => {
                              const queryParams = new URLSearchParams(query).toString();

                              if (isEmpty(queryParams)) {
                                window.open(`${procoreResourceBaseUrl}/${engineName}/${recordServerId}`, '_blank');
                              } else {
                                window.open(`${procoreResourceBaseUrl}/${engineName}/${recordServerId}?${queryParams}`, '_blank');
                              }
                            },
                            (id, title) => {
                              setCurrentItem({ isConfirmModalOpen: true, id, title });
                            })
                          }
                          sx={{
                            '& .MuiDataGrid-pinnedColumns': {
                              backgroundColor: `${Colors.white}`,
                              boxShadow: 'none',
                            },
                            '& .MuiDataGrid-pinnedColumnHeaders': {
                              backgroundColor: `${Colors.white}`,
                              border: 'none',
                              boxShadow: 'none',
                            },
                            '& .actionButton': {
                              opacity: 0,
                            },
                            [`& .${gridClasses.row}`]: {
                              "&:hover, &.Mui-hovered": {
                                ".actionButton": {
                                  opacity: 1
                                }
                              }
                            }
                          }}
                          rows={rows} />
                      </Box>
                    </TabPanel>
                  )
                })
              }
                <TabPanel value=""></TabPanel>
              </TabContext>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
