import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-premium";
import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CloseoutLogContext } from "../contexts/CloseoutLogContextProvider";
import { CloseoutLogState } from "../contexts/CloseoutLogContext";
import { ILogFileRequestAssignee } from "./BulkCreateLogFileRequestsModal";

export type SupportedTypes = ILogFileRequestAssignee;

export type IEditMultipleAutocompleteCellProps = {
  getOptions: (state: CloseoutLogState) => SupportedTypes[];
  isOptionEqualToValue: (option: SupportedTypes, value: SupportedTypes) => boolean;
  renderEditCellParams: GridRenderEditCellParams;
  groupBy?: (option: SupportedTypes) => string;
  getOptionLabel: (value: SupportedTypes) => string;
}

const EditMultipleAutocompleteCell = (props: IEditMultipleAutocompleteCellProps): JSX.Element => {
  const apiRef = useGridApiContext();
  const { state } = React.useContext(CloseoutLogContext);
  const { getOptions, renderEditCellParams } = props;
  const { id, value: valueProp, field } = renderEditCellParams;
  const [value, setValue] = useState<SupportedTypes[]>(valueProp || []);

  return <Autocomplete
    key={`column-cell-${id}`}
    value={value}
    size="small"
    multiple={true}
    fullWidth={true}
    disableCloseOnSelect={true}
    groupBy={props.groupBy}
    isOptionEqualToValue={props.isOptionEqualToValue}
    onChange={async (evt, value, reason) => {
      switch (reason) {
        case 'removeOption': {
          apiRef.current.setEditCellValue({ id, field, value: value, debounceMs: 100 });
          setValue(value);
          break;
        }

        case 'clear': {
          apiRef.current.setEditCellValue({ id, field, value: [], debounceMs: 100 });
          setValue([]);
          break;
        }

        case 'blur': {
          const isValid = await apiRef.current.setEditCellValue({ id, field, value: value, debounceMs: 100 });
          setValue(value);
          if (isValid) {
            apiRef.current.stopCellEditMode({ id, field });
          }
          break;
        }

        case 'selectOption': {
          await apiRef.current.setEditCellValue({ id, field, value: value, debounceMs: 100 });
          setValue(value);
          break;
        }

        default:
          break;
      }
    }}
    clearOnBlur={false}
    ListboxProps={{
      sx: {
        marginTop: 0,
        paddingTop: 0,
      }
    }}
    itemType=""
    renderInput={(params) => (
      <TextField {...params} size="small" key={params.id} label="" autoFocus />
    )}
    getOptionLabel={props.getOptionLabel}
    options={getOptions(state)}
  />
}

export default EditMultipleAutocompleteCell;