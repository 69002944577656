import React, { SyntheticEvent, useState } from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Button } from './shared/Button';
import { Typography } from './shared/Typography';
import { UnderlinedHeader } from './shared/UnderlinedHeader';
import AddCircle from '@mui/icons-material/AddCircle';
import { BodyCard } from './shared/BodyCard';
import { HeaderViewController, HeaderViewControllerTab, StyledVerticalTab, StyledVerticalTabs } from '../styles';

export const ComponentSheet = (): JSX.Element => {
  //functions for Select components
  const [selectValue, setSelectValue] = useState('');
  const [tabValue, setTabValue] = useState('tab 1')

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value);
  };

  return (
    <Box
      minHeight="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="20px"
    >
      <Box
        sx={{
          width: '100%',
          padding: '0px 24px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <BodyCard
          sx={{
            height: '100%',
            width: '900px',
            maxWidth: '100%',
            padding: '32px 36px',
            gap: '28px',
          }}
        >
          <Typography typestyle="xl">Components</Typography>
          <UnderlinedHeader>Typography</UnderlinedHeader>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <Typography typestyle="xxl">XXLarge</Typography>
            <Typography typestyle="xl">XLarge</Typography>
            <Typography typestyle="l">Large</Typography>
            <Typography typestyle="ml">Medium Large</Typography>
            <Typography typestyle="m bold">Medium Bold</Typography>
            <Typography typestyle="m">Medium</Typography>
            <Typography typestyle="s bold">Small Bold</Typography>
            <Typography typestyle="s">Small (Default)</Typography>
            <Typography typestyle="xs bold">XSmall Bold</Typography>
            <Typography typestyle="xs">XSmall</Typography>
            <Typography typestyle="xxs">XXSmall</Typography>
          </Box>
          <UnderlinedHeader>Buttons</UnderlinedHeader>
          <Typography typestyle="ml">Contained</Typography>
          <Box display="flex" gap="8px">
            <Button variant="contained" startIcon={<AddCircle />} size="small">
              Small
            </Button>
            <Button variant="contained" startIcon={<AddCircle />} size="medium">
              Medium
            </Button>
            <Button variant="contained" startIcon={<AddCircle />} size="large">
              Large
            </Button>
            <Button
              variant="contained"
              disabled
              startIcon={<AddCircle />}
              size="small"
            >
              Disabled
            </Button>
            <Button
              variant="contained"
              startIcon={<AddCircle />}
              disabled
              size="medium"
            >
              Disabled
            </Button>
            <Button
              variant="contained"
              startIcon={<AddCircle />}
              disabled
              size="large"
            >
              Disabled
            </Button>
          </Box>
          <Box display="flex" gap="8px">
            <Button
              buttonborderstyle='pill'
              variant="contained"
              startIcon={<AddCircle />}
              size="small"
            >
              Small
            </Button>

            <Button
              buttonborderstyle='pill'
              variant="contained"
              startIcon={<AddCircle />}
              size="medium"
            >
              Medium
            </Button>
            <Button
              buttonborderstyle='pill'
              variant="contained"
              startIcon={<AddCircle />}
              size="large"
            >
              Large
            </Button>
            <Button
              buttonborderstyle='pill'
              variant="contained"
              disabled
              startIcon={<AddCircle />}
              size="small"
            >
              Disabled
            </Button>
            <Button
              buttonborderstyle='pill'
              variant="contained"
              startIcon={<AddCircle />}
              disabled
              size="medium"
            >
              Disabled
            </Button>
            <Button
              buttonborderstyle='pill'
              variant="contained"
              startIcon={<AddCircle />}
              disabled
              size="large"
            >
              Disabled
            </Button>
          </Box>
          <Typography typestyle="ml">Outlined</Typography>
          <Box display="flex" gap="8px">
            <Button variant="outlined" startIcon={<AddCircle />} size="small">
              Small
            </Button>
            <Button variant="outlined" startIcon={<AddCircle />} size="medium">
              Medium
            </Button>
            <Button variant="outlined" startIcon={<AddCircle />} size="large">
              Large
            </Button>
            <Button
              variant="outlined"
              disabled
              startIcon={<AddCircle />}
              size="small"
            >
              Disabled
            </Button>
            <Button
              variant="outlined"
              startIcon={<AddCircle />}
              disabled
              size="medium"
            >
              Disabled
            </Button>
            <Button
              variant="outlined"
              startIcon={<AddCircle />}
              disabled
              size="large"
            >
              Disabled
            </Button>
          </Box>
          <Box display="flex" gap="8px">
            <Button
              buttonborderstyle='pill'
              variant="outlined"
              startIcon={<AddCircle />}
              size="small"
            >
              Small
            </Button>
            <Button
              buttonborderstyle='pill'
              variant="outlined"
              startIcon={<AddCircle />}
              size="medium"
            >
              Medium
            </Button>
            <Button
              buttonborderstyle='pill'
              variant="outlined"
              startIcon={<AddCircle />}
              size="large"
            >
              Large
            </Button>
            <Button
              buttonborderstyle='pill'
              variant="outlined"
              disabled
              startIcon={<AddCircle />}
              size="small"
            >
              Disabled
            </Button>
            <Button
              buttonborderstyle='pill'
              variant="outlined"
              startIcon={<AddCircle />}
              disabled
              size="medium"
            >
              Disabled
            </Button>
            <Button
              buttonborderstyle='pill'
              variant="outlined"
              startIcon={<AddCircle />}
              disabled
              size="large"
            >
              Disabled
            </Button>
          </Box>
          <UnderlinedHeader>Text Field</UnderlinedHeader>
          <Box display="flex" gap="8px">
            <TextField size="small" placeholder="Small"></TextField>
            <TextField placeholder="Large"></TextField>
          </Box>
          <UnderlinedHeader>Select</UnderlinedHeader>
          <Box display="flex" gap="8px">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">Age</InputLabel>

              <Select
                size="small"
                fullWidth
                value={selectValue}
                label="Small"
                onChange={handleSelectChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <UnderlinedHeader>Vertical Tab Menu</UnderlinedHeader>
          <StyledVerticalTabs orientation='vertical' value={tabValue} onChange={(evt: SyntheticEvent, newVal: string)=>setTabValue(newVal)}>
            <StyledVerticalTab label='Tab 1' value='tab 1'></StyledVerticalTab>
            <StyledVerticalTab label='Tab 2' value='tab 2'></StyledVerticalTab>
            <StyledVerticalTab label='Tab 3' value='tab 3'></StyledVerticalTab>
            <StyledVerticalTab label='Tab 4' value='tab 4'></StyledVerticalTab>
          </StyledVerticalTabs>
          <UnderlinedHeader>Header View Controller</UnderlinedHeader>
          <HeaderViewController value={tabValue} onChange={(evt: SyntheticEvent, newVal: string)=>setTabValue(newVal)}>
            <HeaderViewControllerTab label='Projects' value='tab 1'></HeaderViewControllerTab>
            <HeaderViewControllerTab label='Users' value='tab 2'></HeaderViewControllerTab>
            <HeaderViewControllerTab label='Templates' value='tab 3'></HeaderViewControllerTab>
            <HeaderViewControllerTab label='Automations' value='tab 4'></HeaderViewControllerTab>
          </HeaderViewController>
        </BodyCard>
      </Box>
    </Box>
  );
};
