import React, { useState } from 'react';
import { StyledVerticalTab } from '../../styles';
import Box from '@mui/material/Box';
import { IconButton, TabProps } from '@mui/material';
import { Close } from '@mui/icons-material';

interface IVerticalTabWithRemoveProps {
  onRemove: () => void;
}

export const VerticalTabWithRemove = ({
  onRemove,
  ...rest
}: IVerticalTabWithRemoveProps & TabProps): JSX.Element => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <StyledVerticalTab {...rest} sx={{ flex: '1 1 0' }}/>
      {hover &&
        <IconButton
          onClick={onRemove}
          size="small"
          sx={{ position: 'absolute', right: '0px' }}
        >
          <Close sx={{ fontSize: '16px' }} />
        </IconButton>
      }
    </Box>
  );
};
