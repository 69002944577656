import React, { useState } from "react";
import { SelectFilterValue } from "../../types/submittal";
import {
  ListItemText,
  Box,
  MenuItem,
  IconButton,
  IconButtonProps,
  styled,
} from "@mui/material";
import { Colors } from "../../styles";
import {
  FilterSelect,
  FilterSelectInput,
} from "../package/styles";
import CloseIcon from "@mui/icons-material/Close";
import { isEmpty } from "../../utils/utils";

export const RemoveFilterIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    width: "20px",
    height: "20px",
    background: Colors.lightishGray,
    position: "absolute",
    zIndex: 1,
    "&:hover": {
      background: Colors.mediumGray,
    },
  };
});

export const FilterSingleSelect = (props: {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  value: string;
  selectFilterValues: SelectFilterValue[];
  onChange: (
    event: React.ChangeEvent<{ value: unknown }>,
    filterLabel: string
  ) => void;
  filterLabel: string;
  removeFilter: () => void;
}): JSX.Element => {
  const {
    filterLabel,
    value,
    open,
    onOpen,
    onClose,
    selectFilterValues,
    onChange,
    removeFilter,
  } = props;

  const [showRemoveFilterButton, setShowRemoveFilterButton] =
    useState(false);

  return (
    <Box
      display="block"
      position="relative"
      style={{ marginRight: "16px" }}
      onBlur={() => setShowRemoveFilterButton(false)}
      onMouseEnter={() => setShowRemoveFilterButton(true)}
      onMouseLeave={() => setShowRemoveFilterButton(false)}
    >
      <FilterSelect
        key={`filters-single-select-${filterLabel}`}
        multiple={false}
        onFocus={() => setShowRemoveFilterButton(false)}
        open={open}
        onOpen={() => {
          setShowRemoveFilterButton(false);
          onOpen();
        }}
        value={value}
        onChange={(evt) => {
          // TODO: Investigate
          onChange(evt, filterLabel);
        }}
        onClose={() => onClose()}
        input={<FilterSelectInput />}
        autoWidth={true}
        displayEmpty={true}
        renderValue={(value: string[]) =>
          isEmpty(value) ? filterLabel : `${filterLabel} (1)`
        }
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          PaperProps: {
            sx: {
              maxHeight: '400px'
            }
          }
        }}
      >
        {selectFilterValues.map((filterValue) => {
          return (
            <MenuItem
              key={`filter-values-${filterValue.id}`}
              value={filterValue.value}
            >
              <ListItemText primary={filterValue.value} />
            </MenuItem>
          );
        })}
      </FilterSelect>
      {!open && showRemoveFilterButton && (
        <RemoveFilterIconButton
          style={{
            top: "-8px",
            right: "-10px",
          }}
          onClick={() => removeFilter()}
        >
          <CloseIcon
            style={{ height: "14px", width: "14px", color: Colors.darkGray }}
          />
        </RemoveFilterIconButton>
      )}
    </Box>
  );
};
