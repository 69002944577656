import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const ProcoreIconWhite = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon width="17" height="16" viewBox="0 0 17 16" {...props}>
      <g clipPath="url(#clip0_1_957)">
        <path
          d="M12.8556 10.1438L11.2689 13.024H5.73112L2.96446 8L5.73112 2.97604H11.2689L12.8556 5.85623H16.5V3.26359C16.5149 2.82956 16.4447 2.39694 16.2936 1.99275C16.1425 1.58855 15.9139 1.22145 15.622 0.914337C15.3301 0.607228 14.9811 0.366709 14.5969 0.207785C14.2127 0.0488619 13.8015 -0.0250541 13.3889 -0.00935055H3.61112C3.19855 -0.0250541 2.78733 0.0488619 2.40312 0.207785C2.01891 0.366709 1.66995 0.607228 1.37803 0.914337C1.0861 1.22145 0.857477 1.58855 0.706411 1.99275C0.555346 2.39694 0.485084 2.82956 0.500012 3.26359V12.7364C0.485084 13.1704 0.555346 13.6031 0.706411 14.0073C0.857477 14.4115 1.0861 14.7786 1.37803 15.0857C1.66995 15.3928 2.01891 15.6333 2.40312 15.7922C2.78733 15.9511 3.19855 16.0251 3.61112 16.0094H13.3889C13.8015 16.0251 14.2127 15.9511 14.5969 15.7922C14.9811 15.6333 15.3301 15.3928 15.622 15.0857C15.9139 14.7786 16.1425 14.4115 16.2936 14.0073C16.4447 13.6031 16.5149 13.1704 16.5 12.7364V10.1438H12.8556Z"
          fill="#FFFFFF"
        />
        <path
          d="M7 5.27645L5.5 8L7 10.7236H10L11.5022 8L10 5.27645H7Z"
          fill="#FFFFFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_957">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ProcoreIconWhite;
