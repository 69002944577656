import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import { Colors } from "../../styles";
import { Typography } from "../shared/Typography";

export interface IAutomationModuleHeaderProps {
  title: string;
  subtitle: string;
  icon?: ReactNode;
}

const AutomationModuleHeader = ({title, subtitle, icon}: IAutomationModuleHeaderProps) => {
  return (
    <Box
      sx={{
        padding: "12px 16px",
        borderRadius: "8px 8px 0px 0px",
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        backgroundColor: Colors.lightestGray,
        borderBottom: `1px solid ${Colors.mediumLightGray}`,
      }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '36px',
        height: '36px',
        borderRadius: '18px',
        border: `1px solid ${Colors.mediumLightGray}`,
        backgroundColor: Colors.white,
      }}>
        {icon}
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}>
        <Typography typestyle="s bold">{title}</Typography>
        <Typography typestyle="xs" sx={{color: Colors.darkishGray}}>{subtitle}</Typography>
      </Box>
    </Box>
  );
};

export default AutomationModuleHeader;
