import { gql, QueryTuple, useLazyQuery } from "@apollo/client";
import { AccountCompanyData } from "../../types";

interface AccountCompanyVariables {
  accountId: string;
}

export const GetAccountCompaniesQuery = gql`
query GetAccountCompanies($accountId: ID!) {
  procoreCompanies(accountId: $accountId) {
    procoreServerId
    name
    enabled
  }
}
`;

export const useGetAccountCompanies = (
  accountId: string
): QueryTuple<AccountCompanyData, AccountCompanyVariables> =>
  useLazyQuery<AccountCompanyData, AccountCompanyVariables>(
    GetAccountCompaniesQuery,
    {
      variables: {
        accountId: accountId,
      },
      fetchPolicy: "cache-first",
    }
  );
