import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import idx from "idx";
import React, { useState } from "react";
import {
  Action,
  ProcoreItem,
  ProcoreToolFilter,
  ToolTabData,
} from "../../contexts/NewPackageContext";
import { NewPackageContext } from "../../contexts/NewPackageContextProvider";
import { Colors, LinkWithDisabled } from "../../styles";
import { isSelectFilter, SelectFilterValue } from "../../types/submittal";
import Loading from "../shared/Loading";
import {
  FilterSelect,
  FilterSelectInput,
  UncheckedCheckBox,
  ManualSelectCheckbox,
  CheckedCheckBox,
  Search,
  SearchIconWrapper,
  StyledInputBase,
  SearchCloseIcon,
  FilterListItemText,
  SearchCloseIconButton,
} from "./styles";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { BaseProcoreType, FilterOptionInput } from "../../types";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Waypoint } from "react-waypoint";
import SearchIcon from '@mui/icons-material/Search';
import { ProcoreFilterPhotoData, ProcoreFilterPhotoVariables, fetchPaginatedProcorePhotosQuery } from "../../graphql/queries/GetProcorePhotos";
import { ApolloClient, DocumentNode, OperationVariables, useApolloClient } from "@apollo/client";
import { PAGINATION, TOOL_ENGINE_NAMES, checkedItems, itemTypeFrom, procoreFilterToInputFilters, selectedItemKey, showDescriptionFor, transformSelectedItemsFromServerIds } from "../../utils/utils";
import { AccountProjectContext } from "../../contexts/AccountProjectContextProvider";
import { ProcoreFilterSubmittalData, ProcoreFilterSubmittalVariables, fetchPaginatedProcoreSubmittalsQuery } from "../../graphql/queries/GetProcoreSubmittals";
import { SelectedItem } from "../../graphql/mutations/UpdatePackage";
import { ProcoreFilterPunchItemData, ProcoreFilterPunchItemVariables, fetchPaginatedProcorePunchItemsQuery } from "../../graphql/queries/GetProcorePunchItems";
import { ProcoreFilterActionPlanData, ProcoreFilterActionPlanVariables, fetchPaginatedProcoreActionPlansQuery } from "../../graphql/queries/GetProcoreActionPlans";
import { ProcoreFilterRfiData, ProcoreFilterRfiVariables, fetchPaginatedProcoreRfisQuery } from "../../graphql/queries/GetProcoreRfis";
import { ProcoreFilterCorrespondenceData, ProcoreFilterCorrespondenceVariables, fetchPaginatedProcoreCorrespondenceQuery } from "../../graphql/queries/GetProcoreCorrespondence";
import { ProcoreFilterObservationData, ProcoreFilterObservationVariables, fetchPaginatedProcoreObservationsQuery } from "../../graphql/queries/GetProcoreObservations";
import { ProcoreFilterProcoreFormData, ProcoreFilterProcoreFormVariables, fetchPaginatedProcoreFormsQuery } from "../../graphql/queries/GetProcoreForms";
import { ProcoreFilterSpecificationData, ProcoreFilterSpecificationVariables, fetchPaginatedProcoreSpecificationsQuery } from "../../graphql/queries/GetProcoreSpecifications";
import { ProcoreFilterIncidentData, ProcoreFilterIncidentVariables, fetchPaginatedProcoreIncidentsQuery } from "../../graphql/queries/GetProcoreIncidents";
import { ProcoreFilterInspectionData, ProcoreFilterInspectionVariables, fetchPaginatedInspectionsQuery } from "../../graphql/queries/GetProcoreInspections";
import { ProcoreFilterDrawingData, ProcoreFilterDrawingVariables, fetchPaginatedProcoreDrawingsQuery } from "../../graphql/queries/GetProcoreDrawings";
import { AccountProjectState } from "../../contexts/AccountProjectContext";
import { ProcoreFilterMeetingData, ProcoreFilterMeetingVariables, fetchPaginatedProcoreMeetingsQuery } from "../../graphql/queries/GetProcoreMeetings";
import { FilterAutocomplete } from "../shared/FilterAutocomplete";
import { FilterDateRangeSelect } from "../shared/FilterDateRangeSelect";
import { Button } from "../shared/Button";
import { AddFilterAutocomplete } from "../shared/AddFilterAutocomplete";
import { formatISO } from "date-fns";
import { ProcoreFilterDailyLogData, ProcoreFilterDailyLogVariables, fetchPaginatedProcoreDailyLogsQuery } from "../../graphql/queries/GetProcoreDailyLogs";
import { fetchPaginatedProcoreChangeEventsQuery, ProcoreFilterChangeEventData, ProcoreFilterChangeEventVariables } from "../../graphql/queries/GetProcoreChangeEvents";
import { fetchPaginatedProcoreManagedEquipmentsQuery, ProcoreFilterManagedEquipmentData, ProcoreFilterManagedEquipmentVariables } from "../../graphql/queries/GetProcoreManagedEquipment";
import { fetchPaginatedProcoreBidsQuery, ProcoreFilterBidData, ProcoreFilterBidVariables } from "../../graphql/queries/GetProcoreBids";
import { fetchPaginatedProcoreProjectDirectoryUsersQuery, ProcoreFilterProjectDirectoryUserData, ProcoreFilterProjectDirectoryUserVariables } from "../../graphql/queries/GetProcoreProjectDirectoryUsers";
import { fetchPaginatedProcoreProjectVendorsQuery, ProcoreFilterProjectVendorData, ProcoreFilterProjectVendorVariables } from "../../graphql/queries/GetProcoreProjectVendors";

function fetchPaginatedItems<TData, TVariables = OperationVariables>(
  query: DocumentNode,
  toolTabIndex: number,
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>,
  dispatch: (action: Action) => void,
  variables: () => TVariables,
  onSuccess: (value: TData) => ProcoreItem[]) {

  dispatch({
    type: "SET_TOOL_TAB_LOADING_MORE",
    value: {
      toolTabIndex: toolTabIndex,
      loadingMore: true,
    },
  });

  client
    .query<TData, TVariables>({
      query: query,
      variables: variables(),
      fetchPolicy: "cache-first",
    })
    .then((result) => {
      dispatch({
        type: "APPEND_PROCORE_ITEMS",
        value: {
          toolTabIndex: toolTabIndex,
          procoreItems: onSuccess(result.data),
        },
      });
      dispatch({
        type: "SET_TOOL_TAB_LOADING_MORE",
        value: {
          toolTabIndex: toolTabIndex,
          loadingMore: false,
        },
      });
    })
    .catch((err) => {
      console.log(err);

      dispatch({
        type: "SET_TOOL_TAB_LOADING_MORE",
        value: {
          toolTabIndex: toolTabIndex,
          loadingMore: false,
        },
      });

      dispatch({
        type: "SET_TOOL_TAB_FAILED_TO_FETCH_MORE",
        value: {
          toolTabIndex: toolTabIndex,
          failedToFetchMore: true,
        },
      });
    });
}

const queryProcoreItemForTool = (
  toolTabData: ToolTabData,
  toolTabIndex: number,
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>,
  dispatch: (action: Action) => void,
  accountProjectState: AccountProjectState,
  filters: FilterOptionInput[]
) => {
  if (toolTabData.procoreTool === null || toolTabData.procoreTool === undefined) {
    return;
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.ACTION_PLANS) {
    fetchPaginatedItems<ProcoreFilterActionPlanData, ProcoreFilterActionPlanVariables>(
      fetchPaginatedProcoreActionPlansQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.actionPlans,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.PHOTOS) {
    fetchPaginatedItems<ProcoreFilterPhotoData, ProcoreFilterPhotoVariables>(
      fetchPaginatedProcorePhotosQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.photos,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.DAILY_LOG) {
    fetchPaginatedItems<ProcoreFilterDailyLogData, ProcoreFilterDailyLogVariables>(
      fetchPaginatedProcoreDailyLogsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.dailyLogs,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.SUBMITTALS) {
    fetchPaginatedItems<ProcoreFilterSubmittalData, ProcoreFilterSubmittalVariables>(
      fetchPaginatedProcoreSubmittalsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.submittals,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.DIRECTORY) {
    if (toolTabData.procoreTool.directoryType === "vendors") {
      fetchPaginatedItems<ProcoreFilterProjectVendorData, ProcoreFilterProjectVendorVariables>(
        fetchPaginatedProcoreProjectVendorsQuery,
        toolTabIndex,
        client,
        dispatch,
        () => {
          return {
            accountId: accountProjectState.accountId,
            procoreProjectServerId: accountProjectState.procoreProjectServerId,
            filters: filters,
            searchValue: toolTabData.searchValue,
            procoreToolId: toolTabData.procoreTool.id,
            limit: PAGINATION.limit,
            offset: toolTabData.procoreItems.length
          };
        },
        (data) => data.projectVendors,
      )
    } else if (toolTabData.procoreTool.directoryType === "users") {
      fetchPaginatedItems<ProcoreFilterProjectDirectoryUserData, ProcoreFilterProjectDirectoryUserVariables>(
        fetchPaginatedProcoreProjectDirectoryUsersQuery,
        toolTabIndex,
        client,
        dispatch,
        () => {
          return {
            accountId: accountProjectState.accountId,
            procoreProjectServerId: accountProjectState.procoreProjectServerId,
            filters: filters,
            searchValue: toolTabData.searchValue,
            procoreToolId: toolTabData.procoreTool.id,
            limit: PAGINATION.limit,
            offset: toolTabData.procoreItems.length
          };
        },
        (data) => data.projectDirectoryUsers,
      )
    }
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.PUNCH_LIST) {
    fetchPaginatedItems<ProcoreFilterPunchItemData, ProcoreFilterPunchItemVariables>(
      fetchPaginatedProcorePunchItemsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.punchItems,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.RFIS) {
    fetchPaginatedItems<ProcoreFilterRfiData, ProcoreFilterRfiVariables>(
      fetchPaginatedProcoreRfisQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.rfis,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.MEETINGS) {
    fetchPaginatedItems<ProcoreFilterMeetingData, ProcoreFilterMeetingVariables>(
      fetchPaginatedProcoreMeetingsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.meetings,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.CORRESPONDENCE) {
    fetchPaginatedItems<ProcoreFilterCorrespondenceData, ProcoreFilterCorrespondenceVariables>(
      fetchPaginatedProcoreCorrespondenceQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.correspondence,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.OBSERVATIONS) {
    fetchPaginatedItems<ProcoreFilterObservationData, ProcoreFilterObservationVariables>(
      fetchPaginatedProcoreObservationsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.observations,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.FORMS) {
    fetchPaginatedItems<ProcoreFilterProcoreFormData, ProcoreFilterProcoreFormVariables>(
      fetchPaginatedProcoreFormsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.forms,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.SPECIFICATIONS) {
    fetchPaginatedItems<ProcoreFilterSpecificationData, ProcoreFilterSpecificationVariables>(
      fetchPaginatedProcoreSpecificationsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.specifications,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.INCIDENTS) {
    fetchPaginatedItems<ProcoreFilterIncidentData, ProcoreFilterIncidentVariables>(
      fetchPaginatedProcoreIncidentsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.incidents,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.CHANGE_EVENTS) {
    fetchPaginatedItems<ProcoreFilterChangeEventData, ProcoreFilterChangeEventVariables>(
      fetchPaginatedProcoreChangeEventsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.changeEvents,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.EQUIPMENT) {
    fetchPaginatedItems<ProcoreFilterManagedEquipmentData, ProcoreFilterManagedEquipmentVariables>(
      fetchPaginatedProcoreManagedEquipmentsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.managedEquipment,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.BIDDING) {
    fetchPaginatedItems<ProcoreFilterBidData, ProcoreFilterBidVariables>(
      fetchPaginatedProcoreBidsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.bids,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.INSPECTIONS) {
    fetchPaginatedItems<ProcoreFilterInspectionData, ProcoreFilterInspectionVariables>(
      fetchPaginatedInspectionsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.inspections,
    )
  }

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.DRAWINGS) {
    fetchPaginatedItems<ProcoreFilterDrawingData, ProcoreFilterDrawingVariables>(
      fetchPaginatedProcoreDrawingsQuery,
      toolTabIndex,
      client,
      dispatch,
      () => {
        return {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: toolTabData.searchValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTabData.procoreItems.length
        };
      },
      (data) => data.drawings,
    )
  }
};

export interface FilterOptionsProps {
  toolTabIndex: number;
  searchValue: string;
  onSearchValueChanged: (value: string) => void;
  onSearchValueEntered: (value?: string) => void;
  filters: ProcoreToolFilter[];
  toolTabData: ToolTabData;
  onFilterOptionSelectionChange: ({
    selectedFilters,
  }: {
    selectedFilters?: ProcoreToolFilter[];
  }) => void;
  onSelectAllButtonClicked: () => void;
}

const ToolSelectionRequired = (props: {
  toolTabIndex: number;
}): JSX.Element => {
  const { state, dispatch } = React.useContext(NewPackageContext);
  const usedTools = state.toolTabs
    .filter((t) => t.procoreTool !== null)
    .map((t) => t.procoreTool);

  const handleToolChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
    const procoreToolServerId = event.target.value as number;

    const procoreTool = state.procoreTools.find(
      (tool) => tool.procoreServerId === procoreToolServerId
    );
    if (procoreTool) {
      dispatch({
        type: "SET_TOOL",
        value: {
          toolTabIndex: props.toolTabIndex,
          procoreTool: procoreTool,
        },
      });
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{ backgroundColor: Colors.lightestGray }}
      height={1}
      width={1}
    >
      <Typography variant="h6" style={{ marginBottom: "20px" }}>
        {"Select a tool to query"}
      </Typography>
      <FilterSelect
        key={`procore-tool-filter-select`}
        input={<FilterSelectInput />}
        autoWidth={true}
        style={{ marginBottom: "16px" }}
        displayEmpty={true}
        // TODO: Investigate
        onChange={handleToolChanged}
        value={""}
        defaultValue={"Select a tool..."}
        renderValue={() => `Select a tool...`}
        label="Tool"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          PaperProps:{
           sx: {
            maxHeight: '400px'
           }
          }
        }}
      >
        {state.procoreTools.map((procoreTool) => {
          const disabledMenuItem = usedTools.some(t => t.procoreServerId === procoreTool.procoreServerId);
          return (
            <MenuItem
              disabled={disabledMenuItem}
              key={procoreTool.procoreServerId}
              value={procoreTool.procoreServerId}
            >
              {procoreTool.title}
            </MenuItem>
          );
        })}
      </FilterSelect>
    </Box>
  );
};

const FilterOptions = ({
  filters,
  toolTabIndex,
  toolTabData,
  searchValue,
  onSearchValueChanged,
  onSearchValueEntered,
  onFilterOptionSelectionChange,
  onSelectAllButtonClicked,
}: FilterOptionsProps) => {
  const [focusSearch, setFocusSearch] = useState(false);
  const inputBaseRef = React.useRef(null);
  const { dispatch } = React.useContext(NewPackageContext);
  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    toolTabData.selectedFilters.map((f) => f.label)
  );
  const everyFilteredProcoreItemServerIdSelected = (idx(toolTabData, t => t.filteredProcoreItemServerIds)).every(procoreItemServerId => {
    const selectedItem = toolTabData.selectedItems[selectedItemKey(toolTabData.procoreTool.engineName, procoreItemServerId, toolTabData.procoreTool.directoryType)] || { state: 'unchecked' }
    return selectedItem.state === 'checked'
  });

  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    Record<string, string[]>
  >(
    toolTabData.selectedFilters.reduce((acc, current) => {
      if (isSelectFilter(current)) {
        acc[current.label] = current.values.map((v) => v.value);
        return acc;
      } else {
        const values = [];

        if (current?.value?.startDate) {
          values.push(formatISO(current.value.startDate))
        }

        if (current?.value?.endDate) {
          values.push(formatISO(current.value.endDate));
        }
        acc[current.label] = values;
        return acc;
      }
    }, {})
  );

  const dispatchSetSelectedFilters = (
    selectedFilters: string[],
    selectedFilterOptions: Record<string, string[]>,
    triggerFilterOptionSelectionChange = false
  ) => {
    const newSelectedFilters: ProcoreToolFilter[] = selectedFilters.map(
      (selectedFilter) => {
        const filter: ProcoreToolFilter = filters.find(
          (f) => f.label === selectedFilter
        );

        if (isSelectFilter(filter)) {
          return {
            ...filter,
            values: filter.values.filter((v) =>
              (selectedFilterOptions[filter.label] || []).includes(v.value)
            ),
          };
        } else {
          const [startDate, endDate] =
            selectedFilterOptions[filter.label] || [];
          return {
            ...filter,
            value: {
              startDate: startDate ? new Date(startDate) : undefined,
              endDate: endDate ? new Date(endDate) : undefined,
            },
          };
        }
      }
    );

    dispatch({
      type: "SET_SELECTED_FILTERS",
      value: {
        toolTabIndex: toolTabIndex,
        selectedFilters: newSelectedFilters,
      },
    });

    if (triggerFilterOptionSelectionChange) {
      onFilterOptionSelectionChange({ selectedFilters: newSelectedFilters });
    }
  };

  const handleAddFilterSelectChanged = (
    newFilters: string[],
    triggerFilterOptionSelectionChange = false
  ) => {
    const selectedFilterLength = selectedFilters.length;

    // If there are new filters then we can close the AddFilterSelect
    // set all other existings filters open to false and the new 1 to true
    // We'll have to manually manage open/close state for each filter.
    if (newFilters.length > selectedFilterLength) {
      const newFilterOpenState = newFilters.reduce((acc, filterLabel) => {
        const alreadyExists = selectedFilters.includes(filterLabel);
        acc[filterLabel] = !alreadyExists;

        return acc;
      }, {} as Record<string, boolean>);

      dispatch({
        type: "SET_FILTER_OPEN_STATE",
        value: {
          toolTabIndex: toolTabIndex,
          filterOpenState: newFilterOpenState,
        },
      });
    } else {
      const newFilterOpenState = newFilters.reduce((acc, filterLabel) => {
        acc[filterLabel] = false;
        return acc;
      }, {} as Record<string, boolean>);

      dispatch({
        type: "SET_FILTER_OPEN_STATE",
        value: {
          toolTabIndex: toolTabIndex,
          filterOpenState: newFilterOpenState,
        },
      });
    }

    setSelectedFilters(newFilters);
    const newSelectedFilterOptions = newFilters.reduce((acc, filterLabel) => {
      acc[filterLabel] = selectedFilterOptions[filterLabel] || [];

      return acc;
    }, {} as Record<string, string[]>);

    setSelectedFilterOptions(newSelectedFilterOptions);

    dispatchSetSelectedFilters(
      newFilters,
      newSelectedFilterOptions,
      triggerFilterOptionSelectionChange
    );
  };

  const handleSelectedFilterOptionsChanged = (
    values: SelectFilterValue[],
    filterLabel: string
  ) => {
    const state = {
      ...selectedFilterOptions,
      [filterLabel]: values.map(v => v.value),
    };
    setSelectedFilterOptions(state);

    dispatchSetSelectedFilters(selectedFilters, state);
  };

  const onFilterClose = (filterLabel: string) => {
    dispatch({
      type: "SET_FILTER_OPEN_STATE",
      value: {
        toolTabIndex: toolTabIndex,
        filterOpenState: {
          ...toolTabData.filterOpenState,
          [filterLabel]: false
        },
      },
    });

    onFilterOptionSelectionChange({ selectedFilters: undefined });
  };

  const onFilterOpen = (filterLabel: string) => {
    dispatch({
      type: "SET_FILTER_OPEN_STATE",
      value: {
        toolTabIndex: toolTabIndex,
        filterOpenState: {
          ...toolTabData.filterOpenState,
          [filterLabel]: true
        },
      },
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      flexWrap="wrap"
      margin={1}
      style={{
        gap: '4px'
      }}
    >
      <Button
        disabled={toolTabData.loading}
        onClick={() => onSelectAllButtonClicked()}
        variant="outlined"
        disableElevation={true}
        size="medium"
        style={{
          minHeight: '39px',
          marginRight: "12px"
        }}
      >
        {everyFilteredProcoreItemServerIdSelected ? "Deselect Filtered" : "Select Filtered"}
      </Button>
      <Divider orientation="vertical" style={{ height: '39px'}} />
      <Search>
        <SearchIconWrapper disabled={false}>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          isopen={(focusSearch || Boolean((searchValue || '').trim())).toString()}
          inputRef={inputBaseRef}
          fullWidth={true}
          placeholder="Search..."
          value={searchValue}
          onKeyDown={(evt) => {
            if (evt.key.toLowerCase() === "enter") {
              inputBaseRef?.current?.blur();
              onSearchValueEntered()
            }
          }}
          endAdornment={
            (((searchValue || '').trim())) ? (
              <SearchCloseIconButton
                  size={'small'}
                  onClick={() => {
                    onSearchValueChanged('');
                    onSearchValueEntered('');
                  }}
                  style={{ visibility: ((searchValue || '').trim()) ? 'visible' : 'hidden' }}>
                <SearchCloseIcon />
              </SearchCloseIconButton>
            ) : null
          }
          onChange={(evt) => {
            onSearchValueChanged(evt.target.value)
          }}
          onFocus={() => { setFocusSearch(true)}}
          onBlur={() => {
            setFocusSearch(false);
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>
      <AddFilterAutocomplete
        options={filters.filter((f) => f?.values?.length > 0 || f.type === "daterange").map(f => f.label)}
        selectedOptions={selectedFilters}
        key={`grouping-select-${toolTabData.procoreTool.id}`}
        onClose={() => { /** Do Nothing */ }}
        onChange={(values) => {
          handleAddFilterSelectChanged(values, false)
        }}
        filterLabel={"Add Filters"}
      />
      {selectedFilters.map((filterName) => {
        const filter = filters.filter((f) => f.label === filterName)[0];

        if (filter) {
          switch (filter.type) {
            case "select": {
              if (filter.multiple == false) {
                return (
                  <FilterAutocomplete
                    disabled={false}
                    key={`select-single-${filter.label}`}
                    filterLabel={filter.label}
                    open={toolTabData.filterOpenState[filter.label] || false}
                    onOpen={() => onFilterOpen(filter.label)}
                    onClose={() => {
                      dispatch({
                        type: "SET_FILTER_OPEN_STATE",
                        value: {
                          toolTabIndex: toolTabIndex,
                          filterOpenState: {
                            ...toolTabData.filterOpenState,
                            [filter.label]: false
                          },
                        },
                      });
                    }}
                    removeFilter={() => {
                      handleAddFilterSelectChanged(
                        selectedFilters.filter((f) => f !== filter.label),
                        true
                      );
                    }}
                    options={filter.values || []}
                    selectedOptions={toolTabData.selectedFilters.find(f => f.label === filter.label)?.values || []}
                    onChange={(values) => {
                      const state = {
                        ...selectedFilterOptions,
                        [filter.label]: values.map(v => v.value),
                      };
                      setSelectedFilterOptions(state);

                      dispatch({
                        type: "SET_FILTER_OPEN_STATE",
                        value: {
                          toolTabIndex: toolTabIndex,
                          filterOpenState: {
                            ...toolTabData.filterOpenState,
                            [filter.label]: false
                          },
                        },
                      });

                      dispatchSetSelectedFilters(selectedFilters, state, true);
                    }}
                  />
                );
              } else {
                return (
                  <FilterAutocomplete
                    disabled={false}
                    key={`select-multiple-${filter.label}`}
                    filterLabel={filter.label}
                    open={toolTabData.filterOpenState[filter.label] || false}
                    onOpen={() => onFilterOpen(filter.label)}
                    onChange={(values) => {
                      handleSelectedFilterOptionsChanged(values, filter.label)
                    }}
                    selectedOptions={toolTabData.selectedFilters.find(f => f.label === filter.label)?.values || []}
                    onClose={() => onFilterClose(filter.label)}
                    options={filter.values || []}
                    removeFilter={() =>
                      handleAddFilterSelectChanged(
                        selectedFilters.filter((f) => f !== filter.label),
                        true
                      )
                    }
                  />
                );
              }
            }

            case "daterange": {
              const [startDate, endDate] =
                selectedFilterOptions[filter.label] || [];
              const initialDateRange = {
                startDate: startDate ? new Date(startDate) : undefined,
                endDate: endDate ? new Date(endDate) : undefined,
              };
              return (
                <FilterDateRangeSelect
                  key={`date-range-select-${filter.label}`}
                  filterLabel={filter.label}
                  open={toolTabData.filterOpenState[filter.label] || false}
                  onOpen={() => onFilterOpen(filter.label)}
                  onClose={() => onFilterClose(filter.label)}
                  initialDateRange={initialDateRange}
                  onChange={(values) => {
                    const state = {
                      ...selectedFilterOptions,
                      [filter.label]: values,
                    };
                    setSelectedFilterOptions(state);

                    dispatchSetSelectedFilters(selectedFilters, state);
                  }}
                  removeFilter={() =>
                    handleAddFilterSelectChanged(
                      selectedFilters.filter((f) => f !== filter.label),
                      true
                    )
                  }
                />
              );
            }

            default:
              throw new Error("Unsupported type.");
          }
        } else {
          return null;
        }
      }).filter(Boolean)}
    </Box>
  );
};

const Filter = (props: {
  disabled: boolean;
  toolTabIndex: number;
  toolTabData: ToolTabData;
  onSearchValueChanged: (value: string) => void;
  onSearchValueEntered: (value?: string) => void;
  dispatchSelectedItems: (selectedItems: Record<string, SelectedItem>) => void;
  onFilterOptionSelectionChange: ({
    selectedFilters,
  }: {
    selectedFilters?: ProcoreToolFilter[];
  }) => void;
}): JSX.Element => {
  const { dispatch } = React.useContext(NewPackageContext);
  const { state: accountProjState } = React.useContext(AccountProjectContext);
  const client = useApolloClient();

  const { disabled, toolTabIndex, toolTabData, onFilterOptionSelectionChange, dispatchSelectedItems, onSearchValueChanged, onSearchValueEntered } =
    props;
  const onlyCheckedSelectedItems = (idx(toolTabData, t => checkedItems(t.selectedItems)));
  const everyFilteredProcoreItemServerIdSelected = (idx(toolTabData, t => t.filteredProcoreItemServerIds)).every(procoreItemServerId => {
    const selectedItem = toolTabData.selectedItems[selectedItemKey(toolTabData.procoreTool.engineName, procoreItemServerId, toolTabData.procoreTool.directoryType)] || { state: 'unchecked' }
    return selectedItem.state === 'checked'
  });

  const procoreItems: ProcoreItem[] =
    idx(toolTabData, (data) => data.procoreItems) || [];
  const filters = idx(toolTabData, (data) => data.filters) || [];
  const displayLoadingSubLabel =
    (idx(toolTabData, (data) => data.packageResourceSync.status) || "") ===
    "pending";
  const loadingSubLabel = displayLoadingSubLabel
    ? "This can take up to a few minutes on a fresh project."
    : "";
  const showProcoreItemDescription = idx(toolTabData, t => showDescriptionFor(t.procoreTool.engineName)) || false;

  function renderRow(props: ListChildComponentProps<BaseProcoreType>) {
    const { index, style } = props;

    const procoreItem = procoreItems[index] as BaseProcoreType;

    if (procoreItem) {
      const key = selectedItemKey(toolTabData.procoreTool.engineName, procoreItem.procoreServerId, toolTabData.procoreTool.directoryType)
      const currentSelectedItem = toolTabData.selectedItems[key] || { state: 'unchecked' };

      return (
        <div style={style}>
          <ListItem key={`filter-list-item-${procoreItem.id}`} sx={{
            height: showProcoreItemDescription ? '70px' : '46px',
            padding: '0px 16px',
          }}>
            <ManualSelectCheckbox
              disableFocusRipple
              disableRipple
              disableTouchRipple
              color="primary"
              icon={<UncheckedCheckBox />}
              checkedIcon={<CheckedCheckBox />}
              onClick={(evt) => {
                evt.stopPropagation();

                dispatchSelectedItems({
                  ...toolTabData.selectedItems,
                  [key]: {
                    itemId: procoreItem.procoreServerId,
                    itemType: itemTypeFrom(toolTabData.procoreTool.engineName, toolTabData.procoreTool.directoryType),
                    state: currentSelectedItem.state === 'checked' ? 'unchecked' : 'checked'
                  }
                });
              }}
              checked={currentSelectedItem.state === 'checked'}
            />
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} sx={{
              height: '100%',
              width: '100%',
              overflow: 'hidden',
            }}>
              <FilterListItemText
                disableTypography={true}
                sx={{
                  flex: 'unset',
                  margin: 0,
                }}
                key={`filter-list-item-title-${procoreItem.id}`}
              >
                {procoreItem.formattedTitle}
              </FilterListItemText>
              {
                showProcoreItemDescription && (
                  <FilterListItemText
                    sx={{
                      flex: 'unset',
                      color: '#596A75',
                      fontSize: '12px',
                      margin: 0,
                    }}
                    disableTypography={true}
                    key={`filter-list-item-description-${procoreItem.id}`}
                  >
                    {procoreItem.description}
                  </FilterListItemText>
                )
              }
            </Box>
          </ListItem>
          <Divider key={`filter-list-item-divider-${procoreItem.id}`} />
        </div>
      );
    } else {
      return <div style={style}>
        <Waypoint onEnter={() => {
          if (toolTabData.loadingMore || toolTabData.failedToFetchMore) { return; }

          queryProcoreItemForTool(
            toolTabData,
            toolTabIndex,
            client,
            dispatch,
            accountProjState,
            procoreFilterToInputFilters(toolTabData.selectedFilters)
          );
        }}>
          <ListItem key={`filter-list-item-fetch-more`}>
            { toolTabData.failedToFetchMore ? (
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"} style={{ gap: '4px' }}>
                <Typography style={{ fontSize: '1.125em', color: Colors.darkerGray }}>Failed to load more data.</Typography>
                <a href="#" onClick={() => {
                  dispatch({
                    type: "SET_TOOL_TAB_FAILED_TO_FETCH_MORE",
                    value: {
                      toolTabIndex: toolTabIndex,
                      failedToFetchMore: false,
                    },
                  });

                  queryProcoreItemForTool(
                    toolTabData,
                    toolTabIndex,
                    client,
                    dispatch,
                    accountProjState,
                    procoreFilterToInputFilters(toolTabData.selectedFilters)
                  );
                }}>Retry</a>
              </Box>
            ) : (
              <FilterListItemText
                disableTypography={true}
                key={`filter-list-item-text-fetch-more`}
              >
                { toolTabData.loadingMore ? "Loading more data..." : "Load more data." }
              </FilterListItemText>
            )}

          </ListItem>
        </Waypoint>
      </div>
    }
  }

  if (disabled) {
    return <Box
      width={1}
      height={1}
      style={{
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor:
          onlyCheckedSelectedItems.length === 0 ? "#E4EBF0" : "#D2E9FA",
        background:
          onlyCheckedSelectedItems.length === 0 ? "white" : "#F5FBFF",
        borderRadius: "6px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ToolSelectionRequired toolTabIndex={toolTabIndex} />
    </Box>;
  }

  const virtualizedItemCount = (toolTabData.procoreItems.length < toolTabData.filteredProcoreItemServerIds.length) ? procoreItems.length + 1 : procoreItems.length;

  return (
    <>
      <Box
        width={1}
        style={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor:
            onlyCheckedSelectedItems.length === 0 ? "#E4EBF0" : "#D2E9FA",
          background:
            onlyCheckedSelectedItems.length === 0 ? "white" : "#F5FBFF",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "row",
          marginBottom: "12px",
          padding: "14px 20px",
          alignItems: "center",
          minHeight: "57px",
        }}
      >
        {onlyCheckedSelectedItems.length === 0 ? (
          <InfoOutlinedIcon
            style={{ marginRight: "10px" }}
            htmlColor="#B5D7F5"
          />
        ) : (
          <CheckCircleSharpIcon
            style={{ marginRight: "10px" }}
            htmlColor={Colors.newAccentBlue}
          />
        )}
        {onlyCheckedSelectedItems.length === 0 ? (
          <Typography
            style={{ fontSize: 14, fontWeight: 500, color: Colors.darkerGray }}
          >
            {`Select some ${toolTabData.procoreTool.title} to extract`}
          </Typography>
        ) : (
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={1}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: 700,
                  color: Colors.darkerGray,
                  marginRight: "6px",
                }}
              >
                {onlyCheckedSelectedItems.length}
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: Colors.darkerGray,
                }}
              >
                {`${toolTabData.procoreTool.title} will be extracted`}
              </Typography>
            </Box>
            <LinkWithDisabled disabled={displayLoadingSubLabel} onClick={() => {
              dispatch({
                type: 'RESET_SELECTED_ITEMS',
                value: { toolTabIndex: toolTabIndex }
              })
            }}>Deselect All</LinkWithDisabled>
          </Box>
        )}
      </Box>
      <Box
        style={{
          border: "solid 1px #E4EBF0",
          borderRadius: "6px",
        }}
        display="flex"
        flexDirection="column"
        height={1}
        width={1}
      >
        {toolTabData.loading ? (
          <Loading
            loadingLabel={"Loading items..."}
            loadingSubLabel={loadingSubLabel}
          />
        ) : (
          <>
            <FilterOptions
              filters={[...filters] as ProcoreToolFilter[]}
              toolTabIndex={toolTabIndex}
              searchValue={toolTabData.searchValue}
              onSearchValueChanged={onSearchValueChanged}
              onSearchValueEntered={onSearchValueEntered}
              toolTabData={toolTabData}
              onFilterOptionSelectionChange={onFilterOptionSelectionChange}
              onSelectAllButtonClicked={() => {
                if (everyFilteredProcoreItemServerIdSelected) {
                  dispatchSelectedItems(
                    transformSelectedItemsFromServerIds(
                      toolTabData.procoreTool.engineName,
                      toolTabData.filteredProcoreItemServerIds,
                      'unchecked',
                      toolTabData.procoreTool.directoryType
                    )
                  );
                } else {
                  dispatchSelectedItems(
                    transformSelectedItemsFromServerIds(
                      toolTabData.procoreTool.engineName,
                      toolTabData.filteredProcoreItemServerIds,
                      'checked',
                      toolTabData.procoreTool.directoryType
                    )
                  );
                }
              }}
            />
            <Divider />
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
              <div style={{ flex: "1 1 1px" }}>
                <AutoSizer>
                  {({ height, width }) => (
                    <FixedSizeList
                      height={height}
                      width={width}
                      itemCount={virtualizedItemCount}
                      itemSize={showProcoreItemDescription ? 70 : 46}
                    >
                      {renderRow}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </div>
            </div>
            <Box padding={'8px'} borderTop={'1px solid rgb(228, 235, 240)'}>
              <Typography style={{
                fontSize: '12px',
                color: '#A0B0BA',
                textAlign: 'center',
                fontWeight: '400'
              }}>{`Showing ${toolTabData.procoreItems.length} of ${toolTabData.filteredProcoreItemServerIds.length} items`}</Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Filter;
