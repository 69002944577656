import { gql, QueryResult, useQuery } from "@apollo/client";
import { CurrentUserData } from "../../types";

export const GetCurrentUserQuery = gql`
query GetCurrentUser {
  currentUser {
    id
    procoreUserEmail
    createdAt
  }
}
`;

export const useGetCurrentUser = (): QueryResult<CurrentUserData> => useQuery<CurrentUserData>(GetCurrentUserQuery, { fetchPolicy: 'network-only' });