import React from 'react';
import { Box } from '@mui/material';
import { Typography } from './shared/Typography';
import { Colors } from '../styles';

export const CloseoutLogEmptyState = (): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      alignItems="center"
      justifyContent="center"
      gap="12px"
    >
      <Box
        height="175px"
        width="200px"
        sx={{
          background:
            'url("/images/closeout-reqs-empty.png") no-repeat center / contain',
        }}
      ></Box>
      <Typography color={Colors.darkerGray} typestyle="l">No Requirements</Typography>
      <Typography color={Colors.darkishGray} typestyle="xs" textAlign={'center'}>Get started by importing from Procore Submittals, or by manually creating a new requirement</Typography>
    </Box>
  );
};
