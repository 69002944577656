import { gql } from "@apollo/client";
import { ProcoreToolFilter } from "../../contexts/NewPackageContext";
import { FilterOptionInput, ProcoreToolGroupings } from "../../types";
import { ProcoreMeeting } from "../../types/meeting";

export type ProcoreMeetingFilterData = {
  meetingFilters: ProcoreToolFilter[];
  meetingDefaultFilters: ProcoreToolFilter[];
  meetingGroupings: ProcoreToolGroupings[];
};

export type ProcoreFilterMeetingData = {
  meetings: ProcoreMeeting[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreMeetingVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterMeetingVariables = ProcoreMeetingVariables & {
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const MeetingFragment = gql`
fragment MeetingFragment on ProcoreMeeting {
  id
  procoreServerId
  formattedTitle
  meetingTemplateName
}
`;

export const filterProcoreMeetingsQuery = gql`
query FilterProcoreMeetings($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  meetings(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...MeetingFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${MeetingFragment}
`;

export const fetchPaginatedProcoreMeetingsQuery = gql`
query FilterPaginatedProcoreMeetings($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  meetings(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...MeetingFragment
  }
}
${MeetingFragment}
`;