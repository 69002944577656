import {
  Box,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
  styled,
} from "@mui/material";
import React from "react";
import { Account, Project } from "../../types";
import LicensedProjectsEmptyState from "./LicensedProjectsEmptyState";
import { useManageProjectLicense } from "../../graphql/mutations/ManageProjectLicense";
import { LicensedTableRowCell } from "./LicensedTableRowCell";
import {
  isIframe,
  isAnnualLicense,
  isOwnerOrAdmin,
  isWindowReadyForClose,
  isPilotOrCustomer
} from "../../utils/utils";
import {
  Colors, DialogDivider, MyDialog, MyDialogActions,
} from "../../styles";
import { MyDialogTitle } from "./NewPackage";
import { useDeactivateProjectLicense } from "../../graphql/mutations/DeactivateProjectLicense";
import ProjectListBanner from "../shared/ProjectListBanner";
import { Button } from "../shared/Button";
import DmsaRequiredBanner from "../shared/DmsaRequiredBanner";
import { Typography } from "../shared/Typography";

export const TableHeaderCell = styled(TableCell)<TableCellProps>(() => {
  return {
    paddingTop: "8px",
    paddingBottom: "8px",
    borderBottom: "1px solid #EDF2F5",
    color: "#1A2024",
    '&.MuiTableCell-stickyHeader': {
      backgroundColor: "white",
    }
  }
});

const PersonalProjectsList = (props: {
  account: Account;
  licensedProjects: Project[];
  refreshProjects: () => void;
  searchProjectsValue: string;
  onAllowAccessClick: () => void;
}): JSX.Element => {
  const { licensedProjects, account, searchProjectsValue, refreshProjects, onAllowAccessClick } =
    props;
  const [confirmDeactivateProjectModalOpen, setConfirmDeactivateProjectModalOpen] = React.useState(false);
  const [currentProcoreProjectServerId, setCurrentProcoreProjectServerId] = React.useState(null);
  const [deactivateProjectLoading, setDeactivateProjectLoading] = React.useState(false);

  const [deactivateProjectLicense] = useDeactivateProjectLicense({
    accountId: account.id,
    procoreProjectServerId: currentProcoreProjectServerId
  });

  const [
    manageProjectBilling,
    { loading: loadingManageProjectBilling, data: manageProjectBillingData },
  ] = useManageProjectLicense({
    accountId: account.id,
    procoreProjectServerId: -1,
  });

  React.useEffect(() => {
    if (
      loadingManageProjectBilling === undefined ||
      loadingManageProjectBilling === true ||
      !manageProjectBillingData
    ) {
      return;
    }

    if (manageProjectBillingData.manageProjectLicense.success) {
      const managePaymentWindow = window.open(
        manageProjectBillingData.manageProjectLicense.managePaymentUrl,
        isIframe ? "_blank" : "_top"
      );

      if (isIframe && managePaymentWindow) {
        const loop = setInterval(function () {
          if (isWindowReadyForClose(managePaymentWindow)) {
            try {
              managePaymentWindow.close();
            } catch (err) {
              console.log("error closing window", err);
            } finally {
              clearInterval(loop);
            }
          }
        }, 1000);
      }
    }
  }, [loadingManageProjectBilling]);

  const trimmedAndLoweredSearchValue = (searchProjectsValue || "")
    .trim()
    .toLowerCase();
  const filterdLicensedProjects = licensedProjects.filter((d) => {
    const trimmedOption = (d.displayName || d.name || "").trim().toLowerCase();

    return trimmedOption.includes(trimmedAndLoweredSearchValue);
  });

  return (
    <>
      <Box
        height={1}
        width={1}
        display={"flex"}
        flexDirection={"column"}
      >
        { isAnnualLicense(account) && isOwnerOrAdmin(account) && isPilotOrCustomer(account) && account.closeoutLogEnabled && licensedProjects.some(p => !p.dmsaEnabled) && (
          <DmsaRequiredBanner projectCount={licensedProjects.filter(p => !p.dmsaEnabled).length} onAllowAccess={onAllowAccessClick} />
        )}

        {isAnnualLicense(account) && isOwnerOrAdmin(account) && !isPilotOrCustomer(account) && (
          <ProjectListBanner
            borderTopColor={'linear-gradient(140.03deg, #F0F9FF 5%, #89BFE8 120%)'}
            primaryText={
              account.freeTrialAvailable ?
                (<>You’ve got one 7-day free trial available.</>) :
                (<>Your account no longer has a free trail available.</>)}
            secondaryText={account.freeTrialAvailable ?
              (<>Click “Start Free Trial” to license a project. <a href="https://trailb.tech/request-demo" target="_blank" rel="noopener noreferrer">Request a demo</a> to learn more about pricing.</>) :
              (<><a href="https://trailb.tech/request-demo" target="_blank" rel="noopener noreferrer">Contact sales</a> to learn more about licensing.</>)}
          />
        )}
        {isAnnualLicense(account) && isOwnerOrAdmin(account) && isPilotOrCustomer(account) && (
          <ProjectListBanner
            borderTopColor={account.numberOfLicenses > 0 ? '#3D87BF' : '#A0B0BA'}
            primaryText={
              account.numberOfLicenses > 0 ?
                (<>{`You’ve got ${account.numberOfLicenses} unused project licenses`}</>) :
                (<>You’ve got 0 available project licenses.</>)}
            secondaryText={account.numberOfLicenses > 0 ?
              (<>Click “Add Projects” to use a license on a project.</>) :
              (<></>)}
          />
        )}

        <TableContainer
          style={{
            background: "white",
            border: `1px solid ${Colors.mediumLightGray}`,
            borderRadius: '8px 8px 0px 0px',
            flex: "1 1 1px",
            minHeight: licensedProjects.length > 0 ? "100px" : "42px",
          }}
        >
          { licensedProjects.length !== 0 && filterdLicensedProjects.length !== 0 && (
            <Table stickyHeader aria-label="simple table" size="medium">
            <TableBody>
              {filterdLicensedProjects.map((licensedProject) => {
                return (
                  <TableRow
                    key={`licensed-project-${licensedProject.procoreServerId}`}
                  >
                    <LicensedTableRowCell
                      account={account}
                      project={licensedProject}
                      setConfirmDeactivateProjectModalOpen={setConfirmDeactivateProjectModalOpen}
                      setCurrentProcoreProjectServerId={setCurrentProcoreProjectServerId}
                      loading={loadingManageProjectBilling}
                      manageBilling={(procoreProjectServerId) => {
                        manageProjectBilling({
                          variables: {
                            accountId: account.id,
                            procoreProjectServerId: procoreProjectServerId,
                            redirectUrl: isIframe
                              ? `${window.location.origin}/close`
                              : "",
                          },
                        });
                      }}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          )}
          { licensedProjects.length !== 0 && filterdLicensedProjects.length === 0 && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}>
              <Typography>No matching projects found</Typography>
            </Box>
          )}
          {licensedProjects.length === 0 && <LicensedProjectsEmptyState account={account} />}
        </TableContainer>
      </Box>

      <MyDialog
        maxWidth={'sm'}
        open={confirmDeactivateProjectModalOpen}>
        <MyDialogTitle>
          {"Deactivate Project"}
        </MyDialogTitle>
        <DialogContent dividers={false} style={{ marginBottom: '20px'}}>
          <Typography gutterBottom>
            {`Are you sure you want to deactivate this project?`}
          </Typography>
        </DialogContent>
        <DialogDivider />
        <MyDialogActions>
          <Button
            autoFocus
            onClick={() => {
              setCurrentProcoreProjectServerId(null);
              setConfirmDeactivateProjectModalOpen(false);
            }}
            variant="outlined"
            disabled={deactivateProjectLoading}
            disableElevation={true}
            size="large">
            {"Cancel"}
          </Button>
          <Button
            disabled={deactivateProjectLoading}
            onClick={() => {
              setDeactivateProjectLoading(true);

              deactivateProjectLicense({ variables: {
                accountId: account.id,
                procoreProjectServerId: currentProcoreProjectServerId
              }}).then(() => {
                setDeactivateProjectLoading(false);
                setCurrentProcoreProjectServerId(null);
                setConfirmDeactivateProjectModalOpen(false);

                refreshProjects();
              }).catch(() => {
                setDeactivateProjectLoading(false);
                setCurrentProcoreProjectServerId(null);
                setConfirmDeactivateProjectModalOpen(false);
              })
            }}
            size="large"
            variant="contained"
            disableElevation={true}
            style={{ backgroundColor: Colors.red, color: Colors.white }}>
            {'Deactivate'}
          </Button>
        </MyDialogActions>
      </MyDialog>
    </>
  );
};

export default PersonalProjectsList;
