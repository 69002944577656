import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { ExtractAutomation } from "../../../types";

export interface ExtractAutomationDuplicate {
  extractAutomation: ExtractAutomation | null;
  success: boolean;
}

export interface ExtractAutomationDuplicateData {
  duplicateExtractAutomation: ExtractAutomationDuplicate | null;
}

export interface ExtractAutomationDuplicateVariables {
  accountId: string;
  extractAutomationId: number;
}

export const useDuplicateExtractAutomation = createMutationHook<
  ExtractAutomationDuplicateData,
  ExtractAutomationDuplicateVariables
>(gql`
  mutation DuplicateExtractAutomation(
    $accountId: ID!,
    $extractAutomationId: ID!,
  ) {
    duplicateExtractAutomation(
      input: { accountId: $accountId, extractAutomationId: $extractAutomationId }
    ) {
      extractAutomation {
        id
        name
        createdAt
        enabled
        enabledProjectsCount
      }
      success
    }
  }
`);
