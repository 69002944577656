import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { ExtractAutomation } from "../../../types";

export interface ExtractAutomationUpdate {
  extractAutomation?: ExtractAutomation;
  success: boolean;
}

export interface ExtractAutomationUpdateData {
  updateExtractAutomation: ExtractAutomationUpdate;
}

export interface ExtractAutomationUpdateVariables {
  accountId: string;
  extractAutomationId: number;
  attributes: Record<string, any>;
}

export const useUpdateExtractAutomation = createMutationHook<
ExtractAutomationUpdateData,
ExtractAutomationUpdateVariables
>(gql`
  mutation UpdateExtractAutomation(
    $accountId: ID!,
    $extractAutomationId: ID!,
    $attributes: JSON!
  ) {
    updateExtractAutomation(
      input: { accountId: $accountId, extractAutomationId: $extractAutomationId, attributes: $attributes }
    ) {
      extractAutomation {
        id
        name
        createdAt
        enabled
        enabledProjectsCount
      }
      success
    }
  }
`);
