import React from 'react';
import {
  Box,
  IconButton,
  Autocomplete,
  FormControl,
  SelectChangeEvent,
  MenuItem,
  TextField,
} from '@mui/material';
import { Typography } from './shared/Typography';
import Close from '@mui/icons-material/Close';
import { GridFilterItem } from '@mui/x-data-grid-premium';
import { filterFields, FilterField } from './VendorPortalAppContainer';
import { VendorPortalContext } from '../contexts/VendorPortalContextProvider';

interface IFilterRowProps {
  handleRemoveItem: () => void;
  handleUpdateItem: (item: GridFilterItem, index: number) => void;
  filterItem: GridFilterItem;
  index: number;
  filters: GridFilterItem[];
}

export const TradePortalFilterRow = ({
  handleRemoveItem,
  handleUpdateItem,
  filterItem,
  index,
  filters,
}: IFilterRowProps): JSX.Element => {
  const { state, dispatch } = React.useContext(VendorPortalContext);

  //function that gives values to list for the given field
  const getValues = (field: FilterField): Array<any> => {
    switch (field) {
      case 'specSection':
        return state.specSections;
      case 'status':
        return state.statuses;
      default:
        return [];
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        gap: '16px',
        paddingX: '16px',
      }}
    >
      <Box
        sx={{
          height: '40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <IconButton onClick={handleRemoveItem} size="small">
          <Close sx={{ fontSize: '16px' }} />
        </IconButton>
      </Box>
      <FormControl sx={{ width: '50%' }}>
        <TextField
          select
          size="small"
          value={
            filterFields.filter((filter) => filter.id === filterItem.id)[0]
          }
          SelectProps={{
            onChange: (event: SelectChangeEvent<any>) => {
              const newItem = {
                ...filterItem,
                field: event.target.value.field,
                id: event.target.value.id,
                value: undefined,
              };
              handleUpdateItem(newItem, index);
            }
          }}
        >
          {filterFields.map((filterField, i) => {
            return (
              <MenuItem
                value={filterField as any}
                key={`filter-field-menu-item-${i}`}
                disabled={
                  filters.filter((filter) => filter.id === filterField.id)
                    .length > 0
                }
              >
                {filterField.label}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
      <Box
        sx={{
          height: '40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography>is</Typography>
      </Box>
      <FormControl sx={{ width: '50%' }}>
        <Autocomplete
          multiple
          value={filterItem.value || []}
          options={getValues(filterItem.field as FilterField)}
          getOptionLabel={(item) => {
            if (item?.name) {
              return item.name;
            } else {
              return '';
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="" autoFocus size="small" />
          )}
          onChange={(evt, value) => {
            const newItem = {
              ...filterItem,
              value: value.length === 0 ? undefined : value,
            };
            handleUpdateItem(newItem, index);
          }}
        />
      </FormControl>
    </Box>
  );
};
