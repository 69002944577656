import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { CloseoutLog } from "../../../types";

export interface DuplicateCloseoutLog {
  closeoutLog: CloseoutLog | null;
  success: boolean;
}

export interface DuplicateCloseoutLogData {
  duplicateCloseoutLog: DuplicateCloseoutLog | null;
}

export interface DuplicateCloseoutLogVariables {
  accountId: string;
  procoreProjectServerId: number;
  id: number;
}

export const useDuplicateCloseoutLog = createMutationHook<
  DuplicateCloseoutLogData,
  DuplicateCloseoutLogVariables
>(gql`
  mutation DuplicateCloseoutLog(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $id: ID!
  ) {
    duplicateCloseoutLog(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, id: $id }
    ) {
      closeoutLog {
        id
        name
        logRequirementsCount
        logRequirementsCompleteCount
        createdAt
        substantialCompletionDate
      }
      success
    }
  }
`);
