import { gql } from "@apollo/client";
import { ProcoreToolFilter } from "../../contexts/NewPackageContext";
import { FilterOptionInput, ProcoreToolGroupings } from "../../types";
import { ProcoreManagedEquipment } from "../../types/managed_equipment";

export type ProcoreManagedEquipmentFilterData = {
  managedEquipmentFilters: ProcoreToolFilter[];
  managedEquipmentDefaultFilters: ProcoreToolFilter[];
  managedEquipmentGroupings: ProcoreToolGroupings[];
};

export type ProcoreFilterManagedEquipmentData = {
  managedEquipment: ProcoreManagedEquipment[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreManagedEquipmentVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterManagedEquipmentVariables = ProcoreManagedEquipmentVariables & {
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const ManagedEquipmentFragment = gql`
fragment ManagedEquipmentFragment on ProcoreManagedEquipment {
  id
  procoreServerId
  formattedTitle
  type
  make
  model
  description
}
`;

export const filterProcoreManagedEquipmentsQuery = gql`
query FilterProcoreManagedEquipments($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  managedEquipment(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ManagedEquipmentFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${ManagedEquipmentFragment}
`;

export const fetchPaginatedProcoreManagedEquipmentsQuery = gql`
query FilterPaginatedProcoreManagedEquipments($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  managedEquipment(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ManagedEquipmentFragment
  }
}
${ManagedEquipmentFragment}
`;