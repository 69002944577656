import React from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/system/Box";
import { Button } from "./shared/Button";

import { Colors, StyledVerticalTab, StyledVerticalTabs } from "../styles";
import { Typography } from "./shared/Typography";
import { LinkProcoreItemsContext } from "../contexts/LinkProcoreItemsContextProvider";
import LinkProcoreItemsTab from "./LinkProcoreItemTab";
import { useLinkLogRequirementItems } from "../graphql/mutations/closeout/LinkLogRequirementItems";
import { checkedItems } from "../utils/utils";
import { LogRequirementItem } from "../types";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      height={'600px'}
      width={1}
      role="tabpanel"
      display={value !== index ? 'none' : 'flex'}
      flexDirection={'column'}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (children)}
    </Box>
  );
}

interface ILinkProcoreItemsProps {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logRequirementId: number;
  openModal: boolean;
  closeModal: (logRequirementsItems: LogRequirementItem[]) => void;
}

const LinkProcoreItemsModal = (props: ILinkProcoreItemsProps): JSX.Element => {
  const { state, dispatch } = React.useContext(LinkProcoreItemsContext);
  const [currentTabIndex, setCurrenTabIndex] = React.useState(0);
  const containerRef = React.useRef(null);

  const [linkLogRequirementItems, { loading: loadingLinkLogRequirementItems }] = useLinkLogRequirementItems({
    accountId: props.accountId,
    procoreProjectServerId: props.procoreProjectServerId,
    closeoutLogId: props.closeoutLogId,
    logRequirementId: props.logRequirementId,
    linkedItems: [],
  });

  const addButtonDisabled = React.useMemo(() => {
    return state.toolTabs.every((toolTab) => {
      return checkedItems(toolTab.selectedItems).length === 0;
    });
  }, state.toolTabs)

  return (
    <Dialog
      disableScrollLock
      ref={containerRef}
      open={props.openModal}
      PaperProps={{
        sx: {
          maxHeight: "800px",
          minWidth: "950px",
          padding: "24px 28px 28px 28px",
        },
      }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '24px',
        justifyContent: 'space-between'
      }}>
        <Typography typestyle="xl">
          Add Linked Items
        </Typography>
        <IconButton onClick={() => props.closeModal([])}>
          <Close sx={{fontSize: '18px'}}/>
        </IconButton>
      </Box>
      <Box display="flex" height={1} gap="20px">
        <Box
          display="flex"
          flexDirection="column"
          gap="14px"
          height={1}
        >
          <Typography color={Colors.darkishGray} typestyle="xs bold">
            Tools
          </Typography>
          <StyledVerticalTabs
            orientation="vertical"
            value={currentTabIndex}
            onChange={(e, newValue) => {
              setCurrenTabIndex(newValue);
            }}
            sx={{ width: 120 }}
          >
            {state.toolTabs.map((toolTab, toolTabIndex) => {
              return (
                <StyledVerticalTab
                  key={toolTabIndex}
                  value={toolTabIndex}
                  label={toolTab.procoreTool.title}
                />
              );
            })}
          </StyledVerticalTabs>
        </Box>

        <Box display="flex" flexDirection="column" height={1} width={1}>
          {
            state.toolTabs.map((toolTab, toolTabIndex) => {
              return (
                <TabPanel key={`tab-panel-${toolTabIndex}`} value={currentTabIndex} index={toolTabIndex}>
                  <LinkProcoreItemsTab
                    popperContainer={containerRef.current}
                    key={`link-procore-items-${toolTabIndex}`}
                    accountId={props.accountId}
                    procoreProjectServerId={props.procoreProjectServerId}
                    toolTab={toolTab} />
                </TabPanel>
              );
            })
          }
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" height={1} width={1}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"8px"}
          paddingTop={"24px"}
        >
          <Button
            disabled={loadingLinkLogRequirementItems}
            variant="outlined"
            buttonborderstyle="pill"
            size="medium"
            onClick={() => props.closeModal([])}
          >
            Cancel
          </Button>
          <Button
            disabled={addButtonDisabled || loadingLinkLogRequirementItems}
            variant="contained"
            buttonborderstyle="pill"
            size="medium"
            onClick={() => {
              linkLogRequirementItems({
                variables: {
                  accountId: props.accountId,
                  procoreProjectServerId: props.procoreProjectServerId,
                  closeoutLogId: props.closeoutLogId,
                  logRequirementId: props.logRequirementId,
                  linkedItems: state.toolTabs.map((toolTab) => {
                    return {
                      procoreToolId: toolTab.procoreTool.id,
                      engineName: toolTab.procoreTool.engineName,
                      procoreServerIds: checkedItems(toolTab.selectedItems).map((item) => item.itemId),
                    }
                  })
                }
              }).then(({ data }) => {
                props.closeModal(data.linkLogRequirementItems.logRequirementItems);
              }).catch((err) => {
                console.log(err);
              })
            }}>
            Add
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default LinkProcoreItemsModal;
