import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface TemplateLogRequirementTradeUpdate {
  templateLogRequirementTrade?: TemplateLogRequirementTrade;
  success: boolean;
}

export interface TemplateLogRequirementTradeUpdateData {
  updateTemplateLogRequirementTrade: TemplateLogRequirementTradeUpdate | null;
}

export interface TemplateLogRequirementTradeUpdateVariables {
  accountId: string;
  templateCloseoutLogId: number;
  id: number;
  name: string;
}

export const useUpdateTemplateLogRequirementTrade = createMutationHook<
  TemplateLogRequirementTradeUpdateData,
  TemplateLogRequirementTradeUpdateVariables
>(gql`
  mutation UpdateTemplateLogRequirementTrade(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $id: ID!,
    $name: String!
  ) {
    updateTemplateLogRequirementTrade(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, id: $id, name: $name }
    ) {
      templateLogRequirementTrade {
        id
        name
      }
      success
    }
  }
`);
