import * as React from "react";
import {
  IconButton,
  Typography,
  TypographyProps,
  styled,
  IconButtonProps,
} from "@mui/material";

interface DayProps {
  filled?: boolean;
  outlined?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  startOfRange?: boolean;
  endOfRange?: boolean;
  onClick?: () => void;
  onHover?: () => void;
  value: number | string;
}

const CustomTypography = styled(Typography)<TypographyProps & { disabled?: boolean, filled?: boolean }>(({ disabled, filled }) => {
  return {
    color: !disabled && filled && '#FFFFFF',
    lineHeight: 1.6,
  }
});

const CustomIconButton = styled(IconButton)<IconButtonProps & { disabled?: boolean, filled?: boolean, outlined?: boolean }>(({ disabled, filled, outlined }) => {
  return {
    height: 36,
    width: 36,
    padding: 0,
    cursor: 'pointer',
    border: !disabled && outlined ? `1px solid #9e9e9e` : '',
    backgroundColor: !disabled && filled ? '#1976d2' : '',
    "&:hover": {
      backgroundColor: !disabled && filled ? 'rgba(0, 0, 0, 0.04)' : '',
      border: !disabled && filled ? '1px solid #9e9e9e' : ''
    }
  };
});

const Day: React.FunctionComponent<DayProps> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        borderRadius: props.startOfRange ? "50% 0 0 50%" : props.endOfRange ? '0 50% 50% 0' : '',
        backgroundColor: !props.disabled && props.highlighted ? 'rgba(66, 165, 245, 0.6)' : ''
      }}>
      <CustomIconButton
        disabled={props.disabled}
        outlined={props.outlined}
        filled={props.filled}
        onClick={props.onClick}
        onMouseOver={props.onHover}
      >
        <CustomTypography
          color={!props.disabled ? "initial" : "textSecondary"}
          disabled={props.disabled}
          filled={props.filled}
          variant="body2"
        >
          {props.value}
        </CustomTypography>
      </CustomIconButton>
    </div>
  );
};

export default Day;
