import React from 'react';

export type AuthContextState = {
  authenticated: boolean;
  redirectPathContext: {
    accountId?: string;
    procoreProjectServerId?: number;
    closeoutLogId?: number;
  }
  setAuthenticated: (val: boolean) => void;
  setCurrentUserInfo: (id: string | null, email: string | null, createdAt: number | null) => void;
};

export const AuthContext = React.createContext<AuthContextState>({
  authenticated: false,
  redirectPathContext: {
    accountId: undefined,
    procoreProjectServerId: undefined,
    closeoutLogId: undefined,
  },
  setAuthenticated: () => undefined,
  setCurrentUserInfo: () => undefined,
});