import { gql } from "@apollo/client";
import { FilterOptionInput } from "../../types";
import { ProcoreForm } from "../../types/form";

export type ProcoreFilterProcoreFormData = {
  forms: ProcoreForm[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreFormVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterProcoreFormVariables = ProcoreFormVariables & {
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const ProcoreFormFragment = gql`
fragment ProcoreFormFragment on ProcoreForm {
  id
  procoreServerId
  formattedTitle
  createdByName
  formTemplateName
  description
  yearDashMonth
}
`;

export const filterProcoreFormsQuery = gql`
query FilterProcoreForms($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  forms(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ProcoreFormFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${ProcoreFormFragment}
`;

export const fetchPaginatedProcoreFormsQuery = gql`
query FilterPaginatedProcoreForms($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  forms(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ProcoreFormFragment
  }
}
${ProcoreFormFragment}
`;