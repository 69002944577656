import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";

import { LogRequirementItem } from "../types";
import { Box, DialogContent, IconButton, IconButtonProps, LinearProgress, LinearProgressProps, List, ListItem, linearProgressClasses, styled } from "@mui/material";
import { BodyCardHeaderBar } from "./shared/BodyCardHeaderBar";
import { Button } from "./shared/Button";
import { Colors } from "../styles";
import { Typography } from "./shared/Typography";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useCreateProcoreProjectUpload } from "../graphql/mutations/CreateProcoreProjectUpload";
import CircularProgressOverlay from "./shared/CircularProgressOverlay";
import Check from "@mui/icons-material/Check";
import { useCreateProcoreProjectFile } from "../graphql/mutations/CreateProcoreProjectFile";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { CloseoutLogContext } from "../contexts/CloseoutLogContextProvider";
import { useUpdateLogRequirement } from "../graphql/mutations/closeout/UpdateLogRequrement";

interface IFileUploadsModalProps {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logRequirementId: number;
  files: File[];
  openModal: boolean;
  closeModal: (logRequirementsItems: LogRequirementItem[]) => void;
}

const CONCURRENT_UPLOADS = 3;

const CustomLinearProgress = styled(LinearProgress)<LinearProgressProps>(() => ({
  backgroundColor: Colors.lightGray,
  height: '7px',
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: Colors.blue4
  },
}));

const RemoveFileUploadIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    height: "18px",
    width: "18px",
    background: 'transparent',
    color: "white",
    borderRadius: "50%",
    margin: 0,
    padding: 0,
    '& .MuiSvgIcon-root': {
      color: Colors.reallyBlueGray,
      height: "18px",
      width: "18px",
    },
    "&:hover": {
      background: 'transparent',
    },
  };
});

type FileUploadStatus =
  | 'uploading'
  | 'failed' // Either uploading to cloud storage or creating file failed
  | 'queued' // Waiting to be uploaded to cloud storage
  | 'complete' // File has been uploaded to cloud storage and created in Procore
  | 'canceled';

type FileRecord = {
  id: number;
  file: File;
  progress: number;
  status: FileUploadStatus;
}

const FileUploadsModal = (props: IFileUploadsModalProps): JSX.Element => {
  const containerRef = React.useRef(null);
  const {state, dispatch} = React.useContext(CloseoutLogContext);

  const [ createProcoreProjectUpload ] = useCreateProcoreProjectUpload({
    accountId: props.accountId,
    procoreProjectServerId: props.procoreProjectServerId,
    filename: ''
  });

  const [ createProcoreProjectFile ] = useCreateProcoreProjectFile({
    accountId: props.accountId,
    procoreProjectServerId: props.procoreProjectServerId,
    closeoutLogId: props.closeoutLogId,
    logRequirementId: props.logRequirementId,
    filename: '',
    uploadUuid: ''
  })

  const [ updateLogRequirement ] = useUpdateLogRequirement({
    accountId: props.accountId,
    procoreProjectServerId: props.procoreProjectServerId,
    closeoutLogId: props.closeoutLogId,
    logRequirementId: props.logRequirementId,
    attributes: {},
  });

  const [files, setFiles] = useState<Record<number, FileRecord>>(props.files.reduce((acc, nextItem, index) => {
    acc[index] = {
      id: index,
      file: nextItem,
      progress: 0,
      status: 'queued'
    }

    return acc;
  }, {}));

  const [logRequirementItems, setLogRequirementItems] = useState<LogRequirementItem[]>([]);

  const uploadFileToCloudStorageAndCreateFile = (fileRecord: FileRecord, procoreUploadPayload: Record<string, any>): Promise<LogRequirementItem> => {
    return new Promise((resolve, reject) => {
        // Setup XMLHttpRequest
        const xhr = new XMLHttpRequest();

        xhr.open("POST", procoreUploadPayload['url'], true);

        xhr.upload.addEventListener('progress', function(event) {
          if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;

            setFiles((files) => {
             return {
                ...files,
                [fileRecord.id]: {
                  ...fileRecord,
                  status: 'uploading',
                  progress: percentComplete
                }
              }
            });
          }
        });

        // Handle successful upload
        xhr.addEventListener('load', async () => {
          if (xhr.status < 200 || xhr.status >= 300) {
            reject('Failed to upload file to cloud storage');
          } else {
            try {
              const createFileResponse = await createProcoreProjectFile({
                variables: {
                  accountId: props.accountId,
                  procoreProjectServerId: props.procoreProjectServerId,
                  closeoutLogId: props.closeoutLogId,
                  logRequirementId: props.logRequirementId,
                  filename: fileRecord.file.name,
                  uploadUuid: procoreUploadPayload['uuid']
                }
              })

              if (createFileResponse.data.createProcoreProjectFile.success) {
                resolve(createFileResponse.data.createProcoreProjectFile.logRequirementItem);
              } else {
                reject('Failed to create Procore Project File');
              }
            } catch (error) {
              console.log('error', error);
              reject('Failed to create Procore Project File');
            }
          }
        });

        // Handle error
        xhr.addEventListener('error', function (error) {
          console.log('error', error);
          reject('Failed to upload file to cloud storage');
        });

        // Build the FormData
        const formData = new FormData();

        const directUploadFields = procoreUploadPayload['fields'] || {};

        Object.keys(directUploadFields).forEach((key) => {
          formData.append(key, directUploadFields[key]);
        });

        formData.append("file", fileRecord.file);

        // Send the request
        xhr.send(formData);
    });
  }

  const uploadFile = async (fileRecord: FileRecord): Promise<void> => {
    try {
      const response = await createProcoreProjectUpload({
        variables: {
          accountId: props.accountId,
          procoreProjectServerId: props.procoreProjectServerId,
          filename: fileRecord.file.name
        }
      });

      if (response.data.createProcoreProjectUpload.success) {
        const logRequirementItem = await uploadFileToCloudStorageAndCreateFile(
          fileRecord,
          response.data.createProcoreProjectUpload.procoreUploadPayload
        );

        setLogRequirementItems((logRequirementItems) => {
         return [
            ...logRequirementItems,
            logRequirementItem
         ];
        });

        setFiles((files) => {
          return {
            ...files,
            [fileRecord.id]: {
              ...fileRecord,
              status: 'complete'
            }
          }
        });
      }
    } catch (error) {
      console.log('error', error);

      setFiles((files) => {
        return {
          ...files,
          [fileRecord.id]: {
            ...fileRecord,
            status: 'failed'
          }
        }
      });
    }
  }

  React.useEffect(() => {
    const fileRecords = Object.values(files);

    if (Object.keys(files).length > 0) {
      if (fileRecords.every((fileRecord) => fileRecord.status === 'complete' || fileRecord.status === 'canceled')) {
        if (state.currentCloseoutLogDataGridView?.markRequirementCompleteOnFileUpload) {
          updateLogRequirement({
            variables: {
              accountId: props.accountId,
              procoreProjectServerId: props.procoreProjectServerId,
              closeoutLogId: props.closeoutLogId,
              logRequirementId: props.logRequirementId,
              attributes: {
                'status': 'complete',
              },
            },
            fetchPolicy: 'network-only'
          }).then(( { data }) => {
            if (data.updateLogRequirement.success) {
              dispatch({
                type: 'UPDATE_LOG_REQUIREMENT',
                value: {
                  logRequirement: data.updateLogRequirement.logRequirement,
                  logRequirementItems: data.updateLogRequirement.logRequirementItems,
                  triggeredFrom: 'UPDATE_LOG_REQUIREMENT'
                }
              });
            }
            // NOTE: We don't want to pass any logRequirementsItems because those are already included
            // in the UPDATE_LOG_REQUIREMENT dispatch action above.
            props.closeModal([]);
          });
        } else {
          // NOTE: We need to pass all successful logRequirementsItems because we only append to the log requirement
          // items array once the modal is closed.
          props.closeModal(logRequirementItems);
        }
      } else {
        const uploadingRecords = fileRecords.filter((fileRecords) => fileRecords.status === 'uploading');
        const count = uploadingRecords.length < CONCURRENT_UPLOADS ? CONCURRENT_UPLOADS - uploadingRecords.length : 0;
        const queuedRecords = fileRecords.filter((fileRecord) => fileRecord.status === 'queued').slice(0, count);

        if (queuedRecords.length > 0) {
          setFiles((files) => {
            return {
              ...files,
              ...queuedRecords.reduce((acc, nextItem) => {
                acc[nextItem.id] = {
                  ...nextItem,
                  status: 'uploading'
                }

                return acc;
              }, {})
            }
          });

          queuedRecords.map((fileRecord) => {
            uploadFile({ ...fileRecord, status: 'uploading' });
          });
        }
      }
    }
  }, [files]);

  return (
    <Dialog
      ref={containerRef}
      disableScrollLock
      open={props.openModal}
      PaperProps={{
        sx: {
          maxHeight: "550px",
          minWidth: "550px",
          padding: 0,
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '500px',
          padding: 0
        }}
      >
        <BodyCardHeaderBar title="Uploading File(s)">
          <Box display="flex" gap="8px">
            <Button
              variant="outlined"
              onClick={() => {
                setFiles((files) => {
                  return {
                    ...files,
                    ...Object.values(files).reduce((acc, nextItem) => {
                      if (nextItem.status !== 'uploading' && nextItem.status !== 'complete') {
                        acc[nextItem.id] = {
                          ...nextItem,
                          status: 'canceled'
                        }
                      }

                      return acc;
                    }, {})
                  }
                });
              }}
              buttonborderstyle="pill"
              startIcon={<DoNotDisturbAltIcon fontSize="small" sx={{ color: Colors.warningRed }} />}
              sx={{
                color: Colors.warningRed
              }}
            >
              Cancel All
            </Button>
          </Box>
        </BodyCardHeaderBar>
        <Box
          height={1}
          sx={{
            margin: '0 28px 28px 28px',
            borderRadius: '8px',
            border: `1px solid ${Colors.mediumLightGray}`,
            overflowY: 'auto',
          }}>
          <List disablePadding>
            {Object.values(files).map((file) => {
              return <ListItem disableGutters disablePadding key={file.id}>
                <Box padding={'10px 16px'} display={'flex'} flexDirection={"row"} gap="12px" width={1} alignItems={"center"} borderBottom={`1px solid ${Colors.mediumLightGray}`}>
                  {file.status === 'complete' ? (
                    <Box sx={{
                      backgroundColor: Colors.newAccentBlue,
                      minWidth: '18px',
                      maxWidth: '18px',
                      height: '18px',
                      borderRadius: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <Check sx={{
                        color: Colors.white,
                        fontSize: '12px'
                      }}/>
                    </Box>
                  )
                  : (
                    <CircularProgressOverlay
                      size={18}
                      thickness={4}
                      value={0}
                      variant={file.status === 'uploading' ? 'indeterminate' : 'determinate'}
                    />
                    )
                  }
                  <Typography typestyle="m" sx={{ flex: 1 }}>{file.file.name}</Typography>
                  {
                    file.status === 'failed' && (
                      <a href="#" style={{ color: Colors.accentBlue, fontSize: '0.875rem', lineHeight: '1.026rem', fontWeight: '400' }} onClick={() => {
                        setFiles((files) => {
                          return {
                            ...files,
                            [file.id]: {
                              ...file,
                              status: 'queued'
                            }
                          }
                        });
                      }}>
                        Retry
                      </a>
                    )
                  }
                  {
                    (file.status === 'queued' || file.status === 'failed') && (
                      <RemoveFileUploadIconButton disableFocusRipple disableTouchRipple disableRipple onClick={() => {
                        setFiles((files) => {
                          return {
                            ...files,
                            [file.id]: {
                              ...file,
                              status: 'canceled'
                            }
                          }
                        });
                      }}>
                        <CancelOutlinedIcon sx={{ color: Colors.reallyBlueGray }} />
                      </RemoveFileUploadIconButton>
                    )
                  }
                  {
                    file.status === 'canceled' && (
                      <Typography typestyle="s" sx={{ flex: 1, textAlign: 'right' }}>{'Canceled'}</Typography>
                    )
                  }
                </Box>
              </ListItem>
            })}
          </List>
        </Box>
        <CustomLinearProgress variant="indeterminate" />
      </DialogContent>
    </Dialog>
  );
};

export default FileUploadsModal;
