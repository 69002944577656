import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { Typography } from './Typography';

interface ILoadingMessageProps {
  title: string;
}

export const LoadingMessage = ({
  title,
  ...rest
}: ILoadingMessageProps & CircularProgressProps): JSX.Element => {
  return (
    <Stack alignItems="center" gap="12px" sx={{height: '100%', width: '100%', backgroundColor: '#ffffff'}} justifyContent={"center"}>
      <CircularProgress {...rest} size="24px"/>
      <Typography typestyle='m'>{title}</Typography>
    </Stack>
  );
};
