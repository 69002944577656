import React, { useContext, useState } from 'react';
import {
  Box,
  InputBase,
} from '@mui/material';
import { Typography } from './shared/Typography';
import { Colors, HeaderViewController, HeaderViewControllerTab } from '../styles';
import { Button } from './shared/Button';
import { CloseoutLogColumnDefinition, VendorPortalCloseoutManager, VendorPortalLogFileRequest } from '../types';
import VendorPortalLog from './VendorPortalLog';
import { VendorPortalContext } from '../contexts/VendorPortalContextProvider';
import { getDataGridRowExpansionState, isEmpty, saveDataGridRowExpansionState } from '../utils/utils';
import { format } from 'date-fns';
import { GroupingSelect } from './shared/GroupingSelect';
import { GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD, GridEventListener, GridFilterItem, GridGroupNode, GridRowGroupingModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useUpdateVendorPortalDataGridView } from '../graphql/mutations/closeout/UpdateVendorPortalDataGridView';
import { GridStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import SearchIcon from '@mui/icons-material/Search';
import { SearchCloseIcon, SearchCloseIconButton } from './package/styles';
import FilterList from '@mui/icons-material/FilterList';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { TradePortalFilterPopover } from './TradePortalFilterPopover';

interface IVendorPortalAppContainerProps {
  onCloseSubmitLogFileRequestsModal: () => void;
  onOpenSubmitLogFileRequestsModal: (logFileRequests: VendorPortalLogFileRequest[]) => void;
}

const formatCloseoutManager = (manager: VendorPortalCloseoutManager): string => {
  if (manager.vendorName) {
    return `${manager.name} (${manager.vendorName})`;
  } else {
    return `${manager.name}`;
  }
}

export type FilterField = 'specSection' | 'status';
export const filterFields: Array<{ field: FilterField; id: number | string, label: string }> = [
  { field: 'specSection', id: 1, label: 'Spec Section'},
  { field: 'status', id: 2, label: 'Status' }
];

const VendorPortalAppContainer = (props: IVendorPortalAppContainerProps): JSX.Element => {
  const { state, dispatch } = useContext(VendorPortalContext);
  const [selectedTab, setSelectedTab] = useState<'all_requests' | 'open_requests'>('open_requests');
  const [rowGroupingModel, setRowGroupingModel] = useState<GridRowGroupingModel>(
    state.vendorPortalDataGridView?.groupings || []
  );
  const [dataGridFilters, setDataGridFilters] = useState<GridFilterItem[]>([{field: filterFields[0].field, operator: 'isOneOf', id: filterFields[0].id}]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortModel, setSortModel] = useState<GridSortModel>(
    state.vendorPortalDataGridView?.sortModel || []
  );
  const [tradePortalRowExpansionState, setTradePortalRowExpansionState] = useState<Record<string, boolean>>(getDataGridRowExpansionState(state.closeoutLogId, 'trade_portal'));
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState<HTMLButtonElement>(null);

  const apiRef = useGridApiRef();
  const [closeoutLogContextMinimized, setCloseoutLogContextMinimized] = useState<boolean>(false);

  React.useEffect(() => {
    if (apiRef.current) {
      const rowExpansionChangeHandler: GridEventListener<'rowExpansionChange'> = (node: GridGroupNode) => {
        setTradePortalRowExpansionState((prevState) => {
          const result = {
            ...prevState,
            [node.id]: node.childrenExpanded,
          };


          saveDataGridRowExpansionState(state.closeoutLogId, 'trade_portal', result);

          return result;
        });
      };

      return apiRef.current.subscribeEvent("rowExpansionChange", rowExpansionChangeHandler);
    }
  }, [apiRef]);

  React.useEffect(() => {
    if (state.vendorPortalDataGridView) {
      setRowGroupingModel(state.vendorPortalDataGridView.groupings || []);

      const persistedFilters = state.vendorPortalDataGridView.filters;
      if (persistedFilters && persistedFilters.length > 0) {
        handleDataGridFilterChanged('initialize', { filters: persistedFilters })
      } else {
        handleDataGridFilterChanged('initialize')
      }

      setSortModel(state.vendorPortalDataGridView.sortModel || []);
    }
  }, [state.vendorPortalDataGridView]);

  const [ updateVendorPortalDataGridView ] = useUpdateVendorPortalDataGridView(
    {
      vendorId: state.vendorId,
      id: state.vendorPortalDataGridView?.id,
      attributes: {
        columnDefinitions: [],
        sortModel: [],
        groupings: [],
        groupingColumnDefinition: {} as CloseoutLogColumnDefinition,
        filters: [],
      }
    }
  );

  const filterCount = dataGridFilters.filter(filter => filter.value).length;

  const handleDataGridFilterChanged = (
    reason: 'initialize' | 'reset' | 'remove' | 'append' | 'update',
    payload: { id?: number | string, newFilter?: GridFilterItem, index?: number, filters?: GridFilterItem[] } = {}): void => {
    switch (reason) {
      case 'initialize': {
        if (payload.filters && payload.filters.length > 0) {
          setDataGridFilters(payload.filters);
        } else {
          setDataGridFilters([{
            field: filterFields[0].field,
            operator: 'isOneOf',
            id: filterFields[0].id
          }]);
        }
        break;
      }

      case 'reset': {
        const newFilters  = [
          {
            field: filterFields[0].field,
            operator: 'isOneOf',
            id: filterFields[0].id,
          },
        ];

        setDataGridFilters(newFilters);

        if (apiRef.current && apiRef.current.state) {
          handleUpdateDataGridViewState({
            gridState: apiRef.current.state,
            newSortModel: undefined,
            selectedGroupings: undefined,
            newFilters: newFilters,
          });
        }

        break;
      }

      case 'remove': {
        if (dataGridFilters.length === 1) {
          const newFilters = [
            {
              field: filterFields[0].field,
              operator: 'isOneOf',
              id: filterFields[0].id,
            },
          ];
          setDataGridFilters(newFilters);

          if (apiRef.current && apiRef.current.state) {
            handleUpdateDataGridViewState({
              gridState: apiRef.current.state,
              newSortModel: undefined,
              selectedGroupings: undefined,
              newFilters: newFilters,
            });
          }
        } else {
          const newFilters = dataGridFilters.filter((filter) => filter.id !== payload.id);
          setDataGridFilters(newFilters);

          if (apiRef.current && apiRef.current.state) {
            handleUpdateDataGridViewState({
              gridState: apiRef.current.state,
              newSortModel: undefined,
              selectedGroupings: undefined,
              newFilters: newFilters,
            });
          }
        }

        break;
      }

      case 'append': {
        const newFilters = [...dataGridFilters, payload.newFilter];

        setDataGridFilters(newFilters);

        if (apiRef.current && apiRef.current.state) {
          handleUpdateDataGridViewState({
            gridState: apiRef.current.state,
            newSortModel: undefined,
            selectedGroupings: undefined,
            newFilters: newFilters,
          });
        }

        break;
      }

      case 'update': {
        const newFilters = dataGridFilters.map((filter, i) => (i === payload.index) ? payload.newFilter : filter);
        setDataGridFilters(newFilters);

        if (apiRef.current && apiRef.current.state) {
          handleUpdateDataGridViewState({
            gridState: apiRef.current.state,
            newSortModel: undefined,
            selectedGroupings: undefined,
            newFilters: newFilters,
          });
        }
        break;
      }

      default: {
        break;

      }
    }
  }

  const handleUpdateDataGridViewState = (updateProps: {
    gridState: GridStatePremium,
    newSortModel?: GridSortModel,
    selectedGroupings?: string[],
    newFilters?: GridFilterItem[]
  }) => {
    const { gridState, newSortModel, selectedGroupings, newFilters } = updateProps;

    if (newSortModel) {
      setSortModel(newSortModel);
    }

    if (selectedGroupings) {
      setTradePortalRowExpansionState({});
      saveDataGridRowExpansionState(state.closeoutLogId, 'trade_portal', {});
      setRowGroupingModel(selectedGroupings);
    }

    const columnDefinitions = gridState.columns.orderedFields.filter((field) => {
      return field !== GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD;
    }).map((field) => {
      const column = gridState.columns.lookup[field];

      return {
        field: column.field,
        headerName: column.headerName,
        editable: column.editable,
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        width: column.width,
        type: column.type,
        resizable: column.resizable,
        sortable: column.sortable,
        groupable: column.groupable,
      }
    });

    const groupingField = gridState.columns.orderedFields.find(field => {
      return field === GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD;
    });

    let groupingColumnDefinition = undefined;
    if (groupingField) {
      const groupingColumn = gridState.columns.lookup[groupingField];

      groupingColumnDefinition = {
        field: groupingColumn.field,
        headerName: groupingColumn.headerName,
        editable: groupingColumn.editable,
        minWidth: groupingColumn.minWidth,
        maxWidth: groupingColumn.maxWidth,
        width: groupingColumn.width,
        type: groupingColumn.type,
        resizable: groupingColumn.resizable,
        sortable: groupingColumn.sortable,
        groupable: groupingColumn.groupable,
      }
    }

    updateVendorPortalDataGridView({
      variables: {
        vendorId: state.vendorId,
        id: state.vendorPortalDataGridView.id,
        attributes: {
          columnDefinitions: columnDefinitions,
          groupingColumnDefinition: groupingColumnDefinition ? groupingColumnDefinition : {
            ...state.vendorPortalDataGridView.groupingColumnDefinition,
            width: 200
          },
          sortModel: (newSortModel ? newSortModel : sortModel) || [],
          groupings: selectedGroupings ? selectedGroupings : rowGroupingModel,
          filters: newFilters ? newFilters : dataGridFilters
        }
      }
    }).then(({ data }) => {
      if (data.updateVendorPortalDataGridView.success) {
        dispatch({
          type: 'UPDATE_VENDOR_PORTAL_DATA_GRID_VIEW',
          value: data.updateVendorPortalDataGridView.vendorPortalDataGridView,
        });
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  return (
    <>
      <Box height={1} display={'flex'} paddingTop={'20px'} gap={'20px'} paddingRight={'24px'} paddingLeft={'24px'} flexDirection={'row'}>
        <Box
          display={'flex'}
          width={1} height={1} flexDirection={'column'} flexGrow={'1'} sx={{
          backgroundColor: Colors.white,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          paddingTop: '22px',
          paddingLeft: '32px',
          paddingRight: '32px',
          minWidth: 0,
          gap: '25px',
          boxShadow: '0px 2px 4px 1px rgba(26, 32, 36, 0.04), 0px 1px 10px 0px rgba(26, 32, 36, 0.06)'
        }}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} width={1}>
            <Box display={'flex'} flexDirection={'row'} gap={'24px'}>
              <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
                <Typography typestyle='xl'>{'File Requests'}</Typography>
                <Typography typestyle='s'>{state.vendorName}</Typography>
              </Box>
              <Box display={'flex'} flexDirection={'row'} gap={'2px'}>
                <HeaderViewController
                  orientation="horizontal"
                  value={selectedTab}
                  onChange={(evt, value) => setSelectedTab(value)}
                  disabled={true}
                >
                  <HeaderViewControllerTab
                    wrapped={true}
                    label="All Requests"
                    value={"all_requests"}
                    disabled={false}
                  />
                  <HeaderViewControllerTab
                    wrapped={true}
                    label="Open Requests"
                    value={"open_requests"}
                    disabled={false}
                  />
                </HeaderViewController>
              </Box>
            </Box>

            <Box display={'flex'} flexDirection={'row'} gap={'20px'}>
            <Box
                sx={{
                  minHeight: '40px',
                  maxHeight: '40px',
                  flex: '1 0',
                  borderRadius: '20px',
                  paddingRight: '4px',
                  border: `1px solid ${Colors.mediumGray}`,
                  '&:hover': {
                    border: `1px solid ${Colors.mediumDarkGray}`,
                  },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: '12px'
                }}>
                <SearchIcon sx={{fontSize: '18px'}}/>
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1,
                    fontSize: '0.875rem',
                  }}
                  fullWidth={true}
                  placeholder="Search..."
                  value={searchValue}
                  endAdornment={
                    (searchValue || "").trim() ? (
                      <SearchCloseIconButton
                        size={"small"}
                        onClick={() => {
                          setSearchValue("");
                        }}
                        style={{
                          visibility: (searchValue || "").trim()
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        <SearchCloseIcon />
                      </SearchCloseIconButton>
                    ) : null
                  }
                  onChange={(evt) => {
                    setSearchValue(evt.target.value);
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </Box>
              <GroupingSelect
                groupMenuOptions={[
                  {
                    field: 'specSection',
                    headerName: 'Spec Section',
                  }
                ]}
                gridRowGroupingModel={rowGroupingModel}
                setGridRowGroupingModel={(selectedGroupings) => {
                  if (apiRef.current && apiRef.current.state) {
                    handleUpdateDataGridViewState({
                      gridState: apiRef.current.state,
                      newSortModel: undefined,
                      newFilters: undefined,
                      selectedGroupings: selectedGroupings
                    });
                  }
                }}
                disabled={state.logFileRequests.length === 0 || state.logFileRequests.every((logFileRequest) => logFileRequest.vendorWorkflowRequest.vendorWorkflowResponse)}
              />
              <Button
                buttonborderstyle="pill"
                variant="outlined"
                startIcon={<FilterList fontSize="small" />}
                endIcon={<ArrowDropDown fontSize="small" />}
                onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                  setFilterPopoverAnchorEl(evt.currentTarget)
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  {'Filter' + (filterCount ? ` (${filterCount})` : '')}
                </Box>
              </Button>
              <Button
                buttonborderstyle='pill'
                size="medium"
                disabled={state.logFileRequests.length === 0 || state.logFileRequests.every((logFileRequest) => logFileRequest.vendorWorkflowRequest.vendorWorkflowResponse)}
                onClick={() => {
                  props.onOpenSubmitLogFileRequestsModal(
                    state.logFileRequests.filter((logFileRequest) => !logFileRequest.vendorWorkflowRequest.vendorWorkflowResponse)
                  );
                }}
                variant="contained">
                {'Bulk Submit'}
              </Button>
            </Box>
          </Box>
          <VendorPortalLog
            apiRef={apiRef}
            dataGridFilters={dataGridFilters}
            tradePortalRowExpansionState={tradePortalRowExpansionState}
            handleUpdateDataGridViewState={handleUpdateDataGridViewState}
            rowGroupingModel={rowGroupingModel}
            selectedTab={selectedTab}
            sortModel={sortModel}
            filterValue={searchValue}
            onOpenSubmitLogFileRequestsModal={props.onOpenSubmitLogFileRequestsModal} />
        </Box>
        <Box
          sx={{
            background: 'white',
            height: 'fit-content',
            minWidth: closeoutLogContextMinimized ? '26px' : '350px',
            maxWidth: '350px',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0px 1px 10px rgba(26, 32, 36, 0.06), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)',
          }}
        >
          {
            closeoutLogContextMinimized ? (
              <Box
                display="flex"
                flexDirection="column"
                minWidth="26px"
                height={'290px'}
                alignItems="center"
                justifyContent={"center"}
                padding={"28px 13px"}
                onClick={() => {setCloseoutLogContextMinimized(!closeoutLogContextMinimized)}}
                sx={{
                  backgroundColor: Colors.white,
                  '&:hover': {
                    cursor: 'pointer',
                    borderRadius: "8px",
                    backgroundColor: Colors.lightestGray2
                  }
                }}>
                  <KeyboardDoubleArrowLeftIcon fontSize="small" />
              </Box>
            ) : (
              <Box display={'flex'} flexDirection={'column'}>
                <Box
                  sx={{
                    display: 'flex',
                    padding: '24px 28px',
                    gap: '20px',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography typestyle='l'>{state.procoreProjectName}</Typography>

                  <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
                  <Typography typestyle='m bold'>{'Address'}</Typography>
                    <Typography typestyle='m'>{state.projectAddress}</Typography>
                    <Typography typestyle='m'>{state.formattedAddress}</Typography>
                  </Box>

                  <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
                    <Typography typestyle='m bold'>{'Substantial Completion Date'}</Typography>
                    <Typography typestyle='m'>{state.substantialCompletionDate ? format(state.substantialCompletionDate, 'MM/dd/yyyy') : 'TBD'}</Typography>
                  </Box>

                  <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
                    <Typography typestyle='m bold'>{'Closeout Managers'}</Typography>
                    {
                      state.closeoutManagers.map((manager) => {
                        return (
                          <>
                            <Typography typestyle='m'>{formatCloseoutManager(manager)}</Typography>
                            { !isEmpty(manager.email) && <Typography typestyle='m'>{`E: ${manager.email}`}</Typography> }
                            { !isEmpty(manager.phone) && <Typography typestyle='m'>{`P: ${manager.phone}`}</Typography> }
                          </>
                        );
                      })
                    }
                  </Box>
                </Box>

                <Box
                  width={1}
                  height={'40px'}
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap='4px'
                  sx={{
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: Colors.lightestGray2
                    }
                  }}
                  onClick={() => {setCloseoutLogContextMinimized(!closeoutLogContextMinimized)}}>
                  <Typography typestyle="m">
                    {'Minimize'}
                  </Typography>
                  <KeyboardDoubleArrowRightIcon fontSize="small" />
                </Box>
              </Box>
            )
          }

        </Box>
      </Box>

      <TradePortalFilterPopover
        filters={dataGridFilters}
        onResetFilters={() => handleDataGridFilterChanged('reset')}
        onUpdateFilter={(newFilter, index) => handleDataGridFilterChanged('update', { newFilter: newFilter, index: index })}
        onRemoveFilter={(id) => handleDataGridFilterChanged('remove', { id: id })}
        onAppendNewFilter={(newFilter) => handleDataGridFilterChanged('append', { newFilter: newFilter })}
        open={Boolean(filterPopoverAnchorEl)}
        handleCloseModal={() => setFilterPopoverAnchorEl(null)}
        anchorEl={filterPopoverAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </>
  )
}

export default VendorPortalAppContainer;
