import { DocumentNode, gql, QueryResult, useQuery } from "@apollo/client";
import { PackageResourceSyncData } from "../../types";

export interface PackageResourceSyncVariables {
  id: number;
  accountId: string;
}

export const GetPackageResourceSyncQuery = gql`
query GetPackageResourceSync($accountId: ID!, $id: ID!) {
  packageResourceSync(accountId: $accountId, id: $id) {
    id
    status
  }
}
`;

function createQueryHook<TData, TVariables>(
  query: DocumentNode
): (variables: TVariables, skip: boolean) => QueryResult<TData, TVariables> {
  return function(variables, skip) {
    return useQuery(query, { variables, skip, fetchPolicy: 'network-only' });
  };
}

export const useGetPackageResourceSync = createQueryHook<PackageResourceSyncData, PackageResourceSyncVariables>(GetPackageResourceSyncQuery);