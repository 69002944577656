import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogFileRequest } from "../../../types";

export interface LogFileRequestsCreate {
  logFileRequests: LogFileRequest[];
  success: boolean;
}

export interface LogFileRequestsCreateData {
  createLogFileRequests: LogFileRequestsCreate | null;
}

export interface LogFileRequestCreateAttributes {
  logRequirementId: number;
  responsibleContractorServerId: number;
  responsibleContractor: string;
  dueDate: string;
  submitterIds: number[];
  description?: string;
  title: string;
}

export interface LogFileRequestsCreateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  createLogFileRequestAttributes: LogFileRequestCreateAttributes[];
}

export const useCreateLogFileRequests = createMutationHook<
  LogFileRequestsCreateData,
  LogFileRequestsCreateVariables
>(gql`
  mutation CreateLogFileRequests(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $createLogFileRequestAttributes: [LogFileRequestCreateAttributes!]!
  ) {
    createLogFileRequests(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, createLogFileRequestAttributes: $createLogFileRequestAttributes }
    ) {
      logFileRequests {
        id
        title
        logRequirementId
        description
        internalNotes
        dueDate
        complete
        status
        translatedStatus
        assignees {
          id
          logFileRequestId
          procoreServerId
          procoreUserName
          procoreUserEmail
        }
        closeoutManager {
          id
          procoreUserName
        }
        responsibleContractor {
          id
          name
          procoreServerId
        }
        logRequirementTrade {
          id
          name
        }
        specSection {
          id
          name
          divisionName
          procoreServerId
        }
        logRequirementDescription
        vendorWorkflowRequest {
          id
          logFileRequestId
          status
          translatedStatus
          comment
          vendorWorkflowResponse {
            id
            logFileRequestId
            status
            translatedStatus
            comment
            vendorWorkflowRequestId
            attachments {
              id
              procoreFileServerId
              filename
              vendorWorkflowResponseId
              procoreCreatedAt
              procoreRecordUrl
            }
          }
        }
      }
      success
    }
  }
`);
