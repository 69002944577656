import React from "react";
import { initialState, rootReducer, ImportSubmittalsContextState } from './ImportSubmittalsContext';
import { TOOL_ENGINE_NAMES, itemTypeFrom, selectedItemKey } from "../utils/utils";

const ImportSubmittalsContext = React.createContext<ImportSubmittalsContextState>({
  dispatch: () => undefined,
  state: initialState,
});

const ImportSubmittalsContextProvider = (props: { linkedSubmittalServerIds: number[]; children: React.ReactNode }): JSX.Element => {
  const [state, dispatch] = React.useReducer(
    rootReducer,
    {
      ...initialState,
      selectedItems: props.linkedSubmittalServerIds.reduce((acc, procoreServerId) => {
        const key = selectedItemKey(TOOL_ENGINE_NAMES.SUBMITTALS, procoreServerId);
        acc[key] = {
          itemId: procoreServerId,
          itemType: itemTypeFrom(TOOL_ENGINE_NAMES.SUBMITTALS),
          state: 'disabled',
        };

        return acc;
      }, {})
    }
  );

  return (
    <ImportSubmittalsContext.Provider value={{state, dispatch}}>
      {props.children}
    </ImportSubmittalsContext.Provider>
  );
}

export { ImportSubmittalsContext, ImportSubmittalsContextProvider };