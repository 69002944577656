import {
  InputBase,
  Select,
  Fab,
  ListItem,
  ListItemIcon,
  IconButton,
  DialogContent,
  Dialog,
  FormControl,
  Tab,
  Tabs,
  TextField,
  Radio,
  Switch,
  Checkbox,
  styled,
  DialogTitle,
  IconButtonProps,
  SvgIconProps,
  CheckboxProps,
  FabProps,
  FormControlProps,
  DialogTitleProps,
  TextFieldProps,
  ListItemIconProps,
  ListItemProps,
  DialogContentProps,
  DialogProps,
  SelectProps,
  TabsProps,
  TabProps,
  InputBaseProps,
  alpha,
  ListItemText,
  ListItemTextProps
} from "@mui/material";
import { Colors } from "../../styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { TabPanel, TabPanelProps } from "@mui/lab";

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #D5DFE6',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

interface SearchIconWrapperProps {
  disabled: boolean;
}

export const SearchIconWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<SearchIconWrapperProps>(({ theme, disabled }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: disabled ? 0.25 : 1,
}));

export const StyledInputBase = styled(InputBase)<InputBaseProps & { isopen: string }>(({ theme, isopen }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: isopen === "true" ? '156px' : '0px',
      '&:focus': {
        width: '156px',
      },
    },
  },
}));

export const FilterListItemText = styled(ListItemText)<ListItemTextProps & { textcolorstyle?: 'disabled' | 'normal' }>(({ textcolorstyle }) => {
  return {
    color: (textcolorstyle && textcolorstyle === 'disabled') ? Colors.mediumGray : Colors.darkGray,
    fontSize: "1.125em",
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
})

export const SearchCloseIcon = styled(CloseIcon)<SvgIconProps>(() => {
  return {
    height: "16px",
    width: "16px",
    color: Colors.darkerGray,
    background: "transparent",
    "&:hover": {
      color: Colors.darkerGray,
      background: "transparent",
    },
  };
});

export const SearchCloseIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    marginRight: "2px",
    background: Colors.lightGray,
    "&:hover": {
      background: Colors.lightishGray,
    },
  }
});

export const BlueSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: Colors.newAccentBlue,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: Colors.newAccentBlue,
  },
}));

export const ExtractsRadio = styled(Radio)(() => ({
  root: {
    color: "#CED4DA",
    "&:hover": {
      background: "transparent",
    },
  },
  '&.Mui-checked': {
    color: Colors.newAccentBlue,
  }
}));

export const IndeterminateCheckBox = styled(IndeterminateCheckBoxOutlinedIcon)<SvgIconProps>(() => {
  return {
    color: "#171A1C",
    fontSize: "1.175rem",
  };
});

export const UncheckedCheckBox = styled(CheckBoxOutlineBlankIcon)<SvgIconProps>(() => {
  return {
    color: "#D5DFE6",
    fontSize: "1.175rem",
  };
});

export const CheckedCheckBox = styled(CheckBoxIcon)<SvgIconProps & { checkboxstyle?: 'disabled' | 'normal' }>(({ checkboxstyle }) => {
  return {
    color: (checkboxstyle && checkboxstyle === 'disabled') ? Colors.mediumGray : Colors.newAccentBlue,
    fontSize: "1.175rem",
  };
});

export const ManualSelectCheckbox = styled(Checkbox)<CheckboxProps>(() => {
  return {
    marginRight: "8px",
    padding: "2px",
    "&:hover": {
      background: "white",
    }
  }
});

// NOTE: There are 2 different styles here so that the TabPanel
// display CSS isn't overriden for the TabPanels that aren't showing.
export const ExtractToolTabPanel = styled(TabPanel)<TabPanelProps & { enabled: string }>(({ enabled }) => {
  if (enabled === "true") {
    return {
      width: "100%",
      height: "100%",
      padding: 0,
      margin: 0,
      borderRadius: "6px",
      display: "flex",
      flexDirection: "column",
    }
  } else {
    return {
      width: "100%",
      height: "100%",
      padding: 0,
      margin: 0,
      borderRadius: "6px",
    }
  }
});

export const ExtractToolContainerTabs = styled(Tabs)<TabsProps>(() => {
  return {
    overflow: "unset !important",
    paddingTop: '16px',
    paddingRight: '16px',
    paddingLeft: '16px',
  };
});

export const ExtractsToolTab = styled(Tab)<TabProps>(() => {
  return {
    color: Colors.darkerGray,
    textTransform: "none",
    marginBottom: "2px",
    minWidth: "75px",
    fontSize: "12px",
    border: "1px solid white",
    fontWeight: 400,
    "&:hover": {
      border: "1px solid #E4EBF0",
      borderRadius: "6px",
    },
    '&.Mui-selected': {
      border: "1px solid #E4EBF0",
      borderRadius: "6px",
    },
    '&.Mui-disabled': {
      border: "1px solid white",
    },
    '& .MuiTab-labelIcon': {
      minHeight: "75px",
    },
    '& .MuiTab-textColorInherit': {
      opacity: 1,
    }
  };
});

export const ExtractsFab = styled(Fab)<FabProps>(() => {
  return {
    height: "50px",
    width: "50px",
    marginBottom: "10px",
    background: "white",
    backgroundColor: "white",
    boxShadow: "0px 2px 4px 1px #1A20240A, 0px 1px 10px 0px #1A20240F",
    "&:hover": {
      background: "#F2F5F7",
    },
  };
});

export const PrimarySelectInput = styled(InputBase)<InputBaseProps>(() => {
  return {
    borderRadius: 6,
    border: "1px solid transparent",
    '& .MuiInputBase-input': {
      borderRadius: 6,
      position: "relative",
      fontSize: 16,
      fontWeight: 500,
      color: "#1A2024",
      background: "#F2F5F7",
      padding: "10px 26px 10px 12px",
      "&:hover": {
        background: "#EDF2F5",
      },
    },
    '& .Mui-focused': {
      borderRadius: 6,
      border: "1px solid #80BDFF",
      boxShadow: "0px 0px 0px 3px rgba(0, 123, 255, 0.25)",
    }
  }
});

export const AddFilterSelect = styled(Select)<SelectProps>(() => {
  return {
    '& .MuiSelect-select': {
      "&:focus": {
        borderRadius: 6,
      },
    },
    '& .MuiSelect-icon': {
      fill: "#1A2024"
    }
  };
})

export const FilterSelect = styled(Select)<SelectProps>(() => {
  return {
    '& .MuiSelect-select': {
      "&:focus": {
        borderRadius: 6,
      },
      border: "1px solid #E4EBF0",
    },
    '& .MuiSelect-icon': {
      fill: "#1A2024",
    }
  };
})

export const FilterSelectInput = styled(InputBase)<InputBaseProps>(() => {
  return {
    borderRadius: 6,
    border: "1px solid transparent",
    '& .MuiInputBase-input': {
      borderRadius: 6,
      position: "relative",
      fontSize: 16,
      fontWeight: 500,
      color: "#1A2024",
      background: Colors.white,
      padding: "10px 26px 10px 12px",
      "&:hover": {
        border: "1px solid #D8E0E5",
      },
    },
    '& .Mui-focused': {
      borderRadius: 6,
      border: "1px solid #80BDFF",
      boxShadow: "0px 0px 0px 3px rgba(0, 123, 255, 0.25)",
    }
  };
});

export const FileRequestProvideResponseInput = styled(InputBase)<InputBaseProps>(() => {
  return {
    borderRadius: '3px',
    border: "1px solid #D5DFE5",
    '& .MuiInputBase-input': {
      borderRadius: '3px',
      position: "relative",
      fontSize: '12px',
      fontWeight: 700,
      color: "#1A2024",
      background: Colors.white,
      padding: "4px 10px",
    },
    '& .Mui-focused': {
      borderRadius: '3px',
      border: "1px solid #80BDFF",
      boxShadow: "0px 0px 0px 3px rgba(0, 123, 255, 0.25)",
    }
  };
});

export const FeedbackDialog = styled(Dialog)<DialogProps>(() => {
  return {
    '& .MuiDialog-paper': {
      width: "440px",
    }
  };
});

export const MessageTypeFormControl = styled(FormControl)<FormControlProps>(() => {
  return {
    width: "100%",
    marginBottom: "32px",
  };
});

export const FeedbackDialogTitle = styled(DialogTitle)<DialogTitleProps>(() => {
  return {
    padding: '8px 16px 16px 0px',
  };
});

export const MessageTextField = styled(TextField)<TextFieldProps>(() => {
  return {
    display: 'flex'
  };
});

export const TeamHomesTab = styled(Tab)<TabProps>(() => {
  return {
    fontSize: "18px",
    fontWeight: 400,
    minHeight: "32px",
    minWidth: "unset",
    lineHeight: "15px",
    color: Colors.mediumDarkGray,
    opacity: 1,
    '& .MuiTab-labelIcon' : {
      minHeight: 'unset',
      paddingTop: 0,
      paddingBottom: 0,
    },
    '&.MuiTab-wrapped': {
      padding: 0,
      paddingRight: "12px",
      alignItems: "flex-start",
    },
    '&.Mui-selected': {
      fontWeight: 700,
      fontSize: "18px",
      opacity: 1,
      color: Colors.darkerGray,
    }
  };
});

export const TeamHomeTabs = styled(Tabs)<TabsProps>(() => {
  return {
    minHeight: "32px",
    '& .MuiTabs-indicator': {
      right: "unset",
      width: "0px",
      height: "0px",
    },
  };
});

export const ManageCompaniesDialogContent = styled(DialogContent)<DialogContentProps>(() => {
  return {
    padding: 0,
  };
});

export const ManageCompaniesListIcon = styled(ListItemIcon)<ListItemIconProps>(() => {
 return {
   minWidth: "unset",
 };
});

export const ManageCompaniesListItem = styled(ListItem)<ListItemProps>(() => {
  return {
    '&.MuiListItem-gutters': {
      paddingLeft: "32px",
      paddingRight: "32px",
    }
  };
});
