import {
  Box,
  Divider,
  ListItem,
  ListItemProps,
  styled,
} from "@mui/material";
import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { AccountProjectContext } from "../../contexts/AccountProjectContextProvider";
import useNavigationHook from "../../hooks/UseNavigationHook";
import { Colors } from "../../styles";
import { Account, Project } from "../../types";
import { Button } from "../shared/Button";
import { FilterListItemText } from "./styles";
import { Typography } from "../shared/Typography";

export const ProjectListItem = styled(ListItem)<ListItemProps>(() => {
  return {
    '&:hover': {
      background: Colors.lightestGray2,
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
      cursor: 'pointer',
    }
  };
});

const CollaboratorProjectList = (props: {
  account: Account;
  licensedProjects: Project[];
  searchProjectsValue: string;
}): JSX.Element => {
  const { licensedProjects, account, searchProjectsValue } = props;
  const navigateTo = useNavigationHook();
  const { dispatch } = React.useContext(AccountProjectContext);

  const navigateToProject = (procoreProject) => {
    dispatch({
      type: "SET_ACCOUNT_PROJECT",
      value: {
        accountProject: {
          accountId: account.id,
          internal: account.internal,
          closeoutLogEnabled: account.closeoutLogEnabled && procoreProject.dmsaEnabled,
          configuredAutomations: account.configuredAutomations,
          closeoutLogFileRequestEnabled: account.closeoutLogFileRequestEnabled && procoreProject.dmsaEnabled,
          automaticExportsEnabled: account.automaticExportsEnabled && procoreProject.dmsaEnabled,
          procoreCompanyServerId: procoreProject.procoreCompanyServerId,
          procoreProjectServerId: procoreProject.procoreServerId,
          projectName: procoreProject.displayName || procoreProject.name,
          dateFormat: account.dateFormat,
          timeZone: procoreProject.timeZone,
          licensed: procoreProject.licensed,
          licenseType: procoreProject.licenseType,
          licenseEndDate: procoreProject.licenseEndDate,
          appBaseUrl: account.appBaseUrl,
        }
      },
    });
    navigateTo(
      `/teams/${account.id}/projects/${procoreProject.procoreServerId}/packages`
    );
  }

  const trimmedAndLoweredSearchValue = (searchProjectsValue || "")
    .trim()
    .toLowerCase();
  const filterdLicensedProjects = licensedProjects.filter((d) => {
    const trimmedOption = (d.displayName || d.name || "").trim().toLowerCase();

    return trimmedOption.includes(trimmedAndLoweredSearchValue);
  });

  function renderRow(props: ListChildComponentProps<Project>) {
    const [showRowActions, setShowRowActions] = React.useState(false);
    const { index, style } = props;

    const procoreProject: Project = filterdLicensedProjects[index];
    return (
      <div
        style={style}
        onMouseEnter={() => setShowRowActions(true)}
        onMouseLeave={() => setShowRowActions(false)}
      >
        <ProjectListItem key={`project-list-item-${procoreProject.procoreServerId}`}
          onClick={(event) => {
            event.stopPropagation();
            navigateToProject(procoreProject);
          }}>
          <FilterListItemText
            disableTypography={true}
            key={`project-list-item-text-${procoreProject.procoreServerId}`}
          >
            {procoreProject.displayName || procoreProject.name}
          </FilterListItemText>
          {showRowActions && (
            <Button
              size="small"
              variant="contained"
              style={{
                maxHeight: "30px",
              }}
              onClick={(event) => {
                event.stopPropagation();

                navigateToProject(procoreProject);
              }}
            >
              View
            </Button>
          )}
        </ProjectListItem>
        <Divider
          key={`filter-list-item-divider-${procoreProject.procoreServerId}`}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${Colors.mediumLightGray}`,
        height: "100%",
        width: "100%",
        backgroundColor: "white",
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
      }}
    >
      { licensedProjects.length !== 0 && filterdLicensedProjects.length !== 0 && (
        <div style={{ flex: "1 1 1px" }}>
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemCount={filterdLicensedProjects.length}
                itemSize={47}
              >
                {renderRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      )}
      { licensedProjects.length !== 0 && filterdLicensedProjects.length === 0 && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}>
              <Typography>No matching projects found</Typography>
            </Box>
          )}
    </div>
  );
};

export default CollaboratorProjectList;
