import { gql } from "@apollo/client";
import { ProcoreToolFilter } from "../../contexts/NewPackageContext";
import { createLazyQueryHook } from "../GraphqlHelpers";

export type ProcoreCompanyUserFilterData = {
  filters: ProcoreToolFilter[];
  defaultFilters: ProcoreToolFilter[];
};

export type ProcoreCompanyUserFilterVariables = {
  accountId: string;
};

export const getProcoreCompanyUserFiltersQuery = gql`
query GetProcoreToolFilters($accountId: ID!) {
  filters: procoreCompanyUserFilters(accountId: $accountId, default: false) {
    id
    label
    type
    multiple
    dataType
    values {
      id
      key
      value
    }
    value {
      startDate
      endDate
    }
    key
  }

  defaultFilters: procoreCompanyUserFilters(accountId: $accountId, default: true) {
    id
    label
    type
    multiple
    dataType
    values {
      id
      key
      value
    }
    value {
      startDate
      endDate
    }
    key
  }
}
`;

export const useGetProcoreCompanyUserFilters = createLazyQueryHook<ProcoreCompanyUserFilterData, ProcoreCompanyUserFilterVariables>(getProcoreCompanyUserFiltersQuery);