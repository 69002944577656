import { Box, Link } from '@mui/material';
import React from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { InspectionExtractOptions } from '../../types/inspection';
import { ToolTabData } from '../../contexts/NewPackageContext';
import ManualAttachmentSelectionModal from './ManualAttachmentSelectionModal';
import { Colors } from '../../styles';
import ExportOptionToggle from './ExportOptionToggle';

const InspectionOptions = (props: { toolTabIndex: number, toolTabData: ToolTabData }): JSX.Element => {
  const { toolTabIndex, toolTabData } = props;
  const { dispatch } = React.useContext(NewPackageContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const inspectionExtractOptions = toolTabData.extractOptions as InspectionExtractOptions;

  return (
    <Box display="flex" flexDirection="column" width={1} height={1} padding={'20px 40px'} style={{ overflowY: 'auto', gap: '24px' }}>
      <h3 style={{ padding: 0, margin: 0 }}>General</h3>
      <ExportOptionToggle
        label={'Include the Inspection’s attachments in its cover sheet PDF'}
        checked={inspectionExtractOptions.single_pdf}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...toolTabData.extractOptions,
                single_pdf: event.target.checked
              } as InspectionExtractOptions
            }
          });
        }} />

      <ExportOptionToggle
        label={'Show N/A'}
        checked={inspectionExtractOptions.show_not_applicable_items}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...inspectionExtractOptions,
                show_not_applicable_items: event.target.checked,
                collapse_not_applicable_sections: event.target.checked ? inspectionExtractOptions.collapse_not_applicable_sections : false
              }
            }
          });
        }} />

      {
        inspectionExtractOptions.show_not_applicable_items && (
          <Box display={"flex"} paddingLeft={'12px'}>
            <ExportOptionToggle
              label={'Collapse N/A Sections'}
              labelFontSize='12px'
              checked={inspectionExtractOptions.collapse_not_applicable_sections}
              onChange={(event) => {
                dispatch({
                  type: 'SET_TOOL_OPTIONS',
                  value: {
                    toolTabIndex: toolTabIndex,
                    option: {
                      ...inspectionExtractOptions,
                      collapse_not_applicable_sections: event.target.checked
                    } as InspectionExtractOptions
                  }
                });
              }} />
          </Box>
        )
      }

      <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} style={{ gap: '4px' }}>
        <ExportOptionToggle
          label={'Manually choose attachments'}
          checked={inspectionExtractOptions.attachment_option === 'manual'}
          onChange={(event) => {
            const attachmentOption = event.target.checked ? 'manual' : 'all';

            const attachmentOptionChangedToManual = inspectionExtractOptions.attachment_option !== attachmentOption && attachmentOption === 'manual'

            dispatch({
              type: 'SET_TOOL_OPTIONS',
              value: {
                toolTabIndex: toolTabIndex,
                option: {
                  ...toolTabData.extractOptions,
                  attachment_option: attachmentOption,
                  selected_attachment_options: attachmentOption === 'all' ? {} : inspectionExtractOptions.selected_attachment_options
                } as InspectionExtractOptions
              }
            });

            if (attachmentOptionChangedToManual) {
              setIsModalOpen(true);
            }
          }} />
        {
          inspectionExtractOptions.attachment_option === 'manual' && (
            <span>
              <Link style={{ color: Colors.newAccentBlue, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setIsModalOpen(true)}>
                View attachments
              </Link>
            </span>
          )
        }
      </Box>

      <ExportOptionToggle
        label={'Include full-sized image files as well as thumbnails'}
        checked={inspectionExtractOptions.image_attachment_option === 'thumbnails_and_full_size'}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...toolTabData.extractOptions,
                image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only'
              } as InspectionExtractOptions
            }
          });
        }} />

      <ExportOptionToggle
        label={'Include linked Drawings'}
        checked={inspectionExtractOptions.include_linked_drawings}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...inspectionExtractOptions,
                include_linked_drawings: event.target.checked,
                include_linked_drawing_markup: event.target.checked ? inspectionExtractOptions.include_linked_drawing_markup : false,
                include_other_markup: event.target.checked ? inspectionExtractOptions.include_other_markup : false
              }
            }
          });
        }} />

      {
        inspectionExtractOptions.include_linked_drawings && (
          <Box display={"flex"} paddingLeft={'12px'}>
            <ExportOptionToggle
              label={'Include markup'}
              labelFontSize='12px'
              checked={inspectionExtractOptions.include_linked_drawing_markup}
              onChange={(event) => {
                dispatch({
                  type: 'SET_TOOL_OPTIONS',
                  value: {
                    toolTabIndex: toolTabIndex,
                    option: {
                      ...inspectionExtractOptions,
                      include_linked_drawing_markup: event.target.checked,
                      include_other_markup: event.target.checked ? inspectionExtractOptions.include_other_markup : false
                    }
                  }
                });
              }} />
          </Box>
        )
      }

      {
        inspectionExtractOptions.include_linked_drawing_markup && (
          <Box display={"flex"} paddingLeft={'24px'}>
            <ExportOptionToggle
            label={'Include other markup'}
            labelFontSize='12px'
            checked={inspectionExtractOptions.include_other_markup}
            onChange={(event) => {
              dispatch({
                type: 'SET_TOOL_OPTIONS',
                value: {
                  toolTabIndex: toolTabIndex,
                  option: {
                    ...inspectionExtractOptions,
                    include_other_markup: event.target.checked
                  }
                }
              });
            }}/>
          </Box>
        )
      }
      <ExportOptionToggle
        label={'Include linked Observations'}
        checked={inspectionExtractOptions.include_observations}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...inspectionExtractOptions,
                include_observations: event.target.checked,
              }
            }
          });
        }}/>

      <ManualAttachmentSelectionModal
        open={isModalOpen}
        handleOnClose={() => { setIsModalOpen(false); } }
        toolTabIndex={toolTabIndex}
        toolTabData={toolTabData} />
    </Box>
  )
};

export default InspectionOptions;