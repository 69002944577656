import { gql } from "@apollo/client";
import { FilterOptionInput } from "../../types";
import { ProcoreEmail } from "../../types/email";

export type ProcoreFilterProcoreEmailData = {
  emails: ProcoreEmail[]
};

export type ProcoreProcoreEmailVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterProcoreEmailVariables = ProcoreProcoreEmailVariables & {
  filters: FilterOptionInput[]
};

export const ProcoreEmailFragment = gql`
fragment ProcoreEmailFragment on ProcoreEmail {
  id
  procoreServerId
  formattedTitle
  sentByName
}
`;

export const filterProcoreEmailsQuery = gql`
query FilterProcoreEmails($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!) {
  emails(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters) {
    ...ProcoreEmailFragment
  }
}
${ProcoreEmailFragment}
`;