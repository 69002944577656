import React, { createContext, useReducer } from 'react';
import { PackageHistoryContextType, PackageHistoryState } from './PackageContext';
import { AuthContext } from "./AuthContext";
import { AccountProjectContext } from "./AccountProjectContextProvider";
import { useGetPackageQuery } from "../graphql/queries/GetPackages";
import { rootReducer } from './PackageContext';



const PackageHistoryContext = createContext<PackageHistoryContextType>({
  dispatch: () => undefined,
  fetchPackages: () => undefined,
  state: {} as PackageHistoryState,
});

const PackageHistoryContextProvider = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const { authenticated } = React.useContext(AuthContext);
  const { state: accountProjectState } = React.useContext(
    AccountProjectContext
  );
  const { loading, error, data, called, refetch } = useGetPackageQuery(
    {
      accountId: accountProjectState.accountId,
      procoreProjectServerId: accountProjectState.procoreProjectServerId,
    },
    !authenticated || !accountProjectState.procoreProjectServerId,
    'network-only'
  );

  const [state, dispatch] = useReducer(rootReducer, {
    loading: loading,
    data: data,
    error: error,
    displayPackageInProgressToast: false,
    displaySuccessToast: {isOpen: false, message: ''},
    tabValue: 'history',
    closeoutLogEnabled: false
  });

  // TODO: Handle error or expose that via state to be handled by the consumer

  React.useEffect(() => {
    // Update loading whenever this effect is triggered.
    dispatch({
      type: 'SET_LOADING',
      value: loading,
    });

    if (loading || !data) {
      return;
    }

    dispatch({
      type: 'SET_PACKAGE_HISTORY',
      value: data,
    });
  }, [loading, data]);

  React.useEffect(() => {
    dispatch({
      type: 'SET_ERROR',
      value: error,
    });
  }, [error]);

  const fetchPackages = async () => {
    if (called && !state.loading) {
      dispatch({
        type: 'SET_LOADING',
        value: true,
      });

      refetch()
        .then(() => {
          dispatch({
            type: 'SET_LOADING',
            value: false,
          });
        })
        .catch(() => {
          dispatch({
            type: 'SET_LOADING',
            value: false,
          });
        });
    }
  };

  return (
    <PackageHistoryContext.Provider value={{ state, dispatch, fetchPackages }}>
      {props.children}
    </PackageHistoryContext.Provider>
  );
};

export { PackageHistoryContext, PackageHistoryContextProvider };
