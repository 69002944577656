import React from "react";
import { initialState, rootReducer, LinkProcoreItemsContextState } from './LinkProcoreItemsContext';
import { LogRequirementItem, ProcoreTool } from "../types";
import { TOOL_ENGINE_NAMES, itemTypeFrom, selectedItemKey } from "../utils/utils";

const LinkProcoreItemsContext = React.createContext<LinkProcoreItemsContextState>({
  dispatch: () => undefined,
  state: initialState,
});

const LinkProcoreItemsContextProvider = (props: { procoreTools: ProcoreTool[], logRequirementItems: LogRequirementItem[], children: React.ReactNode }): JSX.Element => {
  const [state, dispatch] = React.useReducer(
    rootReducer,
    {
      ...initialState,
      procoreTools: props.procoreTools,
      toolTabs: props.procoreTools.filter((t) => t.engineName !== TOOL_ENGINE_NAMES.DOCUMENTS).map(tool => {
        return {
          procoreTool: tool,
          procoreItems: [],
          filters: [],
          selectedFilters: [],
          searchValue: undefined,
          filterOpenState: {},
          selectedItems: props.logRequirementItems.reduce((acc, logRequirementItem) => {
            if (logRequirementItem.engineName === tool.engineName) {
              const key = selectedItemKey(logRequirementItem.engineName, logRequirementItem.recordServerId);
              acc[key] = {
                itemId: logRequirementItem.recordServerId,
                itemType: itemTypeFrom(logRequirementItem.engineName),
                state: 'disabled',
              };
            }

            return acc;
          }, {}),
          loading: true,
          loadingMore: false,
          failedToFetchMore: false,
          filteredProcoreItemServerIds: [],
        }
      })
    }
  );

  return (
    <LinkProcoreItemsContext.Provider value={{state, dispatch}}>
      {props.children}
    </LinkProcoreItemsContext.Provider>
  );
}

export { LinkProcoreItemsContext, LinkProcoreItemsContextProvider };