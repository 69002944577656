import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogRequirement, LogRequirementItem } from "../../../types";

export interface LogRequirementUpdate {
  logRequirement: LogRequirement;
  logRequirementItems: LogRequirementItem[];
  success: boolean;
}

export interface LogRequirementUpdateData {
  updateLogRequirement: LogRequirementUpdate | null;
}

export interface LogRequirementUpdateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logRequirementId: number;
  attributes: Record<string, any>;
}

export const useUpdateLogRequirement = createMutationHook<
LogRequirementUpdateData,
  LogRequirementUpdateVariables
>(gql`
  mutation UpdateLogRequirement(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $logRequirementId: ID!,
    $attributes: JSON!
  ) {
    updateLogRequirement(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, logRequirementId: $logRequirementId, attributes: $attributes }
    ) {
      logRequirement {
        id
        description
        status
        logRequirementType {
          id
          name
        }
        logRequirementTrade {
          id
          name
        }
        responsibleContractor {
          id
          name
          procoreServerId
        }
        specSection {
          id
          name
          divisionName
          procoreServerId
        }
        customFields {
          id
          key
          fieldType
          value {
            id
            value
          }
        }
      }
      logRequirementItems {
        id
        logRequirementId
        recordType
        recordServerId
        engineName
        payload
      }
      success
    }
  }
`);
