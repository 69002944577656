import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-premium";
import React, { useState } from "react";
import { MenuItem, Select } from "@mui/material";

export type IEditSingleSelectCellProps = {
  options: { label: string, value: string }[];
  renderEditCellParams: GridRenderEditCellParams;
}

const EditSingleSelectCell = (props: IEditSingleSelectCellProps): JSX.Element => {
  const apiRef = useGridApiContext();
  const { options, renderEditCellParams } = props;
  const { id, value: valueProp, field } = renderEditCellParams;
  const [value, setValue] = useState<string | null | undefined>(valueProp);

  const [open, setOpen] = useState(true);

  return <Select
    value={value}
    fullWidth={true}
    open={open}
    onChange={async (event) => {
      setOpen(false);
      const isValid = await apiRef.current.setEditCellValue({ id, field, value: event.target.value, debounceMs: 100 });
      setValue(event.target.value as string);
      if (isValid) {
        apiRef.current.stopCellEditMode({ id, field });
      }
    }}
    displayEmpty
  >
    {
      options.map((option) => {
        return <MenuItem onClick={(event) => {
          if (option.value === value) {
            setOpen(false);
            apiRef.current.stopCellEditMode({ id, field });
          }
        }} key={option.value} value={option.value}>{option.label}</MenuItem>
      })
    }
  </Select>
}

export default EditSingleSelectCell;