import React from "react";
import { AccountProjectState, Action, initialState, rootReducer } from "./AccountProjectContext";
import { useGetAccounts } from "../graphql/queries/GetAccounts";
import { AuthContext } from "./AuthContext";
import { CloseoutTab } from "./CloseoutLogContext";

type AccountProjectContextType = {
  state: AccountProjectState;
  dispatch: (action: Action) => void;
  getQueryParams: (projectServerId?: number) => string;
  getPath: (basePath: string) => string;
};

const getCloseoutLogViewQueryParam = (view: string): CloseoutTab => {
  if (view) {
    if (view === 'log' || view == 'requests') {
      return view;
    }
  }

  return 'log';
};

const AccountProjectContext = React.createContext<AccountProjectContextType>({
  dispatch: () => undefined,
  state: initialState,
  getQueryParams: () => undefined,
  getPath: () => undefined,
});

const AccountProjectContextProvider = (props: { procoreProjectServerIdToSelect?: number, closeoutLogIdToSelect?: number, children: React.ReactNode }):JSX.Element => {
  const { authenticated } = React.useContext(AuthContext);
  const { loading, error, data } = useGetAccounts(
    !authenticated
  );

  React.useEffect(() => {
    dispatch({
      type: 'SET_LOADING',
      value: loading
    });

    if (loading || !data) { return }

    dispatch({
      type: 'SET_ACCOUNTS',
      value: { accounts: data.accounts }
    });

  }, [loading, data]);

  React.useEffect(() => {
    dispatch({
      type: 'SET_ERROR',
      value: error
    })
  }, [error]);

  const params = new URLSearchParams(window.location.search);

  const _initialState: AccountProjectState = {
    loading: loading,
    accounts: data && data.accounts || [],
    error: error,
    accountId: null,
    internal: false,
    closeoutLogEnabled: false,
    configuredAutomations: false,
    closeoutLogFileRequestEnabled: false,
    procoreCompanyServerId: null,
    procoreProjectServerId: null,
    procoreCompanyServerIdToSelect: Number.parseInt(params.get('procoreCompanyId')) || null,
    procoreProjectServerIdToSelect: props.procoreProjectServerIdToSelect ? props.procoreProjectServerIdToSelect : Number.parseInt(params.get('procoreProjectId')) || null,
    closeoutLogSelectedTab: getCloseoutLogViewQueryParam(params.get('view')),
    closeoutLogIdToSelect: props.closeoutLogIdToSelect || null,
    locale: window.navigator.language || 'en',
    dateFormat: 'MM/DD/YYYY',
    licensed: false,
    licenseType: "",
    selectedTab: 'projects',
    automaticExportsEnabled: false,
    selectedAccount: "",
  }

  const [state, dispatch] = React.useReducer(rootReducer, _initialState);

  const getQueryParams = (projectServerId): string => {
    if (projectServerId) {
      return `?procoreProjectId=${projectServerId}`;
    } else if (state.procoreProjectServerIdToSelect) {
      return `?procoreProjectId=${state.procoreProjectServerIdToSelect}`;
    } else {
      return undefined;
    }
  }

  const getPath = (basePath: string): string => {
    const queryParams = getQueryParams(undefined);

    if (queryParams) {
      return `${basePath}${queryParams}`;
    } else {
      return basePath;
    }
  }

  return (
    <AccountProjectContext.Provider value={{state, dispatch, getQueryParams, getPath}}>
      {props.children}
    </AccountProjectContext.Provider>
  );
}

export { AccountProjectContext as AccountProjectContext, AccountProjectContextProvider as AccountProjectContextProvider };