import React from "react";
import { BaseProcoreType, DirectoryType } from "../../../types";
import { Box, Divider, ListItem, Typography } from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { checkedItems, disabledItemIds, itemTypeFrom, selectedItemKey, transformSelectedItemsFromServerIds } from "../../../utils/utils";
import { CheckedCheckBox, FilterListItemText, ManualSelectCheckbox, UncheckedCheckBox } from "../../package/styles";
import { Waypoint } from "react-waypoint";
import { Colors, LinkWithDisabled } from "../../../styles";
import { SelectedItem } from "../../../graphql/mutations/UpdatePackage";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Loading from "../Loading";
import FilterActionBar from "./FilterActionBar";
import { ProcoreToolFilter } from "../../../contexts/NewPackageContext";

interface IFilterProcoreItemsProps {
  filterActionbBarProps: {
    filters: ProcoreToolFilter[];
    selectedFilters: ProcoreToolFilter[];
    searchValue: string;
    filterOpenState: Record<string, boolean>;
    popperContainer?: Element;
    onSearchValueChanged: (value: string) => void;
    onSearchValueEntered: (value: string) => void;
    onSelectedFiltersChanged: (selectedFilters: ProcoreToolFilter[]) => void;
    onFilterOptionSelectionChange: ({ selectedFilters }: { selectedFilters?: ProcoreToolFilter[] }) => void;
    onFilterOpenStateChanged: (filterOpenState: Record<string, boolean>) => void;
  },
  procoreToolEngineName: string;
  procoreToolEngineTitle: string;
  directoryType?: DirectoryType;
  loading: boolean;
  loadingMore: boolean;
  failedToFetchMore: boolean;
  onSelectedItemsChanged: (selectedItems: Record<string, SelectedItem>) => void;
  onFetchMoreProcoreItems: () => void;
  procoreItems: BaseProcoreType[];
  filteredProcoreItemServerIds: number[];
  selectedItems: Record<string, SelectedItem>;
}

const renderVirtualizedTableRow = (
  toolState: {
    procoreToolEngineName: string,
    directoryType?: DirectoryType,
    procoreItems: BaseProcoreType[],
    selectedItems: Record<string, SelectedItem>
  },
  loadingState: { loading: boolean, loadingMore: boolean, failedToFetchMore: boolean },
  onSelectedItemsChanged: (selectedItems: Record<string, SelectedItem>) => void,
  onFetchMoreProcoreItems: () => void,
  props: ListChildComponentProps<BaseProcoreType>) => {
  const { index, style } = props;

  const procoreItem: BaseProcoreType = toolState.procoreItems[index];

  if (procoreItem) {
    const key = selectedItemKey(toolState.procoreToolEngineName, procoreItem.procoreServerId, toolState.directoryType);
    const currentSelectedItem = toolState.selectedItems[key] || { state: 'unchecked' };

    return (
      <div style={style}>
        <ListItem key={`filter-list-item-${procoreItem.id}`}>
          <ManualSelectCheckbox
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disabled={currentSelectedItem.state === 'disabled'}
            color="primary"
            icon={<UncheckedCheckBox />}
            checkedIcon={<CheckedCheckBox checkboxstyle={currentSelectedItem.state === 'disabled' ? 'disabled' : 'normal'} />}
            onClick={(evt) => {
              evt.stopPropagation();

              onSelectedItemsChanged({
                ...toolState.selectedItems,
                  [key]: {
                    itemId: procoreItem.procoreServerId,
                    itemType: itemTypeFrom(toolState.procoreToolEngineName, toolState.directoryType),
                    state: currentSelectedItem.state === 'checked' ? 'unchecked' : 'checked'
                  }
              });
            }}
            checked={currentSelectedItem.state === 'checked' || currentSelectedItem.state === 'disabled'}
          />
          <FilterListItemText
            textcolorstyle={currentSelectedItem.state === 'disabled' ? 'disabled' : 'normal'}
            disableTypography={true}
            key={`filter-list-item-text-${procoreItem.id}`}
          >
            {procoreItem.formattedTitle}
          </FilterListItemText>
        </ListItem>
        <Divider key={`filter-list-item-divider-${procoreItem.id}`} />
      </div>
    );
  } else {
    return <div style={style}>
      <Waypoint onEnter={() => {
        if (loadingState.loadingMore || loadingState.failedToFetchMore) { return; }

        onFetchMoreProcoreItems();
      }}>
        <ListItem key={`filter-list-item-fetch-more`}>
          { loadingState.failedToFetchMore ? (
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} style={{ gap: '4px' }}>
              <Typography style={{ fontSize: '1.125em', color: Colors.darkerGray }}>Failed to load more data.</Typography>
              <a href="#" onClick={() => {
                onFetchMoreProcoreItems();
              }}>Retry</a>
            </Box>
          ) : (
            <FilterListItemText
              disableTypography={true}
              key={`filter-list-item-text-fetch-more`}
            >
              { loadingState.loadingMore ? "Loading more data..." : "Load more data." }
            </FilterListItemText>
          )}

        </ListItem>
      </Waypoint>
    </div>
  }
}

const FilterProcoreItems = (props: IFilterProcoreItemsProps): JSX.Element => {
  const {
    procoreToolEngineName,
    directoryType,
    procoreToolEngineTitle,
    loading,
    loadingMore,
    failedToFetchMore,
    procoreItems,
    selectedItems,
    filteredProcoreItemServerIds,
    onSelectedItemsChanged,
    onFetchMoreProcoreItems,
  } = props;

  const onlyCheckedSelectedItems = React.useMemo(() => { return checkedItems(selectedItems) }, [selectedItems]);

  const disabledSelectedItemIds = React.useMemo(() => { return disabledItemIds(selectedItems) }, []);

  const everyFilteredProcoreItemServerIdSelected = filteredProcoreItemServerIds.every(procoreItemServerId => {
    const selectedItem = selectedItems[selectedItemKey(procoreToolEngineName, procoreItemServerId, directoryType)] || { state: 'unchecked' }
    return selectedItem.state === 'checked' || selectedItem.state === 'disabled';
  });

  const virtualizedItemCount = (procoreItems.length < filteredProcoreItemServerIds.length) ? procoreItems.length + 1 : procoreItems.length;

  return (
    <>
      <Box
        width={1}
        style={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor:
            onlyCheckedSelectedItems.length === 0 ? "#E4EBF0" : "#D2E9FA",
          background:
            onlyCheckedSelectedItems.length === 0 ? "white" : "#F5FBFF",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "row",
          marginBottom: "12px",
          padding: "14px 20px",
          alignItems: "center",
          minHeight: "57px",
        }}
      >
        {onlyCheckedSelectedItems.length === 0 ? (
          <InfoOutlinedIcon
            style={{ marginRight: "10px" }}
            htmlColor="#B5D7F5"
          />
        ) : (
          <CheckCircleSharpIcon
            style={{ marginRight: "10px" }}
            htmlColor={Colors.newAccentBlue}
          />
        )}
        {onlyCheckedSelectedItems.length === 0 ? (
          <Typography
            style={{ fontSize: 14, fontWeight: 500, color: Colors.darkerGray }}
          >
            {`Select some ${procoreToolEngineTitle}`}
          </Typography>
        ) : (
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={1}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: 700,
                  color: Colors.darkerGray,
                  marginRight: "6px",
                }}
              >
                {onlyCheckedSelectedItems.length}
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: Colors.darkerGray,
                }}
              >
                {`${procoreToolEngineTitle} selected`}
              </Typography>
            </Box>
            <LinkWithDisabled disabled={loading} onClick={() => {
              onSelectedItemsChanged(
                {
                  ...transformSelectedItemsFromServerIds(
                    procoreToolEngineName,
                    filteredProcoreItemServerIds,
                    'unchecked',
                    directoryType
                  ),
                  ...transformSelectedItemsFromServerIds(
                    procoreToolEngineName,
                    disabledSelectedItemIds,
                    'disabled',
                    directoryType
                  ),
                }
              );
            }}>Deselect All</LinkWithDisabled>
          </Box>
        )}
      </Box>
      <Box
        style={{
          border: "solid 1px #E4EBF0",
          borderRadius: "6px",
        }}
        display="flex"
        flexDirection="column"
        height={1}
        width={1}
      >
        {loading ? (
          <Loading
            loadingLabel={"Loading items..."}
            loadingSubLabel={""}
          />
        ) : (
          <>
            <FilterActionBar
              filters={props.filterActionbBarProps.filters}
              allItemsAreSelected={everyFilteredProcoreItemServerIdSelected}
              selectedFilters={props.filterActionbBarProps.selectedFilters}
              filterOpenState={props.filterActionbBarProps.filterOpenState}
              searchValue={props.filterActionbBarProps.searchValue}
              onSearchValueChanged={props.filterActionbBarProps.onSearchValueChanged}
              onSearchValueEntered={props.filterActionbBarProps.onSearchValueEntered}
              onFilterOptionSelectionChange={props.filterActionbBarProps.onFilterOptionSelectionChange}
              disabled={props.loading || props.loadingMore}
              onFilterOpenStateChanged={props.filterActionbBarProps.onFilterOpenStateChanged}
              onSelectedFiltersChanged={props.filterActionbBarProps.onSelectedFiltersChanged}
              popperContainer={props.filterActionbBarProps.popperContainer}
              onSelectAllButtonClicked={() => {
                if (everyFilteredProcoreItemServerIdSelected) {
                  onSelectedItemsChanged(
                    {
                      ...transformSelectedItemsFromServerIds(
                        procoreToolEngineName,
                        filteredProcoreItemServerIds,
                        'unchecked',
                        directoryType
                      ),
                      ...transformSelectedItemsFromServerIds(
                        procoreToolEngineName,
                        disabledSelectedItemIds,
                        'disabled',
                        directoryType
                      ),
                    }
                  )
                } else {
                  onSelectedItemsChanged(
                    {
                      ...transformSelectedItemsFromServerIds(
                        procoreToolEngineName,
                        filteredProcoreItemServerIds,
                        'checked',
                        directoryType
                      ),
                      ...transformSelectedItemsFromServerIds(
                        procoreToolEngineName,
                        disabledSelectedItemIds,
                        'disabled',
                        directoryType
                      ),
                    }
                  )
                }
              }} />
            <Divider />
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
              <div style={{ flex: "1 1 1px" }}>
                <AutoSizer>
                  {({ height, width }) => (
                    <FixedSizeList
                      height={height}
                      width={width}
                      itemCount={virtualizedItemCount}
                      itemSize={46}
                    >
                      {({ ...props }) => {
                        return renderVirtualizedTableRow(
                          {
                            procoreToolEngineName: procoreToolEngineName,
                            directoryType: directoryType,
                            procoreItems: procoreItems,
                            selectedItems: selectedItems
                          },
                          {
                            loading: loading,
                            loadingMore: loadingMore,
                            failedToFetchMore: failedToFetchMore,
                          },
                          onSelectedItemsChanged,
                          onFetchMoreProcoreItems,
                          props
                        );
                      }}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </div>
            </div>
            <Box padding={'8px'} borderTop={'1px solid rgb(228, 235, 240)'}>
              <Typography style={{
                fontSize: '12px',
                color: '#A0B0BA',
                textAlign: 'center',
                fontWeight: '400'
              }}>{`Showing ${procoreItems.length} of ${filteredProcoreItemServerIds.length} items`}</Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default FilterProcoreItems;