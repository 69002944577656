import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface TemplateLogRequirementTradeDeleteData {
  deleteTemplateLogRequirementTrade: {
    success: boolean;
  }
}

export interface TemplateLogRequirementTradeDeleteVariables {
  accountId: string;
  templateCloseoutLogId: number;
  id: number;
}

export const useDeleteTemplateLogRequirementTrade = createMutationHook<
  TemplateLogRequirementTradeDeleteData,
  TemplateLogRequirementTradeDeleteVariables
>(gql`
  mutation DeleteTemplateLogRequirementTrade(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $id: ID!
  ) {
    deleteTemplateLogRequirementTrade(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, id: $id }
    ) {
      success
    }
  }
`);
