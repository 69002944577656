import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogRequirement, LogRequirementItem } from "../../../types";

export interface LogRequirementsCreate {
  logRequirements: LogRequirement[];
  logRequirementItems: LogRequirementItem[];
  success: boolean;
}

export interface LogRequirementsCreateData {
  createLogRequirements: LogRequirementsCreate | null;
}

export interface LogRequirementCreateAttributes {
  logRequirementTypeId?: number;
  logRequirementTypeName: string;
  description?: string;
  responsibleContractorServerId?: number;
  responsibleContractor?: string;
  specSectionServerId?: number;
  specSection?: string;
  logRequirementTradeId?: number;
  customFields: Record<string, number>;
}

export interface LogRequirementsCreateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logRequirementAttributes: LogRequirementCreateAttributes[];
}

export const useCreateLogRequirements = createMutationHook<
  LogRequirementsCreateData,
  LogRequirementsCreateVariables
>(gql`
  mutation CreateLogRequirements(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $logRequirementAttributes: [LogRequirementCreateAttributes!]!
  ) {
    createLogRequirements(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, logRequirementAttributes: $logRequirementAttributes }
    ) {
      logRequirements {
        id
        description
        status
        logRequirementType {
          id
          name
        }
        logRequirementTrade {
          id
          name
        }
        responsibleContractor {
          id
          name
          procoreServerId
        }
        specSection {
          id
          name
          divisionName
          procoreServerId
        }
        customFields {
          id
          key
          fieldType
          value {
            id
            value
          }
        }
      }
      logRequirementItems {
        id
        logRequirementId
        recordType
        recordServerId
        engineName
        payload
      }
      success
    }
  }
`);
