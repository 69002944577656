import React, { useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PackageHistory from "../components/packages/PackageHistory";
import NewPackageContainer from "../components/package/NewPackageContainer";
import Login from "../components/Login";
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  Box,
} from "@mui/material";
import { theme } from "../styles";
import {
  AccountProjectContext,
  AccountProjectContextProvider,
} from "../contexts/AccountProjectContextProvider";
import { AuthContext } from "../contexts/AuthContext";
import Error from "../components/Error";
import { PackageHistoryContextProvider } from "../contexts/PackageContextProvider";
import TeamsContainer from "../components/package/TeamsContainer";
import PaymentConfirmation from "../components/PaymentConfirmation";
import { ComponentSheet } from "../components/ComponentSheet";
import { BreadcrumbNav } from "../components/shared/BreadcrumbNav";
import { CloseoutLogContextProvider } from "../contexts/CloseoutLogContextProvider";
import CloseoutLog from "../components/CloseoutLog";
import ExtractCloseoutLog from "../components/ExtractCloseoutLog";
import NewPackageSnackbar from "../components/NewPackageSnackbar";
import NewAutomation from "../components/automated-extract/NewAutomation";
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend'
import { NewAutomationContextProvider } from "../contexts/EditAutomationContextProvider";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { parseLocationPathname } from "../utils/utils";
import { TemplateCloseoutLogContextProvider } from "../contexts/TemplateCloseoutLogContextProvider";
import EditTemplateCloseoutLog from "../components/template-closeout-log/EditTemplateCloseoutLog";

interface AppProps {
  authenticated: boolean;
  procoreAuthUrl: string;
}

const PrivateRoute = (props: any) => {
  const { component: Component, ignoreProcoreContext, validateAccountId, ...rest } = props;
  const { state } = React.useContext(AccountProjectContext);
  const { authenticated } = React.useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props: any) => {
        return authenticated ? (
          state.procoreProjectServerId || ignoreProcoreContext ? (
            validateAccountId ? (
              state.selectedAccount ? (
                <Box height={1} display="flex" flexDirection="column">
                  <BreadcrumbNav />
                  <Component {...props} />
                </Box>
              ) : (
                <Redirect to={{ pathname: "/teams" }} />
              )
            ) : (
              <Box height={1} display="flex" flexDirection="column">
                <BreadcrumbNav />
                <Component {...props} />
              </Box>
            )
          ) : (
            <Redirect to={{ pathname: "/teams" }} />
          )
        ) : (
          <Redirect
            to={{ pathname: "/login", search: props.location.search }}
          />
        );
      }}
    />
  );
};

const App = ({
  authenticated: loggedIn,
  procoreAuthUrl,
}: AppProps): JSX.Element => {
  const [authenticated, setAuthenticated] = useState(loggedIn);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userCreatedAt, setUserCreatedAt] = useState<number | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  const [parsedLocationPath] = React.useState(parseLocationPathname(window.location.pathname))

  const customSetAuthenticated = (value: boolean):void => {
    setAuthenticated(value);

    if (!value) {
      setUserEmail(null);
      setUserCreatedAt(null);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst={false}>
          <AuthContext.Provider
            value={{
              authenticated: authenticated,
              redirectPathContext: parsedLocationPath,
              setAuthenticated: customSetAuthenticated,
              setCurrentUserInfo: (userId, email, createdAt) => {
                setUserId(userId);
                setUserEmail(email);
                setUserCreatedAt(createdAt);
              }
            }}
          >
            <AccountProjectContextProvider
              procoreProjectServerIdToSelect={parsedLocationPath.procoreProjectServerId}
              closeoutLogIdToSelect={parsedLocationPath.closeoutLogId}>
              <NewAutomationContextProvider>
                <PackageHistoryContextProvider>
                  <NewPackageSnackbar />
                  <DndProvider backend={HTML5Backend}>
                    <TemplateCloseoutLogContextProvider>
                      <CloseoutLogContextProvider>
                        <CssBaseline />
                        <BrowserRouter>
                          <Switch>
                            <Route
                              path="/close"
                              render={() => {
                                return <></>
                              }}
                            />
                            <PrivateRoute
                              path="/teams/:accountId/payment-confirmation/:sessionId"
                              ignoreProcoreContext={true}
                              component={PaymentConfirmation}
                            />
                            <PrivateRoute
                              path="/teams/:accountId/projects/:procoreProjectId/packages/new"
                              component={NewPackageContainer}
                            />
                            <PrivateRoute
                              path="/teams/:accountId/automations/new"
                              ignoreProcoreContext={true}
                              component={NewAutomation}
                            />
                            <PrivateRoute
                              path="/teams/:accountId/automations/:id/edit"
                              ignoreProcoreContext={true}
                              validateAccountId={true}
                              component={NewAutomation}
                            />
                            <PrivateRoute
                              path="/teams/:accountId/template_logs/:id/edit"
                              ignoreProcoreContext={true}
                              validateAccountId={true}
                              component={EditTemplateCloseoutLog}
                            />
                            <PrivateRoute
                              path="/teams/:accountId/projects/:procoreProjectId/closeout_logs/:id/extract"
                              component={ExtractCloseoutLog}
                            />
                            <PrivateRoute
                              path="/teams/:accountId/projects/:procoreProjectId/closeout_logs/:id"
                              component={CloseoutLog}
                            />
                            <PrivateRoute
                              path="/teams/:accountId/projects/:procoreProjectId/packages"
                              component={PackageHistory}
                            />
                            <PrivateRoute
                              path="/teams"
                              exact
                              ignoreProcoreContext={true}
                              component={TeamsContainer}
                            />
                            <Route
                              path="/login"
                              render={({ location }) => {
                                if (authenticated) {
                                  return (
                                    <Redirect
                                      to={{
                                        pathname: "/teams",
                                        search: location.search,
                                      }}
                                    />
                                  );
                                } else {
                                  return <Login procoreAuthUrl={procoreAuthUrl} />;
                                }
                              }}
                            />
                            <Route path="/error" component={Error} />
                            <Route path="/internal/components" component={ComponentSheet} />
                            <Route
                              path="/"
                              render={({ location }) => {
                                if (authenticated) {
                                  return (
                                    <Redirect
                                      to={{
                                        pathname: "/teams",
                                        search: location.search,
                                      }}
                                    />
                                  );
                                } else {
                                  return (
                                    <Redirect
                                      to={{ pathname: "/login", search: location.search }}
                                    />
                                  );
                                }
                              }}
                            />
                          </Switch>
                        </BrowserRouter>
                      </CloseoutLogContextProvider>
                    </TemplateCloseoutLogContextProvider>
                  </DndProvider>
                </PackageHistoryContextProvider>
              </NewAutomationContextProvider>
            </AccountProjectContextProvider>
          </AuthContext.Provider>
        </StyledEngineProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
