import { QueryResult, gql, useQuery } from '@apollo/client';
import { ProcoreProjectExtractAutomation } from '../../../types';

export type ProcoreProjectExtractAutomationData = {
  procoreProjectExtractAutomations: ProcoreProjectExtractAutomation[];
};

export type ProcoreProjectExtractAutomationDataVariables = {
  accountId: string;
  extractAutomationId: string;
};

const getProcoreProjectExtractAutomationsQuery = gql`
  query GetProcoreProjectExtractAutomations($accountId: ID!, $extractAutomationId: ID!) {
    procoreProjectExtractAutomations(accountId: $accountId, extractAutomationId: $extractAutomationId) {
      id
      automationType
      procoreCompanyServerId
      procoreProjectServerId
      enabled
    }
  }
`;

export const useGetProcoreProjectExtractAutomations = (
  accountId: string,
  extractAutomationId: string
): QueryResult<ProcoreProjectExtractAutomationData> =>
  useQuery<ProcoreProjectExtractAutomationData, ProcoreProjectExtractAutomationDataVariables>(
    getProcoreProjectExtractAutomationsQuery,
    {
      variables: {
        accountId: accountId,
        extractAutomationId: extractAutomationId,
      },
      fetchPolicy: 'network-only',
    }
  );
