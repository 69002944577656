import { gql } from "@apollo/client";
import { createQueryHook } from "../../GraphqlHelpers";

export type CloseoutLogDirectoryData = {
  closeoutLogDirectory: {
    directoryVendorProcoreServerIds: number[];
    directoryUserProcoreServerIds: number[];
  }
};

export type CloseoutLogDirectoryDataVariables = {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
};

export const GetCloseoutLogDirectoryQuery = gql`
  query GetCloseoutLogDirectory(
    $accountId: ID!
    $procoreProjectServerId: ID!
    $closeoutLogId: ID!
  ) {
    closeoutLogDirectory(
      accountId: $accountId
      procoreProjectServerId: $procoreProjectServerId
      closeoutLogId: $closeoutLogId
    ) {
      directoryVendorProcoreServerIds
      directoryUserProcoreServerIds
    }
  }
`;

export const useGetCloseoutLogDirectory = createQueryHook<
  CloseoutLogDirectoryData,
  CloseoutLogDirectoryDataVariables
>(GetCloseoutLogDirectoryQuery);
