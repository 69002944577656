import { gql } from "@apollo/client";
import { createQueryHook } from "../../GraphqlHelpers";
import { ExtractAutomation } from "../../../types";

export type ExtractAutomationData = {
  extractAutomations: ExtractAutomation[];
};

export type ExtractAutomationDataVariables = {
  accountId: string;
};

const GetExtractAutomationsQuery = gql`
  query GetExtractAutomations(
    $accountId: ID!
  ) {
    extractAutomations(accountId: $accountId) {
      id
      name
      createdAt
      enabled
      enabledProjectsCount
    }
  }
`;

export const useGetExtractAutomations = createQueryHook<
  ExtractAutomationData,
  ExtractAutomationDataVariables
>(GetExtractAutomationsQuery);
