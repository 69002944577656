import { Box } from '@mui/material';
import React from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { ObservationExtractOptions } from '../../types/observation';
import { ToolTabData } from '../../contexts/NewPackageContext';
import ExportOptionToggle from './ExportOptionToggle';


const ObservationOptions = (props: { toolTabIndex: number, toolTabData: ToolTabData }): JSX.Element => {
  const { toolTabIndex, toolTabData } = props;

  const { dispatch } = React.useContext(NewPackageContext);

  return (
    <Box display="flex" flexDirection="column" width={1} height={1} padding={'20px 40px'} style={{ overflowY: 'auto' }}>
      <h3>Image Attachments</h3>
      <ExportOptionToggle
        label={'Include thumbnails and full sized images.'}
        checked={(toolTabData.extractOptions as ObservationExtractOptions).image_attachment_option === 'thumbnails_and_full_size'}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...toolTabData.extractOptions,
                image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only'
              } as ObservationExtractOptions
            }
          });
        }} />

      <h3>Drawings</h3>
      <ExportOptionToggle
        label={'Include markup.'}
        checked={(toolTabData.extractOptions as ObservationExtractOptions).include_linked_drawing_markup}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...toolTabData.extractOptions,
                include_linked_drawing_markup: event.target.checked
              } as ObservationExtractOptions
            }
          });
        }} />
    </Box>
  )
};

export default ObservationOptions;