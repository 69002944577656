import React from "react";
import { CloseoutLogContextState, initialState, rootReducer } from './CloseoutLogContext';

const CloseoutLogContext = React.createContext<CloseoutLogContextState>({
  dispatch: () => undefined,
  state: initialState,
});

const CloseoutLogContextProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const [state, dispatch] = React.useReducer(rootReducer, initialState);

  return (
    <CloseoutLogContext.Provider value={{state, dispatch}}>
      {props.children}
    </CloseoutLogContext.Provider>
  );
}

export { CloseoutLogContext, CloseoutLogContextProvider };