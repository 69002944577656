import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

interface LoadingProps {
  loadingLabel: string
  loadingSubLabel?: string
}

const Loading = ({ loadingLabel, loadingSubLabel }: LoadingProps): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" height={1} alignItems="center" justifyContent="center">
      <Typography variant="h6" style={{ marginBottom: '10px'}}>{loadingLabel}</Typography>
      { loadingSubLabel &&
        (
          <Typography variant='subtitle1' style={{ marginBottom: '20px'}}>{loadingSubLabel}</Typography>
        )
      }
      <CircularProgress />
    </Box>
  );
}

export default Loading;