import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface PackageDiscard {
  success: boolean;
}

export interface PackageDiscardData {
  discardPackage: PackageDiscard | null
}

export interface PackageDiscardVariables {
  id: number;
  accountId: string;
  procoreProjectServerId: number;
}

export const useDiscardPackage = createMutationHook<PackageDiscardData, PackageDiscardVariables>(gql`
mutation DiscardPackage($id: ID!, $accountId: ID!, $procoreProjectServerId: ID!) {
  discardPackage(input: { id: $id, accountId: $accountId, procoreProjectServerId: $procoreProjectServerId }) {
    success
  }
}
`);