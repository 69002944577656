import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogCustomField } from "../../../types";

export interface LogCustomFieldUpsert {
  logCustomField?: LogCustomField;
  success: boolean;
}

export interface LogCustomFieldUpsertData {
  upsertLogCustomField: LogCustomFieldUpsert | null;
}
export interface LogCustomFieldLovEntryAttributes {
  id?: number;
  value: string;
}

export interface LogCustomFieldUpsertAttributes {
  id?: number;
  name: string;
  values: LogCustomFieldLovEntryAttributes[];
}

export interface LogCustomFieldUpsertVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  customField: LogCustomFieldUpsertAttributes;
}

export const useUpsertLogCustomField = createMutationHook<
LogCustomFieldUpsertData,
LogCustomFieldUpsertVariables
>(gql`
  mutation UpsertLogCustomField(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $customField: LogCustomFieldUpsertAttributes!
  ) {
    upsertLogCustomField(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, customField: $customField }
    ) {
      logCustomField {
        id
        name
        fieldType
        key
        values {
          id
          value
        }
      }
      success
    }
  }
`);
