import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface LogRequirementTypeDeleteData {
  deleteLogRequirementType: {
    success: boolean;
  }
}

export interface LogRequirementTypeDeleteVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  id: number;
}

export const useDeleteLogRequirementType = createMutationHook<
  LogRequirementTypeDeleteData,
  LogRequirementTypeDeleteVariables
>(gql`
  mutation DeleteLogRequirementType(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $id: ID!
  ) {
    deleteLogRequirementType(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, id: $id }
    ) {
      success
    }
  }
`);
