import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface FeedbackCreate {
  success: boolean;
}

export interface FeedbackCreateData {
  createPackage: FeedbackCreate | null
}

export interface FeedbackCreateVariables {
  feedbackType: string;
  message: string;
}

export const useCreateFeedback = createMutationHook<FeedbackCreateData, FeedbackCreateVariables>(gql`
mutation CreateFeedback($feedbackType: String!, $message: String!) {
  createFeedback(input: { feedbackType: $feedbackType, message: $message }) {
    success
  }
}
`);