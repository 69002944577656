import React from "react";
import { useCreateImportResourceSync } from "../graphql/mutations/closeout/CreateImportResourceSync";
import { useGetImportResourceSync } from "../graphql/queries/closeout/GetImportResourceSync";
import { ProcoreTool } from "../types";
import { useGetToolFilters } from "../graphql/queries/GetProcoreToolFilters";
import { ProcoreItem, ProcoreToolFilter } from "../contexts/NewPackageContext";
import { LinkProcoreItemsContext } from "../contexts/LinkProcoreItemsContextProvider";
import FilterProcoreItems from "./shared/filters/FilterProcoreItems";
import { Action, LinkProcoreItemToolTab } from "../contexts/LinkProcoreItemsContext";
import { PAGINATION, TOOL_ENGINE_NAMES, procoreFilterToInputFilters } from "../utils/utils";
import { DocumentNode, useApolloClient } from "@apollo/client";
import { ProcoreFilterPhotoData, fetchPaginatedProcorePhotosQuery, filterProcorePhotosQuery } from "../graphql/queries/GetProcorePhotos";
import { ProcoreFilterDrawingData, fetchPaginatedProcoreDrawingsQuery, filterProcoreDrawingsQuery } from "../graphql/queries/GetProcoreDrawings";
import { ProcoreFilterRfiData, fetchPaginatedProcoreRfisQuery, filterProcoreRfisQuery } from "../graphql/queries/GetProcoreRfis";
import { ProcoreFilterSubmittalData, fetchPaginatedProcoreSubmittalsQuery, filterProcoreSubmittalsQuery } from "../graphql/queries/GetProcoreSubmittals";
import { ProcoreFilterPunchItemData, fetchPaginatedProcorePunchItemsQuery, filterProcorePunchItemsQuery } from "../graphql/queries/GetProcorePunchItems";
import { fetchPaginatedProcoreFormsQuery, filterProcoreFormsQuery, ProcoreFilterProcoreFormData } from "../graphql/queries/GetProcoreForms";
import { fetchPaginatedInspectionsQuery, filterProcoreInspectionsQuery, ProcoreFilterInspectionData } from "../graphql/queries/GetProcoreInspections";
import { fetchPaginatedProcoreObservationsQuery, filterProcoreObservationsQuery, ProcoreFilterObservationData } from "../graphql/queries/GetProcoreObservations";
import { fetchPaginatedProcoreMeetingsQuery, filterProcoreMeetingsQuery, ProcoreFilterMeetingData } from "../graphql/queries/GetProcoreMeetings";
import { fetchPaginatedProcoreActionPlansQuery, filterProcoreActionPlansQuery, ProcoreFilterActionPlanData } from "../graphql/queries/GetProcoreActionPlans";

interface ILinkProcoreItemsTabProps {
  accountId: string;
  procoreProjectServerId: number;
  toolTab: LinkProcoreItemToolTab;
  popperContainer?: Element;
}

function useImportResourceSync(props: {
  accountId: string;
  procoreProjectServerId: number;
  procoreTool: ProcoreTool;
  dispatch: (action: Action) => void;
  onFilterOptionSelectionChange: ({
    selectedFilters,
  }: {
    selectedFilters?: ProcoreToolFilter[];
  }) => void;
}) {
  const [getImportResourceSyncData, setImportResourceSyncData] =
    React.useState<{ id: number; skip: boolean }>({ id: 0, skip: true });

  const [
    createImportResourceSync,
    {
      loading: createImportResourceSyncLoading,
      data: createImportResourceSyncData,
    },
  ] = useCreateImportResourceSync({
    accountId: props.accountId,
    procoreProjectServerId: props.procoreProjectServerId,
    resourceType: props.procoreTool.engineName,
  });

  const {
    loading: importResourceSyncLoading,
    data: importResourceSyncData,
    startPolling,
    stopPolling,
  } = useGetImportResourceSync(
    {
      accountId: props.accountId,
      procoreProjectServerId: props.procoreProjectServerId,
      id: getImportResourceSyncData.id,
    },
    getImportResourceSyncData.skip
  );

  React.useEffect(() => {
    createImportResourceSync();
  }, []);

  // Upon kicking off a request to fetch tool data asynchronously this will
  // record the state and trigger polling every X seconds.
  React.useEffect(() => {
    if (!createImportResourceSyncData || createImportResourceSyncLoading) {
      return;
    }

    const importResourceSync =
      createImportResourceSyncData.createImportResourceSync.importResourceSync;

    setImportResourceSyncData({
      id: importResourceSync.id,
      skip: false,
    });

    if (importResourceSync.status !== "synced") {
      startPolling(3000);
    }
  }, [createImportResourceSyncData]);

  React.useEffect(() => {
    if (!importResourceSyncData || importResourceSyncLoading) {
      return;
    }

    if (importResourceSyncData.importResourceSync.status === "synced") {
      stopPolling();

      loadProcoreToolFilters();
    }
  }, [importResourceSyncData]);

  const [
    loadProcoreToolFilters,
    { loading: loadingProcoreToolFilters, data: procoreToolFiltersData },
  ] = useGetToolFilters({
    accountId: props.accountId,
    procoreProjectServerId: props.procoreProjectServerId,
    procoreToolId: props.procoreTool.id,
    directoryType: props.procoreTool?.directoryType,
  });

  React.useEffect(() => {
    if (!procoreToolFiltersData || loadingProcoreToolFilters) {
      return;
    }

    props.dispatch({
      type: "SET_FILTERS",
      value: {
        procoreToolId: props.procoreTool.id,
        filters: procoreToolFiltersData.filters,
        selectedFilters: procoreToolFiltersData.defaultFilters,
      },
    });

    props.onFilterOptionSelectionChange({
      selectedFilters: procoreToolFiltersData.defaultFilters,
    });
  }, [procoreToolFiltersData]);

  return { procoreToolFiltersData };
}

const queryForProcoreToolEngine = (engineName: string): DocumentNode => {
  switch(engineName) {
    case TOOL_ENGINE_NAMES.SUBMITTALS:
      return filterProcoreSubmittalsQuery;
    case TOOL_ENGINE_NAMES.RFIS:
      return filterProcoreRfisQuery;
    case TOOL_ENGINE_NAMES.DRAWINGS:
      return filterProcoreDrawingsQuery;
    case TOOL_ENGINE_NAMES.PUNCH_LIST:
      return filterProcorePunchItemsQuery;
    case TOOL_ENGINE_NAMES.PHOTOS:
      return filterProcorePhotosQuery;
    case TOOL_ENGINE_NAMES.FORMS:
      return filterProcoreFormsQuery;
    case TOOL_ENGINE_NAMES.INSPECTIONS:
      return filterProcoreInspectionsQuery;
    case TOOL_ENGINE_NAMES.OBSERVATIONS:
      return filterProcoreObservationsQuery;
    case TOOL_ENGINE_NAMES.MEETINGS:
      return filterProcoreMeetingsQuery;
    case TOOL_ENGINE_NAMES.ACTION_PLANS:
      return filterProcoreActionPlansQuery;
    default:
      return null;
  }
};

const fetchMoreProcoreItemsQueryForProcoreToolEngine = (engineName: string): DocumentNode => {
  switch(engineName) {
    case TOOL_ENGINE_NAMES.SUBMITTALS:
      return fetchPaginatedProcoreSubmittalsQuery;
    case TOOL_ENGINE_NAMES.RFIS:
      return fetchPaginatedProcoreRfisQuery;
    case TOOL_ENGINE_NAMES.DRAWINGS:
      return fetchPaginatedProcoreDrawingsQuery;
    case TOOL_ENGINE_NAMES.PUNCH_LIST:
      return fetchPaginatedProcorePunchItemsQuery;
    case TOOL_ENGINE_NAMES.PHOTOS:
      return fetchPaginatedProcorePhotosQuery;
    case TOOL_ENGINE_NAMES.FORMS:
      return fetchPaginatedProcoreFormsQuery;
    case TOOL_ENGINE_NAMES.INSPECTIONS:
      return fetchPaginatedInspectionsQuery;
    case TOOL_ENGINE_NAMES.OBSERVATIONS:
      return fetchPaginatedProcoreObservationsQuery;
    case TOOL_ENGINE_NAMES.MEETINGS:
      return fetchPaginatedProcoreMeetingsQuery;
    case TOOL_ENGINE_NAMES.ACTION_PLANS:
      return fetchPaginatedProcoreActionPlansQuery;
    default:
      return null;
  }
}

const processInitialDataLoad = (engineName: string, data: any): { procoreItems: ProcoreItem[]; filteredProcoreItemServerIds: number[] } => {
  switch(engineName) {
    case TOOL_ENGINE_NAMES.DRAWINGS: {
      const newData = (data as ProcoreFilterDrawingData);
      return {
        procoreItems: newData.drawings,
        filteredProcoreItemServerIds: newData.toolRecordServerIds.procoreServerIds
      }
    }

    case TOOL_ENGINE_NAMES.RFIS: {
      const newData = (data as ProcoreFilterRfiData);
      return {
        procoreItems: newData.rfis,
        filteredProcoreItemServerIds: newData.toolRecordServerIds.procoreServerIds
      }
    }

    case TOOL_ENGINE_NAMES.SUBMITTALS: {
      const newData = (data as ProcoreFilterSubmittalData);
      return {
        procoreItems: newData.submittals,
        filteredProcoreItemServerIds: newData.toolRecordServerIds.procoreServerIds
      }
    }

    case TOOL_ENGINE_NAMES.PHOTOS: {
      const newData = (data as ProcoreFilterPhotoData);
      return {
        procoreItems: newData.photos,
        filteredProcoreItemServerIds: newData.toolRecordServerIds.procoreServerIds
      }
    }

    case TOOL_ENGINE_NAMES.PUNCH_LIST: {
      const newData = (data as ProcoreFilterPunchItemData);
      return {
        procoreItems: newData.punchItems,
        filteredProcoreItemServerIds: newData.toolRecordServerIds.procoreServerIds
      }
    }

    case TOOL_ENGINE_NAMES.FORMS: {
      const newData = (data as ProcoreFilterProcoreFormData);
      return {
        procoreItems: newData.forms,
        filteredProcoreItemServerIds: newData.toolRecordServerIds.procoreServerIds
      }
    }

    case TOOL_ENGINE_NAMES.INSPECTIONS: {
      const newData = (data as ProcoreFilterInspectionData);
      return {
        procoreItems: newData.inspections,
        filteredProcoreItemServerIds: newData.toolRecordServerIds.procoreServerIds
      }
    }

    case TOOL_ENGINE_NAMES.OBSERVATIONS: {
      const newData = (data as ProcoreFilterObservationData);
      return {
        procoreItems: newData.observations,
        filteredProcoreItemServerIds: newData.toolRecordServerIds.procoreServerIds
      }
    }

    case TOOL_ENGINE_NAMES.MEETINGS: {
      const newData = (data as ProcoreFilterMeetingData);
      return {
        procoreItems: newData.meetings,
        filteredProcoreItemServerIds: newData.toolRecordServerIds.procoreServerIds
      }
    }

    case TOOL_ENGINE_NAMES.ACTION_PLANS: {
      const newData = (data as ProcoreFilterActionPlanData);
      return {
        procoreItems: newData.actionPlans,
        filteredProcoreItemServerIds: newData.toolRecordServerIds.procoreServerIds
      }
    }

    default: {
      return {
        procoreItems: [],
        filteredProcoreItemServerIds: []
      };
    }
  }
};

const processPaginatedDataLoad = (engineName: string, data: any): ProcoreItem[] => {
  switch(engineName) {
    case TOOL_ENGINE_NAMES.DRAWINGS: {
      const newData = (data as ProcoreFilterDrawingData);
      return newData.drawings;
    }

    case TOOL_ENGINE_NAMES.RFIS: {
      const newData = (data as ProcoreFilterRfiData);
      return newData.rfis;
    }

    case TOOL_ENGINE_NAMES.SUBMITTALS: {
      const newData = (data as ProcoreFilterSubmittalData);

      return newData.submittals;
    }

    case TOOL_ENGINE_NAMES.PHOTOS: {
      const newData = (data as ProcoreFilterPhotoData);
      return newData.photos;
    }

    case TOOL_ENGINE_NAMES.PUNCH_LIST: {
      const newData = (data as ProcoreFilterPunchItemData);
      return newData.punchItems;
    }

    case TOOL_ENGINE_NAMES.FORMS: {
      const newData = (data as ProcoreFilterProcoreFormData);
      return newData.forms;
    }

    case TOOL_ENGINE_NAMES.INSPECTIONS: {
      const newData = (data as ProcoreFilterInspectionData);
      return newData.inspections;
    }

    case TOOL_ENGINE_NAMES.OBSERVATIONS: {
      const newData = (data as ProcoreFilterObservationData);
      return newData.observations;
    }

    case TOOL_ENGINE_NAMES.MEETINGS: {
      const newData = (data as ProcoreFilterMeetingData);
      return newData.meetings;
    }

    case TOOL_ENGINE_NAMES.ACTION_PLANS: {
      const newData = (data as ProcoreFilterActionPlanData);
      return newData.actionPlans;
    }

    default: {
      return [];
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LinkProcoreItemsTab = (props: ILinkProcoreItemsTabProps): JSX.Element => {
  const { toolTab } = props;
  const { dispatch } = React.useContext(LinkProcoreItemsContext);
  const client = useApolloClient();

  const fetchMoreProcoreItems = () => {
    dispatch({
      type: "SET_FAILED_TO_FETCH_MORE",
      value: {
        procoreToolId: toolTab.procoreTool.id,
        failedToFetchMore: false
      },
    });

    dispatch({
      type: "SET_LOADING_MORE",
      value: {
        procoreToolId: toolTab.procoreTool.id,
        loadingMore: true
      }
    });

    client
      .query({
        query: fetchMoreProcoreItemsQueryForProcoreToolEngine(toolTab.procoreTool.engineName),
        variables: {
          accountId: props.accountId,
          procoreProjectServerId: props.procoreProjectServerId,
          filters: procoreFilterToInputFilters(toolTab.selectedFilters),
          searchValue: toolTab.searchValue,
          procoreToolId: toolTab.procoreTool.id,
          limit: PAGINATION.limit,
          offset: toolTab.procoreItems.length
        },
        fetchPolicy: "cache-first",
      })
      .then(({ data }) => {
        dispatch({
          type: "APPEND_PROCORE_ITEMS",
          value: {
            procoreToolId: toolTab.procoreTool.id,
            procoreItems: processPaginatedDataLoad(toolTab.procoreTool.engineName, data),
          }
        });
        dispatch({
          type: "SET_LOADING_MORE",
          value: {
            procoreToolId: toolTab.procoreTool.id,
            loadingMore: false
          }
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_LOADING_MORE",
          value: {
            procoreToolId: toolTab.procoreTool.id,
            loadingMore: false
          }
        });

        dispatch({
          type: "SET_FAILED_TO_FETCH_MORE",
          value: {
            procoreToolId: toolTab.procoreTool.id,
            failedToFetchMore: true
          }
        });
      });
  }

  const fetchPaginatedProcoreItems = (selectedFilters: ProcoreToolFilter[] | undefined = undefined, searchValue: string | undefined) => {
    dispatch({
      type: "SET_LOADING",
      value: { procoreToolId: toolTab.procoreTool.id, loading: true },
    });

    const searchVariableValue = searchValue === undefined ? toolTab.searchValue : searchValue;

    client
      .query({
        query: queryForProcoreToolEngine(toolTab.procoreTool.engineName),
        variables: {
          accountId: props.accountId,
          procoreProjectServerId: props.procoreProjectServerId,
          procoreToolId: toolTab.procoreTool.id,
          searchValue: searchVariableValue,
          filters: procoreFilterToInputFilters(
            selectedFilters !== undefined
              ? selectedFilters
              : toolTab.selectedFilters
          ),
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then(({ data }) => {
        const { procoreItems, filteredProcoreItemServerIds } = processInitialDataLoad(toolTab.procoreTool.engineName, data);

        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            procoreToolId: toolTab.procoreTool.id,
            procoreItems: procoreItems,
            filteredProcoreItemServerIds: filteredProcoreItemServerIds,
          },
        });
        dispatch({
          type: "SET_LOADING",
          value: { procoreToolId: toolTab.procoreTool.id, loading: false },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_LOADING",
          value: { procoreToolId: toolTab.procoreTool.id, loading: false },
        });
      });
  };

  const onFilterOptionSelectionChange = ({
    selectedFilters = undefined,
  }: {
    selectedFilters?: ProcoreToolFilter[];
  }) => {
    fetchPaginatedProcoreItems(selectedFilters, undefined);
  };

  useImportResourceSync({
    accountId: props.accountId,
    procoreProjectServerId: props.procoreProjectServerId,
    procoreTool: props.toolTab.procoreTool,
    dispatch: dispatch,
    onFilterOptionSelectionChange: onFilterOptionSelectionChange
  });

  return (
    <FilterProcoreItems
      filterActionbBarProps={{
      filters: toolTab.filters,
      selectedFilters: toolTab.selectedFilters,
      searchValue: toolTab.searchValue,
      filterOpenState: toolTab.filterOpenState,
      popperContainer: props.popperContainer,
      onSearchValueChanged: (newSearchValue) => {
        dispatch({
          type: "SET_SEARCH_VALUE",
          value: { procoreToolId: toolTab.procoreTool.id, searchValue: newSearchValue },
        });
      },
      onSearchValueEntered: (searchValue) => {
        fetchPaginatedProcoreItems(toolTab.selectedFilters, searchValue);
      },
      onSelectedFiltersChanged: (newSelectedFilters) => {
        dispatch({
          type: "SET_SELECTED_FILTERS",
          value: { procoreToolId: toolTab.procoreTool.id, selectedFilters: newSelectedFilters },
        })
      },
      onFilterOptionSelectionChange: onFilterOptionSelectionChange,
      onFilterOpenStateChanged: (newFilterOpenState) => {
        dispatch({
          type: "SET_FILTER_OPEN_STATE",
          value: { procoreToolId: toolTab.procoreTool.id, filterOpenState: newFilterOpenState },
        });
      }
    }}
    procoreToolEngineName={toolTab.procoreTool.engineName}
    procoreToolEngineTitle={toolTab.procoreTool.title}
    loading={toolTab.loading}
    loadingMore={toolTab.loadingMore}
    failedToFetchMore={toolTab.failedToFetchMore}
    onFetchMoreProcoreItems={() => {
      fetchMoreProcoreItems();
    }}
    onSelectedItemsChanged={(newSelectedItems) => {
      dispatch({
        type: "SET_SELECTED_ITEMS",
        value: { procoreToolId: toolTab.procoreTool.id, selectedItems: newSelectedItems },
      });
    }}
    directoryType={toolTab.procoreTool.directoryType}
    procoreItems={toolTab.procoreItems}
    filteredProcoreItemServerIds={
      toolTab.filteredProcoreItemServerIds
    }
    selectedItems={toolTab.selectedItems}
  />
  );
};

export default LinkProcoreItemsTab;
