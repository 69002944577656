import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import { Colors } from "../../styles";
import { Button } from "../shared/Button";

const TeamHomeEmptyState = (props: { onClickGettingStarted: () => void, accountIsSetup: boolean }): JSX.Element => {
  const { onClickGettingStarted, accountIsSetup } = props;
  return (
    <Box
      display="flex"
      height={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: '20px',
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        border: `1px solid ${Colors.mediumLightGray}`,
      }}
    >
      <img src="/images/projects-empty-state.svg" width="224px" height="160px" />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Typography variant="h6" style={{ color: '#171A1C', fontSize: '18px'}}>Get Started</Typography>
        <Typography variant="subtitle1" style={{ color: "#596A75", fontSize: '13px' }}>
          {
            accountIsSetup ? "Start by selecting companies with projects you want to extract from." : "This is where you’ll license the projects you want to extract from."
          }
        </Typography>
      </Box>
      <Button
          size="medium"
          buttonborderstyle="pill"
          variant="contained"
          onClick={onClickGettingStarted}
        >
          Select Companies
      </Button>
    </Box>
  );
};

export default TeamHomeEmptyState;
