import { Box, Typography } from '@mui/material';
import React from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { ActionPlanExtractOptions } from '../../types/action_plan';
import { ToolTabData } from '../../contexts/NewPackageContext';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Colors, ExtractsCheckbox } from '../../styles';
import { BlueSwitch } from './styles';

const engineNameToOptionName = (engineName: string): string => {
  switch(engineName) {
    case 'communication_types':
      return 'include_correspondence';
    case 'checklists':
      return 'include_inspections';
    case 'submittal_log':
      return 'include_submittals';
    case 'forms':
      return 'include_forms';
    case 'meetings':
      return 'include_meetings';
    case 'observations':
      return 'include_observations';
    case 'attachments':
      return 'include_attachments';
    case 'images':
      return 'include_photos';
    default:
      return '';
  }
}

const ActionPlanOptions = (props: { toolTabIndex: number, toolTabData: ToolTabData }): JSX.Element => {
  const { toolTabIndex, toolTabData } = props;

  const { dispatch } = React.useContext(NewPackageContext);

  const actionPlanExtractOptions = toolTabData.extractOptions as ActionPlanExtractOptions;
  return (
    <Box display="flex" flexDirection="column" width={1} height={1} padding={'20px 40px'} style={{ overflowY: 'auto' }}>
      <h2 style={{ margin: '0px 0px 20px 0px', fontSize: '18px'}}>General</h2>
      <Box display={"flex"} flexDirection={"column"} style={{ gap: '24px'}}>
        <Box display="flex" flexDirection="row" style={{ gap: '8px'}} width={1} alignItems={'center'}>
          <BlueSwitch
            size="small"
            checked={actionPlanExtractOptions.single_pdf}
            onChange={(event) => {
              dispatch({
                type: 'SET_TOOL_OPTIONS',
                value: {
                  toolTabIndex: toolTabIndex,
                  option: {
                    ...actionPlanExtractOptions,
                    single_pdf: event.target.checked
                  }
                }
              });
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Typography style={{ fontSize: '14px', fontWeight: 400, color: Colors.darkerGray }}>{'Include the Action Plan’s attachments in its cover sheet PDF'}</Typography>
        </Box>

        <Box display="flex" flexDirection="row" style={{ gap: '8px'}} width={1} alignItems={'center'}>
          <BlueSwitch
            size="small"
            checked={actionPlanExtractOptions.include_references}
            onChange={(event) => {
              dispatch({
                type: 'SET_TOOL_OPTIONS',
                value: {
                  toolTabIndex: toolTabIndex,
                  option: {
                    ...actionPlanExtractOptions,
                    include_references: event.target.checked
                  }
                }
              });
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Typography style={{ fontSize: '14px', fontWeight: 400, color: Colors.darkerGray }}>{'Include References'}</Typography>
        </Box>
      </Box>

      {
        toolTabData.actionPlanToolTypes.length > 0 && (
          <>
            <h2 style={{ margin: '24px 0px 0px 0px', fontSize: '18px'}}>Records to Include</h2>
            <Box display="flex" flexDirection="column" marginTop={"18px"} gap={'12px'}>
              {
                toolTabData.actionPlanToolTypes.map((toolType, index) => {
                  return (
                    <Box display="flex" flexDirection={"row"} key={`action-plan-tool-type-${index}`}>
                      <ExtractsCheckbox
                        disableRipple
                        sx={{
                          padding: 0
                        }}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={actionPlanExtractOptions.action_plan_records[engineNameToOptionName(toolType.procoreToolEngineName)]}
                        onChange={() => {
                          dispatch({
                            type: 'SET_TOOL_OPTIONS',
                            value: {
                              toolTabIndex: toolTabIndex,
                              option: {
                                ...actionPlanExtractOptions,
                                action_plan_records: {
                                  ...actionPlanExtractOptions.action_plan_records,
                                  [engineNameToOptionName(toolType.procoreToolEngineName)]: !actionPlanExtractOptions.action_plan_records[engineNameToOptionName(toolType.procoreToolEngineName)]
                                }
                              }
                            }
                          });
                        }}
                        style={{ marginRight: 8 }}
                      />
                      <Typography sx={{
                        fontSize: '14px', fontWeight: 400, color: Colors.darkerGray
                      }}>
                        {toolType.title}
                      </Typography>
                    </Box>
                  )
                })
              }
            </Box>
          </>
        )
      }

      <h2 style={{ margin: '24px 0px 20px 0px', fontSize: '18px'}}>Inspection Options</h2>
      <Box display={"flex"} flexDirection={"column"} style={{ gap: '24px'}}>
        <Box display="flex" flexDirection="row" style={{ gap: '8px' }} width={1} alignItems={'center'}>
          <BlueSwitch
            size="small"
            checked={actionPlanExtractOptions.inspections.options.image_attachment_option === 'thumbnails_and_full_size'}
            onChange={(event) => {
              dispatch({
                type: 'SET_TOOL_OPTIONS',
                value: {
                  toolTabIndex: toolTabIndex,
                  option: {
                    ...actionPlanExtractOptions,
                    inspections: {
                      options: {
                        ...actionPlanExtractOptions.inspections.options,
                        image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only'
                      }
                    }
                  }
                }
              });
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Typography style={{ fontSize: '14px', fontWeight: 400, color: Colors.darkerGray }}>{'Include full-sized image files as well as thumbnails'}</Typography>
        </Box>

        <Box display="flex" flexDirection="row" style={{ gap: '8px'}} width={1} alignItems={'center'}>
          <BlueSwitch
            size="small"
            checked={actionPlanExtractOptions.inspections.options.include_linked_drawing_markup}
            onChange={(event) => {
              dispatch({
                type: 'SET_TOOL_OPTIONS',
                value: {
                  toolTabIndex: toolTabIndex,
                  option: {
                    ...actionPlanExtractOptions,
                    inspections: {
                      options: {
                        ...actionPlanExtractOptions.inspections.options,
                        include_linked_drawing_markup: event.target.checked,
                        include_other_markup: event.target.checked ? actionPlanExtractOptions.inspections.options.include_other_markup : false
                      }
                    }
                  }
                }
              });
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Typography style={{ fontSize: '14px', fontWeight: 400, color: Colors.darkerGray }}>{'Include markup on Drawings'}</Typography>
        </Box>
        {
          actionPlanExtractOptions.inspections.options.include_linked_drawing_markup && (
            <Box display="flex" flexDirection="row" style={{ gap: '8px' }} width={1} paddingLeft={'12px'} alignItems={'center'}>
              <BlueSwitch
                size="small"
                checked={actionPlanExtractOptions.inspections.options.include_other_markup}
                onChange={(event) => {
                  dispatch({
                    type: 'SET_TOOL_OPTIONS',
                    value: {
                      toolTabIndex: toolTabIndex,
                      option: {
                        ...actionPlanExtractOptions,
                        inspections: {
                          options: {
                            ...actionPlanExtractOptions.inspections.options,
                            include_other_markup: event.target.checked
                          }
                        }
                      }
                    }
                  });
                }}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Typography style={{ fontSize: '0.9rem', fontWeight: 400, color: Colors.darkerGray }}>{'Include other markup'}</Typography>
            </Box>
          )
        }

        <Box display="flex" flexDirection="row" style={{ gap: '8px' }} width={1} alignItems={'center'}>
          <BlueSwitch
            size="small"
            checked={actionPlanExtractOptions.inspections.options.include_observations}
            onChange={(event) => {
              dispatch({
                type: 'SET_TOOL_OPTIONS',
                value: {
                  toolTabIndex: toolTabIndex,
                  option: {
                    ...actionPlanExtractOptions,
                    inspections: {
                      options: {
                        ...actionPlanExtractOptions.inspections.options,
                        include_observations: event.target.checked,
                      }
                    }
                  }
                }
              });
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Typography style={{ fontSize: '14px', fontWeight: 400, color: Colors.darkerGray }}>{'Include linked Observations'}</Typography>
        </Box>
      </Box>

      <h2 style={{ margin: '24px 0px 20px 0px', fontSize: '18px' }}>Submittal Options</h2>
      <Box display={"flex"} flexDirection={"column"} style={{ gap: '24px'}}>
        <Box display="flex" flexDirection="row" style={{ gap: '8px' }} width={1} alignItems={'center'}>
          <BlueSwitch
            size="small"
            checked={actionPlanExtractOptions.submittals.options.attachment_option === 'distributed'}
            onChange={(event) => {
              dispatch({
                type: 'SET_TOOL_OPTIONS',
                value: {
                  toolTabIndex: toolTabIndex,
                  option: {
                    ...actionPlanExtractOptions,
                    submittals: {
                      options: {
                        ...actionPlanExtractOptions.submittals.options,
                        attachment_option: event.target.checked ? 'distributed' : 'all'
                      }
                    }
                  }
                }
              });
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Typography style={{ fontSize: '14px', fontWeight: 400, color: Colors.darkerGray }}>{'Include distributed attachments only'}</Typography>
        </Box>
      </Box>
    </Box>
  )
};

export default ActionPlanOptions;