import React, { useState } from "react";
import { AccountProjectContext } from "../contexts/AccountProjectContextProvider";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/system/Box";
import { useCreateImportResourceSync } from "../graphql/mutations/closeout/CreateImportResourceSync";
import {
  PAGINATION,
  TOOL_ENGINE_NAMES,
  procoreFilterToInputFilters,
} from "../utils/utils";
import FilterProcoreItems from "./shared/filters/FilterProcoreItems";
import { Typography } from "./shared/Typography";
import { Button } from "./shared/Button";
import { useGetImportResourceSync } from "../graphql/queries/closeout/GetImportResourceSync";
import { LogRequirement, LogRequirementItem, ProcoreTool } from "../types";
import { useGetToolFilters } from "../graphql/queries/GetProcoreToolFilters";
import { ProcoreToolFilter } from "../contexts/NewPackageContext";
import {
  ProcoreFilterSubmittalData,
  ProcoreFilterSubmittalVariables,
  fetchPaginatedProcoreSubmittalsQuery,
  filterProcoreSubmittalsQuery,
} from "../graphql/queries/GetProcoreSubmittals";
import { ImportSubmittalsContext } from "../contexts/ImportSubmittalsContextProvider";
import { useApolloClient } from "@apollo/client";
import { Action } from "../contexts/ImportSubmittalsContext";
import { useImportProcoreSubmittals } from "../graphql/mutations/closeout/ImportProcoreSubmittals";
import { Colors } from "../styles";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import { SubmittalImportedFieldsModal } from "./SubmittalImportedFieldsModal";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import { CloseoutLogContext } from "../contexts/CloseoutLogContextProvider";

interface ImportSubmittalsModalProps {
  closeoutLogId: number;
  openModal: boolean;
  closeModal: (logRequirements: LogRequirement[], logRequirementItems: LogRequirementItem[]) => void;
  submittalsProcoreTool: ProcoreTool;
}

function useImportResourceSync(props: {
  accountId: string;
  procoreProjectServerId: number;
  submittalsProcoreTool: ProcoreTool;
  dispatch: (action: Action) => void;
  onFilterOptionSelectionChange: ({ selectedFilters, }: {
    selectedFilters?: ProcoreToolFilter[];
  }) => void;
}) {
  const [getImportResourceSyncData, setImportResourceSyncData] =
    useState<{ id: number; skip: boolean }>({ id: 0, skip: true });

  const [
    createImportResourceSync,
    {
      loading: createImportResourceSyncLoading,
      data: createImportResourceSyncData,
    },
  ] = useCreateImportResourceSync({
    accountId: props.accountId,
    procoreProjectServerId: props.procoreProjectServerId,
    resourceType: TOOL_ENGINE_NAMES.SUBMITTALS,
  });

  const {
    loading: importResourceSyncLoading,
    data: importResourceSyncData,
    startPolling,
    stopPolling,
  } = useGetImportResourceSync(
    {
      accountId: props.accountId,
      procoreProjectServerId: props.procoreProjectServerId,
      id: getImportResourceSyncData.id,
    },
    getImportResourceSyncData.skip
  );

  React.useEffect(() => {
    createImportResourceSync();
  }, []);

  // Upon kicking off a request to fetch tool data asynchronously this will
  // record the state and trigger polling every X seconds.
  React.useEffect(() => {
    if (!createImportResourceSyncData || createImportResourceSyncLoading) {
      return;
    }

    const importResourceSync =
      createImportResourceSyncData.createImportResourceSync.importResourceSync;

    setImportResourceSyncData({
      id: importResourceSync.id,
      skip: false,
    });

    if (importResourceSync.status !== "synced") {
      startPolling(3000);
    }
  }, [createImportResourceSyncData]);

  React.useEffect(() => {
    if (!importResourceSyncData || importResourceSyncLoading) {
      return;
    }

    if (importResourceSyncData.importResourceSync.status === "synced") {
      stopPolling();

      loadProcoreToolFilters();
    }
  }, [importResourceSyncData]);

  const [
    loadProcoreToolFilters,
    { loading: loadingProcoreToolFilters, data: procoreToolFiltersData },
  ] = useGetToolFilters({
    accountId: props.accountId,
    procoreProjectServerId: props.procoreProjectServerId,
    procoreToolId: props.submittalsProcoreTool.id, // TODO: Get from context
    directoryType: props.submittalsProcoreTool.directoryType
  });

  React.useEffect(() => {
    if (!procoreToolFiltersData || loadingProcoreToolFilters) { return; }

    props.dispatch({
      type: 'SET_FILTERS',
      value: {
        filters: procoreToolFiltersData.filters,
        selectedFilters: procoreToolFiltersData.defaultFilters
      }
    });

    props.onFilterOptionSelectionChange({ selectedFilters: procoreToolFiltersData.defaultFilters })
  }, [procoreToolFiltersData]);

  return { procoreToolFiltersData };
}

const ImportSubmittalsModal = (
  props: ImportSubmittalsModalProps
): JSX.Element => {
  const { state } = React.useContext(AccountProjectContext);
  const { state: closeoutLogContextState } = React.useContext(CloseoutLogContext);
  const { state: importSubmittalState, dispatch } = React.useContext(
    ImportSubmittalsContext
  );
  const containerRef = React.useRef(null);
  const client = useApolloClient();
  const [importedFieldsModalIsOpen, setImportedFieldsModalIsOpen] = useState<boolean>(false);

  const fetchPaginatedProcoreSubmittals = (selectedFilters: ProcoreToolFilter[] | undefined = undefined, searchValue: string | undefined) => {
    dispatch({
      type: "SET_LOADING",
      value: true,
    });

    const searchVariableValue = searchValue === undefined ? importSubmittalState.searchValue : searchValue;

    client
      .query<ProcoreFilterSubmittalData, ProcoreFilterSubmittalVariables>({
        query: filterProcoreSubmittalsQuery,
        variables: {
          accountId: state.accountId,
          procoreProjectServerId: state.procoreProjectServerId,
          procoreToolId: props.submittalsProcoreTool.id,
          searchValue: searchVariableValue,
          filters: procoreFilterToInputFilters(
            selectedFilters !== undefined
              ? selectedFilters
              : importSubmittalState.selectedFilters
          ),
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_SUBMITTALS",
          value: {
            procoreSubmittals: result.data.submittals,
            filteredSubmittalServerIds:
              result.data.toolRecordServerIds.procoreServerIds,
          },
        });
        dispatch({
          type: "SET_LOADING",
          value: false,
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_LOADING",
          value: false,
        });
      });
  };

  const onFilterOptionSelectionChange = ({
    selectedFilters = undefined,
  }: {
    selectedFilters?: ProcoreToolFilter[];
  }) => {
    fetchPaginatedProcoreSubmittals(selectedFilters, undefined);
  };

  const fetchMoreSubmittals = () => {
    dispatch({
      type: "SET_FAILED_TO_FETCH_MORE",
      value: false,
    });

    dispatch({
      type: "SET_LOADING_MORE",
      value: true
    });

    client
      .query<ProcoreFilterSubmittalData, ProcoreFilterSubmittalVariables>({
        query: fetchPaginatedProcoreSubmittalsQuery,
        variables: {
          accountId: state.accountId,
          procoreProjectServerId: state.procoreProjectServerId,
          filters: procoreFilterToInputFilters(importSubmittalState.selectedFilters),
          searchValue: importSubmittalState.searchValue,
          procoreToolId: props.submittalsProcoreTool.id,
          limit: PAGINATION.limit,
          offset: importSubmittalState.procoreSubmittals.length
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "APPEND_PROCORE_SUBMITTALS",
          value: result.data.submittals,
        });
        dispatch({
          type: "SET_LOADING_MORE",
          value: false
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_LOADING_MORE",
          value: false
        });

        dispatch({
          type: "SET_FAILED_TO_FETCH_MORE",
          value: true
        });
      });
  }

  useImportResourceSync({
    accountId: state.accountId,
    procoreProjectServerId: state.procoreProjectServerId,
    submittalsProcoreTool: props.submittalsProcoreTool,
    dispatch: dispatch,
    onFilterOptionSelectionChange: onFilterOptionSelectionChange
  });

  const [importProcoreSubmittals, { loading: loadingImportProcoreSubmittals }] = useImportProcoreSubmittals({
    accountId: state.accountId,
    procoreProjectServerId: state.procoreProjectServerId,
    closeoutLogId: props.closeoutLogId,
    procoreServerIds: Object.values(importSubmittalState.selectedItems).filter((item) => item.state === 'checked').map((item) => item.itemId),
    options: {
      import_submittal_description_from: closeoutLogContextState.extractOptions.import_submittal_description_from
    }
  });

  const handleOnClose = (logRequirements: LogRequirement[], logRequirementItems: LogRequirementItem[]) => {
    props.closeModal(logRequirements, logRequirementItems);
  };

  return (
    <>
      <SubmittalImportedFieldsModal
        onClose={ () => setImportedFieldsModalIsOpen(false)}
        open={importedFieldsModalIsOpen}
      />
      <Dialog
        ref={containerRef}
        disableScrollLock
        open={props.openModal}
        maxWidth={false}
        PaperProps={{
          sx: {
            padding: '28px',
            height: '100%',
            width: '100%',
            maxWidth: '1100px'
          },
        }}
      >
        <Box display="flex" flexDirection="column" height={1} width={1}>
          <Box sx={{
            display: 'flex',
          }}>
            <Box
              display="flex"
              flexDirection="column"
              gap="8px"
              marginBottom= '24px'
              sx={{
                borderRadius: '6px',
              }}
            >
              <Typography typestyle="xl">Import Submittals</Typography>
              <Typography typestyle="s" color={Colors.darkishGray}>
                Each submittal will be imported as a new closeout requirement where
                the type, responsible contractor, and spec section are inherited
                from the submittal. The closeout requirement will always link to the
                current revision of the submittal, and when you extract your
                closeout log, the most current data will be used. Learn more
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={()=> handleOnClose([], [])}>
                <Close sx={{fontSize: '18px'}}/>
              </IconButton>
            </Box>
          </Box>
          <FilterProcoreItems
            filterActionbBarProps={{
              filters: importSubmittalState.filters,
              selectedFilters: importSubmittalState.selectedFilters,
              searchValue: importSubmittalState.searchValue,
              filterOpenState: importSubmittalState.filterOpenState,
              popperContainer: containerRef.current,
              onSearchValueChanged: (newSearchValue) => {
                dispatch({
                  type: 'SET_SEARCH_VALUE',
                  value: newSearchValue,
                });
              },
              onSearchValueEntered: (searchValue) => {
                fetchPaginatedProcoreSubmittals(
                  importSubmittalState.selectedFilters,
                  searchValue
                );
              },
              onSelectedFiltersChanged: (newSelectedFilters) => {
                dispatch({
                  type: 'SET_SELECTED_FILTERS',
                  value: newSelectedFilters,
                });
              },
              onFilterOptionSelectionChange: onFilterOptionSelectionChange,
              onFilterOpenStateChanged: (newFilterOpenState) => {
                dispatch({
                  type: 'SET_FILTER_OPEN_STATE',
                  value: newFilterOpenState,
                });
              },
            }}
            procoreToolEngineName={TOOL_ENGINE_NAMES.SUBMITTALS}
            procoreToolEngineTitle={'Submittals'}
            directoryType="users"
            loading={importSubmittalState.loading}
            loadingMore={importSubmittalState.loadingMore}
            failedToFetchMore={importSubmittalState.failedToFetchMore}
            onFetchMoreProcoreItems={() => {
              fetchMoreSubmittals();
            }}
            onSelectedItemsChanged={(newSelectedItems) => {
              dispatch({
                type: 'SET_SELECTED_ITEMS',
                value: newSelectedItems,
              });
            }}
            procoreItems={importSubmittalState.procoreSubmittals}
            filteredProcoreItemServerIds={
              importSubmittalState.filteredSubmittalServerIds
            }
            selectedItems={importSubmittalState.selectedItems}
          />
          <Box display="flex" alignItems={'center'} justifyContent={'space-between'} paddingTop="24px">
            <Button
              variant="outlined"
              buttonborderstyle="pill"
              size="medium"
              startIcon={<SettingsOutlined/>}
              onClick={() => setImportedFieldsModalIsOpen(true)}
            >
              Configure Fields
            </Button>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              gap={'8px'}
            >
              <Button
                variant="outlined"
                buttonborderstyle="pill"
                size="medium"
                onClick={() => handleOnClose([], [])}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  Object.values(importSubmittalState.selectedItems).filter(
                    (item) => item.state === 'checked'
                  ).length === 0 || loadingImportProcoreSubmittals
                }
                variant="contained"
                onClick={() => {
                  importProcoreSubmittals().then(({ data }) => {
                    if (data?.importProcoreSubmittals?.success) {
                      handleOnClose(
                        data.importProcoreSubmittals.logRequirements,
                        data.importProcoreSubmittals.logRequirementItems
                      );
                    }
                  });
                }}
                buttonborderstyle="pill"
                size="medium"
              >
                Import
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ImportSubmittalsModal;
