import React from "react";
import { initialState, rootReducer, ManageUsersContextState } from './ManageUsersContext';

const ManageUsersContext = React.createContext<ManageUsersContextState>({
  dispatch: () => undefined,
  state: initialState,
});

const ManageUsersContextProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const [state, dispatch] = React.useReducer(
    rootReducer,
    {
      ...initialState,
    }
  );

  return (
    <ManageUsersContext.Provider value={{state, dispatch}}>
      {props.children}
    </ManageUsersContext.Provider>
  );
}

export { ManageUsersContext, ManageUsersContextProvider };