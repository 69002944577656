import React from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  InputBase,
  ListItemButton,
  DialogProps,
  styled,
  DialogTitleProps,
  alpha,
} from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import idx from "idx";
import { Colors } from "../../styles";
import {
  CheckedCheckBox,
  ManualSelectCheckbox,
  UncheckedCheckBox,
  IndeterminateCheckBox,
  SearchCloseIconButton,
  SearchCloseIcon,
} from "./styles";
import { Account, Project } from "../../types";
import SearchIcon from '@mui/icons-material/Search';
import { useGetAccountProjects } from "../../graphql/queries/GetAccountProjects";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useUseProjectLicenses } from "../../graphql/mutations/UseProjectLicenses";
import { Button } from "../shared/Button";

export const FeedbackDialog = styled(Dialog)<DialogProps>(() => {
  return {
    "& .MuiDialog-paper": {
      width: "705px",
      height: "705px",
    }
  };
});

export const ModalDialogTitle = styled(DialogTitle)<DialogTitleProps>(() => {
  return {
    padding: '16px 32px 0px 32px'
  };
});

const AddProjectsAnnualLicenseModal = (props: {
  account: Account;
  handleOnClose: (refresh: boolean, numberOfLicenses: number, licensedProcoreProjectServerIds: number[]) => void;
  open: boolean;
}): JSX.Element => {
  const { account, handleOnClose, open } = props;

  const { loading, data } = useGetAccountProjects(account.id, false);

  const [
    useProjectLicenses,
    { loading: useProjectLicensesLoading, data: useProjectLicensesData },
  ] = useUseProjectLicenses({
    accountId: account.id,
    procoreProjectServerIds: [],
  });

  const [projectsToLicense, setProjectsToLicense] = React.useState<
    Record<number, boolean>
  >({});
  const [searchValue, setSearchValue] = React.useState<string>('');

  const handleProjectClick = (procoreServerId: number) => {
    const add = projectsToLicense[procoreServerId] || false;

    setProjectsToLicense({
      ...projectsToLicense,
      [procoreServerId]: !add,
    });
  };

  function renderRow(props: ListChildComponentProps<Project[]>) {
    const { index, style, data } = props;

    const project = data[index] as Project;

    const checked = projectsToLicense[project.procoreServerId] || false;
    return (
      <div style={style}>
        <ListItemButton
          sx={{
            paddingLeft: '16px'
          }}
          key={`user-list-item-${project.procoreServerId}`}
          disabled={!checked && disableMoreLicenses}
          onClick={() => handleProjectClick(project.procoreServerId)}
        >
          <ManualSelectCheckbox
            disableFocusRipple
            disableRipple
            disableTouchRipple
            color="primary"
            disabled={!checked && disableMoreLicenses}
            indeterminateIcon={<IndeterminateCheckBox />}
            icon={<UncheckedCheckBox />}
            checkedIcon={<CheckedCheckBox />}
            checked={checked}
          />
          <Box display={"flex"} flexDirection={"column"} width={1}>
            <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
              {project.displayName || project.name}
            </Typography>
            <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
              {project.procoreCompanyName}
            </Typography>
          </Box>
        </ListItemButton>
        <Divider key={`grouping-list-item-divider-${project.procoreServerId}`} />
      </div>
    );
  }

  const itemData: Project[] = idx(data, data => data.procoreProjects) || [];

  const trimmedAndLoweredSearchValue = (searchValue || '').trim().toLowerCase();
  const filteredItemData = itemData.filter((d) => {
    const trimmedOption = (d.displayName || d.name || '').trim().toLowerCase();

    return trimmedOption.includes(trimmedAndLoweredSearchValue);
  }).sort((a, b) => {
    const aProjectName = a.displayName || a.name;
    const bProjectName = b.displayName || b.name;
    return aProjectName.localeCompare(bProjectName, undefined, { numeric: true })
  });

  const appliedLicenseCount = Object.values(projectsToLicense).filter(v => v).length;
  const disableMoreLicenses = account.numberOfLicenses - appliedLicenseCount <= 0;

  const licensesToUseCount = Object.values(projectsToLicense).filter((v) => v).length
  const useLicenseText = licensesToUseCount === 0 ? "Use License(s)" : `Use ${licensesToUseCount} License(s)`;

  return (
    <FeedbackDialog
      maxWidth={"md"}
      onClose={() => handleOnClose(false, 0, [])}
      aria-labelledby="help-dialog-title"
      open={open}
    >
      <ModalDialogTitle>
        {"Add Projects"}
      </ModalDialogTitle>
      <DialogContent
        dividers={false}
        style={{
          marginBottom: "20px",
          height: "475px",
          padding: '16px 32px',
          display: 'flex',
          flexDirection: 'column' }}
      >
        {loading ? (
          <Box
            display={"flex"}
            flex={'1 0'}
            sx={{ border: `1px solid ${Colors.mediumLightGray}` }}
            borderRadius={"4px"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent="center"
          >
            <CircularProgress size={12} style={{ margin: "5px" }} />
            <Typography>Loading projects...</Typography>
          </Box>
        ) : (
          <>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"} style={{
              background: '#E4EBF0',
              borderRadius: '6px',
              marginBottom: '16px',
              padding: '10px 10px 10px 16px',
              borderTop: account.numberOfLicenses > 0 ? '6px solid #3D87BF' : '6px solid #A0B0BA',
            }}>
              <InfoOutlinedIcon />
              <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} marginLeft={"10px"}>
                {
                  (account.numberOfLicenses > 0) ? (
                    <>
                      <p style={{ padding: 0, margin: 0, color: Colors.darkerGray, fontWeight: 700, fontSize: '13px' }}>{`You’ve got ${Math.max(0, account.numberOfLicenses - appliedLicenseCount)} unused project licenses`}</p>
                      <p style={{ padding: 0, margin: 0, color: Colors.darkerGray, fontWeight: 400, fontSize: '12px' }}>Select the projects you want to add a license to.</p>
                    </>
                  ) : (
                    <p style={{ padding: 0, margin: 0, color: Colors.darkerGray, fontWeight: 700, fontSize: '13px' }}>{`You’ve got 0 available project licenses.`}</p>
                  )
                }
              </Box>
            </Box>
            <Box display="flex" flexDirection={"row"} alignContent="center" marginBottom={'10px'}>
              <Button
                disabled={loading}
                onClick={() => {
                  if (licensesToUseCount > 0) {
                    setProjectsToLicense({});
                  } else {
                    setProjectsToLicense(filteredItemData.slice(0, account.numberOfLicenses).reduce((acc, nextItem) => {
                      acc[nextItem.procoreServerId] = true;

                      return acc;
                    }, {} as Record<number, boolean>))
                  }
                }}
                variant="outlined"
                disableElevation={true}
                size="medium"
                style={{
                  minHeight: '39px',
                  marginRight: "12px"
                }}
              >
                {licensesToUseCount > 0 ? "Deselect All" : "Select All"}
              </Button>
              <Box sx={{
                position: 'relative',
                minHeight: '39px',
                maxHeight: 'unset',
                flex: '1 0',
                borderRadius: '6px',
                paddingRight: '2px',
                border: '1px solid #D5DFE6',
                backgroundColor: alpha('#FFFFFF', 0.15),
                '&:hover': {
                  backgroundColor: alpha('#FFFFFF', 0.25),
                },
                marginLeft: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '12px'
              }}>
                <SearchIcon />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  fullWidth={true}
                  placeholder="Search..."
                  autoFocus={true}
                  value={searchValue}
                  endAdornment={
                    (((searchValue || '').trim())) ? (
                      <SearchCloseIconButton
                          size={'small'}
                          onClick={() => {
                            setSearchValue('');
                          }}
                          style={{ visibility: ((searchValue || '').trim()) ? 'visible' : 'hidden' }}>
                        <SearchCloseIcon />
                      </SearchCloseIconButton>
                    ) : null
                  }
                  onChange={(evt) => {
                    setSearchValue(evt.target.value)
                  }}
                  inputProps={{ 'aria-label': 'search users' }}
                />
              </Box>
            </Box>
            <div
              style={{
                display: "flex",
                height: "425px",
                width: "100%",
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: "6px",
                borderColor: Colors.mediumLightGray,
              }}
            >
              <FixedSizeList
                height={425}
                width={"100%"}
                itemData={filteredItemData}
                itemCount={filteredItemData.length}
                itemSize={55}
              >
                {renderRow}
              </FixedSizeList>
            </div>
          </>
        )}
        <Box
          display={"flex"}
          flexDirection="row"
          alignItems={"center"}
          justifyContent="flex-end"
          paddingTop={"10px"}
        >
          <Button
            disabled={loading}
            onClick={() => handleOnClose(false, 0, [])}
            variant="outlined"
            disableElevation={true}
            size="medium"
            style={{
              minHeight: '39px',
              marginRight: "12px"
            }}
          >
            {"Cancel"}
          </Button>
          <Button
            size="medium"
            variant="contained"
            disabled={licensesToUseCount === 0 || loading || useProjectLicensesLoading}
            onClick={() => {
              useProjectLicenses({
                variables: {
                  accountId: account.id,
                  procoreProjectServerIds: Object.keys(projectsToLicense).map((v) => parseInt(v))
                }
              }).then((response) => {
                const data = response.data;

                if (data) {
                  handleOnClose(
                    data.useProjectLicenses.success,
                    data.useProjectLicenses.numberOfLicenses,
                    data.useProjectLicenses.procoreProjectServerIds)
                }
              }).catch((err) => {
                console.log(err);

                handleOnClose(true, account.numberOfLicenses, []);
              })
            }}
          >
            {
              useProjectLicensesLoading ? (
                <Box
                    display={"flex"}
                    flexDirection="row"
                    alignItems={"center"}
                    justifyContent="center"
                  >
                    <CircularProgress size={12} style={{ margin: "5px" }} />
                    <Typography>{useLicenseText}</Typography>
                  </Box>
              ) : useLicenseText
            }
          </Button>
        </Box>
      </DialogContent>
    </FeedbackDialog>
  );
};

export default AddProjectsAnnualLicenseModal;
