import React from "react";
import {
  Box,
  Dialog,
  IconButton,
  MenuItem,
} from "@mui/material";
import { Typography } from "../shared/Typography";
import Close from "@mui/icons-material/Close";
import { Button } from "../shared/Button";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { TemplateCloseoutLog } from "../../types";
import { Colors } from "../../styles";
import { FilterSelect, FilterSelectInput } from "./styles";

const CloseoutLogCreateFromModal = (props: {
  templateCloseoutLogs: TemplateCloseoutLog[];
  handleOnClose: () => void;
  disabled: boolean;
  createCloseoutLog: (templateCloseoutLogId?: number) => void;
  open: boolean;
}): JSX.Element => {
  const { handleOnClose, open } = props;
  const [selectedTemplateCloseoutLogId, setSelectedTemplateCloseoutLogId] = React.useState(props.templateCloseoutLogs[0]?.id);

  return (
    <Dialog
      disableScrollLock
      PaperProps={{
        sx: {
          padding: "24px 28px 28px 28px",
        },
      }}
      maxWidth={false}
      onClose={() => {
        if (props.disabled) { return; }
        handleOnClose()
      }}
      aria-labelledby="help-dialog-title"
      open={open}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '24px',
        justifyContent: 'space-between'
      }}>
        <Typography typestyle="xl">
          New Closeout Log
        </Typography>
        <IconButton disabled={props.disabled} onClick={() => handleOnClose()}>
          <Close sx={{fontSize: '18px'}}/>
        </IconButton>
      </Box>

      <Box display="flex" flexDirection={'column'} gap={'20px'} width={1}>
        <Box display={'flex'} flexDirection={'row'} padding={'32px 40px'} gap={'40px'} alignItems={'center'} justifyContent={'space-between'} sx={{
          border: `1px solid ${Colors.mediumLightGray}`,
          borderRadius: '12px'
        }}>
          <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
            <Typography typestyle="l">From Template</Typography>
            <Typography typestyle="s" sx={{ color: Colors.darkishGray }}>Start from a preset list of closeout requirements.</Typography>
            <FilterSelect
              value={selectedTemplateCloseoutLogId}
              fullWidth={true}
              autoWidth={true}
              input={<FilterSelectInput />}
              disabled={props.templateCloseoutLogs.length === 0}
              onChange={(event) => {
                setSelectedTemplateCloseoutLogId(event.target.value as number);
              }}
              label={props.templateCloseoutLogs.length === 0 ? 'No Templates Available' : 'Select Template'}
              displayEmpty
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                PaperProps:{
                 sx: {
                  maxHeight: '400px'
                 }
                }
              }}
            >
              {
                props.templateCloseoutLogs.map((templateCloseoutLog) => {
                  return <MenuItem key={templateCloseoutLog.id} value={templateCloseoutLog.id}>{templateCloseoutLog.name}</MenuItem>
                })
              }
            </FilterSelect>
          </Box>

          <Button
            variant="contained"
            buttonborderstyle="pill"
            endIcon={<ArrowForwardOutlinedIcon />}
            onClick={() => {
              props.createCloseoutLog(selectedTemplateCloseoutLogId);
            }}
            disabled={props.templateCloseoutLogs.length === 0 || !selectedTemplateCloseoutLogId || props.disabled}
            size="medium">Create</Button>
        </Box>

        <Box display={'flex'} flexDirection={'row'} padding={'32px 40px'} gap={'40px'} alignItems={'center'} justifyContent={'space-between'} sx={{
          border: `1px solid ${Colors.mediumLightGray}`,
          borderRadius: '12px'
        }}>
          <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
            <Typography typestyle="l">Empty Log</Typography>
            <Typography typestyle="s" sx={{ color: Colors.darkishGray }}>Build a closeout log from the ground up by starting with an empty log.</Typography>
          </Box>
          <Button
            variant="outlined"
            endIcon={<ArrowForwardOutlinedIcon />}
            buttonborderstyle="pill"
            disabled={props.disabled}
            onClick={() => {
              props.createCloseoutLog();
            }}
            size="medium">Create</Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CloseoutLogCreateFromModal;
