import React, { useState, useContext } from 'react';
import {
  Box, styled,
  FormControlLabel
} from '@mui/material';
import { TabPanel, TabContext, TabPanelProps } from '@mui/lab';
import { Typography } from './shared/Typography';
import { Button } from './shared/Button';
import { Colors, ExtractsCheckbox, StyledVerticalTab, StyledVerticalTabs } from '../styles';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';
import { CloseoutGroupBy } from './shared/CloseoutGroupBy';
import { AttachmentOption } from '../types/submittal';
import { BlueSwitch, ExtractsRadio } from './package/styles';
import ExportOptionToggle from './package/ExportOptionToggle';
import { TOOL_ENGINE_NAMES } from '../utils/utils';
import { UnderlinedHeader } from './shared/UnderlinedHeader';
import { RfiExtractOptions } from '../types/rfi';
import { ExtractToolOptions } from '../contexts/CloseoutLogContext';
import { ObservationExtractOptions } from '../types/observation';
import { InspectionExtractOptions } from '../types/inspection';
import { PunchItemExtractOptions } from '../types/punch_item';
import { ActionPlanExtractOptions } from '../types/action_plan';
import { TemplateCloseoutLogContext } from '../contexts/TemplateCloseoutLogContextProvider';
import { useUpdateTemplateCloseoutLogExportOption } from '../graphql/mutations/closeout/UpdateTemplateCloseoutLogExportOption';
import { ProcoreToolGroupings } from '../types';

const CustomTabPanel = styled(TabPanel)<TabPanelProps & { current: boolean }>(({ current }) => {
  return {
    display: current ? 'flex' : 'none',
    height: current ? '100%' : '0',
  }
});

const attachmentContentOptions = [
  {
    value: 'all',
    label: 'All attachments'
  },
  {
    value: 'distributed',
    label: 'Distributed attachments only'
  }
];

const ManageTemplateCloseoutLogExtractOptions = (): JSX.Element => {
  const { state, dispatch } = useContext(TemplateCloseoutLogContext);
  const { state: accountProjectState } = useContext(AccountProjectContext);
  const [currentToolTab, setCurrentToolTab] = useState(state.templateCloseoutLogTools[0]?.procoreToolEngineName);

  const [updateTemplateCloseoutLogExportOption] = useUpdateTemplateCloseoutLogExportOption({
    accountId: accountProjectState.selectedAccount,
    templateCloseoutLogId: state.id,
    attributes: {
      includeTableOfContents: state.extractOptions.include_table_of_contents,
      groupings: state.extractOptions.selectedGroupings.map((grouping) => grouping.attribute),
      toolExtractOptions: state.extractOptions.toolExtractOptions,
    }
  });

  const onUpdateCloseoutLogExportOption = (params: {
      includeTableOfContents?: boolean,
      groupings?: string[],
      toolExtractOptions?: Record<any, any>,
    }) => {
    const { includeTableOfContents, groupings, toolExtractOptions } = params;

    updateTemplateCloseoutLogExportOption({
      variables: {
          accountId: accountProjectState.selectedAccount,
        templateCloseoutLogId: state.id,
        attributes: {
          includeTableOfContents: includeTableOfContents ===  undefined ? state.extractOptions.include_table_of_contents : includeTableOfContents,
          groupings: groupings === undefined ? state.extractOptions.selectedGroupings.map((grouping) => grouping.attribute) : groupings,
          toolExtractOptions: toolExtractOptions === undefined ? state.extractOptions.toolExtractOptions : toolExtractOptions,
        }
      }
    })
  }

  const handleSubmittalAttachmentContentOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const attachmentOption = event.target.value as AttachmentOption;

    const newToolExtractOptions = {
      ...state.extractOptions.toolExtractOptions,
     submittals: {
        ...state.extractOptions.toolExtractOptions.submittals,
        attachment_option: attachmentOption,
        workflow_responses: [],
      },
    };

    onUpdateCloseoutLogExportOption({
      includeTableOfContents: undefined,
      groupings: undefined,
      toolExtractOptions: newToolExtractOptions
    });


    dispatch({
      type: 'SET_TEMPLATE_EXTRACT_OPTIONS',
      value: {
        ...state.extractOptions,
        toolExtractOptions: newToolExtractOptions,
      }
    });
  };

  const updateAndDispatchExtractOptions = (newExtractOptions: ExtractToolOptions) => {
    onUpdateCloseoutLogExportOption({
      includeTableOfContents: undefined,
      groupings: undefined,
      toolExtractOptions: newExtractOptions
    });


    dispatch({
      type: 'SET_TEMPLATE_EXTRACT_OPTIONS',
      value: {
        ...state.extractOptions,
        toolExtractOptions: newExtractOptions,
      }
    });
  }

  return (
    <Box
      display="flex"
      width={1}
      height={1}
      flexDirection="column"
      borderRadius="4px"
      sx={{
        overflowY: 'auto',
      }}
      gap={'20px'}
    >
      <Box display="flex" flexDirection="column" width={1} gap="16px" sx={{
        overflowY: 'auto',
      }}>
        <UnderlinedHeader>
          Group Items By <span style={{color: Colors.newAccentBlue}}>*</span>
        </UnderlinedHeader>
        <CloseoutGroupBy
          groupings={state.groupings}
          selectedGroupings={state.extractOptions.selectedGroupings.map(
            (g) => g.attribute
          )}
          setSelectedGroupings={(selectedGroupings) => {
            const newSelectingGroupings: ProcoreToolGroupings[] =
              selectedGroupings
                .map((groupingAttribute) => {
                  return state.groupings.find(
                    (g) => g.attribute === groupingAttribute
                  );
                })
                .filter((g) => g !== undefined);

            onUpdateCloseoutLogExportOption({
              includeTableOfContents: undefined,
              groupings: newSelectingGroupings.map((g) => g.attribute),
              toolExtractOptions: undefined
            });

            dispatch({
              type: 'SET_SELECTED_GROUPINGS',
              value: newSelectingGroupings,
            });
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column" width={1} gap="24px">
        <UnderlinedHeader>Add-Ons</UnderlinedHeader>
        <Box
          display="flex"
          flexDirection="row"
          gap="10px"
          alignItems="center"
        >
          <BlueSwitch
            size="small"
            checked={state.extractOptions.include_table_of_contents}
            onChange={(event) => {
              onUpdateCloseoutLogExportOption({
                includeTableOfContents: event.target.checked,
                groupings: undefined,
                toolExtractOptions: undefined
              });

              dispatch({
                type: 'SET_TEMPLATE_EXTRACT_OPTIONS',
                value: {
                  ...state.extractOptions,
                  include_table_of_contents: event.target.checked,
                },
              });
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography typestyle="m bold">Table of Contents</Typography>
            <Typography typestyle="xs" color={Colors.darkishGray}>
              A hyperlinked overview of your extracted items
            </Typography>
          </Box>
        </Box>
      </Box>
        <Box display="flex" flexDirection="column" width={1} gap="24px">
          <UnderlinedHeader>Tool PDF Options</UnderlinedHeader>
        </Box>
        <Box display="flex" width="100%" gap="20px">
          <TabContext value={currentToolTab}>
            <Box
              display="flex"
              flexDirection="column"
              gap="14px"
              sx={{
                height: '100%',
              }}
            >
              <Typography color={Colors.darkishGray} typestyle="xs bold">
                Tools
              </Typography>
              <StyledVerticalTabs
                orientation="vertical"
                value={currentToolTab}
                onChange={(_evt, value) => setCurrentToolTab(value)}
              >
                {state.templateCloseoutLogTools.map((procoreTool, i)=>{
                return (
                  <StyledVerticalTab
                    key={`vertical-tab-${i}`}
                    value={procoreTool.procoreToolEngineName}
                    label={procoreTool.title}
                  />
                )
              })}
              </StyledVerticalTabs>
            </Box>
            <Box
              sx={{
                borderRadius: '6px',
                border: `1px solid ${Colors.mediumLightGray}`,
                height: '400px',
                overflowY: 'auto',
                flexGrow: 1,
              }}
            >
              <CustomTabPanel
                  current={currentToolTab === TOOL_ENGINE_NAMES.SUBMITTALS}
                  value={TOOL_ENGINE_NAMES.SUBMITTALS}>
                <Box
                  display="flex"
                  flexDirection="column"
                  width={1}
                  height={1}
                >
                  <Typography typestyle="m bold">
                    Attachments Content
                  </Typography>
                  {
                    attachmentContentOptions.map(option => {
                      return (
                        <FormControlLabel
                          key={option.value}
                          checked={state.extractOptions.toolExtractOptions.submittals.attachment_option === option.value}
                          onChange={handleSubmittalAttachmentContentOptionChange}
                          color="primary"
                          control={<ExtractsRadio disableFocusRipple={true} disableRipple={true} />}
                          value={option.value}
                          label={<Typography typestyle="s">{option.label}</Typography>}
                        />
                      );
                    })
                  }
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={TOOL_ENGINE_NAMES.RFIS} current={currentToolTab === TOOL_ENGINE_NAMES.RFIS}>
                <Box
                  display="flex"
                  flexDirection="column"
                  width={1}
                  height={1}
                  gap="16px"
                >
                  <Typography typestyle="m bold">
                    Attachments Content
                  </Typography>
                  <ExportOptionToggle
                    label={'Include official RFI responses only'}
                    checked={
                      state.extractOptions.toolExtractOptions.rfis
                        .response_option==='official_only'
                    }
                    onChange={(event) => {
                      updateAndDispatchExtractOptions({
                        ...state.extractOptions.toolExtractOptions,
                        rfis: {
                          response_option: event.target.checked ? 'official_only' : 'all',
                        } as RfiExtractOptions,
                      });
                    }}
                  />
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={TOOL_ENGINE_NAMES.OBSERVATIONS} current={currentToolTab === TOOL_ENGINE_NAMES.OBSERVATIONS}>
                <Box
                  display="flex"
                  flexDirection="column"
                  width={1}
                  height={1}
                  gap="16px"
                >
                  <Typography typestyle="m bold">
                    Attachments Content
                  </Typography>
                  <ExportOptionToggle
                    label={'Include thumbnails and full sized images.'}
                    checked={
                      state.extractOptions.toolExtractOptions.observations
                        .image_attachment_option === 'thumbnails_and_full_size'
                    }
                    onChange={(event) => {
                      updateAndDispatchExtractOptions({
                        ...state.extractOptions.toolExtractOptions,
                        observations: {
                          ...state.extractOptions.toolExtractOptions.observations,
                          image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only',
                        } as ObservationExtractOptions,
                      });
                    }}
                  />
                  <Typography typestyle="m bold">
                    Drawings
                  </Typography>
                  <ExportOptionToggle
                    label={'Include markup.'}
                    checked={state.extractOptions.toolExtractOptions.observations.include_linked_drawing_markup}
                    onChange={(event) => {
                      updateAndDispatchExtractOptions({
                        ...state.extractOptions.toolExtractOptions,
                        observations: {
                          ...state.extractOptions.toolExtractOptions.observations,
                          include_linked_drawing_markup: event.target.checked,
                        } as ObservationExtractOptions,
                      });
                    }}
                  />
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={TOOL_ENGINE_NAMES.INSPECTIONS} current={currentToolTab === TOOL_ENGINE_NAMES.INSPECTIONS}>
              <Box
                  display="flex"
                  flexDirection="column"
                  width={1}
                  height={1}
                  gap="16px"
                >
                  <ExportOptionToggle
                    label={'Show N/A'}
                    checked={state.extractOptions.toolExtractOptions.inspections.show_not_applicable_items}
                    onChange={(event) => {
                      updateAndDispatchExtractOptions({
                        ...state.extractOptions.toolExtractOptions,
                        inspections: {
                          ...state.extractOptions.toolExtractOptions.inspections,
                          show_not_applicable_items: event.target.checked,
                          collapse_not_applicable_sections: !event.target.checked ? false : state.extractOptions.toolExtractOptions.inspections.collapse_not_applicable_sections,
                        } as InspectionExtractOptions,
                      });
                    }}
                  />

                  {
                    state.extractOptions.toolExtractOptions.inspections.show_not_applicable_items && (
                      <ExportOptionToggle
                        sx={{
                          marginLeft: '12px',
                        }}
                        labelFontSize='12px'
                        label={'Collapse N/A Sections'}
                        checked={state.extractOptions.toolExtractOptions.inspections.collapse_not_applicable_sections}
                        onChange={(event) => {
                          updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            inspections: {
                              ...state.extractOptions.toolExtractOptions.inspections,
                              collapse_not_applicable_sections: event.target.checked,
                            } as InspectionExtractOptions,
                          });
                        }}
                      />
                    )
                  }

                  <ExportOptionToggle
                    label={'Include thumbnails and full sized images.'}
                    checked={
                      state.extractOptions.toolExtractOptions.inspections
                        .image_attachment_option === 'thumbnails_and_full_size'
                    }
                    onChange={(event) => {
                      updateAndDispatchExtractOptions({
                        ...state.extractOptions.toolExtractOptions,
                        inspections: {
                          ...state.extractOptions.toolExtractOptions.inspections,
                          image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only',
                        } as InspectionExtractOptions,
                      });
                    }}
                  />

                  <ExportOptionToggle
                    label={'Include linked Drawings.'}
                    checked={state.extractOptions.toolExtractOptions.inspections.include_linked_drawings}
                    onChange={(event) => {
                      updateAndDispatchExtractOptions({
                        ...state.extractOptions.toolExtractOptions,
                        inspections: {
                          ...state.extractOptions.toolExtractOptions.inspections,
                          include_linked_drawings: event.target.checked,
                          include_linked_drawing_markup: !event.target.checked ? false : state.extractOptions.toolExtractOptions.inspections.include_linked_drawing_markup,
                        } as InspectionExtractOptions,
                      });
                    }}
                  />

                  {
                    state.extractOptions.toolExtractOptions.inspections.include_linked_drawings && (
                      <ExportOptionToggle
                        label={'Include markup.'}
                        sx={{
                          marginLeft: '12px',
                        }}
                        labelFontSize='12px'
                        checked={state.extractOptions.toolExtractOptions.inspections.include_linked_drawing_markup}
                        onChange={(event) => {
                          updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            inspections: {
                              ...state.extractOptions.toolExtractOptions.inspections,
                              include_linked_drawing_markup: event.target.checked,
                            } as InspectionExtractOptions,
                          });
                        }}
                      />
                    )
                  }

                  <ExportOptionToggle
                    label={'Include linked Observations.'}
                    checked={state.extractOptions.toolExtractOptions.inspections.include_observations}
                    onChange={(event) => {
                      updateAndDispatchExtractOptions({
                        ...state.extractOptions.toolExtractOptions,
                        inspections: {
                          ...state.extractOptions.toolExtractOptions.inspections,
                          include_observations: event.target.checked
                        } as InspectionExtractOptions,
                      });
                    }}
                  />
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={TOOL_ENGINE_NAMES.DRAWINGS} current={currentToolTab === TOOL_ENGINE_NAMES.DRAWINGS}>
                <Box
                  display="flex"
                  flexDirection="column"
                  width={1}
                  height={1}
                  gap="16px"
                >
                  <Typography typestyle="m bold">
                    Public Markups to Include
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ width: 'fit-content' }}
                    onClick={()=>{
                      Object.values(
                        state.extractOptions.toolExtractOptions.drawings
                      ).includes(true)
                        ? updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            drawings: {
                              ...state.extractOptions.toolExtractOptions.drawings,
                              include_rfis: false,
                              include_photos: false,
                              include_sketches: false,
                              include_submittals: false,
                              include_sheet_links: false,
                              include_other_markup: false,
                            },
                          },
                          )
                        : updateAndDispatchExtractOptions({
                              ...state.extractOptions.toolExtractOptions,
                              drawings: {
                                ...state.extractOptions.toolExtractOptions.drawings,
                                include_rfis: true,
                                include_photos: true,
                                include_sketches: true,
                                include_submittals: true,
                                include_sheet_links: true,
                                include_other_markup: true,
                              },
                            },
                          );

                    }}
                  >
                    {Object.values(
                      state.extractOptions.toolExtractOptions.drawings
                    ).includes(true)
                      ? 'Deselect All'
                      : 'Select All'}
                  </Button>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems={'center'}
                  >
                    <ExtractsCheckbox
                      disableRipple
                      sx={{
                        padding: 0,
                      }}
                      checked={
                        state.extractOptions.toolExtractOptions.drawings.include_photos
                      }
                      onChange={(event) => {
                        updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            drawings: {
                              ...state.extractOptions.toolExtractOptions.drawings,
                              include_photos: event.target.checked,
                            },
                          },
                        );
                      }}
                      style={{ marginRight: 8 }}
                    />
                    <Typography>Photos</Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems={'center'}
                  >
                    <ExtractsCheckbox
                      disableRipple
                      sx={{
                        padding: 0,
                      }}
                      checked={
                        state.extractOptions.toolExtractOptions.drawings.include_rfis
                      }
                      onChange={(event) => {
                        updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            drawings: {
                              ...state.extractOptions.toolExtractOptions.drawings,
                              include_rfis: event.target.checked,
                            },
                          },
                        );
                      }}
                      style={{ marginRight: 8 }}
                    />
                    <Typography>RFIs</Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems={'center'}
                  >
                    <ExtractsCheckbox
                      disableRipple
                      sx={{
                        padding: 0,
                      }}
                      checked={
                        state.extractOptions.toolExtractOptions.drawings.include_sheet_links
                      }
                      onChange={(event) => {
                        updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            drawings: {
                              ...state.extractOptions.toolExtractOptions.drawings,
                              include_sheet_links: event.target.checked,
                            },
                          },
                        );
                      }}
                      style={{ marginRight: 8 }}
                    />
                    <Typography>Sheet Links</Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems={'center'}
                  >
                    <ExtractsCheckbox
                      disableRipple
                      sx={{
                        padding: 0,
                      }}
                      checked={
                        state.extractOptions.toolExtractOptions.drawings.include_sketches
                      }
                      onChange={(event) => {
                        updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            drawings: {
                              ...state.extractOptions.toolExtractOptions.drawings,
                              include_sketches: event.target.checked
                            },
                          },
                        );
                      }}
                      style={{ marginRight: 8 }}
                    />
                    <Typography>Sketches</Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems={'center'}
                  >
                    <ExtractsCheckbox
                      disableRipple
                      sx={{
                        padding: 0,
                      }}
                      checked={
                        state.extractOptions.toolExtractOptions.drawings.include_submittals
                      }
                      onChange={(event) => {
                        updateAndDispatchExtractOptions({
                          ...state.extractOptions.toolExtractOptions,
                          drawings: {
                            ...state.extractOptions.toolExtractOptions.drawings,
                            include_submittals: event.target.checked,
                          },
                        });
                      }}
                      style={{ marginRight: 8 }}
                    />
                    <Typography>Submittals</Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems={'center'}
                  >
                    <ExtractsCheckbox
                      disableRipple
                      sx={{
                        padding: 0,
                      }}
                      checked={
                        state.extractOptions.toolExtractOptions.drawings.include_other_markup
                      }
                      onChange={(event) => {
                        updateAndDispatchExtractOptions({
                          ...state.extractOptions.toolExtractOptions,
                          drawings: {
                            ...state.extractOptions.toolExtractOptions.drawings,
                            include_other_markup: event.target.checked,
                          },
                        });
                      }}
                      style={{ marginRight: 8 }}
                    />
                    <Typography>Other markups</Typography>
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={TOOL_ENGINE_NAMES.PUNCH_LIST} current={currentToolTab === TOOL_ENGINE_NAMES.PUNCH_LIST}>
                <Box
                  display="flex"
                  flexDirection="column"
                  width={1}
                  height={1}
                  gap="16px"
                >
                  <Typography typestyle="m bold">
                    Image Attachments
                  </Typography>
                  <ExportOptionToggle
                    label={'Include thumbnails and full sized images.'}
                    checked={
                      state.extractOptions.toolExtractOptions.punch_items
                        .image_attachment_option === 'thumbnails_and_full_size'
                    }
                    onChange={(event) => {
                      updateAndDispatchExtractOptions({
                        ...state.extractOptions.toolExtractOptions,
                        punch_items: {
                          ...state.extractOptions.toolExtractOptions.punch_items,
                          image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only',
                        } as PunchItemExtractOptions,
                      });
                    }}
                  />
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={TOOL_ENGINE_NAMES.ACTION_PLANS} current={currentToolTab === TOOL_ENGINE_NAMES.ACTION_PLANS}>
                <Box display="flex" flexDirection={"column"} width={1} gap="32px">
                  <Box
                    display="flex"
                    flexDirection="column"
                    width={1}
                    gap="16px"
                  >
                    <Typography typestyle="m bold">
                      General
                    </Typography>
                    <ExportOptionToggle
                      label={'Include References.'}
                      checked={state.extractOptions.toolExtractOptions.action_plans.include_references}
                      onChange={(event) => {
                        updateAndDispatchExtractOptions({
                          ...state.extractOptions.toolExtractOptions,
                          action_plans: {
                            ...state.extractOptions.toolExtractOptions.action_plans,
                            include_references: event.target.checked,
                          } as ActionPlanExtractOptions,
                        });
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width={1}
                    gap="16px"
                  >
                    <Typography typestyle="m bold">
                      Inspections
                    </Typography>
                    <ExportOptionToggle
                      label={'Include full-sized image files as well as thumbnails'}
                      checked={state.extractOptions.toolExtractOptions.action_plans.inspections.options.image_attachment_option === 'thumbnails_and_full_size'}
                      onChange={(event) => {
                        updateAndDispatchExtractOptions({
                          ...state.extractOptions.toolExtractOptions,
                          action_plans: {
                            ...state.extractOptions.toolExtractOptions.action_plans,
                            inspections: {
                              options: {
                                ...state.extractOptions.toolExtractOptions.action_plans.inspections.options,
                                image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only',
                              }
                            }
                          } as ActionPlanExtractOptions,
                        });
                      }}
                    />
                    <ExportOptionToggle
                      label={'Include markup on Drawings'}
                      checked={state.extractOptions.toolExtractOptions.action_plans.inspections.options.include_linked_drawing_markup}
                      onChange={(event) => {
                        updateAndDispatchExtractOptions({
                          ...state.extractOptions.toolExtractOptions,
                          action_plans: {
                            ...state.extractOptions.toolExtractOptions.action_plans,
                            inspections: {
                              options: {
                                ...state.extractOptions.toolExtractOptions.action_plans.inspections.options,
                                include_linked_drawing_markup: event.target.checked,
                                include_other_markup: !event.target.checked ? false : state.extractOptions.toolExtractOptions.action_plans.inspections.options.include_other_markup,
                              }
                            }
                          } as ActionPlanExtractOptions,
                        });
                      }}
                    />
                    {
                      state.extractOptions.toolExtractOptions.action_plans.inspections.options.include_linked_drawing_markup && (
                        <ExportOptionToggle
                          sx={{
                            paddingLeft: '12px'
                          }}
                          label={'Include other markup'}
                          checked={state.extractOptions.toolExtractOptions.action_plans.inspections.options.include_other_markup}
                          onChange={(event) => {
                            updateAndDispatchExtractOptions({
                              ...state.extractOptions.toolExtractOptions,
                              action_plans: {
                                ...state.extractOptions.toolExtractOptions.action_plans,
                                inspections: {
                                  options: {
                                    ...state.extractOptions.toolExtractOptions.action_plans.inspections.options,
                                    include_other_markup: event.target.checked,
                                  }
                                }
                              } as ActionPlanExtractOptions,
                            });
                          }}
                        />
                      )
                    }
                    <ExportOptionToggle
                      label={'Include linked Observations'}
                      checked={state.extractOptions.toolExtractOptions.action_plans.inspections.options.include_observations}
                      onChange={(event) => {
                        updateAndDispatchExtractOptions({
                          ...state.extractOptions.toolExtractOptions,
                          action_plans: {
                            ...state.extractOptions.toolExtractOptions.action_plans,
                            inspections: {
                              options: {
                                ...state.extractOptions.toolExtractOptions.action_plans.inspections.options,
                                include_observations: event.target.checked,
                              }
                            }
                          } as ActionPlanExtractOptions,
                        });
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width={1}
                    gap="16px"
                  >
                    <Typography typestyle="m bold">
                      Submittals
                    </Typography>
                    <ExportOptionToggle
                      label={'Include distributed attachments only'}
                      checked={state.extractOptions.toolExtractOptions.action_plans.submittals.options.attachment_option === 'distributed'}
                      onChange={(event) => {
                        updateAndDispatchExtractOptions({
                          ...state.extractOptions.toolExtractOptions,
                          action_plans: {
                            ...state.extractOptions.toolExtractOptions.action_plans,
                            submittals: {
                              options: {
                                ...state.extractOptions.toolExtractOptions.action_plans.submittals.options,
                                attachment_option: event.target.checked ? 'distributed' : 'all',
                              }
                            }
                          } as ActionPlanExtractOptions,
                        });
                      }}
                    />
                  </Box>
                </Box>
              </CustomTabPanel>
            </Box>
          </TabContext>
        </Box>
    </Box>
  );
};

export default ManageTemplateCloseoutLogExtractOptions;
