import { FormControlLabel, Box } from '@mui/material';
import React from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { AttachmentOption, SelectFilterValue, SubmittalExtractOptions } from '../../types/submittal';
import { ToolTabData } from '../../contexts/NewPackageContext';
import ExportOptionToggle from './ExportOptionToggle';
import { ExtractsRadio } from './styles';
import { FilterAutocomplete } from '../shared/FilterAutocomplete';
import { Colors } from '../../styles';

const attachmentContentOptions = [
  {
    value: 'all',
    label: 'All attachments'
  },
  {
    value: 'distributed',
    label: 'Distributed attachments only'
  },
  {
    value: 'last_workflow_response',
    label: 'Last workflow response with status'
  }
];

const SubmittalOptions = (props: { toolTabIndex: number, toolTabData: ToolTabData }): JSX.Element => {
  const { toolTabIndex, toolTabData } = props;
  const { dispatch } = React.useContext(NewPackageContext);

  const handleAttachmentContentOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const attachmentOption = event.target.value as AttachmentOption;

    dispatch({
      type: 'SET_TOOL_OPTIONS',
      value: {
        toolTabIndex: toolTabIndex,
        option: {
          ...toolTabData.extractOptions,
          attachment_option: attachmentOption,
          workflow_responses: [],
        } as SubmittalExtractOptions
      }
    });
  };

  const submittalExtractOptions = (toolTabData.extractOptions as SubmittalExtractOptions);

  const menuOptions: SelectFilterValue[] = toolTabData.submittalResponses.map((response) => {
    return {
      id: response.id.toString(),
      key: response.id,
      value: response.name
    } as SelectFilterValue
  });

  const selectedOptions = submittalExtractOptions.workflow_responses.map((response) => {
    return {
      id: response.id.toString(),
      key: response.id,
      value: response.name
    } as SelectFilterValue
  });

  return (
    <Box display="flex" flexDirection="column" width={1} height={1} padding={'20px 40px'}>
      <h3>Attachments Location</h3>
      <ExportOptionToggle
        label={`Include the Submittal's attachments in its cover sheet PDF`}
        checked={(toolTabData.extractOptions as SubmittalExtractOptions).single_pdf}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...toolTabData.extractOptions,
                single_pdf: event.target.checked
              } as SubmittalExtractOptions
            }
          });
        }} />
      <h3>Attachments Content</h3>
      {
        attachmentContentOptions.map(option => {
          if (option.value === 'last_workflow_response') {
            return (
              <>
                <FormControlLabel
                  key={option.value}
                  checked={submittalExtractOptions.attachment_option === option.value}
                  onChange={handleAttachmentContentOptionChange}
                  color="primary"
                  control={<ExtractsRadio disableFocusRipple={true} disableRipple={true} />}
                  value={option.value}
                  label={option.label}
                />
                {
                  submittalExtractOptions.attachment_option === 'last_workflow_response' &&
                  (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      flexWrap="wrap"
                      borderLeft={`4px solid ${Colors.mediumLightGray}`}
                      paddingLeft={'20px'}
                      margin={1}
                      marginLeft={'30px'}>
                      <FilterAutocomplete
                        disabled={toolTabData.loading}
                        key={`select-single-submittal-response-status`}
                        filterLabel={'Response Status'}
                        open={false}
                        onOpen={() => {}}
                        onClose={() => {}}
                        onChange={function (values): void {
                          const keys = values.map((value) => value.key);

                          dispatch({
                            type: 'SET_TOOL_OPTIONS',
                            value: {
                              toolTabIndex: toolTabIndex,
                              option: {
                                ...toolTabData.extractOptions,
                                attachment_option: 'last_workflow_response',
                                workflow_responses: toolTabData.submittalResponses.filter((response) => {
                                    return keys.includes(response.id);
                                }),
                              }
                            }
                          });
                        }}
                        options={menuOptions}
                        selectedOptions={selectedOptions}
                      />
                    </Box>
                  )
                }
              </>
            );
          } else {
            return (
              <FormControlLabel
                key={option.value}
                checked={(toolTabData.extractOptions as SubmittalExtractOptions).attachment_option === option.value}
                onChange={handleAttachmentContentOptionChange}
                color="primary"
                control={<ExtractsRadio disableFocusRipple={true} disableRipple={true} />}
                value={option.value}
                label={option.label}
              />
            );
          }
        })
      }
    </Box>
  )
};

export default SubmittalOptions;