import { gql } from "@apollo/client";
import { FilterOptionInput } from "../../types";
import { ProcoreDocument } from "../../types/document";

export type ProcoreFilterDocumentFolderData = {
  documentFolders: ProcoreDocument[];
  documentFiles: ProcoreDocument[];
};

export type DocumentFolderVariables = {
  accountId: string;
  packageId: number;
  procoreProjectServerId: number;
  procoreFolderServerId?: number;
  pathIds: number[];
};

export type ProcoreFilterDocumentFolderVariables = DocumentFolderVariables & {
  filters: FilterOptionInput[]
};

export const DocumentFragment = gql`
fragment DocumentFragment on ProcoreDocument {
  id
  formattedTitle
  procoreServerId
  pathIds
  parentId
  documentType
  hasChildren
  hasChildrenFolders
}
`;

export const filterDocumentFoldersQuery = gql`
query FilterProcoreDocumentFolders($accountId: ID!, $packageId: ID!, $procoreProjectServerId: ID!, $procoreFolderServerId: ID, $pathIds: [ID!]!, $filters: [FilterOptionInput!]!) {
  documentFolders(accountId: $accountId, packageId: $packageId, procoreProjectServerId: $procoreProjectServerId, procoreFolderServerId: $procoreFolderServerId, pathIds: $pathIds, filters: $filters) {
    ...DocumentFragment
  }
  documentFiles(accountId: $accountId, packageId: $packageId, procoreProjectServerId: $procoreProjectServerId, procoreFolderServerId: $procoreFolderServerId, pathIds: $pathIds, filters: $filters) {
    ...DocumentFragment
  }
}
${DocumentFragment}
`;