import React, { useContext } from 'react';
import {
  Alert,
  Box,
  Breadcrumbs,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { Colors } from '../styles';
import { Typography } from './shared/Typography';
import SubmitLogFileRequestsModal from './SubmitLogFileRequestsModal';
import { useParams } from 'react-router';
import { useGetVendorPortalData } from '../graphql/queries/GetVendorPortalData';
import { VendorPortalContext } from '../contexts/VendorPortalContextProvider';
import VendorPortalAppContainer from './VendorPortalAppContainer';

const VendorPortalApp = (): JSX.Element => {
  const { id: vendorId } = useParams<{ id: string; }>();
  const { state, dispatch } = useContext(VendorPortalContext);
  const [submitLogRequestsState, setSubmitLogRequestsState] = React.useState({ isOpen: false, logFileRequests: [] });

  const { loading, data } = useGetVendorPortalData({ vendorId: vendorId });

  React.useEffect(() => {
    if (!loading && data) {

      if (data.closeoutLog) {
        dispatch({
          type: "SET_VENDOR_PORTAL_STATE",
          value: {
            vendorId: vendorId,
            logFileRequests: data.logFileRequests,
            closeoutLog: data.closeoutLog,
            dataGridView: data.vendorPortalDataGridView,
            closeoutManagers: data.closeoutManagers
          }
        });
      } else {
        dispatch({
          type: 'SET_NOT_FOUND',
          value: true,
        })
      }
    }
  }, [loading, data]);

  const handleSuccessToastClose = () => {
    dispatch({
      type: 'SET_DISPLAY_SUCCESS_TOAST',
      value: { isOpen: false, message: '' },
    });
  };

  return (
    <>
      {submitLogRequestsState.isOpen && (
        <SubmitLogFileRequestsModal
          vendorId={vendorId}
          logFileRequests={submitLogRequestsState.logFileRequests}
          modalIsOpen={submitLogRequestsState.isOpen}
          openFileInputDialog={() => { }}
          onCloseModal={() => {
            setSubmitLogRequestsState({ isOpen: false, logFileRequests: [] });
          }} />
        )
      }

      <Snackbar
        open={state.displaySuccessToast.isOpen}
        onClose={handleSuccessToastClose}
        autoHideDuration={6000}
      >
        <Alert
          severity="success"
          elevation={6}
          onClose={handleSuccessToastClose}
        >
          {state.displaySuccessToast.message}
        </Alert>
      </Snackbar>
      <Box height={1} width={1} display={'flex'} flexDirection={'column'}>
        <Box
          sx={{
            minHeight: '40px',
            padding: '8px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: `${Colors.white}`,
            boxShadow:
              '0px 1px 10px rgba(26, 32, 36, 0.06), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)',
          }}
        >
          <Breadcrumbs
            sx={{
              '& .MuiBreadcrumbs-separator': {
                color: `${Colors.reallyBlueGray}`,
                margin: '0px 10px',
              },
            }}
          >
            <Box height={29} width={112} sx={{
              background: 'url("/images/extracts_pro_logo_banner.svg") no-repeat center'
            }} />

            {
              !state.notFound && !state.loading && (<Typography typestyle='m' sx={{ color: Colors.darkGray }}>{state.procoreProjectName}</Typography>)
            }
            {
              !state.notFound && !state.loading && (<Typography typestyle='m' sx={{ color: Colors.darkGray }}>{state.vendorName}</Typography>)
            }

            {
              !state.notFound && !state.loading && (<Typography typestyle='m bold' sx={{ color: Colors.darkGray }}>{'File Requests'}</Typography>)
            }
          </Breadcrumbs>
        </Box>
          { state.loading ? (
            <Box
              height={1}
              width={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap="24px"
            >
              <CircularProgress
                size={40}
                thickness={2}
                variant="indeterminate"
              />
              <Typography typestyle="l">Loading closeout requests...</Typography>
            </Box>
          ) : ( state.notFound ? <Box display={'flex'} flexDirection={'column'} alignItems={'center'} alignContent={'center'} gap={'24px'} height={1} width={1} justifyContent={'center'}>
            <Typography typestyle="xxl">{'404'}</Typography>
            <Typography typestyle="ml">{"The page you're looking for doesn't exist."}</Typography>
          </Box> : <VendorPortalAppContainer
              onCloseSubmitLogFileRequestsModal={() => {
                setSubmitLogRequestsState({ isOpen: false, logFileRequests: [] });
              }}
              onOpenSubmitLogFileRequestsModal={(logFileRequests) => {
                setSubmitLogRequestsState({ isOpen: true, logFileRequests });
              }}
            />)
          }
      </Box>
    </>
  )
}

export default VendorPortalApp;
