import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from './shared/Typography';
import Box from '@mui/material/Box';
import { LoadingButton as Button } from './shared/Button';

import { TemplateCloseoutLogContext } from '../contexts/TemplateCloseoutLogContextProvider';
import { useDeleteTemplateLogRequirements } from '../graphql/mutations/closeout/DeleteTemplateLogRequirements';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';
interface IConfirmDeleteTemplateRequirementModal {
  templateLogRequirementIds: number[];
  onClose: () => void;
}

export const ConfirmDeleteTemplateRequirementModal = (
  props: IConfirmDeleteTemplateRequirementModal & DialogProps
): JSX.Element => {
  const { templateLogRequirementIds, onClose, ...rest } = props;
  const { state, dispatch } = React.useContext(TemplateCloseoutLogContext);
  const { state: accountProjectState } = React.useContext(AccountProjectContext);

  const [deleteTemplateLogRequirements, { loading: loadingDeleteTemplateRequirements }] =
    useDeleteTemplateLogRequirements({
      accountId: accountProjectState.selectedAccount,
      templateCloseoutLogId: state.id,
      templateLogRequirementIds: [],
    });

  const handleDeleteLogRequirement = () => {
    deleteTemplateLogRequirements({
      variables: {
        accountId: accountProjectState.selectedAccount,
        templateCloseoutLogId: state.id,
        templateLogRequirementIds: templateLogRequirementIds,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: 'DELETE_TEMPLATE_LOG_REQUIREMENTS',
          value: { templateLogRequirementIds: templateLogRequirementIds },
        });
        onClose();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <Dialog fullWidth disableScrollLock maxWidth="xs" {...rest}>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
      >
        <Typography typestyle="xl">Confirm Action</Typography>
        <Typography>{`Are you sure you want to delete ${
          templateLogRequirementIds.length
        } requirement${
          templateLogRequirementIds.length === 1 ? '' : 's'
        }?`}</Typography>
        <Box display="flex" justifyContent="end" gap="8px">
          <Button
            disabled={loadingDeleteTemplateRequirements}
            variant="outlined"
            buttonborderstyle="pill"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            loading={loadingDeleteTemplateRequirements}
            variant="contained"
            buttonborderstyle="pill"
            onClick={() => handleDeleteLogRequirement()}
          >
            {loadingDeleteTemplateRequirements ? '' : 'Delete'}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
