import { Paper, styled } from '@mui/material';

export const BodyCard = styled(Paper)(() => ({
  borderRadius: '8px',
  boxShadow:
    '0px 1px 10px rgba(26, 32, 36, 0.06), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));
