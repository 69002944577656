import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface UpdateProcoreIntegrationData {
  updateProcoreIntegration: {
    success: boolean;
  }
}

export interface UpdateProcoreIntegrationVariables {
  accountId: string;
  procoreProjectServerIds: string[] | number[];
}

export const useUpdateProcoreIntegration = createMutationHook<UpdateProcoreIntegrationData, UpdateProcoreIntegrationVariables>(gql`
mutation UpdateProcoreIntegration($accountId: ID!, $procoreProjectServerIds: [ID!]!) {
  updateProcoreIntegration(input: { accountId: $accountId, procoreProjectServerIds: $procoreProjectServerIds }) {
    success
  }
}
`);