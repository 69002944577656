import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from './Typography';
import { Colors } from '../../styles';

export const DataGridBasicEmptyState = (props: {
  title: string;
  subtitle?: string;
}): JSX.Element => {
  const { title, subtitle } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      alignItems="center"
      justifyContent="center"
      gap="12px"
    >
      <Typography color={Colors.darkerGray} typestyle="l">
        {title}
      </Typography>
      {subtitle && (
        <Typography
          color={Colors.darkishGray}
          typestyle="xs"
          textAlign={'center'}
          sx={{ color: Colors.darkishGray, width: '500px', lineHeight: '1.2rem' }}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};
