import { gql, QueryResult, useQuery } from "@apollo/client";
import { FilterOptionInput, User } from "../../types";

export interface ProcoreCompanyUserData {
  procoreCompanyUsers: User[];
  procoreCompanyUserRecordServerIds: {
    procoreServerIds: number[]
  },
}

export interface ProcoreCompanyUserVariables {
  accountId: string;
  filters: FilterOptionInput[];
  searchValue: string;
  withAccess: boolean;
  limit: number;
  offset: number;
}


export const AccountUserFragment = gql`
fragment AccountUserFragment on AccountUser {
  id
  procoreServerId
  name
  vendorName
  role
  isActive
  jobTitle
  formattedRole
  formattedTitle
  description
}
`;

export const GetAccountUsersQuery = gql`
query GetProcoreCompanyUsers(
  $accountId: ID!,
  $filters: [FilterOptionInput!]!,
  $searchValue: String,
  $withAccess: Boolean!,
  $limit: Int!,
  $offset: Int!) {
  procoreCompanyUsers(accountId: $accountId, filters: $filters, searchValue: $searchValue, withAccess: $withAccess, limit: $limit, offset: $offset) {
    ...AccountUserFragment
  }

  procoreCompanyUserRecordServerIds(accountId: $accountId, filters: $filters, searchValue: $searchValue, withAccess: $withAccess) {
    procoreServerIds
  }
}
${AccountUserFragment}
`;

export const useGetProcoreCompanyUsersWithAccess = (
  accountId: string,
  filters: FilterOptionInput[],
  searchValue: string,
  limit: number,
  offset: number,
): QueryResult<ProcoreCompanyUserData> =>
  useQuery<ProcoreCompanyUserData, ProcoreCompanyUserVariables>(
    GetAccountUsersQuery,
    {
      variables: {
        accountId: accountId,
        filters: filters,
        searchValue: searchValue,
        withAccess: true,
        limit: limit,
        offset: offset,
      },
      fetchPolicy: "network-only",
    }
  );

export const filterProcoreCompanyUsersQuery = gql`
query FilterProcoreCompanyUsers(
  $accountId: ID!,
  $filters: [FilterOptionInput!]!,
  $searchValue: String,
  $withAccess: Boolean!,
  $limit: Int!,
  $offset: Int!) {
  procoreCompanyUsers(accountId: $accountId, filters: $filters, searchValue: $searchValue, withAccess: $withAccess, limit: $limit, offset: $offset) {
    ...AccountUserFragment
  }

  procoreCompanyUserRecordServerIds(accountId: $accountId, filters: $filters, searchValue: $searchValue, withAccess: $withAccess) {
    procoreServerIds
  }
}
${AccountUserFragment}
`;

export const fetchPaginatedProcoreCompanyUsersQuery = gql`
query FilterPaginatedProcoreCompanyUsers(
  $accountId: ID!,
  $filters: [FilterOptionInput!]!,
  $searchValue: String,
  $withAccess: Boolean!,
  $limit: Int!,
  $offset: Int!) {
  procoreCompanyUsers(accountId: $accountId, filters: $filters, searchValue: $searchValue, withAccess: $withAccess, limit: $limit, offset: $offset) {
    ...AccountUserFragment
  }
}
${AccountUserFragment}
`;