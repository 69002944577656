import {
  ListItemText,
  ListItem,
  Divider,
  Box,
  Typography,
  ListItemIcon,
  CircularProgress,
  ListItemIconProps,
  styled,
  ListItemTextProps,
} from "@mui/material";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";
import { NewPackageContext } from "../../contexts/NewPackageContextProvider";
import Loading from "../shared/Loading";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Colors } from "../../styles";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  propagateFromFile,
  propagateFromFolder,
  transformFlatDocumentsToHierarchy,
} from "../../utils/multi-tier-grouping";
import { ProcoreDocument } from "../../types/document";
import {
  CheckedCheckBox,
  IndeterminateCheckBox,
  ManualSelectCheckbox,
  UncheckedCheckBox,
} from "./styles";
import { SelectedItem } from "../../graphql/mutations/UpdatePackage";
import {
  filterDocumentFoldersQuery,
  ProcoreFilterDocumentFolderData,
  ProcoreFilterDocumentFolderVariables,
} from "../../graphql/queries/GetDocumentFolders";
import { AccountProjectContext } from "../../contexts/AccountProjectContextProvider";
import { useApolloClient } from "@apollo/client";
import { calculateParentCheckedState } from "../../utils/utils";
import { Button } from "../shared/Button";

const GroupingListItemIcon = styled(ListItemIcon)<ListItemIconProps>(({ theme }) => {
  return {
    minWidth: "unset",
    marginRight: "12px",
  }
});

const CustomListItemText = styled(ListItemText)<ListItemTextProps>(() => {
  return {
    color: Colors.darkGray,
    fontSize: "1.125em",
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
});

const HierarchyFilter = (props: { toolTabIndex: number }): JSX.Element => {
  const { toolTabIndex } = props;
  const { state, dispatch } = React.useContext(NewPackageContext);
  const { state: accountProjState } = React.useContext(AccountProjectContext);
  const client = useApolloClient();
  const toolTabData = state.toolTabs[toolTabIndex];
  const [openCollapse, setOpenCollapse] = useState<
    Record<number, boolean>
  >({});
  const [changed, setChanged] = useState(false);
  const [idAndChildrenIds, setIdAndChildrenIds] = useState<
    Record<
      number,
      { document: ProcoreDocument; children: Set<ProcoreDocument> }
    >
  >({});

  const allSelectedItems = Object.values(toolTabData.selectedItems);

  const isFileChecked = allSelectedItems.filter(d => d.itemType === 'file').reduce((acc, next) => {
    acc[next.itemId] = next.state;

    return acc;
  }, {});

  const isFolderChecked = allSelectedItems.filter(d => d.itemType === 'folder').reduce((acc, next) => {
    acc[next.itemId] = next.state;

    return acc;
  }, {});

  const handleExpandFolderClick = (procoreDocument: ProcoreDocument) => {
    const key = procoreDocument.procoreServerId;

    const open = openCollapse[key] || false;
    const newExpandedValue = !open;
    setOpenCollapse({
      ...openCollapse,
      [key]: newExpandedValue,
    });

    if (newExpandedValue) {
      const alreadyLoaded = toolTabData.loadingItems[key];
      if (alreadyLoaded === undefined) {
        dispatch({
          type: "SET_ITEM_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            key: procoreDocument.procoreServerId,
            loading: true,
          },
        });

        fetchChildren(procoreDocument);
      }
    }

    setChanged(!changed);
  };

  const fetchChildren = (procoreDocument: ProcoreDocument) => {
    client
      .query<
        ProcoreFilterDocumentFolderData,
        ProcoreFilterDocumentFolderVariables
      >({
        query: filterDocumentFoldersQuery,
        variables: {
          accountId: accountProjState.accountId,
          packageId: state.packageId,
          procoreProjectServerId: accountProjState.procoreProjectServerId,
          procoreFolderServerId: procoreDocument.procoreServerId,
          pathIds: procoreDocument.pathIds,
          filters: [],
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "APPEND_PROCORE_DOCUMENT_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreDocuments: [
              ...result.data.documentFolders.concat(result.data.documentFiles),
            ]
          },
        });

        dispatch({
          type: "SET_ITEM_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            key: procoreDocument.procoreServerId,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_ITEM_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            key: procoreDocument.procoreServerId,
            loading: false,
          },
        });
      });
  };

  const handleOnFileSelected = (document: ProcoreDocument) => {
    const _isFileChecked = { ...isFileChecked };
    const _isFolderChecked = { ...isFolderChecked };

    const checked = _isFileChecked[document.procoreServerId] || "unchecked";

    const getNewState = () => {
      switch (checked) {
        case "checked":
        case "indeterminate":
          return "unchecked";

        case "unchecked":
          return "checked";
      }
    };

    const newState = getNewState();

    propagateFromFile(
      idAndChildrenIds,
      document,
      _isFileChecked,
      _isFolderChecked,
      newState
    );

    const selectedProcoreItems: Record<number, SelectedItem> = [...toolTabData.procoreItems].reduce((acc, item) => {
      const document = item as ProcoreDocument;

        if (document.parentId) {
          if (document.documentType === "folder") {
            acc[`folder_${item.procoreServerId}`] = {
              itemId: document.procoreServerId,
              itemType: document.documentType,
              state: _isFolderChecked[document.procoreServerId] || "unchecked",
            };
          } else {
            acc[`file_${item.procoreServerId}`] = {
              itemId: document.procoreServerId,
              itemType: document.documentType,
              state: _isFileChecked[document.procoreServerId] || "unchecked",
            };
          }
        }

      return acc;
    }, {});

    dispatch({
      type: "SET_SELECTED_ITEMS",
      value: {
        toolTabIndex: toolTabIndex,
        selectedItems: selectedProcoreItems,
      },
    });
  };

  const handleOnUseSelectButton = () => {
    const rootFolder = toolTabData.procoreItems.find((f) => {
      const document = f as ProcoreDocument;

      return document.parentId === null;
    }) as ProcoreDocument;

    if (rootFolder) {
      handleOnFolderSelected(rootFolder, true);
    }
  };

  const handleOnFolderSelected = (
    document: ProcoreDocument,
    isRoot = false
  ) => {
    const _isFileChecked = { ...isFileChecked };
    const _isFolderChecked = { ...isFolderChecked };

    const getRootCheckState = () => {
      const childCheckStates = Array.from(idAndChildrenIds[document.procoreServerId].children).map(child => {
        if (child.documentType === 'folder') {
          return _isFolderChecked[child.procoreServerId] || 'unchecked'
        } else {
          return _isFileChecked[child.procoreServerId] || 'unchecked'
        }
      });

      return calculateParentCheckedState(childCheckStates);
    }

    // transition states
    // 'checked' -> 'unchecked'
    // 'indeterminate' -> 'unchecked'
    // 'unchecked' -> 'checked'
    const checked = isRoot ? getRootCheckState() : _isFolderChecked[document.procoreServerId] || "unchecked";

    const getNewState = () => {
      switch (checked) {
        case "checked":
        case "indeterminate":
          return "unchecked";

        case "unchecked":
          return "checked";
      }
    };
    const newState = getNewState();

    propagateFromFolder(
      idAndChildrenIds,
      document,
      _isFileChecked,
      _isFolderChecked,
      newState
    );

    const selectedProcoreItems: Record<number, SelectedItem> = [...toolTabData.procoreItems].reduce((acc, item) => {
      const document = item as ProcoreDocument;

        if (document.parentId) {
          if (document.documentType === "folder") {
            acc[`folder_${item.procoreServerId}`] = {
              itemId: document.procoreServerId,
              itemType: document.documentType,
              state: _isFolderChecked[document.procoreServerId] || "unchecked",
            };
          } else {
            acc[`file_${item.procoreServerId}`] = {
              itemId: document.procoreServerId,
              itemType: document.documentType,
              state: _isFileChecked[document.procoreServerId] || "unchecked",
            };
          }
        }

      return acc;
    }, {});

    dispatch({
      type: "SET_SELECTED_ITEMS",
      value: {
        toolTabIndex: toolTabIndex,
        selectedItems: selectedProcoreItems,
      },
    });
  };

  React.useEffect(() => {
    if (toolTabData.procoreItems.length > 0) {
      const procoreDocuments: ProcoreDocument[] = [
        ...toolTabData.procoreItems,
      ] as ProcoreDocument[];

      const idWithChildren: Record<
        number,
        { document: ProcoreDocument; children: Set<ProcoreDocument> }
      > = procoreDocuments
        .sort((a, b) => {
          const aDocument = a as ProcoreDocument;
          const bDocument = b as ProcoreDocument;
          // > 0 -> sort b before a
          // < 0 -> sort a before b
          // === 0 -> keep original order of a and b
          if (
            aDocument.documentType === "folder" &&
            bDocument.documentType === "folder"
          ) {
            const aPathIdLength = aDocument.pathIds.length;
            const bPathIdLength = bDocument.pathIds.length;

            if (aPathIdLength === bPathIdLength) {
              return 0;
            } else if (aPathIdLength < bPathIdLength) {
              return -1;
            } else {
              return 1;
            }
          } else if (
            aDocument.documentType === "file" &&
            bDocument.documentType === "folder"
          ) {
            return 1;
          } else if (
            aDocument.documentType === "folder" &&
            bDocument.documentType === "file"
          ) {
            return -1;
          } else {
            return aDocument.formattedTitle.localeCompare(
              bDocument.formattedTitle
            );
          }
        })
        .reduce((acc, nextDocument) => {
          const document = nextDocument as ProcoreDocument;

          if (document.documentType === "file") {
            acc[document.parentId].children.add(document);
          } else {
            // Folder should add itself in case no children
            acc[document.procoreServerId] ||= {
              document: document,
              children: new Set<ProcoreDocument>(),
            };

            if (document.parentId) {
              acc[document.parentId].children.add(document);
            }
          }

          return acc;
        }, {});

      setIdAndChildrenIds(idWithChildren);
    }
  }, [toolTabData.procoreItems]);

  function renderGroupedRowItem(
    props: ListChildComponentProps<ProcoreDocument[]>
  ) {
    const { index, style, data } = props;

    const procoreDocument = data[index] as ProcoreDocument;
    const loadingItem =
      toolTabData.loadingItems[procoreDocument.procoreServerId] || false;

    switch (procoreDocument.documentType) {
      case "folder":
        return (
          <div style={style}>
            <ListItem
              style={{
                paddingLeft: `${procoreDocument.pathIds.length * 16}px`,
              }}
              key={`folder-${procoreDocument.procoreServerId}`}
              onClick={() => handleExpandFolderClick(procoreDocument)}
            >
              <ManualSelectCheckbox
                disableFocusRipple
                disableRipple
                disableTouchRipple
                color="primary"
                indeterminateIcon={<IndeterminateCheckBox />}
                icon={<UncheckedCheckBox />}
                checkedIcon={<CheckedCheckBox />}
                onClick={(evt) => {
                  evt.stopPropagation();
                  handleOnFolderSelected(procoreDocument);
                }}
                indeterminate={
                  isFolderChecked[procoreDocument.procoreServerId] ===
                  "indeterminate"
                }
                checked={
                  isFolderChecked[procoreDocument.procoreServerId] === "checked"
                }
              />
              <GroupingListItemIcon>
                <FolderOpenIcon htmlColor="#1A2024" />
              </GroupingListItemIcon>
              <CustomListItemText
                disableTypography={true}
              >
                {procoreDocument.formattedTitle}
              </CustomListItemText>
              {loadingItem && (
                <CircularProgress
                  thickness={6}
                  size={12}
                  style={{ margin: "5px" }}
                />
              )}
              {!loadingItem &&
                procoreDocument.hasChildren &&
                (openCollapse[procoreDocument.procoreServerId] || false ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                ))}
            </ListItem>
            <Divider
              key={`folder-list-item-divider-${procoreDocument.procoreServerId}`}
            />
          </div>
        );

      case "file":
        return (
          <div style={style}>
            <ListItem
              style={{
                paddingLeft: `${(procoreDocument.pathIds.length + 1) * 16}px`,
              }}
              key={`file-list-item-${procoreDocument.procoreServerId}`}
            >
              <ManualSelectCheckbox
                disableFocusRipple
                disableRipple
                disableTouchRipple
                color="primary"
                indeterminateIcon={<IndeterminateCheckBox />}
                icon={<UncheckedCheckBox />}
                checkedIcon={<CheckedCheckBox />}
                onClick={(evt) => {
                  evt.stopPropagation();
                  handleOnFileSelected(procoreDocument);
                }}
                indeterminate={
                  isFileChecked[procoreDocument.procoreServerId] ===
                  "indeterminate"
                }
                checked={
                  isFileChecked[procoreDocument.procoreServerId] === "checked"
                }
              />
              <GroupingListItemIcon>
                <InsertDriveFileOutlinedIcon htmlColor="#1A2024" />
              </GroupingListItemIcon>
              <CustomListItemText
                disableTypography={true}
                key={`file-list-item-text-${procoreDocument.procoreServerId}`}
              >
                {procoreDocument.formattedTitle}
              </CustomListItemText>
            </ListItem>
            <Divider
              key={`file-list-item-divider-${procoreDocument.procoreServerId}`}
            />
          </div>
        );
    }
  }

  const renderItems = (): JSX.Element => {
    if (changed || !changed) {
      const procoreDocuments = [
        ...toolTabData.procoreItems,
      ] as ProcoreDocument[];
      const rootFolder = procoreDocuments.filter((d) => d.parentId === null)[0];
      const anyDocuments = procoreDocuments.length > 0;
      const itemData = transformFlatDocumentsToHierarchy(
        anyDocuments ? procoreDocuments.filter((d) => d.parentId !== null) : [],
        openCollapse,
        true,
        anyDocuments ? rootFolder.procoreServerId : null
      );
      const itemCount = itemData.length;

      return (
        <div style={{ display: "flex", height: "100%", width: "100%" }}>
          <div style={{ flex: "1 1 1px" }}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  height={height}
                  width={width}
                  itemData={itemData}
                  itemCount={itemCount}
                  itemSize={46}
                >
                  {renderGroupedRowItem}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      );
    }
  };

  const selectedItems = allSelectedItems.filter(
    (s) => s.state === "checked" || s.state === "indeterminate"
  );

  return (
    <>
      <Box
        width={1}
        style={{
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: selectedItems.length === 0 ? "#E4EBF0" : "#D2E9FA",
          background: selectedItems.length === 0 ? "white" : "#F5FBFF",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "row",
          marginBottom: "12px",
          padding: "14px 20px",
          alignItems: "center",
          minHeight: "57px",
        }}
      >
        {selectedItems.length === 0 ? (
          <InfoOutlinedIcon
            style={{ marginRight: "10px" }}
            htmlColor="#B5D7F5"
          />
        ) : (
          <CheckCircleSharpIcon
            style={{ marginRight: "10px" }}
            htmlColor="#4787BF"
          />
        )}
        {selectedItems.length === 0 ? (
          <Typography
            style={{ fontSize: 14, fontWeight: 500, color: Colors.darkerGray }}
          >
            {"Select some documents to extract"}
          </Typography>
        ) : (
          <>
            <Typography
              style={{
                fontSize: 18,
                fontWeight: 700,
                color: Colors.darkerGray,
                marginRight: "6px",
              }}
            >
              {selectedItems.length}
            </Typography>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: Colors.darkerGray,
              }}
            >
              {"documents will be extracted"}
            </Typography>
          </>
        )}
      </Box>
      <Box
        width={1}
        height={1}
        style={{
          border: "solid 1px #E4EBF0",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "column",
          flex: "1 1 1px",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          paddingLeft="20px"
          paddingTop="12px"
          paddingBottom="12px"
        >
          <Button
            disabled={toolTabData.loading}
            onClick={() => handleOnUseSelectButton()}
            variant="outlined"
            disableElevation={true}
            size="medium"
          >
            {selectedItems.length > 0 ? "Deselect All" : "Select All"}
          </Button>
        </Box>
        <Divider />
        {toolTabData.loading ? (
          <Loading loadingLabel={"Loading items..."} />
        ) : (
          renderItems()
        )}
      </Box>
    </>
  );
};

export default HierarchyFilter;
