import React from 'react';
import { AccountProjectContext } from '../../contexts/AccountProjectContextProvider';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { ProcoreFilterDocumentFolderData, ProcoreFilterDocumentFolderVariables, fetchPaginatedProcoreDocumentFoldersQuery, useGetProcoreDocumentFolders } from '../../graphql/queries/GetProcoreDocumentFolders';
import { ProcoreDocument } from '../../types/document';
import { PAGINATION, TOOL_ENGINE_NAMES } from '../../utils/utils';
import {
  Box,
  Divider,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  Breadcrumbs,
  styled,
  ListItemIconProps,
  ListItemButton,
  ListItemTextProps,
  ListItemButtonProps,
  BreadcrumbsProps,
} from '@mui/material';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Waypoint } from "react-waypoint";
import { BaseProcoreType } from '../../types';
import { Colors } from '../../styles';
import { CheckedCheckBox, ManualSelectCheckbox, UncheckedCheckBox } from './styles';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { NewPackageExtractOptions } from '../../contexts/NewPackageContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useCreatePackageResourceSync } from '../../graphql/mutations/CreatePackageResourceSync';
import { useGetPackageResourceSync } from '../../graphql/queries/GetPackageResourceSync';
import Loading from '../shared/Loading';
import { AccountProjectState } from '../../contexts/AccountProjectContext';
import { Button } from '../shared/Button';

const fetchPaginatedFolders = (
  client: ApolloClient<object>,
  accountProjectState: AccountProjectState,
  setLoadingMore: (loading: boolean) => void,
  setLoadingMoreFailed: (loading: boolean) => void,
  append: (documents: ProcoreDocument[]) => void,
  offset: number,
  procoreDocument?: ProcoreDocument
) => {
  setLoadingMoreFailed(false);
  setLoadingMore(true);

  client
    .query<ProcoreFilterDocumentFolderData, ProcoreFilterDocumentFolderVariables>({
      query: fetchPaginatedProcoreDocumentFoldersQuery,
      variables: {
        accountId: accountProjectState.accountId,
        procoreProjectServerId: accountProjectState.procoreProjectServerId,
        procoreParentFolderServerId: procoreDocument ? procoreDocument?.procoreServerId : null,
        limit: PAGINATION.limit,
        offset: offset,
      },
      fetchPolicy: "cache-first",
    })
    .then((result) => {
      append(result.data.procoreDocumentFolders);
      setLoadingMore(false);
    })
    .catch((err) => {
      console.log(err);

      setLoadingMoreFailed(true);
      setLoadingMore(false);
    });
}

const GroupingListItemIcon = styled(ListItemIcon)<ListItemIconProps>(() => {
  return {
    minWidth: "unset",
    marginRight: "12px",
  };
});

export const CustomListItemText = styled(ListItemText)<ListItemTextProps>(() => {
  return {
    color: Colors.darkerGray,
    fontSize: "1.125em",
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
});

export const CustomBreadcrumbs = styled(Breadcrumbs)<BreadcrumbsProps>(() => {
  return {
    '& .MuiBreadcrumbs-li': {
      color: Colors.darkerGray,
      fontSize: "13px",
      fontWeight: 700,
      padding: '6px 0px',
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  }
})

export const CustomListItemButton = styled(ListItemButton)<ListItemButtonProps>(() => {
  return {
    '&:hover': {
      background: Colors.lightestGray2,
      cursor: 'pointer',
    }
  };
});

export const DocumentFolderPaginatedSelection = (props: {
  handleOnClose: () => void;
  runExtract: (options: NewPackageExtractOptions) => void;
}): JSX.Element => {
  const { handleOnClose, runExtract } = props;
  const client = useApolloClient();
  const { state, dispatch } = React.useContext(NewPackageContext);
  const { state: accountProjectState } = React.useContext(AccountProjectContext);

  const [documentFolders, setDocumentFolders] = React.useState([]);
  const [documentFolderServerIds, setDocumentFolderServerIds] = React.useState([]);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [failedToFetchMore, setFailedToFetchMore] = React.useState(false);
  const [selectedFolderId, setSelectedFolderId] = React.useState(null);
  const [documentPath, setDocumentPath] = React.useState<ProcoreDocument[]>([]);
  const [getPackageResourceSyncData, setGetPackageResourceSyncData] =
    React.useState<{ id: number; status: string; skip: boolean }>({ id: 0, status: "pending", skip: true });

  const [
    createPackageResourceSync,
    {
      loading: createdPackageResourceSyncLoading,
      data: createPackageResourceSyncData,
    },
  ] = useCreatePackageResourceSync({
    packageId: state.packageId,
    accountId: accountProjectState.accountId,
    resourceType: TOOL_ENGINE_NAMES.DOCUMENTS,
  });

  const {
    loading: packageResourceSyncLoading,
    data: packageResourceSyncData,
    startPolling,
    stopPolling,
  } = useGetPackageResourceSync(
    {
      accountId: accountProjectState.accountId,
      id: getPackageResourceSyncData.id,
    },
    getPackageResourceSyncData.skip
  );

  React.useEffect(() => {
    if (!createPackageResourceSyncData || createdPackageResourceSyncLoading) {
      return;
    }

    const packageResourceSync =
      createPackageResourceSyncData.createPackageResourceSync
        .packageResourceSync;

    setGetPackageResourceSyncData({
      id: packageResourceSync.id,
      status: packageResourceSync.status,
      skip: packageResourceSync.status === "complete",
    });

    if (packageResourceSync.status === "complete") {
      loadingProcoreDocumentFolders();
    } else {
      startPolling(5000);
    }
  }, [createPackageResourceSyncData]);

  React.useEffect(() => {
    if (!packageResourceSyncData || packageResourceSyncLoading) {
      return;
    }

    setGetPackageResourceSyncData({
      id: packageResourceSyncData.packageResourceSync.id,
      status: packageResourceSyncData.packageResourceSync.status,
      skip: false,
    });

    if (packageResourceSyncData.packageResourceSync.status === "complete") {
      stopPolling();

      loadingProcoreDocumentFolders();
    }
  }, [packageResourceSyncData]);

  React.useEffect(() => {
    createPackageResourceSync();
  },[]);

  const [loadingProcoreDocumentFolders, { loading, data }] = useGetProcoreDocumentFolders({
    accountId: accountProjectState.accountId,
    procoreProjectServerId: accountProjectState.procoreProjectServerId,
    procoreParentFolderServerId: documentPath.length === 0 ? null : documentPath[documentPath.length - 1].procoreServerId,
    limit: PAGINATION.limit,
    offset: PAGINATION.offset
   },
  );

  React.useEffect(() => {
    if (!data || loading) {
      return;
    }

    setDocumentFolders(data.procoreDocumentFolders);
    setDocumentFolderServerIds(data.procoreDocumentFolderServerIds.procoreServerIds);
  }, [data]);

  React.useEffect(() => {
    if (getPackageResourceSyncData.status === "complete") {
      loadingProcoreDocumentFolders();
    }
  }, [documentPath]);

  function renderRow(props: ListChildComponentProps<BaseProcoreType>) {
    const { index, style } = props;

    const procoreItem: BaseProcoreType = documentFolders[index];
    const document = procoreItem as ProcoreDocument;
    if (procoreItem) {
      const checked = procoreItem.procoreServerId === selectedFolderId;

      return (
        <div style={style}>
          <CustomListItemButton
            disabled={selectedFolderId && !checked}
            key={`filter-list-item-${procoreItem.id}`}
            onClick={() => {
            if (!document.hasChildrenFolders) {
              if (checked) {
                setSelectedFolderId(null)
              } else {
                setSelectedFolderId(procoreItem.procoreServerId);
              }
            } else {
              setDocumentPath(
                [
                  ...documentPath,
                  document
                ]
              )
            }
          }}>
            <ManualSelectCheckbox
              disableFocusRipple
              disableRipple
              disableTouchRipple
              color="primary"
              icon={<UncheckedCheckBox />}
              checkedIcon={<CheckedCheckBox />}
              onClick={(evt) => {
                evt.stopPropagation();

                if (checked) {
                  setSelectedFolderId(null)
                } else {
                  setSelectedFolderId(procoreItem.procoreServerId);
                }
              }}
              checked={checked}
            />
            <GroupingListItemIcon>
              <FolderOpenIcon htmlColor="#1A2024" />
            </GroupingListItemIcon>
            <CustomListItemText
              disableTypography={true}
              key={`filter-list-item-text-${procoreItem.id}`}
            >
              {procoreItem.formattedTitle}
            </CustomListItemText>
            {
              document.hasChildrenFolders && (
                <ChevronRightOutlinedIcon htmlColor={Colors.mediumDarkGray} />
              )
            }
          </CustomListItemButton>
          <Divider key={`document-list-item-divider-${procoreItem.id}`} />
        </div>
      );
    } else {
      return <div style={style}>
        <Waypoint onEnter={() => {
          if (loadingMore || failedToFetchMore) { return; }

          fetchPaginatedFolders(
            client,
            accountProjectState,
            setLoadingMore,
            setFailedToFetchMore,
            (documents) => {
              setDocumentFolders([
                ...documentFolders,
                ...documents
              ]);
            },
            documentFolders.length,
            documentPath[documentPath.length - 1]
          )
        }}>
          <ListItem key={`filter-list-item-fetch-more`}>
            { failedToFetchMore ? (
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"} style={{ gap: '4px' }}>
                <Typography style={{ fontSize: '1.125em', color: Colors.darkerGray }}>Failed to load more data.</Typography>
                <a href="#" onClick={() => {
                  fetchPaginatedFolders(
                    client,
                    accountProjectState,
                    setLoadingMore,
                    setFailedToFetchMore,
                    (documents) => {
                      setDocumentFolders([
                        ...documentFolders,
                        ...documents
                      ]);
                    },
                    documentFolders.length,
                    documentPath[documentPath.length - 1]
                  )
                }}>Retry</a>
              </Box>
            ) : (
              <CustomListItemText
                disableTypography={true}
                key={`filter-list-item-text-fetch-more`}
              >
                { loadingMore ? "Loading more data..." : "Load more data." }
              </CustomListItemText>
            )}
          </ListItem>
        </Waypoint>
      </div>
    }
  }

  const isLoading = getPackageResourceSyncData.status !== "complete" || loading || !documentFolders;

  const virtualizedItemCount = (documentFolders.length < documentFolderServerIds.length) ? documentFolders.length + 1 : documentFolders.length;

  const docPathBreadcrumbs = documentPath.map((document, index) => {
    return (
      <Link
        onClick= {() => setDocumentPath(documentPath.slice(0, index + 1))}
        key={`breadcrumb-${document.procoreServerId}`}>
          {document.formattedTitle}
      </Link>
    );
  });

  const breadcrumbs = [
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      paddingLeft={"20px"}
      style={{ gap: '8px' }}
      key={`breadcrumb-container-${accountProjectState.procoreProjectServerId}`}>
      <FolderOpenIcon htmlColor="#1A2024" fontSize='small' />
      <Link
        onClick= {() => setDocumentPath([])}
        key={`breadcrumb-${accountProjectState.procoreProjectServerId}`}>
          {accountProjectState.projectName}
      </Link>
    </Box>,
    ...docPathBreadcrumbs
  ]

  return (
    <Box>
      <Box display={"flex"} width={1} padding={'0px 32px'}>
        <Box display={"flex"} width={1} flexDirection={"column"} style={{ border: '1px solid #D5DFE6', borderRadius: '6px' }}>
          <CustomBreadcrumbs style={{ borderBottom: '1px solid rgb(228, 235, 240)' } } separator={<NavigateNextOutlinedIcon fontSize='small' />}>
            {breadcrumbs}
          </CustomBreadcrumbs>
          {
            isLoading ? (
              <div style={{ display: "flex", height: "485px", width: "100%", justifyContent: "center", alignContent: "center" }}>
                <Loading loadingLabel={"Loading folders..."} />
              </div>
            ) : (
              <>
                <div style={{ display: "flex", height: "450px", width: "100%" }}>
                  <div style={{ flex: "1 1 1px" }}>
                    <AutoSizer>
                      {({ height, width }) => (
                        <FixedSizeList
                          height={height}
                          width={width}
                          itemCount={virtualizedItemCount}
                          itemSize={47}
                        >
                          {renderRow}
                        </FixedSizeList>
                      )}
                    </AutoSizer>
                  </div>
                </div>
                <Box padding={'8px'} borderTop={'1px solid rgb(228, 235, 240)'}>
                  <Typography style={{
                    fontSize: '12px',
                    color: '#A0B0BA',
                    textAlign: 'center',
                    fontWeight: '400'
                  }}>{`Showing ${documentFolders.length} of ${documentFolderServerIds.length} items`}</Typography>
                </Box>
              </>
            )
          }
        </Box>
      </Box>

      <Divider style={{ marginTop: '32px' }} />

      <Box display={"flex"} flexDirection={"row"} padding={"16px 20px"} justifyContent={"space-between"}>
        <Box display={"flex"} flexDirection={"row"} style={{ gap: '4px' }} alignItems={"center"}>
          <Tooltip title={"A file version will be appended if parent and filename match."} arrow={true}>
            <InfoOutlinedIcon htmlColor={Colors.darkishGray} fontSize="small" />
          </Tooltip>

          <Tooltip title={"A file version will be appended if parent and filename match."} arrow={true}>
            <Typography style={{
              fontSize: '12px',
              fontWeight: 400,
              color: Colors.darkishGray
            }}>{'How are duplicates handled?'}</Typography>
          </Tooltip>
        </Box>

        <Box display={"flex"} flexDirection={"row"} style={{ gap: '8px' }}>
          <Button
            onClick={() => handleOnClose()}
            variant="outlined"
            disableElevation={true}
            size="medium"
          >
            {"Cancel"}
          </Button>

          <Button
            onClick={() => {
              runExtract({
                ...state.options,
                external_storage_options: {
                  document_storage_system: 'procore',
                  storage_parent_id: selectedFolderId ? selectedFolderId : documentPath[documentPath.length - 1]?.procoreServerId
                }
              });
            }}
            disabled={isLoading}
            size="large"
            variant="contained"
            disableElevation={true}>
            {selectedFolderId ? "Extract to Selected Folder" : "Extract to Current Folder"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}