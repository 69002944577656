import React, { useState } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from './Typography';
import Box from '@mui/material/Box';
import { LoadingButton as Button } from './Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';

interface ISingleTextFieldModalProps {
  headerText: string;
  defaultText: string;
  submitText: string;
  onClose: () => void;
  onClickSubmit: (text: string) => void;
  submitLoading: boolean;
}

export const SingleTextFieldModal = (
  props: ISingleTextFieldModalProps & DialogProps
): JSX.Element => {
  const {
    headerText,
    defaultText,
    submitText,
    onClose,
    onClickSubmit,
    submitLoading,
    ...rest
  } = props;
  const [textValue, setTextValue] = useState<string>(defaultText);

  React.useEffect(()=>{
    setTextValue(defaultText);
  }, [defaultText])

  const handleClose = () => {
    onClose();
    setTextValue(defaultText);
  };

  return (
    <Dialog disableScrollLock fullWidth maxWidth="xs" onClose={handleClose} {...rest}>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
      >
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography typestyle="xl">{headerText}</Typography>
          <IconButton onClick={handleClose}>
            <Close sx={{fontSize: '18px'}}/>
          </IconButton>
        </Box>
        <TextField
          value={textValue}
          autoComplete="off"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            setTextValue(evt.target.value)
          }
        />
        <Box display="flex" justifyContent="end" gap="8px">
          <Button
            disabled={submitLoading}
            variant="outlined"
            buttonborderstyle="pill"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            loading={submitLoading}
            variant="contained"
            disabled={textValue === ''}
            buttonborderstyle="pill"
            onClick={() => onClickSubmit(textValue)}
          >
            {submitLoading ? '' : submitText}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
