import { gql } from "@apollo/client";
import { FilterOptionInput, ProcoreProjectUser } from "../../types";

export type ProcoreFilterProjectDirectoryUserData = {
  projectDirectoryUsers: ProcoreProjectUser[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  },
};

export type ProcoreFilterProjectDirectoryUserVariables = {
  accountId: string;
  procoreProjectServerId: number;
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const ProjectDirectoryUserFragment = gql`
fragment ProjectDirectoryUserFragment on ProjectUser {
    id
    formattedTitle
    name
    procoreServerId
    description
}
`;

export const filterProcoreProjectDiectoryUsersQuery = gql`
query FilterProcoreProjectDirectoryUsers($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  projectDirectoryUsers(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ProjectDirectoryUserFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId, directoryType: "users") {
    procoreServerIds
  }
}
${ProjectDirectoryUserFragment}
`;

export const fetchPaginatedProcoreProjectDirectoryUsersQuery = gql`
query FilterPaginatedProjectDirectoryUsers($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  projectDirectoryUsers(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ProjectDirectoryUserFragment
  }
}
${ProjectDirectoryUserFragment}
`;