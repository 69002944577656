import { gql } from "@apollo/client";
import { FilterOptionInput } from "../../types";
import { ProcoreSpecification } from "../../types/specification";

export type ProcoreFilterSpecificationData = {
  specifications: ProcoreSpecification[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreSpecificationVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterSpecificationVariables = ProcoreSpecificationVariables & {
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const SpecificationFragment = gql`
fragment SpecificationFragment on ProcoreSpecification {
  id
  formattedTitle
  procoreServerId
  specificationSectionDivisionName
}
`;

export const filterProcoreSpecificationsQuery = gql`
query FilterProcoreSpecifications($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  specifications(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...SpecificationFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${SpecificationFragment}
`;

export const fetchPaginatedProcoreSpecificationsQuery = gql`
query FilterPaginatedProcoreSpecifications($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  specifications(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...SpecificationFragment
  }
}
${SpecificationFragment}
`;