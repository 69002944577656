import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogFileRequestSubmitter } from "../../../types";

export interface LogFileRequestSubmitterUpsert {
  logFileRequestSubmitters: LogFileRequestSubmitter[];
  success: boolean;
}

export interface LogFileRequestSubmitterUpsertData {
  upsertLogFileRequestSubmitters: LogFileRequestSubmitterUpsert | null;
}

export interface LogFileRequestSubmitterUpsertAttributes {
  responsibleContractorServerId: number;
  procoreUserServerIds: number[];
}

export interface LogFileRequestSubmitterUpsertVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  submitters: LogFileRequestSubmitterUpsertAttributes[];
}

export const useUpsertLogFileRequestSubmitters = createMutationHook<
LogFileRequestSubmitterUpsertData,
LogFileRequestSubmitterUpsertVariables
>(gql`
  mutation UpsertLogFileRequestSubmitters(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $submitters: [LogFileRequestSubmitterUpsertAttributes!]!,
  ) {
    upsertLogFileRequestSubmitters(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, submitters: $submitters }
    ) {
      logFileRequestSubmitters {
        id
        responsibleContractorServerId
        procoreUserServerId
      }
      success
    }
  }
`);
