import { gql, QueryResult, useQuery } from "@apollo/client";
import { AccountData } from "../../types";

export const GetAccountsQuery = gql`
query GetAccounts {
  accounts {
    id
    name
    licenseType
    numberOfLicenses
    personal
    role
    freeTrialAvailable
    internal
    closeoutLogEnabled
    configuredAutomations
    closeoutLogFileRequestEnabled
    automaticExportsEnabled
    setup
    customerType
    dateFormat
    appBaseUrl
  }
}
`;

export const useGetAccounts = (skip = false): QueryResult<AccountData> => useQuery<AccountData>(GetAccountsQuery, { fetchPolicy: 'cache-first', skip: skip });