import React from "react";
import { TemplateCloseoutLogContextState, initialState, rootReducer } from './TemplateCloseoutLogContext';

const TemplateCloseoutLogContext = React.createContext<TemplateCloseoutLogContextState>({
  dispatch: () => undefined,
  state: initialState,
});

const TemplateCloseoutLogContextProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const [state, dispatch] = React.useReducer(rootReducer, initialState);

  return (
    <TemplateCloseoutLogContext.Provider value={{state, dispatch}}>
      {props.children}
    </TemplateCloseoutLogContext.Provider>
  );
}

export { TemplateCloseoutLogContext, TemplateCloseoutLogContextProvider };