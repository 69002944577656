import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { TemplateLogRequirementTrade } from "../../../types";

export interface TemplateLogRequirementTradeCreate {
  templateLogRequirementTrade?: TemplateLogRequirementTrade;
  success: boolean;
}

export interface TemplateLogRequirementTradeCreateData {
  createTemplateLogRequirementTrade: TemplateLogRequirementTradeCreate | null;
}

export interface TemplateLogRequirementTradeCreateVariables {
  accountId: string;
  templateCloseoutLogId: number;
  name: string;
}

export const useCreateTemplateLogRequirementTrade = createMutationHook<
  TemplateLogRequirementTradeCreateData,
  TemplateLogRequirementTradeCreateVariables
>(gql`
  mutation CreateTemplateLogRequirementTrade(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $name: String!
  ) {
    createTemplateLogRequirementTrade(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, name: $name }
    ) {
      templateLogRequirementTrade {
        id
        name
      }
      success
    }
  }
`);
