import { ProcoreProjectExtractAutomation } from '../../../types';
import { gql } from '@apollo/client';
import { createMutationHook } from '../../GraphqlHelpers';

export interface UpsertProcoreProjectExtractAutomationData {
  procoreProjectExtractAutomations: ProcoreProjectExtractAutomation[];
}

export interface UpsertProcoreProjectExtractAutomationDataVariables {
  accountId: string;
  extractAutomationId: string;
  attributes: ProcoreProjectExtractAutomationUpsertAttributes[];
}

interface ProcoreProjectExtractAutomationUpsertAttributes {
  procoreProjectServerId: string;
  automationType: string;
  enabled: boolean;
}

export const UpsertProcoreProjectExtractAutomationQuery = gql`
  mutation UpsertProcoreProjectExtractAutomation(
    $accountId: ID!
    $extractAutomationId: ID!
    $attributes: [ProcoreProjectExtractAutomationUpsertAttributes!]!
  ) {
    upsertProcoreProjectExtractAutomation(
      input: {
        accountId: $accountId
        extractAutomationId: $extractAutomationId
        attributes: $attributes
      }
    ) {
      success
    }
  }
`;

export const useUpsertProcoreProjectExtractAutomation = createMutationHook<
  UpsertProcoreProjectExtractAutomationData,
  UpsertProcoreProjectExtractAutomationDataVariables
>(UpsertProcoreProjectExtractAutomationQuery);
