import { ProcoreItem } from "../contexts/NewPackageContext";
import { ProcoreDocument } from "../types/document";

export type Attribute = {
  label: string;
  defaultValue: string;
};

export type Node = {
  id: string;
  label: string;
  level: number;
  parentId?: string;
  type: "node" | "procore_item";
  children: Node[];
  data?: ProcoreItem;
};

export const transformProcoreItemsToNodes = (
  items: ProcoreItem[],
  attributes: Attribute[]
): { nodes: Node[]; procoreItemMap: Map<string, ProcoreItem[]> } => {
  const map = new Map<string, ProcoreItem[]>();

  const getAttributeValue = (
    procoreItem: ProcoreItem,
    attribute: Attribute
  ): string => {
    return (procoreItem[attribute.label as keyof typeof procoreItem] ||
      attribute.defaultValue) as string;
  };

  const result = items.reduce((acc, current) => {
    const key = attributes
      .map((attr) => getAttributeValue(current, attr))
      .join(" > ");
    if (map.has(key)) {
      map.set(key, [...(map.get(key) as ProcoreItem[]), current]);
    } else {
      map.set(key, [current]);
    }

    attributes.forEach((attr, index) => {
      if (index === 0) {
        const id = getAttributeValue(current, attr);
        const node = {
          id: id,
          label: id,
          level: index + 1,
          type: "node",
          data: undefined,
          parentId: undefined,
          children: [],
        } as Node;
        acc.add(JSON.stringify(node));
      } else {
        const node = {
          id: attributes
            .slice(0, index + 1)
            .map((attr) => getAttributeValue(current, attr))
            .join(" > "),
          label: getAttributeValue(current, attr),
          level: index + 1,
          data: undefined,
          type: "node",
          parentId: attributes
            .slice(0, index)
            .map((attr) => getAttributeValue(current, attr))
            .join(" > "),
          children: [],
        } as Node;
        acc.add(JSON.stringify(node));
      }
    });

    return acc;
  }, new Set<string>());

  return {
    nodes: Array.from(result).map((json) => JSON.parse(json) as Node),
    procoreItemMap: map,
  };
};

export type CheckState = "checked" | "unchecked" | "indeterminate" | "disabled";

export const propagateFromFolderDown = (
  idsWithChildrenAndSelf: Record<
    number,
    { document: ProcoreDocument; children: Set<ProcoreDocument> }
  >,
  folder: ProcoreDocument,
  isFileChecked: Record<number, CheckState>,
  isFolderChecked: Record<number, CheckState>,
  state: CheckState
): void => {
  isFolderChecked[folder.procoreServerId] = state;

  const selfAndChildren = idsWithChildrenAndSelf[folder.procoreServerId];
  selfAndChildren.children.forEach((document) => {
    if (document.documentType === "file") {
      isFileChecked[document.procoreServerId] = state;
    } else {
      isFolderChecked[document.procoreServerId] = state;
    }
  });

  selfAndChildren.children.forEach((f) => {
    if (f.documentType === "folder") {
      propagateFromFolderDown(
        idsWithChildrenAndSelf,
        f,
        isFileChecked,
        isFolderChecked,
        state
      );
    }
  });
};

export const propagateFromFolder = (
  idsWithChildrenAndSelf: Record<
    number,
    { document: ProcoreDocument; children: Set<ProcoreDocument> }
  >,
  folder: ProcoreDocument,
  isFileChecked: Record<number, CheckState>,
  isFolderChecked: Record<number, CheckState>,
  checked: CheckState
): void => {
  isFolderChecked[folder.procoreServerId] = checked;

  const selfAndChildren = idsWithChildrenAndSelf[folder.procoreServerId];
  selfAndChildren.children.forEach((document) => {
    if (document.documentType === "file") {
      isFileChecked[document.procoreServerId] = checked;
    } else {
      isFolderChecked[document.procoreServerId] = checked;
    }
  });

  selfAndChildren.children.forEach((f) => {
    if (f.documentType === "folder") {
      propagateFromFolderDown(
        idsWithChildrenAndSelf,
        f,
        isFileChecked,
        isFolderChecked,
        checked
      );
    }
  });

  if (folder.parentId) {
    const parentAndChildren = idsWithChildrenAndSelf[folder.parentId];

    evaluateFolderAndPropagateUp(
      idsWithChildrenAndSelf,
      parentAndChildren.document,
      isFileChecked,
      isFolderChecked
    );
  }
};

export const propagateFromFile = (
  idsWithChildrenAndSelf: Record<
    number,
    { document: ProcoreDocument; children: Set<ProcoreDocument> }
  >,
  file: ProcoreDocument,
  isFileChecked: Record<number, CheckState>,
  isFolderChecked: Record<number, CheckState>,
  checked: CheckState
): void => {
  isFileChecked[file.procoreServerId] = checked;

  const parentAndChildren = idsWithChildrenAndSelf[file.parentId];

  evaluateFolderAndPropagateUp(
    idsWithChildrenAndSelf,
    parentAndChildren.document,
    isFileChecked,
    isFolderChecked
  );
};

export const evaluateFolderAndPropagateUp = (
  idsWithChildrenAndSelf: Record<
    number,
    { document: ProcoreDocument; children: Set<ProcoreDocument> }
  >,
  folder: ProcoreDocument,
  isFileChecked: Record<number, CheckState>,
  isFolderChecked: Record<number, CheckState>
): void => {
  const { children } = idsWithChildrenAndSelf[folder.procoreServerId];

  const childCheckedStates = Array.from(children).map((child) => {
    if (child.documentType === "file") {
      return isFileChecked[child.procoreServerId] || "unchecked";
    } else {
      return isFolderChecked[child.procoreServerId] || "unchecked";
    }
  });

  if (childCheckedStates.every((s) => s === "checked")) {
    isFolderChecked[folder.procoreServerId] = "checked";
  } else if (
    childCheckedStates.some((s) => s === "checked" || s === "indeterminate")
  ) {
    isFolderChecked[folder.procoreServerId] = "indeterminate";
  } else {
    isFolderChecked[folder.procoreServerId] = "unchecked";
  }

  if (folder.parentId) {
    const parentAndChildren = idsWithChildrenAndSelf[folder.parentId];

    evaluateFolderAndPropagateUp(
      idsWithChildrenAndSelf,
      parentAndChildren.document,
      isFileChecked,
      isFolderChecked
    );
  }
};

export const transformFlatDocumentsToHierarchy = (
  documents: ProcoreDocument[],
  isOpen: Record<number, boolean>,
  isRoot = true,
  parentId?: number
): ProcoreDocument[] => {
  // First iteration it will find all children with parent_id of undefined | null;
  const childDocuments = documents
    .filter((item) => item.parentId === parentId)
    .sort((a, b) => {
      // > 0 -> sort b before a
      // < 0 -> sort a before b
      // === 0 -> keep original order of a and b
      if (a.documentType === "folder" && b.documentType === "folder") {
        return a.formattedTitle.localeCompare(b.formattedTitle);
      } else if (a.documentType === "file" && b.documentType === "folder") {
        return 1;
      } else if (a.documentType === "folder" && b.documentType === "file") {
        return -1;
      } else {
        return a.formattedTitle.localeCompare(b.formattedTitle);
      }
    });

  return childDocuments.reduce((acc, nextDocument) => {
    if (isRoot) {
      acc.push(nextDocument);

      if (nextDocument.documentType === "folder") {
        if (isOpen[nextDocument.procoreServerId]) {
          const children = transformFlatDocumentsToHierarchy(
            documents,
            isOpen,
            false,
            nextDocument.procoreServerId
          );

          if (children.length > 0) {
            acc.push(...children);
          }
        }
      }
    } else {
      // nextDocument is a "folder"
      if (nextDocument.documentType === "folder") {
        const isParentFolderExpanded = isOpen[parentId];

        if (isParentFolderExpanded) {
          acc.push(nextDocument);

          const children = transformFlatDocumentsToHierarchy(
            documents,
            isOpen,
            false,
            nextDocument.procoreServerId
          );

          if (children.length > 0) {
            acc.push(...children);
          }
        }
      } else {
        // nextDocument is a "file"
        const isParentFolderExpanded = isOpen[parentId];
        if (isParentFolderExpanded) {
          acc.push(nextDocument);
        }
      }
    }

    return acc;
  }, []);
};

export const transformNodesToHierarchy = (
  items: Node[],
  procoreItemMap: Map<string, ProcoreItem[]>,
  parentId?: string
): Node[] => {
  const childrenItems = items.filter((item) => item.parentId === parentId);

  return childrenItems.map((item) => {
    const children = transformNodesToHierarchy(items, procoreItemMap, item.id);

    if (children.length === 0) {
      return {
        id: item.id,
        label: item.label,
        level: item.level,
        type: item.type,
        data: item.data,
        parentId: item.parentId,
        children: (procoreItemMap.get(item.id) || []).map((procoreItem) => {
          return {
            id: procoreItem.id.toString(),
            label: procoreItem.formattedTitle,
            level: item.level + 1,
            parentId: item.id,
            children: [],
            data: procoreItem,
            type: "procore_item",
          } as Node;
        }),
      };
    } else {
      return {
        id: item.id,
        label: item.label,
        level: item.level,
        parentId: item.parentId,
        children: children,
        type: item.type,
        data: item.data,
      };
    }
  });
};

export const flatten = (children: Node[], withChildren = false): Node[] => {
  return children.reduce((acc, node) => {
    acc = acc.concat({
      id: node.id,
      label: node.label,
      level: node.level,
      parentId: node.parentId,
      children: withChildren ? node.children : [],
      type: node.type,
      data: node.data,
    });

    acc = acc.concat(flatten(node.children, withChildren));
    return acc;
  }, []);
};
