import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface UseProjectLicensesData {
  useProjectLicenses: {
    success: boolean;
    numberOfLicenses: number;
    procoreProjectServerIds: number[];
  }
}

export interface UseProjectLicensesVariables {
  accountId: string;
  procoreProjectServerIds: number[];
}

export const useUseProjectLicenses = createMutationHook<UseProjectLicensesData, UseProjectLicensesVariables>(gql`
mutation UseProjectLicenses($accountId: ID!, $procoreProjectServerIds: [ID!]!) {
  useProjectLicenses(input: { accountId: $accountId, procoreProjectServerIds: $procoreProjectServerIds }) {
    success
    numberOfLicenses
    procoreProjectServerIds
  }
}
`);