import React from 'react';
import { ListItemText, styled } from '@mui/material';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { Colors } from '../../styles';
import { Typography } from '../shared/Typography';
import IconButton from '@mui/material/IconButton';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import EditOutlined from '@mui/icons-material/EditOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const StyledRow = styled(TableRow)<TableRowProps>({
  '&:hover': {
    backgroundColor: Colors.lightestGray2,
    cursor: 'pointer',
  },
});

export const TableRowCell = styled(TableCell)<TableCellProps>(() => {
  return {
    borderBottom: `1px solid ${Colors.mediumLightGray}`,
    color: Colors.darkerGray,
  };
});

interface ICloseoutLogListRowProps {
  name: string;
  count: string;
  percentComplete: string;
  date: string;
  disabled: boolean;
  onClickDelete: () => void;
  onClickRename: () => void;
  onClickDuplicate: () => void;
}

export const CloseoutLogListRow = (
  props: ICloseoutLogListRowProps & TableRowProps
): JSX.Element => {
  const { name, count, percentComplete, date, onClickDelete, onClickRename, disabled, onClickDuplicate, ...rest } = props;
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>(null);
  return (
    <>
      <Popover
        id="add-req-menu"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            padding: '12px 8px',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            onClickRename();
            setAnchorEl(null);
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <EditOutlined sx={{ fontSize: '20px' }} />
            <ListItemText>Rename</ListItemText>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClickDuplicate();
            setAnchorEl(null);
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <ContentCopyOutlinedIcon sx={{ fontSize: '20px' }} />
            <ListItemText>Duplicate</ListItemText>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClickDelete();
            setAnchorEl(null);
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <DeleteOutline color="error" sx={{ fontSize: '20px' }} />
            <ListItemText>Delete</ListItemText>
          </Box>
        </MenuItem>

      </Popover>
      <StyledRow
        {...rest}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <TableRowCell>
          <Typography>{name}</Typography>
        </TableRowCell>
        <TableRowCell>
          <Typography>{count}</Typography>
        </TableRowCell>
        <TableRowCell>
          <Typography>{percentComplete}</Typography>
        </TableRowCell>
        <TableRowCell>
          <Typography>{date}</Typography>
        </TableRowCell>
        <TableRowCell component="td" align="right">
          <IconButton
            sx={{
              opacity: isHovered ? '1' : '0',
            }}
            disabled={disabled}
            onClick={(evt) => {
              evt.stopPropagation();
              setAnchorEl(evt.currentTarget);
            }}
          >
            <MoreHoriz sx={{ fontSize: '20px' }} />
          </IconButton>
        </TableRowCell>
      </StyledRow>
    </>
  );
};
