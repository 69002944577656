import { gql } from "@apollo/client";
import { ProcoreToolFilter } from "../../contexts/NewPackageContext";
import { FilterOptionInput, ProcoreToolGroupings } from "../../types";
import { ProcoreObservation } from "../../types/observation";

export type ProcoreObservationFilterData = {
  observationFilters: ProcoreToolFilter[];
  observationDefaultFilters: ProcoreToolFilter[];
  observationGroupings: ProcoreToolGroupings[];
};

export type ProcoreFilterObservationData = {
  observations: ProcoreObservation[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreObservationVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterObservationVariables = ProcoreObservationVariables & {
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const ObservationFragment = gql`
fragment ObservationFragment on ProcoreObservation {
  id
  procoreServerId
  formattedTitle
  locationName
  createdBy
  assignee
  originType
  specificationSection
  trade
  typeName
  status
  description
}
`;

export const filterProcoreObservationsQuery = gql`
query FilterProcoreObservations($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  observations(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ObservationFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${ObservationFragment}
`;

export const fetchPaginatedProcoreObservationsQuery = gql`
query FilterPaginatedProcoreObservations($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  observations(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ObservationFragment
  }
}
${ObservationFragment}
`;