import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface CloseoutLogDeleteData {
  deleteCloseoutLog: {
    success: boolean;
  }
}

export interface CloseoutLogDeleteVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
}

export const useDeleteCloseoutLog = createMutationHook<
CloseoutLogDeleteData,
CloseoutLogDeleteVariables
>(gql`
  mutation DeleteCloseoutLog(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!
  ) {
    deleteCloseoutLog(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId }
    ) {
      success
    }
  }
`);
