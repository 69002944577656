import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface VendorPortalCreateProcoreProjectUpload {
  success: boolean;
  procoreUploadPayload: Record<string, any>;
}

export interface VendorPortalCreateProcoreProjectUploadData {
  createProcoreProjectUpload: VendorPortalCreateProcoreProjectUpload
}

export interface VendorPortalCreateProcoreProjectUploadVariables {
  vendorId: string;
  filename: string;
}

export const useVendorPortalCreateProcoreProjectUpload = createMutationHook<VendorPortalCreateProcoreProjectUploadData, VendorPortalCreateProcoreProjectUploadVariables>(gql`
mutation CreateProcoreProjectUpload($vendorId: ID!, $filename: String!) {
  createProcoreProjectUpload(input: { vendorId: $vendorId, filename: $filename }) {
    success
    procoreUploadPayload
  }
}
`);