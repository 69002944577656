import React from 'react';
import { List, Box, TextField, CircularProgress, Tooltip } from '@mui/material';
import { Typography } from './shared/Typography';
import { Colors } from '../styles';
import AddCircle from '@mui/icons-material/AddCircle';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';
import { useCreateTemplateLogRequirementType } from '../graphql/mutations/closeout/CreateTemplateLogRequirementType';
import { useUpdateTemplateLogRequirementType } from '../graphql/mutations/closeout/UpdateTemplateLogRequirementType';
import { useDeleteTemplateLogRequirementType } from '../graphql/mutations/closeout/DeleteTemplateLogRequirementType';
import { TemplateLogRequirementType } from '../types';
import { isEmpty } from '../utils/utils';
import { LoadingButton } from './shared/Button';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { TemplateCloseoutLogContext } from '../contexts/TemplateCloseoutLogContextProvider';

interface ITypeRowProps {
  item: TemplateLogRequirementType;
  loading: {isLoading: boolean, id: number};
  disableDelete: boolean;
  onDelete: (id: number) => void;
  onUpdate: (id: number, name: string) => void;
}

const TypeRow = ({ item, loading, disableDelete, onDelete, onUpdate }: ITypeRowProps): JSX.Element => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [newName, setNewName] = React.useState(item.name);
  return (
    <Box
      display="flex"
      gap="2px"
      padding="12px 12px 12px 16px"
      borderBottom={`1px solid ${Colors.mediumLightGray}`}
      fontSize={'14px'}
      alignItems={'center'}
    >
      {
        isEditing ? (
        <TextField
          sx={{
            '& .MuiOutlinedInput-input': {
                padding: "2px 12px",
            },
          }}
            variant='outlined'
          fullWidth
          autoFocus
          disabled={loading.isLoading}
          inputRef={(input) => input && input.focus()}
          onFocus={(evt) => setNewName(item.name)}
          onBlur={() => {
            if (!isEmpty(newName) && newName !== item.name) {
              onUpdate(item.id, newName);
              setIsEditing(false);
                setNewName('')
            } else {
              setIsEditing(false);
              setNewName('');
            }
          }}
          value={newName}
          onKeyDown={(evt: any) => {
              if (evt.key === 'Enter' || evt.key === 'Escape' || evt.key === 'Tab') {
              evt.stopPropagation();
              evt.preventDefault();
              evt.target?.blur();
            }
          }}
            onChange={(evt) => setNewName(evt.target.value) }
        />
      ) : (
        <Typography typestyle="s" flexGrow="1">
          {item.name}
        </Typography>
      )}
      {loading.isLoading && loading.id === item.id && (
        <CircularProgress size={16} sx={{marginRight: '8px'}}/>
      )}
      <IconButton
        size="small"
        disabled={loading.isLoading}
        onClick={() => setIsEditing(true)}
      >
        <EditOutlined fontSize="inherit" />
      </IconButton>
      <Tooltip
        placement='right'
        title={
          disableDelete
            ? 'This type cannot be deleted because it is currently referenced by at least one closeout requirement'
            : ''
        }
      >
        <span>
          <IconButton
            size="small"
            disabled={loading.isLoading || disableDelete}
            onClick={() => onDelete(item.id)}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export const ManageTemplateTypes = (): JSX.Element => {
  const { state: accountProjectState } = React.useContext(AccountProjectContext);
  const { state, dispatch } = React.useContext(TemplateCloseoutLogContext);
  const [value, setValue] = React.useState('');

  const [ createLogRequirementType, { loading: loadingCreateType } ] = useCreateTemplateLogRequirementType({
    accountId: accountProjectState.selectedAccount,
    templateCloseoutLogId: state.id,
    name: 'test'
  });

  const [ updateLogRequirementType, { loading: loadingUpdateType } ] = useUpdateTemplateLogRequirementType({
    accountId: accountProjectState.selectedAccount,
    templateCloseoutLogId: state.id,
    id: -1,
    name: 'test'
  });

  const [ deleteLogRequirementType, { loading: loadingDeleteType } ] = useDeleteTemplateLogRequirementType({
    accountId: accountProjectState.selectedAccount,
    templateCloseoutLogId: state.id,
    id: -1
  });

  const [loading, setLoading] = React.useState<{isLoading: boolean, id: number}>({isLoading: false, id: undefined})

  const usedTypeIds = React.useMemo(() => {
      return state.templateLogRequirements.reduce((acc, currentVal) => {
        if (currentVal.templateLogRequirementType) {
          acc.add(currentVal.templateLogRequirementType.id);
        }
        return acc;
      }, new Set<number>())
  }, [state.templateLogRequirements]);

  const handleCreate = () => {
    if (isEmpty(value) || state.templateLogRequirementTypes.some((item) => {
      return (item.name || '').trim().localeCompare((value || '').trim(), undefined, { numeric: true, sensitivity: 'base' }) === 0
    })) { return; }

    createLogRequirementType({
      variables: {
        accountId: accountProjectState.selectedAccount,
        templateCloseoutLogId: state.id,
        name: value
      }
    }).then(({ data }) => {
      if (data.createTemplateLogRequirementType.success) {
        setValue('');
        dispatch({ type: 'PREPEND_TEMPLATE_LOG_REQUIREMENT_TYPE', value: data.createTemplateLogRequirementType.templateLogRequirementType })
      }
    }).catch((err) => {
      console.log('err', err)
    });
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'16px'} height={1}>
      <Box display="flex" gap="8px" flex={'0 0 auto'}>
        <TextField
          value={value}
          autoComplete='off'
          onChange={(e) => setValue(e.target.value)}
          placeholder="Name a new type..."
          onKeyDown={(evt) => {
            if (evt.key === 'Enter') {
              handleCreate();
            }
          }}
          sx={{
            flexGrow: 1,
            '& .MuiInputBase-root': {
              height: '44px',
              fontSize: '0.875rem',
          },
            '& .MuiOutlinedInput-root': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: Colors.mediumGray,
              }
            }
          }}
        />
        <LoadingButton
          variant="outlined"
          size="large"
          startIcon={<AddCircle fontSize="small" />}
          disabled={isEmpty(value) || state.templateLogRequirementTypes.some((item) => {
            return (item.name || '').trim().localeCompare((value || '').trim(), undefined, { numeric: true, sensitivity: 'base' }) === 0
          })}
          loading={loadingCreateType}
          loadingPosition='start'
          onClick={handleCreate}
        >
          Add
        </LoadingButton>
      </Box>
      <List
        sx={{
          flex: '1 1 0px',
          overflowY: 'auto',
          border: `1px solid ${Colors.mediumLightGray}`,
          borderRadius: '6px',
          padding: '0px',
        }}
      >
        {state.templateLogRequirementTypes.map((item, i) => {
          return (
            <TypeRow key={`log-requirement-type-${i}`}
              item={item}
              loading={loading}
              disableDelete={usedTypeIds.has(item.id)}
              onUpdate={(id, name) => {
                setLoading({isLoading: true, id: id})
                updateLogRequirementType({
                  variables: {
                    accountId: accountProjectState.selectedAccount,
                    templateCloseoutLogId: state.id,
                    id: id,
                    name: name
                  }
                }).then(({ data }) => {
                  if (data.updateTemplateLogRequirementType.success) {
                    dispatch({ type: 'UPDATE_TEMPLATE_LOG_REQUIREMENT_TYPE', value: data.updateTemplateLogRequirementType.templateLogRequirementType })
                  }
                  setLoading({isLoading: false, id: undefined})
                }).catch((err) => {
                  setLoading({isLoading: false, id: undefined})
                  console.log('err', err)
                });
              }}
              onDelete={(id) => {
                setLoading({isLoading: true, id: id})
                deleteLogRequirementType({
                  variables: {
                    accountId: accountProjectState.selectedAccount,
                    templateCloseoutLogId: state.id,
                    id: id
                  }
                }).then(({ data }) => {
                  if (data.deleteTemplateLogRequirementType.success) {
                    dispatch({ type: 'DELETE_TEMPLATE_LOG_REQUIREMENT_TYPE', value: id })
                  }
                  setLoading({isLoading: false, id: undefined})
                }).catch((err) => {
                  setLoading({isLoading: false, id: undefined})
                  console.log('err', err)
                });
              }} />
          );
        })}
      </List>
    </Box>
  );
};
