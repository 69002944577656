import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface CreateProcoreProjectUpload {
  success: boolean;
  procoreUploadPayload: Record<string, any>;
}

export interface CreateProcoreProjectUploadData {
  createProcoreProjectUpload: CreateProcoreProjectUpload
}

export interface CreateProcoreProjectUploadVariables {
  accountId: string;
  procoreProjectServerId: number;
  filename: string;
}

export const useCreateProcoreProjectUpload = createMutationHook<CreateProcoreProjectUploadData, CreateProcoreProjectUploadVariables>(gql`
mutation CreateProcoreProjectUpload($accountId: ID!, $procoreProjectServerId: ID!, $filename: String!) {
  createProcoreProjectUpload(input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filename: $filename }) {
    success
    procoreUploadPayload
  }
}
`);