import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface ExtractAutomationDeleteData {
  deleteExtractAutomation: {
    success: boolean;
  }
}

export interface ExtractAutomationDeleteVariable {
  accountId: string;
  extractAutomationIds: number[];
}

export const useDeleteExtractAutomations = createMutationHook<
ExtractAutomationDeleteData,
ExtractAutomationDeleteVariable
>(gql`
  mutation DeleteExtractAutomations(
    $accountId: ID!,
    $extractAutomationIds: [ID!]!
  ) {
    deleteExtractAutomations(
      input: { accountId: $accountId, extractAutomationIds: $extractAutomationIds }
    ) {
      success
    }
  }
`);
