import React from 'react';
import { DataGridBasicEmptyState } from './shared/DataGridBasicEmptyState';

export const VendorUserListEmptyState = (): JSX.Element => {
  return (
    <DataGridBasicEmptyState
      title="No Users"
      subtitle="Make sure you've selected 1 or more companies that have users associated with them."
    />
  );
};
