import Box from '@mui/material/Box';
import React, { useContext } from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { EditableHeaderInput } from '../../styles';

interface NewPackageActionBarProps {
  showModal: () => void;
  runExtract: () => void;
}

const NewPackageActionBar = ({
  showModal,
  runExtract,
}: NewPackageActionBarProps): JSX.Element => {
  const { state, dispatch } = useContext(NewPackageContext);

  function handlePackageNameChanged(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: 'SET_NAME',
      name: e.target.value,
    });
  }

  function handlePackageInputOnBlur() {
    if (state.name === '' || state.name === undefined) {
      dispatch({
        type: 'SET_NAME',
        name: 'Untitled Package',
      });
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      width={1}
      marginTop={3}
      marginBottom={2}
      alignItems={'center'}
    >
      <Box flexGrow="1">
        <EditableHeaderInput
          autoFocus
          onKeyUp={(evt) => {
            if (evt.key === 'Enter') {
              evt.currentTarget.blur();
            }
          }}
          value={state.name}
          placeholder="Name this package"
          onBlur={handlePackageInputOnBlur}
          onChange={handlePackageNameChanged}
        />
      </Box>
    </Box>
  );
};

export default NewPackageActionBar;
