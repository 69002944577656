import React from 'react'

import { Icon, Box, Typography } from '@mui/material';
import { Button } from './shared/Button';

const Error = ():JSX.Element => {
  return (
    <Box display="flex" flexDirection='column' justifyContent="center" alignItems="center" width={1} height={1}>
      <Icon style={{ height: '280px', width: '230px' }}>
        <img style={{ height: '100%', width: '100%' }} src={'/images/warning.svg'} />
      </Icon>
      <Typography variant='h6'>
        {'Oops!'}
      </Typography>
      <Typography variant='subtitle1' style={{ color: '#596A75' }}>
        {'Looks like something went wrong.'}
      </Typography>
      <Button
        size="large"
        variant="contained"
        disableElevation={true}
        style={{
          marginTop: '20px'
        }}
        onClick={() => { window.location.href = "mailto:support@extractspro.com" }}>
        {'Contact Extracts Pro Support'}
      </Button>
    </Box>
  )
};

export default Error;