import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface RemoveUsersFromAccountData {
  removeUsersFromAccount: {
    success: boolean;
  }
}

export interface RemoveUsersFromAccountVariables {
  accountId: string;
  procoreLoginInformationIds: number[];
}

export const useRemoveUsersFromAccount = createMutationHook<RemoveUsersFromAccountData, RemoveUsersFromAccountVariables>(gql`
mutation RemoveUsersFromAccount($accountId: ID!, $procoreLoginInformationIds: [ID!]!) {
  removeUsersFromAccount(input: { accountId: $accountId, procoreLoginInformationIds: $procoreLoginInformationIds }) {
    success
  }
}
`);