import { gql } from "@apollo/client";
import { ProcoreToolData } from "../../types";
import { createLazyQueryHook } from "../GraphqlHelpers";

export interface ProcoreToolVariables {
  accountId: string;
  procoreProjectServerId: number;
}

export const getProcoreTools = createLazyQueryHook<ProcoreToolData, ProcoreToolVariables>(gql`
query GetProcoreTools($accountId: ID!, $procoreProjectServerId: ID!) {
  procoreTools(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId) {
    id
    title
    engineName
    procoreServerId
  }

  exportOptions(accountId: $accountId) {
    id
    engineName
    options
  }

  packageExportOption(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId) {
    id
    exportToProcoreDocuments
    encryptPdfs
  }
}
`, 'cache-and-network');