import React, { useState } from 'react';
import { Typography } from './Typography';
import Box from '@mui/material/Box';
import { LoadingButton as Button } from './Button';
import TextField from '@mui/material/TextField';
import { FieldContainer } from '../../styles';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { parse } from 'date-fns';

interface IManageCloseoutLogDetails {
  defaultText: string;
  substantialCompletionDate?: Date;
  onClickSubmit: (text: string, newDate?: Date) => void;
  submitLoading: boolean;
}

export const ManageCloseoutLogDetails = (
  props: IManageCloseoutLogDetails
): JSX.Element => {
  const {
    defaultText,
    substantialCompletionDate,
    onClickSubmit,
    submitLoading,
  } = props;
  const [textValue, setTextValue] = useState<string>(defaultText);
  const [newSubstantialCompletionDate, setNewSubstantialCompletionDate] = useState<Date | null>(
    typeof substantialCompletionDate === 'string' ? parse(substantialCompletionDate, 'yyyy-MM-dd', new Date()) : substantialCompletionDate
  );

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'24px'} width={'50%'}>
      <FieldContainer>
        <Typography typestyle="m">{'Name'}</Typography>
        <TextField
          value={textValue}
          autoComplete="off"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            setTextValue(evt.target.value)
          }
        />
      </FieldContainer>
      <FieldContainer>
        <Typography typestyle="m">{'Substantial Completion Date'}</Typography>
        <DatePicker value={newSubstantialCompletionDate}
          onChange={(value) => {
            setNewSubstantialCompletionDate(value);
          }} />
      </FieldContainer>

      <Box display="flex" justifyContent="end" gap="8px">
        <Button
          loading={submitLoading}
          variant="contained"
          disabled={textValue === ''}
          buttonborderstyle="pill"
          onClick={() => onClickSubmit(textValue, newSubstantialCompletionDate)}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
