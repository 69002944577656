import { SpecSection, VendorPortalCloseoutLog, VendorPortalCloseoutManager, VendorPortalDataGridView, VendorPortalLogFileRequest } from "../types";
import { parseDate } from "../utils/utils";

export type VendorWorkflowResponseAttachment = {
  fileRecordId: string;
  procoreFileServerId: number;
  filename: string;
  createdAt: string;
  createData: Record<string, any>;
}

export type VendorPortalState = {
  vendorId: string;
  vendorName: string;
  closeoutLogId: number;
  procoreProjectName: string;
  projectAddress: string;
  formattedAddress: string;
  substantialCompletionDate?: Date;
  logFileRequests: VendorPortalLogFileRequest[];
  specSections: SpecSection[];
  statuses: { value: string; label: string, name: string }[];
  responseAttachments: Record<number, VendorWorkflowResponseAttachment[]>;
  displaySuccessToast: { isOpen: boolean, message: string };
  vendorPortalDataGridView?: VendorPortalDataGridView;
  loading: boolean;
  notFound: boolean;
  closeoutManagers: VendorPortalCloseoutManager[];
};

export const initialState: VendorPortalState = {
  vendorId: null,
  vendorName: '',
  closeoutLogId: null,
  procoreProjectName: '',
  projectAddress: '',
  formattedAddress: '',
  substantialCompletionDate: null,
  logFileRequests: [],
  specSections: [],
  statuses: [],
  responseAttachments: {},
  displaySuccessToast: { isOpen: false, message: '' },
  vendorPortalDataGridView: null,
  loading: true,
  notFound: false,
  closeoutManagers: [],
};

export type Action =
  { type: "SET_VENDOR_PORTAL_STATE"; value: {
    vendorId: string;
    logFileRequests: VendorPortalLogFileRequest[],
    closeoutLog: VendorPortalCloseoutLog,
    dataGridView: VendorPortalDataGridView,
    closeoutManagers: VendorPortalCloseoutManager[]
  }; }
  | { type: "APPEND_RESPONSE_ATTACHMENTS"; value: { logFileRequestId: number; attachments: VendorWorkflowResponseAttachment[] }; }
  | { type: "REMOVE_RESPONSE_ATTACHMENT"; value: { logFileRequestId: number; fileRecordId: string }; }
  | { type: "CLEAR_RESPONSE_ATTACHMENTS"; }
  | { type: 'UPDATE_LOG_FILE_REQUESTS', value: VendorPortalLogFileRequest[] }
  | { type: 'SET_DISPLAY_SUCCESS_TOAST', value: { isOpen: boolean; message: string; } }
  | { type: 'UPDATE_VENDOR_PORTAL_DATA_GRID_VIEW', value: VendorPortalDataGridView }
  | { type: 'SET_NOT_FOUND', value: boolean }
  ;

export const rootReducer: React.Reducer<VendorPortalState, Action> = (
  state,
  action
) => {
  switch (action.type) {
    case "SET_NOT_FOUND": {
      return {
        ...state,
        notFound: action.value,
        loading: false
      };
    }

    case "SET_VENDOR_PORTAL_STATE": {
      return {
        ...state,
        vendorId: action.value.vendorId,
        logFileRequests: action.value.logFileRequests,
        specSections: action.value.logFileRequests.reduce((acc, request) => {
          if (request.specSection && !acc.find(s => s.id === request.specSection.id)) {
            acc.push(request.specSection);
          }

          return acc;
        }, []),
        statuses: action.value.logFileRequests.reduce((acc, request) => {
          if (request.vendorWorkflowRequest.vendorWorkflowResponse) {
            const status = request.vendorWorkflowRequest.vendorWorkflowResponse.translatedStatus;
            if (!acc.find(s => s.value === status)) {
              acc.push({ value: status, label: status, name: status });
            }
          } else {
            const status = request.vendorWorkflowRequest?.translatedStatus
            if (status && !acc.find(s => s.value === status)) {
              acc.push({ value: status, label: status, name: status });
            }
          }

          return acc;
        }, []),
        loading: false,
        closeoutLogId: action.value.closeoutLog.id,
        vendorName: action.value.closeoutLog.vendorName,
        procoreProjectName: action.value.closeoutLog.procoreProjectName,
        projectAddress: action.value.closeoutLog.projectAddress,
        formattedAddress: action.value.closeoutLog.formattedAddress,
        substantialCompletionDate: parseDate(action.value.closeoutLog.substantialCompletionDate),
        vendorPortalDataGridView: action.value.dataGridView,
        closeoutManagers: action.value.closeoutManagers
      };
    }

    case "REMOVE_RESPONSE_ATTACHMENT": {
      const { logFileRequestId, fileRecordId } = action.value;
      const updatedAttachments = state.responseAttachments[logFileRequestId].filter(a => a.fileRecordId !== fileRecordId);
      return {
        ...state,
        responseAttachments: {
          ...state.responseAttachments,
          [logFileRequestId]: updatedAttachments
        }
      };
    }

    case "CLEAR_RESPONSE_ATTACHMENTS": {
      return {
        ...state,
        responseAttachments: {}
      };
    }

    case "APPEND_RESPONSE_ATTACHMENTS": {
      const { logFileRequestId, attachments } = action.value;

      const existingAttachments = state.responseAttachments[logFileRequestId] || [];
      const existAttachmentIds = existingAttachments.map(a => a.procoreFileServerId);

      const responseAttachments = {
        ...state.responseAttachments,
        [logFileRequestId]: [
          ...existingAttachments,
          ...attachments.filter(a => !existAttachmentIds.includes(a.procoreFileServerId))
        ]
      };

      return {
        ...state,
        responseAttachments
      };
    }

    case "UPDATE_LOG_FILE_REQUESTS": {
      let updatedCount = 0;
      const newLogFileRequests = state.logFileRequests.reduce((acc, request) => {
        const updatedRequest = action.value.find(r => r.id === request.id);
        if (updatedRequest) {
          updatedCount += 1;
          acc.push(updatedRequest);
        } else {
          acc.push(request);
        }
        return acc;
      }, []);

      return {
        ...state,
        logFileRequests: newLogFileRequests,
        responseAttachments: {},
        displaySuccessToast: {
          isOpen: updatedCount > 0,
          message: (updatedCount > 0) ? `${updatedCount} file(s) submitted.` : ''
        }
      };
    }

    case "SET_DISPLAY_SUCCESS_TOAST": {
      return {
        ...state,
        displaySuccessToast: action.value
      };
    }

    case "UPDATE_VENDOR_PORTAL_DATA_GRID_VIEW": {
      return {
        ...state,
        vendorPortalDataGridView: action.value
      };
    }

    default:
      return state;
  }
};

export type VendorPortalContextState = {
  state: VendorPortalState;
  dispatch: (action: Action) => void;
};
