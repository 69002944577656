import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from './Typography';
import Box from '@mui/material/Box';
import { LoadingButton as Button } from './Button';


interface IConfirmationModalProps {
  headerText: string;
  bodyText: string;
  submitText: string;
  onClose: () => void;
  onSubmit: () => void;
  submitLoading: boolean;
}

export const ConfirmationModal = (
  props: IConfirmationModalProps & DialogProps
): JSX.Element => {
  const { headerText, bodyText, submitText, onClose, onSubmit, submitLoading, ...rest } = props;

  return (
    <Dialog fullWidth disableScrollLock maxWidth="xs" onClose={onClose} {...rest}>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
      >
        <Typography typestyle="xl">{headerText}</Typography>
        <Typography sx={{lineHeight: '1.4rem'}}>{bodyText}</Typography>
        <Box display="flex" justifyContent="end" gap="8px">
          <Button
            disabled={submitLoading}
            variant="outlined"
            buttonborderstyle="pill"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            loading={submitLoading}
            variant="contained"
            buttonborderstyle="pill"
            onClick={() => onSubmit()}
          >
            {submitLoading ? '' : submitText}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
