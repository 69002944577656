import React, { ChangeEvent, useContext } from 'react';
import { Box, MenuItem, IconButton, TextField, Autocomplete } from '@mui/material';
import { NewAutomationContext } from '../../contexts/EditAutomationContextProvider';
import Clear from '@mui/icons-material/Clear';
import { ProcoreToolFilter } from '../../contexts/NewPackageContext';
import { Typography } from '../shared/Typography';
import { ToolAutomation } from '../../contexts/EditAutomationContext';
import { ExtractsCheckbox } from '../../styles';

export interface ConditionRowProps {
  toolAutomation: ToolAutomation;
  selectedFilter: ProcoreToolFilter;
  index: number;
}

const ConditionRow = ({
  selectedFilter,
  toolAutomation,
  index,
}: ConditionRowProps) => {
  const { dispatch } = useContext(NewAutomationContext);

  const selectedFilterOptions = toolAutomation.filters.filter(
    (filter) => filter.key === selectedFilter.key
  )[0].values;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'stretch',
        gap: '16px',
      }}
    >
      <Typography typestyle="m">{index === 0 ? 'When' : 'and'}</Typography>
      <TextField
        select
        size="small"
        value={
          toolAutomation.filters.filter(
            (filter) => filter.key === selectedFilter.key
          )[0]
        }
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            },
            sx: {
              height: '250px',
            },
          },
          onChange: (evt: ChangeEvent<any>) => {
            if (typeof evt.target.value !== 'string') {
              dispatch({
                type: 'UPDATE_SELECTED_FILTER',
                value: {
                  procoreToolEngineName: toolAutomation.procoreToolEngineName,
                  selectedFilterKey: selectedFilter.key,
                  selectedFilter: {
                    ...evt.target.value,
                    values: [],
                  },
                },
              });
            }
          },
        }}
        sx={{ flex: '1 1 0' }}
      >
        {toolAutomation.filters.map((filter, i) => {
          return (
            //@ts-ignore from passing object as value
            <MenuItem
              key={`filters-menu-item-${i}`}
              value={filter}
              disabled={
                toolAutomation.selectedFilters.filter(
                  (selectedFilter) => selectedFilter.key === filter.key
                ).length > 0
              }
            >
              {filter.label}
            </MenuItem>
          );
        })}
      </TextField>
      <Typography typestyle="m">is</Typography>
      <Autocomplete
        disableCloseOnSelect={true}
        clearOnBlur={false}
        multiple={true}
        size="small"
        renderTags={() => {
          return null;
        }}
        value={selectedFilter.values}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id
        }
        onChange={(evt, value, reason) => {
          switch (reason) {
            case 'clear': {
              dispatch({
                type: 'UPDATE_SELECTED_FILTER',
                value: {
                  procoreToolEngineName: toolAutomation.procoreToolEngineName,
                  selectedFilterKey: selectedFilter.key,
                  selectedFilter: {
                    ...selectedFilter,
                    values: [],
                  },
                },
              });
              break;
            }

            case 'removeOption':
            case 'selectOption':
              dispatch({
                type: 'UPDATE_SELECTED_FILTER',
                value: {
                  procoreToolEngineName: toolAutomation.procoreToolEngineName,
                  selectedFilterKey: selectedFilter.key,
                  selectedFilter: {
                    ...selectedFilter,
                    values: value,
                  },
                },
              });
              break;

            default:
              break;
          }
        }}
        renderInput={(params) => {
          const selectValues = (selectedFilter.values || []);

          return <TextField {...params} size='small' label={selectValues.length === 0 ? '' : `${selectedFilter.label} (${selectValues.length})`} />
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <ExtractsCheckbox
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: '1.2rem',
                },
              }}
              checked={selected}
            />
            {option.value}
          </li>
        )}
        getOptionLabel={(option) => {
          return option.value;
        }}
        options={selectedFilterOptions}
        sx={{ flex: '1 1 0', width: '264px' }}
      />
      <IconButton
        size="small"
        onClick={() => {
          dispatch({
            type: 'REMOVE_SELECTED_FILTER',
            value: {
              procoreToolEngineName: toolAutomation.procoreToolEngineName,
              selectedFilterKey: selectedFilter.key,
            },
          });
        }}
      >
        <Clear sx={{ fontSize: '18px' }} />
      </IconButton>
    </Box>
  );
};

export default ConditionRow;
