import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Popper, ButtonBase, styled, ButtonBaseProps } from '@mui/material';
import { RemoveFilterIconButton } from './FilterSingleSelect';
import { Colors } from '../../styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { DateRangePicker } from "../daterange-picker";
import { differenceInDays, formatISO } from "date-fns";

type OtherFilterButtonProps = {
  open: boolean;
};

const FilterButton = styled(ButtonBase)<ButtonBaseProps & OtherFilterButtonProps>(({ open }) => {
  return {
    fontSize: 16,
    textAlign: "left",
    padding: "10px 12px",
    fontWeight: 500,
    borderRadius: 6,
    border: open ? "1px solid #80BDFF" : "1px solid #E4EBF0",
    boxShadow: open ? "0px 0px 0px 3px rgba(0, 123, 255, 0.25)" : '',
    "&:hover": {
      border: "1px solid #D8E0E5",
    },
    "&:focus": {
      borderRadius: 6,
      border: "1px solid #80BDFF",
      boxShadow: "0px 0px 0px 3px rgba(0, 123, 255, 0.25)",
    },
    "& span": {
      marginRight: "6px",
    },
    "& svg": {
      width: 16,
      height: 16,
    }
  };
});

export const FilterDateRangeSelect = (props: {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  onChange: (dates: string[]) => void;
  filterLabel: string;
  initialDateRange: { startDate?: Date; endDate?: Date };
  removeFilter: () => void;
  popperContainer?: Element;
}): JSX.Element => {
  const {
    filterLabel,
    open,
    onOpen,
    onClose,
    initialDateRange,
    onChange,
    removeFilter,
    popperContainer
  } = props;

  const [showRemoveFilterButton, setShowRemoveFilterButton] = useState(false);
  const [childSelectIsOpen, setChildSelectIsOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      setAnchorEl(buttonRef?.current);
    }

    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const handleOnClose = () => {
    if (childSelectIsOpen) { return; }

    if (open) {
      if (anchorEl) {
        anchorEl.focus();
      }
      setAnchorEl(null);

      onClose();
    }
  }

  const id = open ? 'filter-options-popover' : undefined;

  const getFilterLabelWithValue = () => {
    if (initialDateRange.startDate) {
      if (initialDateRange.endDate) {
        const days = differenceInDays(
          initialDateRange.endDate,
          initialDateRange.startDate
        );

        return `${filterLabel} (${days})`;
      } else {
        return `${filterLabel} (1)`;
      }
    } else {
      return `${filterLabel}`;
    }
  }

  return (
    <React.Fragment>
      <Box
        display="block"
        position="relative"
        width={"fit-content"}
        style={{ marginRight: "16px" }}
        onBlur={() => setShowRemoveFilterButton(false)}
        onMouseEnter={() => setShowRemoveFilterButton(true)}
        onMouseLeave={() => setShowRemoveFilterButton(false)}
      >
        <FilterButton
          disableRipple
          open={open}
          aria-describedby={id}
          ref={buttonRef}
          onClick={handleClick}
          >
            <span>{getFilterLabelWithValue()}</span>
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </FilterButton>
          {!open && showRemoveFilterButton && (
            <RemoveFilterIconButton
              style={{
                top: "-8px",
                right: "-10px",
              }}
              onClick={() => removeFilter()}
            >
              <CloseIcon
                style={{ height: "14px", width: "14px", color: Colors.darkGray }}
              />
            </RemoveFilterIconButton>
          )}
      </Box>

      {
        open && (
          <ClickAwayListener onClickAway={handleOnClose}>
            <Popper
              container={popperContainer ? popperContainer : document.body}
              id={id}
              open={open}
              anchorEl={anchorEl}
              placement="bottom-start"
            >
              <Box display={"flex"} sx={{
                boxShadow: '0 3px 12px rgba(27,31,35,.15)',
                borderRadius: '3px',
                zIndex: 1,
                fontSize: 13,
                color: '#586069',
                backgroundColor: Colors.white,
              }}>
                <DateRangePicker
                  open={open}
                  initialDateRange={initialDateRange}
                  onChange={(range) => {
                    const values = [];
                    if (range.startDate) {
                      values.push(formatISO(range.startDate));
                    }

                    if (range.endDate) {
                      values.push(formatISO(range.endDate));
                    }

                    onChange(values);
                  }}
                  onSelectClosed={() => {
                    setChildSelectIsOpen(false)
                  }}
                  onSelectOpen={() => {
                    setChildSelectIsOpen(true)
                  }}
                />
              </Box>
            </Popper>
          </ClickAwayListener>
        )
      }
    </React.Fragment>
  );
}