import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface CloseoutLogDirectoryUpdateData {
  updateCloseoutLogDirectory: {
    success: boolean;
  }
}

export interface CloseoutLogDirectoryUpdateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  vendorProcoreServerIds: string[];
  userProcoreServerIds: string[];
}

export const useUpdateCloseoutLogDirectory = createMutationHook<
CloseoutLogDirectoryUpdateData,
CloseoutLogDirectoryUpdateVariables
>(gql`
  mutation UpdateCloseoutLogDirectory(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $vendorProcoreServerIds: [ID!]!,
    $userProcoreServerIds: [ID!]!
  ) {
    updateCloseoutLogDirectory(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, vendorProcoreServerIds: $vendorProcoreServerIds, userProcoreServerIds: $userProcoreServerIds }
    ) {
      success
    }
  }
`);
