import { Box } from "@mui/material";
import React, { useContext } from "react";
import { NewPackageContext } from "../../contexts/NewPackageContextProvider";
import FilterListIcon from "@mui/icons-material/FilterList";
import ListAltIcon from "@mui/icons-material/ListAlt";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import SubmittalOptions from "./SubmittalOptions";
import Organize from "./Organize";
import Filter from "./Filter";
import { TabContext } from "@mui/lab";
import { ExtractToolContainerTabs, ExtractToolTabPanel, ExtractsToolTab } from "./styles";
import {
  Action,
  ProcoreToolFilter,
  ToolTabData,
} from "../../contexts/NewPackageContext";
import { ApolloClient, useApolloClient } from "@apollo/client";
import {
  AccountProjectContext,
} from "../../contexts/AccountProjectContextProvider";
import {
  filterProcoreSubmittalsQuery,
  ProcoreFilterSubmittalData,
  ProcoreFilterSubmittalVariables,
} from "../../graphql/queries/GetProcoreSubmittals";
import { useGetToolFilters } from "../../graphql/queries/GetProcoreToolFilters";
import {
  PAGINATION,
  procoreFilterToInputFilters,
  procoreToolHasOptionsSupport,
  procoreToolHasOrganizeSupport,
  selectedFiltersWithCreatedFromPackage,
  TOOL_ENGINE_NAMES,
} from "../../utils/utils";
import { useCreatePackageResourceSync } from "../../graphql/mutations/CreatePackageResourceSync";
import { useGetPackageResourceSync } from "../../graphql/queries/GetPackageResourceSync";
import RfiOptions from "./RfiOptions";
import { FilterOptionInput, ProcoreTool } from "../../types";
import {
  filterProcoreRfisQuery,
  ProcoreFilterRfiData,
  ProcoreFilterRfiVariables,
} from "../../graphql/queries/GetProcoreRfis";
import {
  filterProcoreInspectionsQuery,
  ProcoreFilterInspectionData,
  ProcoreFilterInspectionVariables,
} from "../../graphql/queries/GetProcoreInspections";
import {
  filterProcoreObservationsQuery,
  ProcoreFilterObservationData,
  ProcoreFilterObservationVariables,
} from "../../graphql/queries/GetProcoreObservations";
import {
  filterProcorePhotosQuery,
  ProcoreFilterPhotoData,
  ProcoreFilterPhotoVariables,
} from "../../graphql/queries/GetProcorePhotos";
import {
  filterProcorePunchItemsQuery,
  ProcoreFilterPunchItemData,
  ProcoreFilterPunchItemVariables,
} from "../../graphql/queries/GetProcorePunchItems";
import {
  filterProcoreDrawingsQuery,
  ProcoreFilterDrawingData,
  ProcoreFilterDrawingVariables,
} from "../../graphql/queries/GetProcoreDrawings";
import HierarchyFilter from "./HierarchyFilter";
import { filterProcoreActionPlansQuery, ProcoreFilterActionPlanData, ProcoreFilterActionPlanVariables } from "../../graphql/queries/GetProcoreActionPlans";
import { filterProcoreSpecificationsQuery, ProcoreFilterSpecificationData, ProcoreFilterSpecificationVariables } from "../../graphql/queries/GetProcoreSpecifications";
import DrawingOptions from "./DrawingOptions";
import InspectionOptions from "./InspectionOptions";
import ObservationOptions from "./ObservationOptions";
import ActionPlanOptions from "./ActionPlanOptions";
import { filterProcoreIncidentsQuery, ProcoreFilterIncidentData, ProcoreFilterIncidentVariables } from "../../graphql/queries/GetProcoreIncidents";
import { filterProcoreCorrespondenceQuery, ProcoreFilterCorrespondenceData, ProcoreFilterCorrespondenceVariables } from "../../graphql/queries/GetProcoreCorrespondence";
import { filterProcoreFormsQuery, ProcoreFilterProcoreFormData, ProcoreFilterProcoreFormVariables } from "../../graphql/queries/GetProcoreForms";
import { filterProcoreEmailsQuery, ProcoreFilterProcoreEmailData, ProcoreFilterProcoreEmailVariables } from "../../graphql/queries/GetProcoreEmails";
import { filterDocumentFoldersQuery, ProcoreFilterDocumentFolderData, ProcoreFilterDocumentFolderVariables } from "../../graphql/queries/GetDocumentFolders";
import OrganizeInspections from "./OrganizeInspections";
import CorrespondenceOptions from "./CorrespondenceOptions";
import { SelectedItem } from "../../graphql/mutations/UpdatePackage";
import { AccountProjectState } from "../../contexts/AccountProjectContext";
import { ProcoreFilterMeetingData, ProcoreFilterMeetingVariables, filterProcoreMeetingsQuery } from "../../graphql/queries/GetProcoreMeetings";
import { ProcoreFilterDailyLogData, ProcoreFilterDailyLogVariables, filterProcoreDailyLogsQuery } from "../../graphql/queries/GetProcoreDailyLogs";
import DailyLogOptions from "./DailyLogOptions";
import DailyLogFilter from "./DailyLogFilter";
import { filterProcoreChangeEventsQuery, ProcoreFilterChangeEventData, ProcoreFilterChangeEventVariables } from "../../graphql/queries/GetProcoreChangeEvents";
import { filterProcoreBidsQuery, ProcoreFilterBidData, ProcoreFilterBidVariables } from "../../graphql/queries/GetProcoreBids";
import { filterProcoreManagedEquipmentsQuery, ProcoreFilterManagedEquipmentData, ProcoreFilterManagedEquipmentVariables } from "../../graphql/queries/GetProcoreManagedEquipment";
import { filterProcoreProjectDiectoryUsersQuery, ProcoreFilterProjectDirectoryUserData, ProcoreFilterProjectDirectoryUserVariables } from "../../graphql/queries/GetProcoreProjectDirectoryUsers";
import { filterProcoreProjectVendorsQuery, ProcoreFilterProjectVendorData, ProcoreFilterProjectVendorVariables } from "../../graphql/queries/GetProcoreProjectVendors";
import ProjectDirectoryUserOptions from "./ProjectDirectoryUserOptions";
import PunchItemOptions from "./PunchItemOptions";
import ManagedEquipmentOptions from "./ManagedEquipmentOptions";

type TabType = "filter" | "organize" | "options";

const queryProcoreItemForTool = (
  packageId: number,
  toolTabData: ToolTabData,
  toolTabIndex: number,
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>,
  dispatch: (action: Action) => void,
  accountProjectState: AccountProjectState,
  filters: FilterOptionInput[],
  searchValue?: string,
) => {
  if (toolTabData.procoreTool === null) {
    return;
  }

  dispatch({
    type: "SET_TOOL_TAB_LOADING",
    value: {
      toolTabIndex: toolTabIndex,
      loading: true,
    },
  });

  const searchVariableValue = searchValue === undefined ? toolTabData.searchValue : searchValue;

  if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.RFIS) {
    client
      .query<ProcoreFilterRfiData, ProcoreFilterRfiVariables>({
        query: filterProcoreRfisQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.rfis,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.MEETINGS) {
    client
      .query<ProcoreFilterMeetingData, ProcoreFilterMeetingVariables>({
        query: filterProcoreMeetingsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.meetings,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.INSPECTIONS) {
    client
      .query<ProcoreFilterInspectionData, ProcoreFilterInspectionVariables>({
        query: filterProcoreInspectionsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.inspections,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.OBSERVATIONS) {
    client
      .query<ProcoreFilterObservationData, ProcoreFilterObservationVariables>({
        query: filterProcoreObservationsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.observations,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.INCIDENTS) {
    client
      .query<ProcoreFilterIncidentData, ProcoreFilterIncidentVariables>({
        query: filterProcoreIncidentsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.incidents,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.CHANGE_EVENTS) {
    client
      .query<ProcoreFilterChangeEventData, ProcoreFilterChangeEventVariables>({
        query: filterProcoreChangeEventsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.changeEvents,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.BIDDING) {
    client
      .query<ProcoreFilterBidData, ProcoreFilterBidVariables>({
        query: filterProcoreBidsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.bids,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.EQUIPMENT) {
    client
      .query<ProcoreFilterManagedEquipmentData, ProcoreFilterManagedEquipmentVariables>({
        query: filterProcoreManagedEquipmentsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.managedEquipment,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.CORRESPONDENCE) {
    client
      .query<ProcoreFilterCorrespondenceData, ProcoreFilterCorrespondenceVariables>({
        query: filterProcoreCorrespondenceQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.correspondence,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.PHOTOS) {
    client
      .query<ProcoreFilterPhotoData, ProcoreFilterPhotoVariables>({
        query: filterProcorePhotosQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.photos,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds,
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.PUNCH_LIST) {
    client
      .query<ProcoreFilterPunchItemData, ProcoreFilterPunchItemVariables>({
        query: filterProcorePunchItemsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          filters: filters,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.punchItems,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.DRAWINGS) {
    client
      .query<ProcoreFilterDrawingData, ProcoreFilterDrawingVariables>({
        query: filterProcoreDrawingsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.drawings,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });

        dispatch({
          type: 'SET_SUBMITTAL_RESPONSES',
          value: {
            toolTabIndex: toolTabIndex,
            submittalResponses: result.data.submittalResponses
          }
        });

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.DOCUMENTS) {
    client
      .query<ProcoreFilterDocumentFolderData, ProcoreFilterDocumentFolderVariables>({
        query: filterDocumentFoldersQuery,
        variables: {
          accountId: accountProjectState.accountId,
          packageId: packageId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          procoreFolderServerId: null,
          pathIds: [],
          filters: filters,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.documentFolders.concat(result.data.documentFiles),
            filteredProcoreItemServerIds: []
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.ACTION_PLANS) {
    client
      .query<ProcoreFilterActionPlanData, ProcoreFilterActionPlanVariables>({
        query: filterProcoreActionPlansQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          procoreToolId: toolTabData.procoreTool.id,
          searchValue: searchVariableValue,
          filters: filters,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.actionPlans,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds,
          },
        });
        dispatch({
          type: 'SET_ACTION_PLAN_TOOL_TYPES',
          value: {
            toolTabIndex: toolTabIndex,
            actionPlanToolTypes: result.data.actionPlanToolTypes
          }
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.SPECIFICATIONS) {
    client
      .query<ProcoreFilterSpecificationData, ProcoreFilterSpecificationVariables>({
        query: filterProcoreSpecificationsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.specifications,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.FORMS) {
    client
      .query<ProcoreFilterProcoreFormData, ProcoreFilterProcoreFormVariables>({
        query: filterProcoreFormsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
          searchValue: searchVariableValue,
          procoreToolId: toolTabData.procoreTool.id,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.forms,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.EMAILS) {
    client
      .query<ProcoreFilterProcoreEmailData, ProcoreFilterProcoreEmailVariables>({
        query: filterProcoreEmailsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          filters: filters,
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.emails,
            filteredProcoreItemServerIds: []
          },
        });
        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.DIRECTORY) {
    if (toolTabData.procoreTool.directoryType === "vendors") {
      client
        .query<ProcoreFilterProjectVendorData, ProcoreFilterProjectVendorVariables>({
          query: filterProcoreProjectVendorsQuery,
          variables: {
            accountId: accountProjectState.accountId,
            procoreProjectServerId: accountProjectState.procoreProjectServerId,
            procoreToolId: toolTabData.procoreTool.id,
            searchValue: searchVariableValue,
            filters: filters,
            limit: PAGINATION.limit,
            offset: PAGINATION.offset
          },
          fetchPolicy: "cache-first",
        })
        .then((result) => {
          dispatch({
            type: "SET_PROCORE_ITEMS",
            value: {
              toolTabIndex: toolTabIndex,
              procoreItems: result.data.projectVendors,
              filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
            },
          });

          dispatch({
            type: "SET_TOOL_TAB_LOADING",
            value: {
              toolTabIndex: toolTabIndex,
              loading: false,
            },
          });
        })
        .catch((err) => {
          console.log(err);

          dispatch({
            type: "SET_TOOL_TAB_LOADING",
            value: {
              toolTabIndex: toolTabIndex,
              loading: false,
            },
          });
        });
    } else if (toolTabData.procoreTool.directoryType === "users") {
      client
        .query<ProcoreFilterProjectDirectoryUserData, ProcoreFilterProjectDirectoryUserVariables>({
          query: filterProcoreProjectDiectoryUsersQuery,
          variables: {
            accountId: accountProjectState.accountId,
            procoreProjectServerId: accountProjectState.procoreProjectServerId,
            procoreToolId: toolTabData.procoreTool.id,
            searchValue: searchVariableValue,
            filters: filters,
            limit: PAGINATION.limit,
            offset: PAGINATION.offset
          },
          fetchPolicy: "cache-first",
        })
        .then((result) => {
          dispatch({
            type: "SET_PROCORE_ITEMS",
            value: {
              toolTabIndex: toolTabIndex,
              procoreItems: result.data.projectDirectoryUsers,
              filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
            },
          });

          dispatch({
            type: "SET_TOOL_TAB_LOADING",
            value: {
              toolTabIndex: toolTabIndex,
              loading: false,
            },
          });
        })
        .catch((err) => {
          console.log(err);

          dispatch({
            type: "SET_TOOL_TAB_LOADING",
            value: {
              toolTabIndex: toolTabIndex,
              loading: false,
            },
          });
        });
    }
  } else if (toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.DAILY_LOG) {
    client
      .query<ProcoreFilterDailyLogData, ProcoreFilterDailyLogVariables>({
        query: filterProcoreDailyLogsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          procoreToolId: toolTabData.procoreTool.id,
          filters: filters,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.dailyLogs,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  } else {
    client
      .query<ProcoreFilterSubmittalData, ProcoreFilterSubmittalVariables>({
        query: filterProcoreSubmittalsQuery,
        variables: {
          accountId: accountProjectState.accountId,
          procoreProjectServerId: accountProjectState.procoreProjectServerId,
          procoreToolId: toolTabData.procoreTool.id,
          searchValue: searchVariableValue,
          filters: filters,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset
        },
        fetchPolicy: "cache-first",
      })
      .then((result) => {
        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            toolTabIndex: toolTabIndex,
            procoreItems: result.data.submittals,
            filteredProcoreItemServerIds: result.data.toolRecordServerIds.procoreServerIds
          },
        });

        dispatch({
          type: 'SET_SUBMITTAL_RESPONSES',
          value: {
            toolTabIndex: toolTabIndex,
            submittalResponses: result.data.submittalResponses
          }
        });

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_TOOL_TAB_LOADING",
          value: {
            toolTabIndex: toolTabIndex,
            loading: false,
          },
        });
      });
  }
};

const getOrganizeForTool = (
  toolTabData: ToolTabData,
  toolTabIndex: number
): JSX.Element => {
  switch(toolTabData.procoreTool.engineName) {
    case TOOL_ENGINE_NAMES.INSPECTIONS: {
      return <OrganizeInspections toolTabIndex={toolTabIndex} />
    }

    default: {
      return <Organize toolTabIndex={toolTabIndex} />
    }
  }
}

const getOptionsForTool = (
  toolTabData: ToolTabData,
  toolTabIndex: number
): JSX.Element => {
  if (toolTabData.procoreTool === null) {
    return <></>;
  }

  switch (toolTabData.procoreTool.engineName) {
    case TOOL_ENGINE_NAMES.RFIS: {
      return (
        <RfiOptions toolTabData={toolTabData} toolTabIndex={toolTabIndex} />
      );
    }

    case TOOL_ENGINE_NAMES.SUBMITTALS: {
      return (
        <SubmittalOptions
          toolTabData={toolTabData}
          toolTabIndex={toolTabIndex}
        />
      );
    }

    case TOOL_ENGINE_NAMES.INSPECTIONS: {
      return (
        <InspectionOptions
          toolTabData={toolTabData}
          toolTabIndex={toolTabIndex}
        />
      )
    }

    case TOOL_ENGINE_NAMES.OBSERVATIONS: {
      return (
        <ObservationOptions
          toolTabData={toolTabData}
          toolTabIndex={toolTabIndex}
        />
      );
    }

    case TOOL_ENGINE_NAMES.PHOTOS: {
      return (
        <div style={{ marginLeft: "16px" }}>
          <p style={{ fontSize: "18px", fontWeight: 400 }}>
            No options available for Photos.
          </p>
        </div>
      );
    }

    case TOOL_ENGINE_NAMES.DRAWINGS: {
      return (
        <DrawingOptions
          toolTabData={toolTabData}
          toolTabIndex={toolTabIndex}
        />
      )
    }

    case TOOL_ENGINE_NAMES.PUNCH_LIST: {
      return <PunchItemOptions
        toolTabData={toolTabData}
        toolTabIndex={toolTabIndex}
      />
    }

    case TOOL_ENGINE_NAMES.EQUIPMENT: {
      return <ManagedEquipmentOptions
        toolTabData={toolTabData}
        toolTabIndex={toolTabIndex}
      />
    }

    case TOOL_ENGINE_NAMES.ACTION_PLANS: {
      return (
        <ActionPlanOptions
          toolTabData={toolTabData}
          toolTabIndex={toolTabIndex}
        />
      )
    }

    case TOOL_ENGINE_NAMES.CORRESPONDENCE: {
      return (
        <CorrespondenceOptions toolTabData={toolTabData} toolTabIndex={toolTabIndex} />
      );
    }

    case TOOL_ENGINE_NAMES.DAILY_LOG: {
      return (
        <DailyLogOptions toolTabData={toolTabData} toolTabIndex={toolTabIndex} />
      )
    }

    case TOOL_ENGINE_NAMES.DIRECTORY: {
      if (toolTabData.procoreTool.directoryType === "vendors") {
        return <></>;
      } else if (toolTabData.procoreTool.directoryType === "users") {
        return <ProjectDirectoryUserOptions toolTabData={toolTabData} toolTabIndex={toolTabIndex} />
      } else {
        return <></>;
      }
    }

    default: {
      return <></>;
    }
  }
};

const extractTabOptions = (procoreTool: ProcoreTool) => {
  if (procoreTool === null) {
    return [
      {
        label: "Filter",
        value: "filter",
        icon: FilterListIcon,
      },
      {
        label: "Organize",
        value: "organize",
        icon: ListAltIcon,
      },
      {
        label: "Options",
        value: "options",
        icon: TuneOutlinedIcon,
      },
    ];
  } else {
    return [
      {
        label: "Filter",
        value: "filter",
        icon: FilterListIcon,
      },
      procoreToolHasOrganizeSupport(procoreTool) && {
        label: "Organize",
        value: "organize",
        icon: ListAltIcon,
      },
      procoreToolHasOptionsSupport(procoreTool) && {
        label: "Options",
        value: "options",
        icon: TuneOutlinedIcon,
      },
    ].filter(Boolean);
  }
};

const ExtractToolContainer = (props: {
  toolTabIndex: number;
  toolTabData: ToolTabData;
}): JSX.Element => {
  const { toolTabIndex, toolTabData } = props;
  const { state, dispatch } = useContext(NewPackageContext);
  const { state: accountProjState } = React.useContext(AccountProjectContext);
  const [selectedTab, setSelectedTab] = React.useState<TabType>("filter");
  const client = useApolloClient();

  const [getPackageResourceSyncData, setGetPackageResourceSyncData] =
    React.useState<{ id: number; skip: boolean }>({ id: 0, skip: true });

  const handleOnSelectedTabChanged = (newValue: string) => {
    setSelectedTab(newValue as TabType);
  };

  const [loadProcoreToolFilters, { loading, data }] = useGetToolFilters({
    accountId: accountProjState.accountId,
    procoreProjectServerId: accountProjState.procoreProjectServerId,
    procoreToolId: toolTabData.procoreTool?.id,
    directoryType: toolTabData.procoreTool?.directoryType,
  });

  const [
    createPackageResourceSync,
    {
      loading: createdPackageResourceSyncLoading,
      data: createPackageResourceSyncData,
    },
  ] = useCreatePackageResourceSync({
    packageId: state.packageId,
    accountId: accountProjState.accountId,
    resourceType: toolTabData.procoreTool?.engineName,
  });

  const {
    loading: packageResourceSyncLoading,
    data: packageResourceSyncData,
    startPolling,
    stopPolling,
  } = useGetPackageResourceSync(
    {
      accountId: accountProjState.accountId,
      id: getPackageResourceSyncData.id,
    },
    getPackageResourceSyncData.skip
  );

  // ProcoreTool is selected this kicks off a request to fetch
  // tool data asynchronously and poll until data has synced.
  React.useEffect(() => {
    if (
      toolTabData.procoreTool !== null &&
      toolTabData.packageResourceSync?.status !== "complete"
    ) {
      dispatch({
        type: "SET_TOOL_TAB_LOADING",
        value: {
          toolTabIndex: toolTabIndex,
          loading: true,
        },
      });
      createPackageResourceSync();
    } else if (
      toolTabData.procoreTool === null ||
      toolTabData.procoreTool === undefined
    ) {
      setSelectedTab("filter");
    }
  }, [toolTabData.procoreTool]);

  // Upon kicking off a request to fetch tool data asynchronously this will
  // record the state and trigger polling every X seconds.
  React.useEffect(() => {
    if (!createPackageResourceSyncData || createdPackageResourceSyncLoading) {
      return;
    }

    const packageResourceSync =
      createPackageResourceSyncData.createPackageResourceSync
        .packageResourceSync;

    dispatch({
      type: "SET_PACKAGE_RESOURCE_SYNC",
      value: {
        toolTabIndex: toolTabIndex,
        packageResourceSync: packageResourceSync,
      },
    });

    setGetPackageResourceSyncData({
      id: packageResourceSync.id,
      skip: false,
    });

    if (packageResourceSync.status !== "complete") {
      startPolling(5000);
    }
  }, [createPackageResourceSyncData]);

  React.useEffect(() => {
    if (!packageResourceSyncData || packageResourceSyncLoading) {
      return;
    }

    dispatch({
      type: "SET_PACKAGE_RESOURCE_SYNC",
      value: {
        toolTabIndex: toolTabIndex,
        packageResourceSync: packageResourceSyncData.packageResourceSync,
      },
    });

    if (packageResourceSyncData.packageResourceSync.status === "complete") {
      stopPolling();
      loadProcoreToolFilters();
    }
  }, [packageResourceSyncData]);

  React.useEffect(() => {
    if (!data || loading) {
      return;
    }

    dispatch({
      type: "SET_FILTERS",
      value: { toolTabIndex, filters: data.filters },
    });

    const defaultFilters = selectedFiltersWithCreatedFromPackage(
      state,
      toolTabData,
      data.filters,
      data.defaultFilters
    );

    dispatch({
      type: "SET_SELECTED_FILTERS",
      value: { toolTabIndex, selectedFilters: defaultFilters },
    });

    dispatch({
      type: "SET_GROUPINGS",
      value: { toolTabIndex, groupings: data.groupings },
    });

    onFilterOptionSelectionChange({ selectedFilters: defaultFilters });
  }, [data]);

  const onFilterOptionSelectionChange = ({
    selectedFilters = undefined,
  }: {
    selectedFilters?: ProcoreToolFilter[];
  }) => {
    queryProcoreItemForTool(
      state.packageId,
      toolTabData,
      toolTabIndex,
      client,
      dispatch,
      accountProjState,
      procoreFilterToInputFilters(
        selectedFilters !== undefined
          ? selectedFilters
          : toolTabData.selectedFilters
      )
    );
  };

  const disabledUI = toolTabData.procoreTool === null;

  return (
    <Box
      display="flex"
      flexDirection="row"
      style={{ background: "white" }}
      flexGrow={1}
      height={1}
      width={1}
      overflow="hidden"
    >
      <TabContext value={selectedTab}>
        <ExtractToolContainerTabs
          key={"tabs-extracts"}
          value={selectedTab}
          orientation="vertical"
          onChange={(_evt, value) => handleOnSelectedTabChanged(value)}
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          {extractTabOptions(toolTabData.procoreTool).map((tabOption) => {
            return (
              <ExtractsToolTab
                aria-label={tabOption.value}
                label={tabOption.label}
                key={`tabs-${tabOption.value}`}
                value={tabOption.value}
                disabled={disabledUI}
                icon={<tabOption.icon />}
              />
            );
          })}
        </ExtractToolContainerTabs>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          paddingTop: '16px',
          paddingRight: '16px',
          paddingBottom: '16px',
        }}>
          <ExtractToolTabPanel
            key={"tab-panel-filter"}
            value={"filter"}
            style={{ padding: 0 }}
            enabled={(selectedTab === "filter").toString()}
          >
            {(toolTabData.procoreTool === null ||
              (toolTabData.procoreTool && toolTabData.procoreTool.engineName !== TOOL_ENGINE_NAMES.DOCUMENTS && toolTabData.procoreTool.engineName !== TOOL_ENGINE_NAMES.DAILY_LOG)) && (
              <Filter
                disabled={disabledUI}
                toolTabData={toolTabData}
                toolTabIndex={toolTabIndex}
                onSearchValueChanged={(value) => {
                  dispatch({
                    type: 'SET_SEARCH_VALUE',
                    value: {
                      toolTabIndex: toolTabIndex,
                      value: value
                    }
                  })
                }}
                onSearchValueEntered={(searchValue) => {
                  queryProcoreItemForTool(
                    state.packageId,
                    toolTabData,
                    toolTabIndex,
                    client,
                    dispatch,
                    accountProjState,
                    procoreFilterToInputFilters(toolTabData.selectedFilters),
                    searchValue
                  );
                }}
                onFilterOptionSelectionChange={onFilterOptionSelectionChange}
                dispatchSelectedItems={(selectedItems: Record<string, SelectedItem>) => {
                  dispatch({
                    type: 'SET_SELECTED_ITEMS',
                    value: {
                      toolTabIndex: toolTabIndex,
                      selectedItems: selectedItems,
                    }
                  });
                }}
              />
            )}

            {(toolTabData.procoreTool && toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.DAILY_LOG) && (
              <DailyLogFilter
                disabled={disabledUI}
                toolTabData={toolTabData}
                toolTabIndex={toolTabIndex}
                onFilterOptionSelectionChange={onFilterOptionSelectionChange}
                dispatchSelectedItems={(selectedItems: Record<string, SelectedItem>) => {
                  dispatch({
                    type: 'SET_SELECTED_ITEMS',
                    value: {
                      toolTabIndex: toolTabIndex,
                      selectedItems: selectedItems,
                    }
                  });
                }}
              />
            )}

            {(toolTabData.procoreTool && toolTabData.procoreTool.engineName === TOOL_ENGINE_NAMES.DOCUMENTS) && (
              <HierarchyFilter toolTabIndex={toolTabIndex} />
            )}
          </ExtractToolTabPanel>
          {procoreToolHasOrganizeSupport(toolTabData.procoreTool) && (
            <ExtractToolTabPanel
              key={"tab-panel-organize"}
              value={"organize"}
              style={{ padding: 0 }}
              enabled={(selectedTab === "organize").toString()}
            >
              { getOrganizeForTool(toolTabData, toolTabIndex) }
            </ExtractToolTabPanel>
          )}
          {procoreToolHasOptionsSupport(toolTabData.procoreTool) && (
            <ExtractToolTabPanel
              key={"tab-panel-options"}
              value={"options"}
              style={{ padding: 0 }}
              enabled={(selectedTab === "options").toString()}
            >
              <Box
                style={{
                  border: "solid 1px #E4EBF0",
                  borderRadius: "6px",
                }}
                display="flex"
                flexDirection="column"
                height={1}
                width={1}
              >
                {getOptionsForTool(toolTabData, toolTabIndex)}
              </Box>
            </ExtractToolTabPanel>
          )}
        </Box>
      </TabContext>
    </Box>
  );
};

export default ExtractToolContainer;
