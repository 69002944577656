import { Box, Typography } from "@mui/material";
import React from "react";
import { Colors } from "../../styles";

interface FreeTrialBannerProps {
  daysRemaining: number;
}

const FreeTrialBanner = ({
  daysRemaining,
}: FreeTrialBannerProps): JSX.Element => {
  return (
    <Box
      display="flex"
      height="32px"
      padding="9px 20px"
      alignItems="center"
      style={{
        background: "#F5E538",
      }}
    >
      <Typography
        style={{ color: Colors.darkerGray, fontSize: 12, fontWeight: "bold" }}
      >{`This project’s free trial ends in ${daysRemaining} days!`}</Typography>
    </Box>
  );
};

export default FreeTrialBanner;
