import { gql } from "@apollo/client";
import { FilterOptionInput } from "../../types";
import { ProcoreCorrespondence } from "../../types/correspondence";

export type ProcoreFilterCorrespondenceData = {
  correspondence: ProcoreCorrespondence[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreCorrespondenceVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterCorrespondenceVariables = ProcoreCorrespondenceVariables & {
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const CorrespondenceFragment = gql`
fragment CorrespondenceFragment on ProcoreCorrespondence {
  id
  procoreServerId
  formattedTitle
  locationName
  status
  tradeName
  genericToolItemType
}
`;

export const filterProcoreCorrespondenceQuery = gql`
query FilterProcoreCorrespondence($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  correspondence(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...CorrespondenceFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${CorrespondenceFragment}
`;

export const fetchPaginatedProcoreCorrespondenceQuery = gql`
query FilterPaginatedProcoreCorrespondence($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  correspondence(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...CorrespondenceFragment
  }
}
${CorrespondenceFragment}
`;