import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface LogCustomFieldDeleteData {
  deleteLogCustomField: {
    success: boolean;
  }
}

export interface LogCustomFieldDeleteVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  id: number;
}

export const useDeleteLogCustomField = createMutationHook<
LogCustomFieldDeleteData,
  LogCustomFieldDeleteVariables
>(gql`
  mutation DeleteLogCustomField(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $id: ID!
  ) {
    deleteLogCustomField(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, id: $id }
    ) {
      success
    }
  }
`);
