import { Box } from '@mui/material';
import React from 'react';
import { Colors } from '../../styles';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Button } from './Button';

const DmsaRequiredBanner = (props: { projectCount: number, onAllowAccess: () => void }): JSX.Element => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      style={{
        background: 'white',
        borderRadius: "8px",
        marginBottom: "16px",
        boxShadow: '0px 2px 4px 1px rgba(26, 32, 36, 0.04), 0px 1px 10px 0px rgba(26, 32, 36, 0.06)',
      }}
    >
      <div style={{
        height: '6px',
        width: '100%',
        background: Colors.yellow,
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px'
      }}></div>
      <Box
        display={"flex"}
        flexDirection={"row"}
        style={{
          padding: "10px 10px 10px 16px"
        }}
        alignItems={"center"}>
        <WarningAmberOutlinedIcon />
        <Box
          display={"flex"}
          flex={1}
          flexDirection={"column"}
          alignItems={"flex-start"}
          marginLeft={"10px"}
        >
          <p
            style={{
              padding: 0,
              margin: 0,
              color: Colors.darkerGray,
              fontWeight: 700,
              fontSize: "13px",
            }}>{'Project data may not be synced'}</p>
            <p
              style={{
                padding: 0,
                margin: 0,
                color: Colors.darkerGray,
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              {`${props.projectCount} project(s) listed below are using Closeout but do not have DMSA permissions configured.`}
            </p>
        </Box>
        <Button onClick={props.onAllowAccess} variant='outlined' buttonborderstyle='rounded'>
          {'Allow Access'}
        </Button>
      </Box>
    </Box>
  )
};

export default DmsaRequiredBanner;