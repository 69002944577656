import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface RefreshDmsaProjectVendorsData {
  refreshDmsaProjectVendors: {
    success: boolean;
  }
}

export interface RefreshProjectVendorsDataVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
}

export const useRefreshDmsaProjectVendors = createMutationHook<RefreshDmsaProjectVendorsData, RefreshProjectVendorsDataVariables>(gql`
mutation RefreshDmsaProjectVendors($accountId: ID!, $procoreProjectServerId: ID!, $closeoutLogId: ID!) {
  refreshDmsaProjectVendors(input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId}) {
    success
  }
}
`);