import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import { Typography } from './shared/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';
import { RequirementDescriptionImportOptions } from '../types/submittal';
import ProcoreIcon from './shared/ProcoreIcon';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Colors } from '../styles';
import { styled } from '@mui/material/styles';
import { MenuItem, TextField } from '@mui/material';
import { useUpdateCloseoutLogExportOption } from '../graphql/mutations/closeout/UpdateCloseoutLogExportOption';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';

interface ISubmittalImportFieldsModal {
  onClose: () => void;
}

const Row = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
}));

const Arrow = styled(ArrowForward)(() => ({
  fontSize: '16px',
  color: Colors.mediumDarkGray,
}));

export const SubmittalImportedFieldsModal = (
  props: ISubmittalImportFieldsModal & DialogProps
): JSX.Element => {
  const { onClose, ...rest } = props;
  const { state, dispatch } = React.useContext(CloseoutLogContext);
  const { state: accountProjectState } = React.useContext(AccountProjectContext);
  const [descriptionValue, setDescriptionValue] =
    React.useState<RequirementDescriptionImportOptions>(state.extractOptions.import_submittal_description_from);

  React.useEffect(() => {
    if (state.extractOptions.import_submittal_description_from) {
      setDescriptionValue(state.extractOptions.import_submittal_description_from);
    }
  }, [state.extractOptions.import_submittal_description_from]);

  const [updateCloseoutLogExportOption] = useUpdateCloseoutLogExportOption({
    accountId: accountProjectState.accountId,
    procoreProjectServerId: accountProjectState.procoreProjectServerId,
    closeoutLogId: state.id,
    attributes: {
      includeTableOfContents: state.extractOptions.include_table_of_contents,
      includeVendorList: state.extractOptions.include_vendor_list,
      groupings: state.extractOptions.selectedGroupings.map((grouping) => grouping.attribute),
      importSubmittalDescriptionFrom: state.extractOptions.import_submittal_description_from,
      toolExtractOptions: state.extractOptions.toolExtractOptions,
    }
  });

  const handleClose = () => {
    updateCloseoutLogExportOption({
      variables: {
        accountId: accountProjectState.accountId,
        procoreProjectServerId: accountProjectState.procoreProjectServerId,
        closeoutLogId: state.id,
        attributes: {
          includeTableOfContents: state.extractOptions.include_table_of_contents,
          includeVendorList: state.extractOptions.include_vendor_list,
          groupings: state.extractOptions.selectedGroupings.map((grouping) => grouping.attribute),
          importSubmittalDescriptionFrom: descriptionValue,
          toolExtractOptions: state.extractOptions.toolExtractOptions
        }
      }
    }).then(()=>{
      dispatch({
        type: 'SET_EXTRACT_OPTIONS',
        value: {
          ...state.extractOptions,
          import_submittal_description_from: descriptionValue,
        },
      });
      onClose();
    }).catch((err)=>{
      console.error(err);
      onClose();
    })
  };

  return (
    <Dialog
      fullWidth
      disableScrollLock
      maxWidth="xs"
      onClose={handleClose}
      {...rest}
    >
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px', height: '400px' }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography typestyle="xl">Imported Fields</Typography>
          <IconButton onClick={handleClose}>
            <Close sx={{ fontSize: '18px' }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            alignSelf: 'stretch',
          }}
        >
          <Row
            sx={{
              paddingY: '12px',
              borderBottom: `1px solid ${Colors.mediumLightGray}`,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                width: '50%',
              }}
            >
              <Typography typestyle="s bold">Submittal</Typography>
              <ProcoreIcon sx={{ fontSize: '12px' }} />
            </Box>
            <Arrow />
            <Typography typestyle="s bold" sx={{ width: '50%' }}>
              Closeout Requirement
            </Typography>
          </Row>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <Row>
              <Typography sx={{ width: '50%' }}>Type</Typography>
              <Arrow />
              <Typography sx={{ width: '50%' }}>Type</Typography>
            </Row>
            <Row>
              <Typography sx={{ width: '50%' }}>
                Responsible Contractor
              </Typography>
              <Arrow />
              <Typography sx={{ width: '50%' }}>
                Responsible Contractor
              </Typography>
            </Row>
            <Row>
              <Typography sx={{ width: '50%' }}>Status</Typography>
              <Arrow />
              <Typography sx={{ width: '50%' }}>Status</Typography>
            </Row>
            <Row>
              <TextField
                select
                size="small"
                sx={{
                    width: '50%',
                    fontSize: '0.8125rem'    ,
                }}
                value={descriptionValue}
                onChange={(evt) => {
                  const newValue = evt.target.value as RequirementDescriptionImportOptions;
                  setDescriptionValue(newValue);
                }}
              >
                <MenuItem value="description">Description</MenuItem>
                <MenuItem value="title">Title</MenuItem>
              </TextField>
              <Arrow />
              <Typography sx={{ width: '50%' }}>Description</Typography>
            </Row>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
