import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import { Account } from "../../types";

const messageBasedOnAccount = (account: Account): string => {
  switch(account.customerType) {
    case "prospect": {
      if (account.freeTrialAvailable) {
        return `No licensed projects. Click “Start Free Trial” to get started.`
      } else {
        return "Your account no longer has a free trail available."
      }
    }

    case "pilot":
    case "customer":
    default:
      return "No licensed projects. Click “Add Project” to get started.";
  }
}

const LicensedProjectsEmptyState = (props: { account: Account }): JSX.Element => {
  return (
    <Box
      display="flex"
      height={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: '6px',
        backgroundColor: 'white',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px'
      }}
    >
      <img src="/images/empty-license-projects.svg" width="285" height="134px" />
      <Typography variant="subtitle1" style={{ color: "#596A75", fontSize: '13px' }}>
        {messageBasedOnAccount(props.account)}
      </Typography>
    </Box>
  );
};

export default LicensedProjectsEmptyState;
