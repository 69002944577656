import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface TemplateLogRequirementTypeDeleteData {
  deleteTemplateLogRequirementType: {
    success: boolean;
  }
}

export interface TemplateLogRequirementTypeDeleteVariables {
  accountId: string;
  templateCloseoutLogId: number;
  id: number;
}

export const useDeleteTemplateLogRequirementType = createMutationHook<
  TemplateLogRequirementTypeDeleteData,
  TemplateLogRequirementTypeDeleteVariables
>(gql`
  mutation DeleteTemplateLogRequirementType(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $id: ID!
  ) {
    deleteTemplateLogRequirementType(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, id: $id }
    ) {
      success
    }
  }
`);
