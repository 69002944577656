import { gql } from "@apollo/client";
import { createQueryHook } from "../../GraphqlHelpers";
import { ExtractAutomationCloudStorageSystem, ExtractAutomationTool, ExtractToolAutomation, ProcoreToolGroupings } from "../../../types";

export type ExtractAutomationData = {
  extractAutomationCloudStorageSystems: ExtractAutomationCloudStorageSystem[];
  extractAutomationToolTypes: ExtractAutomationTool[];
  extractToolAutomations: ExtractToolAutomation[];
  extractAutomationToolGroupings: ProcoreToolGroupings[];
  // TODO: Add destinations to this query and response
};

export type ExtractAutomationDataVariables = {
  accountId: string;
  extractAutomationId: number;
  procoreToolEngineName: string;
};

export const GetExtractAutomationDataQuery = gql`
  query GetExtractAutomationData(
    $accountId: ID!,
    $extractAutomationId: ID,
    $procoreToolEngineName: ID!
  ) {
    extractAutomationCloudStorageSystems(accountId: $accountId) {
      id
      label
      documentStorageSystem
    }

    extractAutomationToolTypes(accountId: $accountId) {
      id
      procoreToolEngineName
      title
    }

    extractAutomationToolGroupings(accountId: $accountId, procoreToolEngineName: $procoreToolEngineName) {
      id
      label
      attribute
      attributeSource
      defaultValue
      groupingLabel
      operator
      conditional
    }

    extractToolAutomations(accountId: $accountId, extractAutomationId: $extractAutomationId) {
      id
      procoreToolEngineName
      title
      conditions
      documentStorageSystem
      cloudStorageSystem {
        id
        label
        documentStorageSystem
      }
      destination
      groupings
      exportOptions
    }
  }
`;

export const useGetExtractAutomationData = createQueryHook<
ExtractAutomationData,
  ExtractAutomationDataVariables
>(GetExtractAutomationDataQuery);
