import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { TemplateLogRequirementType } from "../../../types";

export interface TemplateLogRequirementTypeUpdate {
  templateLogRequirementType?: TemplateLogRequirementType;
  success: boolean;
}

export interface TemplateLogRequirementTypeUpdateData {
  updateTemplateLogRequirementType: TemplateLogRequirementTypeUpdate | null;
}

export interface TemplateLogRequirementTypeUpdateVariables {
  accountId: string;
  templateCloseoutLogId: number;
  id: number;
  name: string;
}

export const useUpdateTemplateLogRequirementType = createMutationHook<
  TemplateLogRequirementTypeUpdateData,
  TemplateLogRequirementTypeUpdateVariables
>(gql`
  mutation UpdateTemplateLogRequirementType(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $id: ID!,
    $name: String!
  ) {
    updateTemplateLogRequirementType(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, id: $id, name: $name }
    ) {
      templateLogRequirementType {
        id
        name
      }
      success
    }
  }
`);
