import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface BuyProjectLicensesData {
  buyProjectLicenses: {
    success: boolean;
    checkoutUrl?: string;
  }
}

export interface BuyProjectLicenseAttributes {
  procoreProjectServerId: number;
  numberOfMonths: number;
}

export interface BuyProjectLicensesVariables {
  accountId: string;
  projectLicenses: BuyProjectLicenseAttributes[];
  redirectUrl?: string;
}

export const useBuyProjectLicenses = createMutationHook<BuyProjectLicensesData, BuyProjectLicensesVariables>(gql`
mutation BuyProjectLicenses($accountId: ID!, $projectLicenses: [BuyProjectLicenseAttributes!]!, $redirectUrl: String) {
  buyProjectLicenses(input: { accountId: $accountId, projectLicenses: $projectLicenses, redirectUrl: $redirectUrl }) {
    success
    checkoutUrl
  }
}
`);