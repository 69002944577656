import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface LogRequirementItemDeleteData {
  deleteLogRequirementItems: {
    success: boolean;
  }
}

export interface LogRequirementItemDeleteVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logRequirementId: number;
  ids: number[];
}

export const useDeleteLogRequirementItems = createMutationHook<
  LogRequirementItemDeleteData,
  LogRequirementItemDeleteVariables
>(gql`
  mutation DeleteLogRequirementItems(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $logRequirementId: ID!,
    $ids: [ID!]!
  ) {
    deleteLogRequirementItems(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, logRequirementId: $logRequirementId, ids: $ids }
    ) {
      success
    }
  }
`);
