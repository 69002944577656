import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface ManageProjectLicenseData {
  manageProjectLicense: {
    success: boolean;
    managePaymentUrl?: string;
  }
}

export interface ManageProjectLicenseVariables {
  accountId: string;
  procoreProjectServerId: number;
  redirectUrl?: string;
}

export const useManageProjectLicense = createMutationHook<ManageProjectLicenseData, ManageProjectLicenseVariables>(gql`
mutation ManageProjectLicense($accountId: ID!, $procoreProjectServerId: ID!, $redirectUrl: String) {
  manageProjectLicense(input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, redirectUrl: $redirectUrl }) {
    success
    managePaymentUrl
  }
}
`);