import React from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { Box, CircularProgress, Dialog, DialogContent, DialogProps, DialogTitle, Divider, ListItem, ListItemText, ListItemTextProps, Typography, styled } from '@mui/material';
import { useGetRecordAttachments } from '../../graphql/queries/GetRecordAttachments';
import { ToolTabData } from '../../contexts/NewPackageContext';
import { AccountProjectContext } from '../../contexts/AccountProjectContextProvider';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import idx from 'idx';
import { Colors } from '../../styles';
import { CheckedCheckBox, ManualSelectCheckbox, UncheckedCheckBox, IndeterminateCheckBox } from './styles';
import { CheckState } from '../../utils/multi-tier-grouping';
import { calculateParentCheckedState, checkedItems, sortByFormattedTitle } from '../../utils/utils';
import { RecordAttachment } from '../../types/record_attachment';
import { Button } from '../shared/Button';

export const FeedbackDialog = styled(Dialog)<DialogProps>(({ theme }) => {
  return {
    '& .MuiDialog-paper': {
      width: '705px',
      height: '575px',
    }
  }
});

export type Node = {
  id: string;
  label: string;
  type: 'branch' | 'leaf';
  level: number;
  hasChildren: boolean;
};

const CustomListItemText = styled(ListItemText)<ListItemTextProps>(() => {
  return {
    color: Colors.darkGray,
    fontSize: "1.125em",
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
});

const ManualAttachmentSelectionModal = (props: {
  toolTabIndex: number,
  toolTabData: ToolTabData,
  handleOnClose: () => void,
  open: boolean }): JSX.Element => {
  const [openCollapse, setOpenCollapse] = React.useState<
    Record<string, boolean>
  >({});
  const [isAttachmentChecked, setIsAttachmentChecked] = React.useState<
    Record<string, CheckState>
  >({});
  const [idAndChildrenIds, setIdAndChildrenIds] = React.useState<
    Record<string, string[]>
  >({});
  const [idAndParentId, setIdAndParentId] = React.useState<
    Record<string, string | undefined>
  >({});

  const { toolTabIndex, toolTabData, handleOnClose, open } = props;

  const { dispatch } = React.useContext(NewPackageContext);
  const { state } = React.useContext(AccountProjectContext);

  const { loading, data } = useGetRecordAttachments(
    state.accountId,
    state.procoreProjectServerId,
    toolTabData.procoreTool.engineName,
    checkedItems(toolTabData.selectedItems).map((item) => item.itemId)
  );

  const recordAttachments: RecordAttachment[] = idx(data, data => [...data.recordAttachments]) || [];

  React.useEffect(() => {
    if (
      loading === undefined ||
      loading === true ||
      !data
    ) {
      return;
    }

    if (data.recordAttachments) {
      const _idAndParentIds = {};
      const _idAndChildrenIds = {};

      const selectedAttachmentOptions: Record<any, any> = toolTabData.extractOptions['selected_attachment_options'] || {};

      data.recordAttachments.forEach(recordAttachment => {
        _idAndParentIds[recordAttachment.procoreServerId] = undefined;

        const recordAttachmentOptions: Record<any, any> = selectedAttachmentOptions[recordAttachment.procoreServerId] || {};

        const recordChildrenIds = [];
        const groupedCheckedStates: CheckState[] = [];
        recordAttachment.attachmentGroupings.forEach(attachmentGrouping => {
          const attachmentGroupingOptions: Record<any, any>[] = recordAttachmentOptions[attachmentGrouping.id] || [];

          recordChildrenIds.push(attachmentGrouping.id);

          _idAndParentIds[attachmentGrouping.id] = recordAttachment.procoreServerId;

          const groupingChildrenIds = [];
          const attachmentCheckedStates: CheckState[] = [];
          attachmentGrouping.attachments.forEach(attachment => {
            const attachmentOptionState: Record<any, any> = attachmentGroupingOptions.find(v => v['id'] === attachment.id) || {};
            const includeState = attachmentOptionState['include'];
            const checkedState = (includeState === undefined ? true : includeState) ? 'checked' : 'unchecked';
            attachmentCheckedStates.push(checkedState);
            isAttachmentChecked[attachment.id] = checkedState;

            groupingChildrenIds.push(attachment.id);
            _idAndParentIds[attachment.id] = attachmentGrouping.id;
          });

          const groupingCheckState = calculateParentCheckedState(attachmentCheckedStates);
          groupedCheckedStates.push(groupingCheckState);
          isAttachmentChecked[attachmentGrouping.id] = groupingCheckState;
          _idAndChildrenIds[attachmentGrouping.id] = groupingChildrenIds;
        });

        _idAndChildrenIds[recordAttachment.procoreServerId] = recordChildrenIds;

        isAttachmentChecked[recordAttachment.procoreServerId] = calculateParentCheckedState(groupedCheckedStates);
      });

      setIdAndChildrenIds(_idAndChildrenIds);
      setIdAndParentId(_idAndParentIds);
      setIsAttachmentChecked({ ...isAttachmentChecked });
    }
  }, [loading]);

  const onClose = () => {
    const result = recordAttachments.reduce((acc, recordAttachment) => {
      const _recordAttachments = {}
      recordAttachment.attachmentGroupings.map(attachmentGrouping => {
        const attachments = attachmentGrouping.attachments.map(attachment => {
          return {
            id: attachment.id,
            filename: attachment.filename,
            key: attachment.key,
            include: (isAttachmentChecked[attachment.id] || 'unchecked') === 'checked'
          }
        })

        _recordAttachments[attachmentGrouping.id] = attachments;
      })
      acc[recordAttachment.procoreServerId] = _recordAttachments;

      return acc;
    }, {});

    dispatch({
      type: 'SET_TOOL_OPTIONS',
      value: {
        toolTabIndex: toolTabIndex,
        option: {
          ...toolTabData.extractOptions,
          selected_attachment_options: result
        }
      }
    });

    handleOnClose();
  }

  const propagateDown = (nodeId: string, isAttachmentChecked: Record<string, CheckState>, state: CheckState): void => {
    const childrenIds = idAndChildrenIds[nodeId] || [];

    childrenIds.forEach(childId => {
      isAttachmentChecked[childId] = state;

      propagateDown(childId, isAttachmentChecked, state);
    });
  }

  const propagateUp = (nodeId: string, isAttachmentChecked: Record<string, CheckState>): void => {
    const parentId = idAndParentId[nodeId];

    if (parentId) {
      const childrenIds = idAndChildrenIds[parentId] || [];

      const childrenState = childrenIds.map(id => isAttachmentChecked[id] || 'unchecked');
      isAttachmentChecked[parentId] = calculateParentCheckedState(childrenState);

      propagateUp(parentId, isAttachmentChecked);
    }
  }

  const handleOnNodeSelect = (node: Node): void => {
    const checked = isAttachmentChecked[node.id] || "unchecked";

    const getNewState = () => {
      switch (checked) {
        case "checked":
        case "indeterminate":
          return "unchecked";

        case "unchecked":
          return "checked";
      }
    };

    const newState = getNewState();

    isAttachmentChecked[node.id] = newState;

    if (node.type === 'leaf') {
      propagateUp(node.id, isAttachmentChecked);
    } else {
      propagateDown(node.id, isAttachmentChecked, newState);
      propagateUp(node.id, isAttachmentChecked);
    }

    setIsAttachmentChecked({ ...isAttachmentChecked });
  }

  const handleCategoryClick = (key: string) => {
    const open = openCollapse[key] || false;

    setOpenCollapse({
      ...openCollapse,
      [key]: !open,
    });
  };

  function renderRow(props: ListChildComponentProps<Node[]>) {
    const { index, style, data } = props;

    const node = data[index] as Node;

    switch (node.type) {
      case 'branch':
        return (
          <div style={style}>
            {/* TODO: Replace with ListeItemButton */}
            <ListItem
              style={{ paddingLeft: `${node.level * 16}px` }}
              key={`grouping-category-${node.id}`}
              button={node.hasChildren}
              onClick={() => handleCategoryClick(node.id)}
            >
              <ManualSelectCheckbox
                disableFocusRipple
                disableRipple
                disableTouchRipple
                color="primary"
                indeterminateIcon={<IndeterminateCheckBox />}
                icon={<UncheckedCheckBox />}
                checkedIcon={<CheckedCheckBox />}
                onClick={(evt) => {
                  evt.stopPropagation();

                  handleOnNodeSelect(node);
                }}
                indeterminate={isAttachmentChecked[node.id] === "indeterminate"}
                checked={isAttachmentChecked[node.id] === "checked"}
              />
              <ListItemText primary={node.label}
                primaryTypographyProps={{
                  style: {
                    fontWeight: 700,
                    color: Colors.darkerGray,
                    fontSize: 13
                  }
                }} />
              { node.hasChildren && (openCollapse[node.id] || false ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            <Divider key={`grouping-list-item-divider-${node.id}`} />
          </div>
        );

      case 'leaf':
        return (
          <div style={style}>
            <ListItem
              style={{ paddingLeft: `${node.level * 16}px` }}
              key={`organize-list-item-${node.id}`}>
              <ManualSelectCheckbox
                disableFocusRipple
                disableRipple
                disableTouchRipple
                color="primary"
                indeterminateIcon={<IndeterminateCheckBox />}
                icon={<UncheckedCheckBox />}
                checkedIcon={<CheckedCheckBox />}
                onClick={(evt) => {
                  evt.stopPropagation();

                  handleOnNodeSelect(node);
                }}
                indeterminate={isAttachmentChecked[node.id] === "indeterminate"}
                checked={isAttachmentChecked[node.id] === "checked"}
              />
              <CustomListItemText
                disableTypography={true}
                style={{
                  fontWeight: 400,
                  color: Colors.darkerGray,
                  fontSize: 13
                }}
                key={`organize-list-item-text-${node.id}`}
              >
                {node.label}
              </CustomListItemText>
            </ListItem>
            <Divider key={`organize-list-item-divider-${node.id}`} />
          </div>
        );
    }
  }

  const itemData: Node[] = [];
  sortByFormattedTitle(recordAttachments).forEach((recordAttachment) => {

    const attachmentGroupings: Node[] = [];
    const attachments: Node[] = [];
    recordAttachment.attachmentGroupings.forEach(attachmentGrouping => {
      attachmentGroupings.push({
        id: attachmentGrouping.id,
        label: attachmentGrouping.groupingTitle,
        level: 2,
        type: 'branch',
        hasChildren: false
      });

      attachmentGrouping.attachments.forEach(attachment => {
        attachments.push({
          id: attachment.id.toString(),
          label: attachment.filename,
          level: 3,
          type: 'leaf',
          hasChildren: false
        });
      })
    });

    itemData.push(
      {
        id: recordAttachment.procoreServerId.toString(),
        label: recordAttachment.formattedTitle,
        level: 1,
        type: 'branch',
        hasChildren: attachments.length > 0
      }
    );

    if (openCollapse[recordAttachment.procoreServerId] || false) {
      itemData.push(...attachmentGroupings);
      itemData.push(...attachments);
    }
  });

  const hasSelectedItems = checkedItems(toolTabData.selectedItems).length > 0;
  const hasAttachments = itemData.length > 0;

  return (
    <FeedbackDialog
        maxWidth={'sm'}
        onClose={() => onClose()}
        aria-labelledby="help-dialog-title"
        open={open}>
      <DialogTitle>
        {'Select Attachments'}
      </DialogTitle>
      <DialogContent dividers={false} style={{ marginBottom: '20px', height: '475px', overflowY: 'unset' }}>
        {
          loading ? (
            <Box
              display={"flex"}
              height={'475px'}
              sx={{ border: `1px solid ${Colors.mediumLightGray}` }}
              borderRadius={'4px'}
              flexDirection="row"
              alignItems={"center"}
              justifyContent="center"
            >
              <CircularProgress size={12} style={{ margin: "5px" }} />
              <Typography>Loading attachments...</Typography>
            </Box>
          ) : (
            (!hasSelectedItems || !hasAttachments) ? (
              <Box
                display={"flex"}
                width={"100%"}
                sx={{ border: `1px solid ${Colors.mediumLightGray}` }}
                borderRadius={'4px'}
                height={'425px'}
                flexDirection="row"
                alignItems={"center"}
                justifyContent="center"
              >
                <Typography>{ !hasSelectedItems ? 'No items have been selected.' : 'The selected items do not have any attachments.'}</Typography>
              </Box>
            ) : (
              <div style={{ display: "flex", height: "425px", width: "100%", borderWidth: 1, borderStyle: 'solid', borderRadius: '6px', borderColor: Colors.mediumLightGray }}>
                <FixedSizeList
                  height={425}
                  width={'100%'}
                  itemData={itemData}
                  itemCount={itemData.length}
                  itemSize={46}
                >
                  {renderRow}
                </FixedSizeList>
              </div>
            )
          )
        }
        <Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent="flex-end" paddingTop={"10px"}>
          <Button
            size="medium"
            variant="contained"
            onClick={() => onClose()}
          >
            Done
          </Button>
        </Box>
      </DialogContent>
    </FeedbackDialog>
  )
};

export default ManualAttachmentSelectionModal;