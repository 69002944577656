import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogRequirementItem } from "../../../types";

export interface LinkLogRequirementItems {
  logRequirementItems: LogRequirementItem[];
  success: boolean;
}

export interface LinkLogRequirementItemsData {
  linkLogRequirementItems: LinkLogRequirementItems | null;
}

export interface LinkLogRequirementItemType {
  procoreToolId: number;
  engineName: string;
  procoreServerIds: number[];
}

export interface LinkLogRequirementItemsVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logRequirementId: number;
  linkedItems: LinkLogRequirementItemType[];
}

export const useLinkLogRequirementItems = createMutationHook<
LinkLogRequirementItemsData,
LinkLogRequirementItemsVariables
>(gql`
  mutation LinkLogRequirementItems(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $logRequirementId: ID!,
    $linkedItems: [LinkLogRequirementItem!]!
  ) {
    linkLogRequirementItems(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, logRequirementId: $logRequirementId, linkedItems: $linkedItems }
    ) {
      logRequirementItems {
        id
        logRequirementId
        recordType
        recordServerId
        engineName
        payload
      }
      success
    }
  }
`);
