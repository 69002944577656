import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogRequirementType } from "../../../types";

export interface LogRequirementTypeCreate {
  logRequirementType?: LogRequirementType;
  success: boolean;
}

export interface LogRequirementTypeCreateData {
  createLogRequirementType: LogRequirementTypeCreate | null;
}

export interface LogRequirementTypeCreateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  name: string;
}

export const useCreateLogRequirementType = createMutationHook<
  LogRequirementTypeCreateData,
  LogRequirementTypeCreateVariables
>(gql`
  mutation CreateLogRequirementType(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $name: String!
  ) {
    createLogRequirementType(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, name: $name }
    ) {
      logRequirementType {
        id
        name
      }
      success
    }
  }
`);
