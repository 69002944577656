import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { ExtractAutomation } from "../../../types";

export interface ExtractAutomationUpsert {
  extractAutomation: ExtractAutomation | null;
  success: boolean;
}

export interface ExtractAutomationUpsertData {
  upsertExtractAutomation: ExtractAutomationUpsert | null;
}

export interface ExtractToolAutomationUpsertAttributes {
  procoreToolEngineName: string;
  conditions: Record<string, any>[];
  documentStorageSystem: string;
  cloudStorageSystemId: string;
  destination: Record<string, any>;
  groupings: Record<string, any>[];
  exportOptions: Record<string, any>;
}

export interface ExtractAutomationUpsertAttributes {
  id: number | null;
  name: string;
  accountId: string;
  extractToolAutomations: ExtractToolAutomationUpsertAttributes[]
}

export interface ExtractAutomationUpsertVariables {
  attributes: ExtractAutomationUpsertAttributes;
}

export const useUpsertExtractAutomation = createMutationHook<
  ExtractAutomationUpsertData,
  ExtractAutomationUpsertVariables
>(gql`
  mutation UpsertExtractAutomation(
    $attributes: ExtractAutomationUpsertAttributes!
  ) {
    upsertExtractAutomation(
      input: { attributes: $attributes }
    ) {
      extractAutomation {
        id
        name
        createdAt
        enabled
        enabledProjectsCount
      }
      success
    }
  }
`);
