import { Box } from '@mui/material';
import React from 'react';
import { Colors } from '../../styles';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ProjectListBanner = (props: { borderTopColor: string, primaryText: React.ReactNode, secondaryText: React.ReactNode }): JSX.Element => {
  const { borderTopColor, primaryText, secondaryText } = props;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{
        background: 'white',
        borderRadius: "8px",
        marginBottom: "16px",
        boxShadow: '0px 2px 4px 1px rgba(26, 32, 36, 0.04), 0px 1px 10px 0px rgba(26, 32, 36, 0.06)',
      }}
    >
      <div style={{
        height: '6px',
        width: '100%',
        background: borderTopColor,
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px'
      }}></div>
      <Box
        display={"flex"}
        flexDirection={"row"}
        style={{
          padding: "10px 10px 10px 16px"
        }}
        alignItems={"center"}>
        <InfoOutlinedIcon />
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          marginLeft={"10px"}
        >
          <p
            style={{
              padding: 0,
              margin: 0,
              color: Colors.darkerGray,
              fontWeight: 700,
              fontSize: "13px",
            }}>{primaryText}</p>
            <p
              style={{
                padding: 0,
                margin: 0,
                color: Colors.darkerGray,
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              {secondaryText}
            </p>
        </Box>
      </Box>
    </Box>
  )
};

export default ProjectListBanner;