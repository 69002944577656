import React from "react";
import { NewPackageContextState, initialState, newPackageReducer } from './NewPackageContext';

const NewPackageContext = React.createContext<NewPackageContextState>({
  dispatch: () => undefined,
  state: initialState,
});

const NewPackageContextProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const [state, dispatch] = React.useReducer(newPackageReducer, initialState);

  return (
    <NewPackageContext.Provider value={{state, dispatch}}>
      {props.children}
    </NewPackageContext.Provider>
  );
}

export { NewPackageContext, NewPackageContextProvider };