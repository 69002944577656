import { gql } from '@apollo/client';
import { Package } from '../../types';
import { createMutationHook } from '../GraphqlHelpers';

export interface PackageCreate {
  package: Package | null;
  success: boolean;
}

export interface PackageCreateData {
  createPackage: PackageCreate | null
}

export interface PackageCreateAttributes {
  name?: string;
  accountId: string;
  procoreProjectServerId: number;
  createdFromPackageId?: number;
}
export interface PackageCreateVariables {
  package: PackageCreateAttributes
}

export const useCreatePackage = createMutationHook<PackageCreateData, PackageCreateVariables>(gql`
mutation CreatePackage($package: PackageCreateAttributes!) {
  createPackage(input: { package: $package }) {
    package {
      id
      name
      status
      createdAt
      exportTo
      downloadExportUrl
      createdFromPackage {
        id
        name
        options
        packageQueries {
          id
          procoreTool {
            id
            title
            procoreProjectId
            engineName
          }
          selectedItems
          options
          filters
          groupings
        }
      }
    }
    success
  }
}
`);