import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogRequirementTrade } from "../../../types";

export interface LogRequirementTradeCreate {
  logRequirementTrade?: LogRequirementTrade;
  success: boolean;
}

export interface LogRequirementTradeCreateData {
  createLogRequirementTrade: LogRequirementTradeCreate | null;
}

export interface LogRequirementTradeCreateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  name: string;
}

export const useCreateLogRequirementTrade = createMutationHook<
  LogRequirementTradeCreateData,
  LogRequirementTradeCreateVariables
>(gql`
  mutation CreateLogRequirementTrade(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $name: String!
  ) {
    createLogRequirementTrade(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, name: $name }
    ) {
      logRequirementTrade {
        id
        name
      }
      success
    }
  }
`);
