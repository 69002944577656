import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface PackageResourceSync {
  id: number;
  status: string;
}

export interface PackageResourceSyncCreate {
  packageResourceSync: PackageResourceSync | null;
  success: boolean;
}

export interface PackageResourceSyncCreateData {
  createPackageResourceSync: PackageResourceSyncCreate | null
}

export interface PackageResourceSyncCreateVariables {
  packageId: number;
  accountId: string;
  resourceType: string;
}

export const useCreatePackageResourceSync = createMutationHook<PackageResourceSyncCreateData, PackageResourceSyncCreateVariables>(gql`
mutation CreatePackageResourceSync($packageId: ID!, $accountId: ID!, $resourceType: String!) {
  createPackageResourceSync(input: { packageId: $packageId, accountId: $accountId, resourceType: $resourceType }) {
    packageResourceSync {
      id
      status
    }
    success
  }
}
`);