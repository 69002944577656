import { Box } from '@mui/material';
import React from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { ToolTabData } from '../../contexts/NewPackageContext';
import ExportOptionToggle from './ExportOptionToggle';
import { ProjectUserExtractOptions } from '../../types/project_user';


const ProjectDirectoryUserOptions = (props: { toolTabIndex: number, toolTabData: ToolTabData }): JSX.Element => {
  const { toolTabIndex, toolTabData } = props;
  const { dispatch } = React.useContext(NewPackageContext);


  return (
    <Box display="flex" flexDirection="column" width={1} height={1} padding={'20px 40px'}>
      <h3>PDF Options</h3>
      <ExportOptionToggle
        label={`Include Directory User Job Title`}
        checked={(toolTabData.extractOptions as ProjectUserExtractOptions).include_job_title}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...toolTabData.extractOptions,
                include_job_title: event.target.checked
              } as ProjectUserExtractOptions
            }
          });
        }} />
    </Box>
  )
};

export default ProjectDirectoryUserOptions;