import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { TemplateCloseoutLog } from "../../../types";

export interface TemplateCloseoutLogUpdate {
  templateCloseoutLog?: TemplateCloseoutLog;
  success: boolean;
}

export interface TemplateCloseoutLogUpdateData {
  updateTemplateCloseoutLog: TemplateCloseoutLogUpdate;
}

export interface TemplateCloseoutLogUpdateVariables {
  accountId: string;
  templateCloseoutLogId: number;
  attributes: Record<string, any>;
}

export const useUpdateTemplateCloseoutLog = createMutationHook<
  TemplateCloseoutLogUpdateData,
  TemplateCloseoutLogUpdateVariables
>(gql`
  mutation UpdateTemplateCloseoutLog(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $attributes: JSON!
  ) {
    updateTemplateCloseoutLog(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, attributes: $attributes }
    ) {
      templateCloseoutLog {
        id
        name
        createdAt
        templateLogRequirementsCount
      }
      success
    }
  }
`);
