import React from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { Typography } from './Typography';
import { Colors } from '../../styles';
import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import { isIframe } from '../../utils/utils';

interface ICloseoutShareableLinkMenuItem {
  id: number;
  title: string;
  vendorUrl: string;
}

export const CloseoutShareableLinkMenuItem = (
  props: ICloseoutShareableLinkMenuItem & MenuItemProps
): JSX.Element => {
  const { title, ...other } = props;
  const [hover, setHover] = React.useState(true);
  const [copied, setCopied] = React.useState({});

  return (
    <MenuItem
      {...other}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(true)}
      sx={{
        padding: '8px 12px 12px 12px',
        borderRadius: '6px',
        whiteSpace: 'normal',
        '&:hover': {
          backgroundColor: Colors.lightestGray,
        },
      }}
      onClick={async () => {
        if (isIframe) {
          window.open(props.vendorUrl, '_blank');
        } else {
          try {
            await navigator.clipboard.writeText(props.vendorUrl);
            setCopied({ [props.id]: true });
            setTimeout(() => {
              setCopied({[props.id]: false});
            }, 1000);
          } catch {
            console.error('Failed to copy to clipboard');
          }
        }
      }}
      >
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          alignItems: 'center',
          maxWidth: '100%',
        }}
      >
        <Tooltip title={copied[props.id] ? "Copied" : ""} open={copied[props.id] || false}>
          { (!copied[props.id]) ?
            <ContentPasteOutlinedIcon sx={{
              visibility: hover ? 'visible' : 'hidden',
              color: Colors.darkGray,
              height: '16px',
              width: '16px',
            }} /> : <CheckCircleOutlineOutlinedIcon sx={{
              visibility: hover ? 'visible' : 'hidden',
              color: Colors.darkGray,
              height: '16px',
              width: '16px',
            }} />
        }
        </Tooltip>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '200px',
          }}
        >
          <Typography typestyle="s bold" color={Colors.darkGray}>{title}</Typography>
        </Box>
      </Box>
    </MenuItem>
  );
};
