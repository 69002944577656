import { Colors } from "../../styles";
import React from 'react';
import { Box } from '@mui/material';

export interface StatusPillProps {
  status: string;
  color?: string;
  backgroundColor?: string;
}

const getBackgroundColorForPillStatus = (status: string): string => {
  switch (status) {
    case "ready_for_review":
      return "#F5F3C4";
    case "revise_and_resubmit":
      return Colors.lightRed;
    case "pending":
      return Colors.blue2;
    case 'accepted':
      return "#DCF5EE";
    default:
      return "";
  }
};

const getColorForPillStatus = (status: string): string => {
  switch (status) {
    case "ready_for_review":
      return "#5C2E00";
    case 'revise_and_resubmit':
      return Colors.darkerRed;
    case 'pending':
      return "#0A2C66";
    case "accepted":
      return Colors.darkGreen;
    default:
      return "";
  }
};

const FileRequestStatusPill = (props: { status: string, color?: string, backgroundColor?: string, display: 'inline-block' | 'none', children: string | JSX.Element | JSX.Element[] }): JSX.Element => {
  const { status, color, backgroundColor } = props;

  return (
    <Box
      margin={0}
      fontWeight={'bold'}
      display={props.display}
      style={{
        color: color ? color : getColorForPillStatus(status),
        backgroundColor: backgroundColor ? backgroundColor : getBackgroundColorForPillStatus(status),
        fontSize: '14px',
        textAlign: 'center',
        padding: '4px 10px',
        borderRadius: '12px',
        maxWidth: 'fit-content'
      }}
    >
      {props.children}
    </Box>
  )
};

export default FileRequestStatusPill;