import { gql } from '@apollo/client';
import { createMutationHook } from '../../GraphqlHelpers';
import { ImportResourceSync } from '../../../types';

export interface ImportResourceSyncCreate {
  importResourceSync: ImportResourceSync | null;
  success: boolean;
}

export interface ImportResourceSyncCreateData {
  createImportResourceSync: ImportResourceSyncCreate | null
}

export interface ImportResourceSyncCreateVariables {
  accountId: string;
  procoreProjectServerId: number;
  resourceType: string;
}

export const useCreateImportResourceSync = createMutationHook<ImportResourceSyncCreateData, ImportResourceSyncCreateVariables>(gql`
mutation CreateImportResourceSync($accountId: ID!, $procoreProjectServerId: ID!, $resourceType: String!) {
  createImportResourceSync(input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, resourceType: $resourceType }) {
    importResourceSync {
      id
      status
    }
    success
  }
}
`);