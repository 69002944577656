import React, { KeyboardEvent, ChangeEvent, useState } from "react";
import { ButtonBaseProps, InputBaseProps, ListItemButton, ListItemText, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  Typography,
  Popper,
  ButtonBase,
  InputBase,
} from "@mui/material";
import { RemoveFilterIconButton } from "./FilterSingleSelect";
import { Colors, ExtractsCheckbox } from "../../styles";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { SelectFilterValue } from "../../types/submittal";
import {
  getSelectAllOptions,
  getSelectAllOptionsWithFilter,
} from "../../utils/utils";
import ClickAwayListener from '@mui/material/ClickAwayListener';

type OtherFilterButtonProps = {
  open: boolean;
};

const FilterButton = styled(ButtonBase)<ButtonBaseProps & OtherFilterButtonProps>(({ open }) => {
  return {
    fontSize: 16,
    textAlign: "left",
    padding: "10px 12px",
    fontWeight: 500,
    borderRadius: 6,
    border: open ? "1px solid #80BDFF" : "1px solid #E4EBF0",
    boxShadow: open ? "0px 0px 0px 3px rgba(0, 123, 255, 0.25)" : '',
    "&:hover": {
      border: "1px solid #D8E0E5",
    },
    "&:focus": {
      borderRadius: 6,
      border: "1px solid #80BDFF",
      boxShadow: "0px 0px 0px 3px rgba(0, 123, 255, 0.25)",
    },
    "& span": {
      marginRight: "6px",
    },
    "& svg": {
      width: 16,
      height: 16,
    }
  };
});

export const CustomInputBase = styled(InputBase)<InputBaseProps>(() => {
  return {
    padding: "10px",
    minWidth: "300px",
    borderBottom: "1px solid #dfe2e5",
    '& .MuiInputBase-input': {
      borderRadius: 6,
      backgroundColor: 'white',
      padding: 8,
      border: "1px solid #ced4da",
      fontWeight: 400,
      fontSize: 16,
      "&:hover": {
        border: "1px solid #D8E0E5",
      },
      "&:focus": {
        borderRadius: 6,
        border: "1px solid #80BDFF",
        boxShadow: "0px 0px 0px 3px rgba(0, 123, 255, 0.25)",
      },
    },
  }
});

export const FilterAutocomplete = (props: {
  open: boolean;
  disabled: boolean;
  onOpen: () => void;
  onClose: () => void;
  onChange: (values: SelectFilterValue[]) => void;
  options: SelectFilterValue[];
  selectedOptions: SelectFilterValue[];
  filterLabel: string;
  removeFilter?: () => void;
  popperContainer?: Element;
}): JSX.Element => {
  const {
    filterLabel,
    open: shouldOpen,
    options,
    onChange,
    onClose,
    onOpen,
    selectedOptions,
    popperContainer
  } = props;
  const inputRef = React.useRef(null);
  const [focusedTag, setFocusedTag] = useState(-1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValues, setSelectedValues] =
    useState<SelectFilterValue[]>(selectedOptions);
  const [inputValue, setInputValue] = useState("");
  const buttonRef = React.useRef(null);
  const [showRemoveFilterButton, setShowRemoveFilterButton] =
    useState(false);

  React.useEffect(() => {
    if (shouldOpen) {
      setAnchorEl(buttonRef?.current);
    }
  }, []);

  React.useEffect(() => {
    if (open) {
      onChange(selectedValues);
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [selectedValues]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      event.stopPropagation();

      if (filteredOptions.length > 0) {
        setFocusedTag(Math.max(0, focusedTag - 1));
      } else {
        setFocusedTag(-1);
      }
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      event.stopPropagation();
      if (filteredOptions.length > 0) {
        setFocusedTag(Math.min(filteredOptions.length - 1, focusedTag + 1));
      } else {
        setFocusedTag(-1);
      }
    } else if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();

      if (
        filteredOptions.length > 0 &&
        focusedTag > -1 &&
        focusedTag < filteredOptions.length
      ) {
        const selectFilterValue: SelectFilterValue =
          filteredOptions[focusedTag];

        if (selectFilterValue) {
          const checkFilterValue = selectedValues.find(
            (v) => selectFilterValue.value === v.value
          );
          const checked = checkFilterValue ? true : false;

          if (checked) {
            setSelectedValues([
              ...selectedValues.filter(
                (v) => v.value !== selectFilterValue.value
              ),
            ]);
          } else {
            setSelectedValues([...selectedValues, selectFilterValue]);
          }
        }
      }
    } else if (event.key == "Escape") {
      handleOnClose();
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setInputValue(value);
  };

  const handleOnClose = () => {
    if (open) {
      setFocusedTag(-1);

      setInputValue("");

      if (anchorEl) {
        anchorEl.focus();
      }
      setAnchorEl(null);

      onClose();
    }
  };

  const getFilteredOptions = (): {
    match: SelectFilterValue[];
    unmatch: SelectFilterValue[];
  } => {
    const trimmedValue = inputValue.trim().toLowerCase();

    if (trimmedValue) {
      const match = [];
      const unmatch = [];

      options.forEach((option) => {
        const matches = (option.value || "")
          .trim()
          .toLowerCase()
          .includes(trimmedValue);

        if (matches) {
          match.push(option);
        } else {
          unmatch.push(option);
        }
      });

      return { match: match, unmatch: unmatch };
    } else {
      return { match: options, unmatch: [] };
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-options-popover" : undefined;
  const { match: filteredOptions, unmatch } = getFilteredOptions();
  const filteredValues = filteredOptions.map(
    (filterValue) => filterValue.value
  );
  const unmatchFilteredValues = unmatch.map((filterValue) => filterValue.value);

  const trimmedAndLowerInputValue = (inputValue || "")
    .trim()
    .toLocaleLowerCase();

  const getCheckedState = (): boolean => {
    if (trimmedAndLowerInputValue) {
      const selectedFilteredValues = selectedValues.filter((v) =>
        filteredValues.includes(v.value)
      );

      return filteredOptions.length === selectedFilteredValues.length;
    } else {
      return selectedValues.length === options.length;
    }
  };
  const getIntermediateState = (): boolean => {
    if (trimmedAndLowerInputValue) {
      const selectedFilteredValues = selectedValues.filter((v) =>
        filteredValues.includes(v.value)
      );
      const selectedUnmatchedValues = selectedValues.filter((v) =>
        unmatchFilteredValues.includes(v.value)
      );
      if (selectedUnmatchedValues.length > 0) {
        return (
          selectedUnmatchedValues.length > 0 &&
          selectedFilteredValues.length < filteredOptions.length
        );
      } else {
        return (
          selectedFilteredValues.length > 0 &&
          selectedFilteredValues.length < filteredOptions.length
        );
      }
    } else {
      return (
        selectedValues.length > 0 && selectedValues.length < options.length
      );
    }
  };

  function renderRow(props: ListChildComponentProps<SelectFilterValue>) {
    const { index, style } = props;

    const selectFilterValue: SelectFilterValue = filteredOptions[index];

    const checkFilterValue = selectedValues.find(
      (v) => selectFilterValue.value === v.value
    );
    const checked = checkFilterValue ? true : false;

    return (
      <div style={style}>
        <ListItemButton
          sx={{
            padding: 0,
            color: Colors.darkerGray,
            fontSize: "14px",
            backgroundColor:
              index === focusedTag ? "rgba(0, 0, 0, 0.04)" : "none",
          }}
          key={`filter-list-item-${selectFilterValue.id}`}
          onClick={(evt) => {
            evt.stopPropagation();

            if (checked) {
              setSelectedValues([
                ...selectedValues.filter(
                  (v) => v.value !== selectFilterValue.value
                ),
              ]);
            } else {
              setSelectedValues([...selectedValues, selectFilterValue]);
            }
          }}
        >
          <ExtractsCheckbox
            color="default"
            checked={checked}
            onClick={(evt) => {
              evt.stopPropagation();

              if (checked) {
                setSelectedValues([
                  ...selectedValues.filter(
                    (v) => v.value !== selectFilterValue.value
                  ),
                ]);
              } else {
                setSelectedValues([...selectedValues, selectFilterValue]);
              }
            }}
          />
          <ListItemText
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            disableTypography={true}
            key={`filter-list-item-text-${selectFilterValue.id}`}
          >
            {selectFilterValue.value}
          </ListItemText>
        </ListItemButton>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Box
        display="block"
        position="relative"
        width={"fit-content"}
        style={{ marginRight: "16px" }}
        onBlur={() => { if (props.removeFilter) { setShowRemoveFilterButton(false) } }}
        onMouseEnter={() => { if (props.removeFilter) { setShowRemoveFilterButton(true) }}}
        onMouseLeave={() => { if (props.removeFilter) { setShowRemoveFilterButton(false) }}}
      >
        <FilterButton
          disableRipple
          open={open}
          disabled={props.disabled}
          aria-describedby={id}
          ref={buttonRef}
          onClick={handleClick}
        >
          <span>
            {selectedValues.length > 0
              ? `${filterLabel} (${selectedValues.length})`
              : filterLabel}
          </span>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </FilterButton>
        {!open && props.removeFilter && showRemoveFilterButton && (
          <RemoveFilterIconButton
            style={{
              top: "-8px",
              right: "-10px",
            }}
            onClick={() => props.removeFilter()}
          >
            <CloseIcon
              style={{ height: "14px", width: "14px", color: Colors.darkGray }}
            />
          </RemoveFilterIconButton>
        )}
      </Box>

      {open && (
        <ClickAwayListener onClickAway={handleOnClose}>
          <Popper
            container={(popperContainer) ? popperContainer : document.body}
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
          >
            <Box
              display={"flex"}
              sx={{
                boxShadow:
                  "0px 1px 10px rgba(26, 32, 36, 0.06), 0px 4px 5px rgba(26, 32, 36, 0.07), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)",
                borderRadius: "6px",
                minWidth: 300,
                zIndex: 1,
                fontSize: 13,
                color: "#586069",
                backgroundColor: Colors.white,
              }}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <CustomInputBase
                  inputRef={inputRef}
                  fullWidth={true}
                  onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                  autoFocus
                />
                {filteredOptions.length > 0 && (
                  <Button
                    fullWidth
                    style={{ padding: 0, justifyContent: "left" }}
                    onClick={() => {
                      if (trimmedAndLowerInputValue) {
                        const selectedFilteredValues = selectedValues.filter(
                          (v) => filteredValues.includes(v.value)
                        );
                        const selectedUnmatchedValues = selectedValues.filter(
                          (v) => unmatchFilteredValues.includes(v.value)
                        );
                        setSelectedValues(
                          getSelectAllOptionsWithFilter(
                            selectedUnmatchedValues,
                            selectedFilteredValues,
                            filteredOptions
                          )
                        );
                      } else {
                        setSelectedValues(
                          getSelectAllOptions(selectedValues, options)
                        );
                      }
                    }}
                  >
                    <ExtractsCheckbox
                      color={"default"}
                      checked={getCheckedState()}
                      indeterminate={getIntermediateState()}
                    />
                    <Typography
                      style={{
                        fontSize: 14,
                        color: Colors.darkerGray,
                        fontWeight: 500,
                      }}
                    >
                      Select All
                    </Typography>
                  </Button>
                )}
                {filteredOptions.length > 0 && (
                  <FixedSizeList
                    height={Math.min(200, filteredOptions.length * 42)}
                    width={300}
                    itemCount={filteredOptions.length}
                    itemSize={42}
                  >
                    {renderRow}
                  </FixedSizeList>
                )}
                {filteredOptions.length <= 0 && (
                  <Box padding={"10px"}>
                    <Typography
                      fontSize="12px"
                      color={Colors.darkishGray}
                      paddingY="8px"
                    >
                      {"No items match your search terms."}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </React.Fragment>
  );
};
