import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  InputBase,
  ListItemButton,
  DialogProps,
  styled,
  DialogTitleProps,
  alpha,
} from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import CheckIcon from '@mui/icons-material/Check';
import { Colors } from "../../styles";
import {
  CheckedCheckBox,
  ManualSelectCheckbox,
  UncheckedCheckBox,
  IndeterminateCheckBox,
  SearchCloseIconButton,
  SearchCloseIcon,
} from "./styles";
import { Account, Project } from "../../types";
import SearchIcon from '@mui/icons-material/Search';
import { Button } from "../shared/Button";
import { useUpdateProcoreIntegration } from "../../graphql/mutations/UpdateProcoreIntegration";

export const FeedbackDialog = styled(Dialog)<DialogProps>(() => {
  return {
    "& .MuiDialog-paper": {
      width: "705px",
      height: "625px",
    }
  };
});

export const ModalDialogTitle = styled(DialogTitle)<DialogTitleProps>(() => {
  return {
    padding: '16px 32px 0px 32px'
  };
});

const EnableDmsaOnProjectsModal = (props: {
  account: Account;
  procoreProjects: Project[];
  handleOnClose: (refresh: boolean) => void;
  open: boolean;
}): JSX.Element => {
  const { account, handleOnClose, open } = props;

  const [projectsToEnableDmsa, setProjectsToEnableDmsa] = React.useState<
  Record<number, boolean>
  >(props.procoreProjects.reduce((acc, project) => {
    acc[project.procoreServerId] = true;

    return acc;
  }, {}));

  const [updateProcoreIntegration, { loading }] = useUpdateProcoreIntegration({
    accountId: account.id,
    procoreProjectServerIds: Object.keys(projectsToEnableDmsa).filter((key) => projectsToEnableDmsa[key] || false)
  });

  const [searchValue, setSearchValue] = React.useState<string>('');

  const handleProjectClick = (procoreServerId: number) => {
    const add = projectsToEnableDmsa[procoreServerId] || false;

    setProjectsToEnableDmsa({
      ...projectsToEnableDmsa,
      [procoreServerId]: !add,
    });
  };

  function renderRow(props: ListChildComponentProps<Project[]>) {
    const { index, style, data } = props;

    const project = data[index] as Project;

    const checked = projectsToEnableDmsa[project.procoreServerId] || false;
    return (
      <div style={style}>
        <ListItemButton
          sx={{
            paddingLeft: '16px'
          }}
          key={`user-list-item-${project.procoreServerId}`}
          onClick={() => handleProjectClick(project.procoreServerId)}
        >
          <ManualSelectCheckbox
            disableFocusRipple
            disableRipple
            disableTouchRipple
            color="primary"
            disabled={!checked}
            indeterminateIcon={<IndeterminateCheckBox />}
            icon={<UncheckedCheckBox />}
            checkedIcon={<CheckedCheckBox />}
            checked={checked}
          />
          <Box display={"flex"} flexDirection={"column"} width={1}>
            <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
              {project.displayName || project.name}
            </Typography>
            <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
              {project.procoreCompanyName}
            </Typography>
          </Box>
        </ListItemButton>
        <Divider key={`grouping-list-item-divider-${project.procoreServerId}`} />
      </div>
    );
  }

  const trimmedAndLoweredSearchValue = (searchValue || '').trim().toLowerCase();
  const filteredItemData = props.procoreProjects.filter((d) => {
    const trimmedOption = (d.displayName || d.name || '').trim().toLowerCase();

    return trimmedOption.includes(trimmedAndLoweredSearchValue);
  }).sort((a, b) => {
    const aProjectName = a.displayName || a.name;
    const bProjectName = b.displayName || b.name;
    return aProjectName.localeCompare(bProjectName, undefined, { numeric: true })
  });

  const licensesToUseCount = Object.values(projectsToEnableDmsa).filter((v) => v).length

  return (
    <FeedbackDialog
      maxWidth={"sm"}
      onClose={() => handleOnClose(false)}
      aria-labelledby="help-dialog-title"
      open={open}
    >
      <ModalDialogTitle>
        {"Permission Needed"}
      </ModalDialogTitle>
      <DialogContent
        dividers={false}
        style={{
          marginBottom: "20px",
          height: "475px",
          padding: '16px 32px',
          display: 'flex',
          flexDirection: 'column' }}
      >
        <>
            <Box display="flex" flexDirection={"row"} alignContent="center" marginBottom={'10px'}>
              <Button
                disabled={loading}
                onClick={() => {
                  if (licensesToUseCount > 0) {
                    setProjectsToEnableDmsa({});
                  } else {
                    setProjectsToEnableDmsa(filteredItemData.slice(0, account.numberOfLicenses).reduce((acc, nextItem) => {
                      acc[nextItem.procoreServerId] = true;

                      return acc;
                    }, {} as Record<number, boolean>))
                  }
                }}
                variant="outlined"
                disableElevation={true}
                size="medium"
                style={{
                  minHeight: '39px',
                  marginRight: "12px"
                }}
              >
                {licensesToUseCount > 0 ? "Deselect All" : "Select All"}
              </Button>
              <Box sx={{
                position: 'relative',
                minHeight: '39px',
                maxHeight: 'unset',
                flex: '1 0',
                borderRadius: '6px',
                paddingRight: '2px',
                border: '1px solid #D5DFE6',
                backgroundColor: alpha('#FFFFFF', 0.15),
                '&:hover': {
                  backgroundColor: alpha('#FFFFFF', 0.25),
                },
                marginLeft: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '12px'
              }}>
                <SearchIcon />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  fullWidth={true}
                  placeholder="Search..."
                  autoFocus={true}
                  value={searchValue}
                  endAdornment={
                    (((searchValue || '').trim())) ? (
                      <SearchCloseIconButton
                          size={'small'}
                          onClick={() => {
                            setSearchValue('');
                          }}
                          style={{ visibility: ((searchValue || '').trim()) ? 'visible' : 'hidden' }}>
                        <SearchCloseIcon />
                      </SearchCloseIconButton>
                    ) : null
                  }
                  onChange={(evt) => {
                    setSearchValue(evt.target.value)
                  }}
                  inputProps={{ 'aria-label': 'search users' }}
                />
              </Box>
            </Box>
            <div
              style={{
                display: "flex",
                height: "425px",
                width: "100%",
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: "6px",
                borderColor: Colors.mediumLightGray,
              }}
            >
              <FixedSizeList
                height={425}
                width={"100%"}
                itemData={filteredItemData}
                itemCount={filteredItemData.length}
                itemSize={55}
              >
                {renderRow}
              </FixedSizeList>
            </div>
          </>
        <Box
          display={"flex"}
          flexDirection="row"
          alignItems={"center"}
          justifyContent="flex-end"
          paddingTop={"10px"}
        >
          <Button
            disabled={loading}
            onClick={() => handleOnClose(false)}
            variant="outlined"
            buttonborderstyle="pill"
            disableElevation={true}
            size="medium"
            style={{
              minHeight: '39px',
              marginRight: "12px"
            }}
          >
            {"Cancel"}
          </Button>
          <Button
            disabled={licensesToUseCount === 0 || loading}
            buttonborderstyle="pill"
            onClick={() => {
              updateProcoreIntegration().then(({ data }) => {
                if (data.updateProcoreIntegration.success) {
                  props.handleOnClose(true);
                }
              })
            }}
            startIcon={<CheckIcon fontSize="small" />}
            sx={{
              backgroundColor: Colors.green,
              color: Colors.white,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: Colors.green,
                opacity: 0.8,
              },
              '&:focus': {
                boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
              },
            }}>
            {'Allow Access'}
          </Button>
        </Box>
      </DialogContent>
    </FeedbackDialog>
  );
};

export default EnableDmsaOnProjectsModal;
