import React, { useState } from 'react';
import { List, Box, TextField, CircularProgress, Tooltip } from '@mui/material';
import { Typography } from './shared/Typography';
import { Colors } from '../styles';
import AddCircle from '@mui/icons-material/AddCircle';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';
import { LogRequirementTrade } from '../types';
import { isEmpty } from '../utils/utils';
import { LoadingButton } from './shared/Button';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { TemplateCloseoutLogContext } from '../contexts/TemplateCloseoutLogContextProvider';
import { useCreateTemplateLogRequirementTrade } from '../graphql/mutations/closeout/CreateTemplateLogRequirementTrade';
import { useUpdateTemplateLogRequirementTrade } from '../graphql/mutations/closeout/UpdateTemplateLogRequirementTrade';
import { useDeleteTemplateLogRequirementTrade } from '../graphql/mutations/closeout/DeleteTemplateLogRequirementTrade';

interface ITradeRowProps {
  item: LogRequirementTrade;
  loading: {isLoading: boolean, id: number};
  disableDelete: boolean;
  onDelete: (id: number) => void;
  onUpdate: (id: number, name: string) => void;
}

const TradeRow = ({ item, loading, disableDelete, onDelete, onUpdate }: ITradeRowProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(item.name);
  return (
    <Box
      display="flex"
      gap="2px"
      padding="12px 12px 12px 16px"
      borderBottom={`1px solid ${Colors.mediumLightGray}`}
      fontSize={'14px'}
      alignItems={'center'}
    >
      {
        isEditing ? (
        <TextField
          sx={{
            '& .MuiOutlinedInput-input': {
                padding: "2px 12px",
            },
          }}
            variant='outlined'
          fullWidth
          autoFocus
          disabled={loading.isLoading}
          inputRef={(input) => input && input.focus()}
          onFocus={(evt) => setNewName(item.name)}
          onBlur={() => {
            if (!isEmpty(newName) && newName !== item.name) {
              onUpdate(item.id, newName);
              setIsEditing(false);
                setNewName('')
            } else {
              setIsEditing(false);
              setNewName('');
            }
          }}
          value={newName}
          onKeyDown={(evt: any) => {
              if (evt.key === 'Enter' || evt.key === 'Escape' || evt.key === 'Tab') {
              evt.stopPropagation();
              evt.preventDefault();
              evt.target?.blur();
            }
          }}
            onChange={(evt) => setNewName(evt.target.value) }
        />
      ) : (
        <Typography typestyle="s" flexGrow="1">
          {item.name}
        </Typography>
      )}
      {loading.isLoading && loading.id === item.id && (
        <CircularProgress size={16} sx={{marginRight: '8px'}}/>
      )}
      <IconButton
        size="small"
        disabled={loading.isLoading}
        onClick={() => setIsEditing(true)}
      >
        <EditOutlined fontSize="inherit" />
      </IconButton>
      <Tooltip
        placement='right'
        title={
          disableDelete
            ? 'This trade cannot be deleted because it is currently referenced by at least one closeout requirement'
            : ''
        }
      >
        <span>
          <IconButton
            size="small"
            disabled={loading.isLoading || disableDelete}
            onClick={() => onDelete(item.id)}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export const ManageTemplateTrades = (): JSX.Element => {
  const { state: accountProjectState } = React.useContext(AccountProjectContext);
  const { state, dispatch } = React.useContext(TemplateCloseoutLogContext);
  const [value, setValue] = useState('');

  const [ createLogRequirementTrade, { loading: loadingCreateTrade } ] = useCreateTemplateLogRequirementTrade({
    accountId: accountProjectState.selectedAccount,
    templateCloseoutLogId: state.id,
    name: 'test'
  });

  const [ updateLogRequirementTrade, { loading: loadingUpdateTrade } ] = useUpdateTemplateLogRequirementTrade({
    accountId: accountProjectState.selectedAccount,
    templateCloseoutLogId: state.id,
    id: -1,
    name: 'test'
  });

  const [ deleteLogRequirementTrade, { loading: loadingDeleteTrade } ] = useDeleteTemplateLogRequirementTrade({
    accountId: accountProjectState.selectedAccount,
    templateCloseoutLogId: state.id,
    id: -1
  });

  const [loading, setLoading] = useState<{isLoading: boolean, id: number}>({isLoading: false, id: undefined})

  const usedTradeIds = React.useMemo(() => {
      return state.templateLogRequirements.reduce((acc, currentVal) => {
        if (currentVal.templateLogRequirementTrade) {
          acc.add(currentVal.templateLogRequirementTrade.id);
        }
        return acc;
      }, new Set<number>())
  }, [state.templateLogRequirements]);

  const handleCreate = () => {
    if (isEmpty(value) || state.templateLogRequirementTrades.some((item) => {
      return (item.name || '').trim().localeCompare((value || '').trim(), undefined, { numeric: true, sensitivity: 'base' }) === 0
    })) { return; }

    createLogRequirementTrade({
      variables: {
        accountId: accountProjectState.selectedAccount,
        templateCloseoutLogId: state.id,
        name: value
      }
    }).then(({ data }) => {
      if (data.createTemplateLogRequirementTrade.success) {
        setValue('');
        dispatch({ type: 'PREPEND_TEMPLATE_LOG_REQUIREMENT_TRADE', value: data.createTemplateLogRequirementTrade.templateLogRequirementTrade })
      }
    }).catch((err) => {
      console.log('err', err)
    });
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'16px'} height={1}>
      <Box display="flex" gap="8px" flex={'0 0 auto'}>
        <TextField
          value={value}
          autoComplete='off'
          onChange={(e) => setValue(e.target.value)}
          placeholder="Name a new trade..."
          onKeyDown={(evt) => {
            if (evt.key === 'Enter') {
              handleCreate();
            }
          }}
          sx={{
            flexGrow: 1,
            '& .MuiInputBase-root': {
              height: '44px',
              fontSize: '0.875rem',
          },
            '& .MuiOutlinedInput-root': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: Colors.mediumGray,
              }
            }
          }}
        />
        <LoadingButton
          variant="outlined"
          size="large"
          startIcon={<AddCircle fontSize="small" />}
          disabled={isEmpty(value) || state.templateLogRequirementTrades.some((item) => {
            return (item.name || '').trim().localeCompare((value || '').trim(), undefined, { numeric: true, sensitivity: 'base' }) === 0
          })}
          loading={loadingCreateTrade}
          loadingPosition='start'
          onClick={handleCreate}
        >
          Add
        </LoadingButton>
      </Box>
      <List
        sx={{
          flex: '1 1 0px',
          overflowY: 'auto',
          border: `1px solid ${Colors.mediumLightGray}`,
          borderRadius: '6px',
          padding: '0px',
        }}
      >
        {state.templateLogRequirementTrades.map((item, i) => {
          return (
            <TradeRow key={`log-requirement-trade-${i}`}
              item={item}
              loading={loading}
              disableDelete={usedTradeIds.has(item.id)}
              onUpdate={(id, name) => {
                setLoading({isLoading: true, id: id})
                updateLogRequirementTrade({
                  variables: {
                    accountId: accountProjectState.selectedAccount,
                    templateCloseoutLogId: state.id,
                    id: id,
                    name: name
                  }
                }).then(({ data }) => {
                  if (data.updateTemplateLogRequirementTrade.success) {
                    dispatch({ type: 'UPDATE_TEMPLATE_LOG_REQUIREMENT_TRADE', value: data.updateTemplateLogRequirementTrade.templateLogRequirementTrade })
                  }
                  setLoading({isLoading: false, id: undefined})
                }).catch((err) => {
                  setLoading({isLoading: false, id: undefined})
                  console.log('err', err)
                });
              }}
              onDelete={(id) => {
                setLoading({isLoading: true, id: id})
                deleteLogRequirementTrade({
                  variables: {
                    accountId: accountProjectState.selectedAccount,
                    templateCloseoutLogId: state.id,
                    id: id
                  }
                }).then(({ data }) => {
                  if (data.deleteTemplateLogRequirementTrade.success) {
                    dispatch({ type: 'DELETE_TEMPLATE_LOG_REQUIREMENT_TRADE', value: id })
                  }
                  setLoading({isLoading: false, id: undefined})
                }).catch((err) => {
                  setLoading({isLoading: false, id: undefined})
                  console.log('err', err)
                });
              }} />
          );
        })}
      </List>
    </Box>
  );
};
