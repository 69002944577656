import { gql } from "@apollo/client";
import { createQueryHook } from "../../GraphqlHelpers";
import { TemplateCloseoutLog } from "../../../types";

export type TemplateCloseoutLogData = {
  templateCloseoutLogs: TemplateCloseoutLog[];
};

export type TemplateCloseoutLogDataVariables = {
  accountId: string;
};

const GetTemplateCloseoutLogsQuery = gql`
  query GetTemplateCloseoutLogs(
    $accountId: ID!
  ) {
    templateCloseoutLogs(accountId: $accountId) {
      id
      name
      createdAt
      templateLogRequirementsCount
    }
  }
`;

export const useGetTemplateCloseoutLogs = createQueryHook<
  TemplateCloseoutLogData,
  TemplateCloseoutLogDataVariables
>(GetTemplateCloseoutLogsQuery);
