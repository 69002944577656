import React from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { ToolTabData } from '../../contexts/NewPackageContext';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box, FormControlLabel, Typography } from "@mui/material";
import { DrawingExtractOptions } from '../../types/drawing';
import { Button } from '../shared/Button';
import { Colors, ExtractsCheckbox } from '../../styles';
import ExportOptionToggle from './ExportOptionToggle';
import { ExtractsRadio } from './styles';
import { FilterAutocomplete } from '../shared/FilterAutocomplete';
import { AttachmentOption, SelectFilterValue } from '../../types/submittal';

const submittalAttachmentContentOptions = [
  {
    value: 'all',
    label: 'All attachments'
  },
  {
    value: 'distributed',
    label: 'Distributed attachments only'
  },
  {
    value: 'last_workflow_response',
    label: 'Last workflow response with status'
  }
];

const getMarkupName = (optionName: string): string => {
  switch (optionName) {
    case "include_correspondence":
      return "Correspondence";

    case "include_rfis":
      return "RFIs";

      case "include_documents":
      return "Documents";

    case "include_inspections":
      return "Inspections";

    case "include_observations":
      return "Observations";

    case "include_photos":
      return "Photos";

    case "include_punch_items":
      return "Punch Items";

    case "include_sketches":
      return "Sketches";

    case "include_submittals":
      return "Submittals";

    case "include_sheet_links":
      return "Include Sheet Links";

    case "include_other_markup":
      return "Other Markup";

    default:
      return "";
  }
}

const DrawingOptions = (props: { toolTabIndex: number, toolTabData: ToolTabData }): JSX.Element => {
  const { toolTabIndex, toolTabData } = props;

  const { dispatch } = React.useContext(NewPackageContext);

  const drawingOptions = toolTabData.extractOptions as DrawingExtractOptions;
  const [markupOptions, setMarkupOptions] = React.useState({
    // include_correspondence: drawingOptions.include_correspondence,
    include_documents: drawingOptions.include_documents,
    // include_inspections: drawingOptions.include_inspections,
    // include_observations: drawingOptions.include_observations,
    include_other_markup: drawingOptions.include_other_markup,
    include_photos: drawingOptions.include_photos,
    // include_punch_items: drawingOptions.include_punch_items,
    include_rfis: drawingOptions.include_rfis,
    include_sketches: drawingOptions.include_sketches,
    include_sheet_links: drawingOptions.include_sheet_links,
    include_submittals: drawingOptions.include_submittals,
  } as Record<string, boolean>);

  const [rfiResponseOption, setRfiResponseOption] = React.useState(drawingOptions.rfis.options.response_option);
  const [submittalOption, setSubmittalOption] = React.useState({
    attachment_option: drawingOptions.submittals.options.attachment_option,
    single_pdf: drawingOptions.submittals.options.single_pdf,
    workflow_responses: drawingOptions.submittals.options.workflow_responses
  });

  const menuOptions: SelectFilterValue[] = toolTabData.submittalResponses.map((response) => {
    return {
      id: response.id.toString(),
      key: response.id,
      value: response.name
    } as SelectFilterValue
  });

  const selectedOptions = submittalOption.workflow_responses.map((response) => {
    return {
      id: response.id.toString(),
      key: response.id,
      value: response.name
    } as SelectFilterValue
  });

  React.useEffect(() => {
    dispatch({
      type: 'SET_TOOL_OPTIONS',
      value: {
        toolTabIndex: toolTabIndex,
        option: {
          ...markupOptions,
          submittals: {
            options: {
              ...submittalOption
            }
          },
          rfis: {
            options: {
              response_option: rfiResponseOption
            }
          }
        }
      }
    });
  }, [markupOptions, rfiResponseOption, submittalOption]);

  return (
    <Box display="flex" alignItems="flex-start" flexDirection="column" width={1} height={1} padding={'20px 40px'} style={{ overflowY: 'auto' }}>
      <h3>Public Markups to Include</h3>
      <Button
        onClick={() => {
          if (Object.values(markupOptions).some(t => t)) {
            const options = Object.keys(markupOptions).reduce((acc, current) => {
              acc[current] = false;

              return acc;
            }, {} as Record<string, boolean>);

            setMarkupOptions(options);
          } else {
            const options = Object.keys(markupOptions).reduce((acc, current) => {
              acc[current] = true;

              return acc;
            }, {} as Record<string, boolean>);

            setMarkupOptions(options);
          }
        }}
        variant="outlined"
        disableElevation={true}
        size="medium"
      >
        {Object.values(markupOptions).some(t => t) ? "Deselect All" : "Select All"}
      </Button>
      {
        Object.keys(markupOptions).map(key => {
          return (
            <Box key={`drawing-option-container-${key}`} display="flex" flexDirection="column" marginTop={"18px"} gap={'12px'}>
              <Box display="flex" flexDirection={"row"}>
                <ExtractsCheckbox
                  key={`drawing-option-${key}`}
                  disableRipple
                  sx={{
                    padding: 0
                  }}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={markupOptions[key]}
                  onChange={() => {
                    setMarkupOptions({
                      ...markupOptions,
                      [key]: !markupOptions[key]
                    });
                  }}
                  style={{ marginRight: 8 }}
                />
                <Typography>
                  {getMarkupName(key)}
                </Typography>
              </Box>

              { key === 'include_rfis' && markupOptions[key] && (
                <Box display={'flex'} flexDirection={'column'} sx={{
                  marginLeft: '20px'
                }}>
                  <ExportOptionToggle
                    label={'Include official RFI responses only'}
                    checked={rfiResponseOption == 'official_only'}
                    onChange={(event) => {
                      setRfiResponseOption(event.target.checked ? 'official_only' : 'all');
                    }} />
                </Box>
              )}

              { key === 'include_submittals' && markupOptions[key] && (
                <Box display={'flex'} flexDirection={'column'} sx={{
                  marginLeft: '20px'
                }}>
                  {
                    submittalAttachmentContentOptions.map(option => {
                      if (option.value === 'last_workflow_response') {
                        return (
                          <>
                            <FormControlLabel
                              key={option.value}
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "14px",
                                },
                              }}
                              checked={submittalOption.attachment_option === option.value}
                              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                setSubmittalOption({
                                  ...submittalOption,
                                  attachment_option: evt.target.value as AttachmentOption,
                                  workflow_responses: [],
                                })
                              }}
                              color="primary"
                              control={<ExtractsRadio disableFocusRipple={true} disableRipple={true} />}
                              value={option.value}
                              label={option.label}
                            />
                            {
                              submittalOption.attachment_option === 'last_workflow_response' &&
                              (
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="flex-start"
                                  alignItems="flex-start"
                                  flexWrap="wrap"
                                  borderLeft={`4px solid ${Colors.mediumLightGray}`}
                                  paddingLeft={'20px'}
                                  margin={1}
                                  marginLeft={'30px'}>
                                  <FilterAutocomplete
                                    disabled={toolTabData.loading}
                                    key={`select-single-submittal-response-status`}
                                    filterLabel={'Response Status'}
                                    open={false}
                                    onOpen={() => {}}
                                    onClose={() => {}}
                                    onChange={function (values): void {
                                      const keys = values.map((value) => value.key);

                                      setSubmittalOption({
                                        ...submittalOption,
                                        attachment_option: 'last_workflow_response',
                                        workflow_responses: toolTabData.submittalResponses.filter((response) => {
                                            return keys.includes(response.id)
                                        }),
                                      });
                                    }}
                                    options={menuOptions}
                                    selectedOptions={selectedOptions}
                                  />
                                </Box>
                              )
                            }
                          </>
                        );
                      } else {
                        return (
                          <FormControlLabel
                            key={option.value}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "14px",
                              },
                            }}
                            checked={submittalOption.attachment_option === option.value}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                              setSubmittalOption({
                                ...submittalOption,
                                attachment_option: evt.target.value as AttachmentOption,
                                workflow_responses: [],
                              })
                            }}
                            color="primary"
                            control={<ExtractsRadio disableFocusRipple={true} disableRipple={true} />}
                            value={option.value}
                            label={option.label}
                          />
                        );
                      }
                    })
                  }
                </Box>
              )}
            </Box>
          );
        })
      }
    </Box>
  )
};

export default DrawingOptions;