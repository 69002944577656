import {
  Grid,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButtonProps,
  styled,
} from "@mui/material";
import React from "react";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { setMonth, getMonth, setYear, getYear } from "date-fns";

interface HeaderProps {
  date: Date;
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
  onSelectOpen: () => void;
  onSelectClosed: () => void;
}

const CustomIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    padding: 10,
    "&:hover": {
      background: "none",
    },
  }
});

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const generateYears = (relativeTo: Date, count: number) => {
  const half = Math.floor(count / 2);
  return Array(count)
    .fill(0)
    .map((y, i) => relativeTo.getFullYear() - half + i); // TODO: make part of the state
};

const Header: React.FunctionComponent<HeaderProps> = ({
  date,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
  onSelectOpen,
  onSelectClosed
}) => {
  const handleMonthChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    setDate(setMonth(date, parseInt(event.target.value as string)));
  };

  const handleYearChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    setDate(setYear(date, parseInt(event.target.value as string)));
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item style={{ padding: 5 }}>
        <CustomIconButton
          style={{ cursor: 'pointer'}}
          disabled={prevDisabled}
          onClick={onClickPrevious}
        >
          <ChevronLeft color={prevDisabled ? "disabled" : "action"} />
        </CustomIconButton>
      </Grid>
      <Grid item>
        <Select
          variant="standard"
          value={getMonth(date)}
          onOpen={onSelectOpen}
          onChange={handleMonthChange}
          MenuProps={{
            TransitionProps: {
              onExited: () => {
                onSelectClosed();
              }
            },
            PaperProps: {
              sx: {
                maxHeight: '400px'
              }
            }
          }}
        >
          {MONTHS.map((month, idx) => (
            <MenuItem key={`month-menu-item-${idx}`} value={idx}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item>
        <Select
          variant="standard"
          value={getYear(date)}
          onChange={handleYearChange}
          onOpen={onSelectOpen}
          MenuProps={{
            TransitionProps: {
              onExited: () => {
                onSelectClosed();
              }
            },
            PaperProps: {
              sx: {
                maxHeight: '400px'
              }
            }
          }}
        >
          {generateYears(date, 30).map((year, index) => (
            <MenuItem key={`year-menu-item-${index}`} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item style={{ padding: 5 }}>
        <CustomIconButton
          style={{ cursor: 'pointer'}}
          disabled={nextDisabled}
          onClick={onClickNext}
        >
          <ChevronRight color={nextDisabled ? "disabled" : "action"} />
        </CustomIconButton>
      </Grid>
    </Grid>
  );
};

export default Header;
