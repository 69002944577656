import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { CloseoutLogColumnDefinition, VendorPortalDataGridView } from "../../../types";
import { GridFilterItem } from "@mui/x-data-grid-premium";

export interface VendorPortalDataGridViewUpdateData {
  updateVendorPortalDataGridView: {
    success: boolean;
    vendorPortalDataGridView: VendorPortalDataGridView;
  }
}

interface VendorPortalDataGridViewUpdateAttributes {
  columnDefinitions: CloseoutLogColumnDefinition[];
  sortModel: { field: string; sort: string; }[];
  groupings: string[];
  groupingColumnDefinition: CloseoutLogColumnDefinition;
  filters: GridFilterItem[];
}

export interface VendorPortalDataGridViewUpdateVariables {
  vendorId: string;
  id: number;
  attributes: VendorPortalDataGridViewUpdateAttributes;
}

export const useUpdateVendorPortalDataGridView = createMutationHook<
VendorPortalDataGridViewUpdateData,
VendorPortalDataGridViewUpdateVariables
>(gql`
  mutation UpdateVendorPortalDataGridView(
    $vendorId: ID!,
    $id: ID!,
    $attributes: VendorPortalDataGridViewUpdateAttributes!
  ) {
    updateVendorPortalDataGridView(
      input: { vendorId: $vendorId, id: $id, attributes: $attributes }
    ) {
      success
      vendorPortalDataGridView {
        id
        columnDefinitions
        sortModel
        groupingColumnDefinition
        groupings
        filters
      }
    }
  }
`);
