import React from 'react';
import { Box, IconButton, SxProps, Theme } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { Colors, EditableHeaderInput } from '../../styles';
import { Typography } from './Typography';

interface IBodyCardHeaderBarProps {
  title: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClose?: () => void;
  justifyContent?: string,
  gap?: string,
  onEditTitle?: (value: string) => void;
  sx?: SxProps<Theme>
}

export const BodyCardHeaderBar = ({
  title,
  children,
  disabled,
  onClose,
  justifyContent,
  gap,
  onEditTitle,
  sx,
}: IBodyCardHeaderBarProps): JSX.Element => {
  return (
    <Box
      display="flex"
      gap={gap ? gap : '12px'}
      padding="0px 28px"
      alignItems="center"
      height="80px"
      minHeight={'80px'}
      sx={sx}
    >
      {onEditTitle ? (
        <EditableHeaderInput
          onKeyUp={(evt) => {
            if (evt.key === 'Enter') {
              evt.currentTarget.blur();
            }
          }}
          defaultValue={title}
          onBlur={(evt) => {
            if (evt.currentTarget.value === '') {
              evt.currentTarget.value = title;
              onEditTitle(title);
            } else {
              onEditTitle(evt.currentTarget.value);
            }
          }}
          sx={{
            '&:hover': {
              background: Colors.lightestGray,
            },
            '&.Mui-focused': {
              borderBottom: `2px solid ${Colors.newAccentBlue}`,
              background: Colors.lightestGray,
            },
          }}
        />
      ) : (
        <Typography
          typestyle="xl"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textWrap: 'nowrap',
          }}
        >
          {title}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: justifyContent ? justifyContent : 'end',
          flex: '1 1 0',
          gap: '8px',
        }}
      >
        {children}
      </Box>
      {onClose && (
        <IconButton disabled={disabled} onClick={() => onClose()}>
          <Close sx={{ fontSize: '20px' }} />
        </IconButton>
      )}
    </Box>
  );
};
