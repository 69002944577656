import { SelectedItem } from "../graphql/mutations/UpdatePackage";
import { Submittal } from "../types/submittal";
import { sortProcoreItemsByFormattedTitle } from "../utils/utils";
import { ProcoreToolFilter } from "./NewPackageContext";

export type ImportSubmittalState = {
  procoreSubmittals: Submittal[];
  filters: ProcoreToolFilter[];
  defaultFilters: ProcoreToolFilter[];
  selectedFilters: ProcoreToolFilter[];
  searchValue: string;
  filterOpenState: Record<string, boolean>,
  selectedItems: Record<string, SelectedItem>;
  loading: boolean;
  loadingMore: boolean;
  failedToFetchMore: boolean;
  filteredSubmittalServerIds: number[];
};

export const initialState: ImportSubmittalState = {
  procoreSubmittals: [],
  filters: [],
  selectedFilters: [],
  searchValue: "",
  filterOpenState: {},
  selectedItems: {},
  loading: true,
  loadingMore: false,
  failedToFetchMore: false,
  filteredSubmittalServerIds: [],
  defaultFilters: []
};

export type Action =
  | { type: 'SET_PROCORE_SUBMITTALS'; value: { procoreSubmittals: Submittal[], filteredSubmittalServerIds: number[] }; }
  | { type: 'APPEND_PROCORE_SUBMITTALS'; value: Submittal[]; }
  | { type: 'SET_LOADING'; value: boolean; }
  | { type: 'SET_LOADING_MORE'; value: boolean; }
  | { type: 'SET_FAILED_TO_FETCH_MORE'; value: boolean; }
  | { type: 'SET_SEARCH_VALUE'; value: string; }
  | { type: 'SET_FILTER_OPEN_STATE'; value: Record<string, boolean>; }
  | { type: 'SET_FILTERS'; value: { filters: ProcoreToolFilter[], selectedFilters: ProcoreToolFilter[] }; }
  | { type: 'SET_SELECTED_FILTERS'; value: ProcoreToolFilter[]; }
  | { type: 'SET_SELECTED_ITEMS'; value: Record<string, SelectedItem>; }

export const rootReducer: React.Reducer<ImportSubmittalState, Action> = (
  state,
  action
) => {
  switch (action.type) {
    case "SET_PROCORE_SUBMITTALS": {
      return {
        ...state,
        procoreSubmittals: action.value.procoreSubmittals,
        filteredSubmittalServerIds: action.value.filteredSubmittalServerIds
      };
    }

    case 'APPEND_PROCORE_SUBMITTALS': {
      return {
        ...state,
        procoreSubmittals: sortProcoreItemsByFormattedTitle([...state.procoreSubmittals, ...action.value])
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.value
      };
    }

    case 'SET_LOADING_MORE': {
      return {
        ...state,
        loadingMore: action.value
      };
    }

    case 'SET_FAILED_TO_FETCH_MORE': {
      return {
        ...state,
        failedToFetchMore: action.value
      };
    }

    case 'SET_SEARCH_VALUE': {
      return {
        ...state,
        searchValue: action.value
      };
    }

    case 'SET_FILTER_OPEN_STATE': {
      return {
        ...state,
        filterOpenState: action.value
      };
    }

    case 'SET_FILTERS': {
      return {
        ...state,
        filters: action.value.filters,
        defaultFilters: action.value.selectedFilters,
        selectedFilters: action.value.selectedFilters
      };
    }

    case 'SET_SELECTED_FILTERS': {
      return {
        ...state,
        selectedFilters: action.value
      };
    }

    case 'SET_SELECTED_ITEMS': {
      return {
        ...state,
        selectedItems: action.value
      };
    }

    default:
      return state;
  }
};

export type ImportSubmittalsContextState = {
  state: ImportSubmittalState;
  dispatch: (action: Action) => void;
};
