import React from "react";
import { useParams } from "react-router";
import { useUpdateProjectLicense } from "../graphql/mutations/UpdateProjectLicense";
import Loading from "./shared/Loading";
import { Box } from '@mui/material';

const PaymentConfirmation = (): JSX.Element => {
  const { accountId, sessionId } =
    useParams<{
      accountId: string;
      sessionId: string;
    }>();

  const [confirmationLoading, setConfirmationLoading] = React.useState(true);

  const [updateLicense, { loading: updateLicenseLoading, data: updateLicenseData }] =
    useUpdateProjectLicense({
      accountId: accountId,
      sessionId: sessionId
    });

  React.useEffect(() => {
    setTimeout(() => {
      setConfirmationLoading(false);
    }, 3000);
    updateLicense();
  }, []);

  React.useEffect(() => {
    if (
      updateLicenseLoading === undefined ||
      updateLicenseLoading === true ||
      !updateLicenseData ||
      confirmationLoading
    ) {
      return;
    }

    if (updateLicenseData.updateProjectLicense) {
      window.location.replace(updateLicenseData.updateProjectLicense.redirectUrl);
    }
  }, [updateLicenseLoading]);

  React.useEffect(() => {
    if (confirmationLoading) { return; }

    if (updateLicenseData && updateLicenseData.updateProjectLicense) {
      window.location.replace(updateLicenseData.updateProjectLicense.redirectUrl);
    }
  }, [confirmationLoading]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width={1}
      height={1}
    >
      <Loading loadingLabel="Confirming payment. Page will redirect automatically" />
    </Box>
  );
};

export default PaymentConfirmation;
