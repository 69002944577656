import {
  TextField,
  Link,
  Checkbox,
  Dialog,
  DialogActions,
  Divider,
  Typography,
  Container,
  styled,
  DialogActionsProps,
  DialogProps,
  CheckboxProps,
  LinkProps,
  TypographyProps,
  createTheme,
  ContainerProps,
  TextFieldProps,
  Button,
  ButtonProps,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
  TableCell,
  TableCellProps,
  InputBase,
  InputBaseProps,
  BoxProps,
  Box,
  Stack,
  StackProps,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import { DataGridPremium, DataGridPremiumProps } from '@mui/x-data-grid-premium';
import { getTypeStyles } from './components/shared/Typography';

export const FieldContainer = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  };
});

export const CustomFormLabel = styled(FormLabel)(() => {
  return {
    ...getTypeStyles('s'),
    color: Colors.darkerGray,
  };
});


export const FieldLabel = styled(FormControlLabel)(() => {
  return {
    '& .MuiSvgIcon-root': {
      fontSize: '1.175rem',
    },
    '& .MuiTypography-root': {
      fontSize: getTypeStyles('m').fontSize,
    },
    width: 'max-content',
  };
});

export const StyledDataGridPremium = styled(DataGridPremium)<DataGridPremiumProps>(() => {
  return {
    fontSize: '0.8125rem',
    lineHeight: '0.952rem',
    fontWeight: '400',
    marginX: '28px',
    border: `1px solid ${Colors.mediumLightGray}`,
    '&.MuiDataGrid-root': {
      borderRadius: '8px',
      flex: '1 1 1px',
      overflow: 'hidden',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      backgroundColor: 'transparent !important',
    },
    // Found here: https://stackoverflow.com/a/73283092
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: Colors.lightestGray3,
    },
    '& .MuiDataGrid-columnHeader': {
      color: Colors.darkGray,
      backgroundColor: Colors.lightestGray3,
      padding: '0px 16px',
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      padding: '0px 16px',
      borderBottom: `1px solid ${Colors.mediumLightGray}`,
      '&:focus': {
        backgroundColor: `${Colors.blue2}`,
        outline: 'none',
      },
    },
    '& .MuiDataGrid-withBorderColor': {
      borderColor: Colors.mediumLightGray,
    },
    '& .MuiDataGrid-row.Mui-hovered': {
      backgroundColor: Colors.lightestGray2,
      '&.Mui-selected': {
        backgroundColor: Colors.blue1,
      },
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: Colors.blue1,
    },
    '&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: Colors.blue1,
    },
    //Needed because one initial page load default row hover style was visible for a moment
    '& .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: Colors.lightestGray2,
      },
    },
    //Hides the filter icon (workaround for firefox)
    '& .MuiDataGrid-filterIcon': {
      display: 'none',
    },
    //Hides the filter icon button container
    '& .MuiDataGrid-iconButtonContainer:has(.MuiDataGrid-filterIcon)': {
      display: 'none',
    },
    '& .MuiDataGrid-pinnedColumns': {
      backgroundColor: 'white',
    },
    '& .MuiDataGrid-pinnedColumnHeaders': {
      backgroundColor: Colors.lightestGray3,
      border: 'none',
    }
  };
});
export const TableHeaderCell = styled(TableCell)<TableCellProps>(() => {
  return {
    paddingTop: '12px',
    paddingBottom: '12px',
    borderBottom: `1px solid ${Colors.mediumLightGray}`,
    color: `${Colors.darkerGray}`,
    '&.MuiTableCell-stickyHeader': {
      backgroundColor: 'white',
    },
  };
});
export const EditableHeaderInput = styled(InputBase)<InputBaseProps>(() => {
  return {
    borderBottom: '2px solid transparent',
    fontSize: '22px',
    fontWeight: 700,
    '&:hover': {
      background: '#D8E0E6',
    },
    '& .MuiInputBase-input': {
      padding: '7px 0px 5px 0px',
    },
    '&.Mui-focused': {
      borderBottom: `2px solid ${Colors.newAccentBlue}`,
      background: '#D8E0E6',
    },
  };
});

export const StyledFormControlLabel = styled(FormControlLabel)(() => {
  return {
    '& .MuiSvgIcon-root': {
      fontSize: '1.175rem',
    },
    '& .MuiTypography-root': {
      fontSize: getTypeStyles('m').fontSize,
      userSelect: 'none',
    },
    width: 'max-content',
    margin: '0px',
  };
});

export const FieldLabelGroup = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  };
});

export const TableRowCell = styled(TableCell)<TableCellProps>(() => {
  return {
    borderBottom: `1px solid ${Colors.mediumLightGray}`,
    color: Colors.darkerGray,
  };
});
export const RowActionButton = styled(Button)({
  fontSize: '0.7rem',
  '&.MuiButtonBase-root': {
    height: '28px',
  },
  '& .MuiButton-startIcon': {
    marginRight: '4px',
  },
});
export const SecondarySettingsButton = styled(Button)<ButtonProps>(() => {
  return {
    minWidth: "unset",
    height: "30px",
    "&:hover": {
      background: "transparent",
    },
    '&.MuiButton-outlinedSizeSmall': {
      padding: "5px",
    },
    '& .MuiButton-startIcon': {
      margin: 0,
    }
  };
});

export const FillStack = styled(Stack)<StackProps>(()=>{
  return {
    flex: '1 1 0',
    alignSelf: 'stretch',
  }
})

export const OutlinedCard = styled(Box)<BoxProps>(() => {
  return {
    display: 'flex',
    border: `1px solid ${Colors.mediumLightGray}`,
    borderRadius: '8px',
  };
});



export const LinkWithDisabled = styled(Link)<LinkProps>(() => {
  return {
    cursor: 'pointer',
    color: '#0082E6',
    textDecoration: 'none',
    "&[disabled]": {
      color: "grey",
      cursor: "auto",
      "&:hover": {
        textDecoration: "none"
      }
    }
  }
});
export const Colors = {
  darkerGray: '#171A1C',
  darkGray: '#333E45',
  darkishGray: '#596A75',
  mediumDarkerGray: '#8495A1',
  mediumDarkGray: '#A0B0BA',
  reallyBlueGray: '#CAD4DB',
  mediumGray: '#D5DFE6',
  mediumLightGray: '#E4EBF0',
  lightishGray: '#EDF2F5',
  lightestGray: '#F5F8FA',
  lightestGray2: '#F7FAFC',
  lightestGray3: '#FAFBFC',
  lightGray: '#F2F5F7',
  lightBlue: '#EBF4FC',
  lightAccentBlue: '#B5DBFF',
  lightestAccentBlue: '#E5F4FF',
  newAccentBlue: '#2278CF',
  mediumBlue: '#0B2338',
  green: '#13AD84',
  lightGreen: '#DFF7E9',
  mediumGreen: '#366B4B',
  darkGreen: '#1C3827',
  white: '#FFFFFF',
  blue6: '#62A1D1',
  blue5: '#89BFE8',
  blue4: '#B5D9F5',
  blue3point5: '#C3E1F7',
  blue3: '#D2E9FA',
  blue2: '#E1F1FC',
  blue1: '#EBF7FF',
  blue0: '#F5FBFF',
  blue7point1: '#3D87BF',
  accentBlue: '#0082E5',
  lightRed: '#FFE5E9',
  darkerRed: '#6B0000',
  red: '#CC4B4C',
  overdueRed: '#E83C56',
  warningRed: '#E83841',
  warningOrange: '#FF6F00',
  yellow: '#F7D839',
};
export const theme = createTheme({
  palette: {
    primary: { main: Colors.darkerGray },
    info: {main: Colors.newAccentBlue},
    //   secondary: { main: '#E4EBF0' },
    //   info: { main: '#007BFF' },
    background: {
      default: Colors.mediumLightGray,
    },
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: Colors.darkGray,
        }
      }
    },
    MuiAlert : {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: Colors.lightGreen,
          borderLeft: `7px solid ${Colors.mediumGreen}`,
          color: Colors.mediumGreen,
          '& .MuiAlert-icon': {
            color: Colors.mediumGreen,
          }
        },
        standardError: {
          borderLeft: `7px solid ${Colors.red}`,
          color: Colors.red,
          '& .MuiAlert-icon': {
            color: Colors.red,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          border: '1px solid transparent',
          '&:hover': {
            backgroundColor: Colors.lightGray,
            border: `1px solid ${Colors.white}`,
            '& .MuiSvgIcon-root': {
              color: Colors.darkerGray,
            },
          },
          '& .MuiSvgIcon-root': {
            color: Colors.darkGray,
          },
          '&:disabled': {
            '& .MuiSvgIcon-root': {
              color: Colors.reallyBlueGray,
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          transition: 'all 0.25s cubic-bezier(0.4, 0, 0.2, 1)',
          "& .MuiOutlinedInput-input": {
            color: Colors.darkerGray,
            transition: 'all 0.25s cubic-bezier(0.4, 0, 0.2, 1)',
          },
          "& .MuiInputLabel-root": {
            color: Colors.darkerGray,
            transition: 'all 0.25s cubic-bezier(0.4, 0, 0.2, 1)',
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.mediumGray,
            transition: 'all 0.25s cubic-bezier(0.4, 0, 0.2, 1)',
          },
          "&:hover .MuiInputLabel-root": {
            color: Colors.darkerGray
          },
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.mediumDarkGray
          },
          "& .MuiInputLabel-root.Mui-focused": {
            backgroundColor: Colors.white,
            padding: '0px 12px 0px 12px',
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${Colors.mediumGray}`,
            boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
          },
          "& .MuiOutlinedInput-input.Mui-disabled": {
            color: Colors.mediumDarkGray,
            cursor: 'not-allowed'
          },
          "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.mediumLightGray,
          },
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
export const AppContainer = styled(Container)<ContainerProps>(() => {
 return {
   height: '100%',
   "@media (min-width: 320px) and (max-width: 600px)": {
     paddingLeft: 70,
     paddingRight: 70
   },
   "@media (min-width: 600px) and (max-width: 960px)": {
     paddingLeft: 70,
     paddingRight: 70
   }
 }
});
export const NewPackageAppContainer = styled(Container)<ContainerProps>(() => {
  return {
    paddingLeft: 70,
    paddingRight: 70,
    height: '100%',
    "@media (min-width: 320px) and (max-width: 600px)": {
      paddingLeft: 70,
      paddingRight: 70
    },
    "@media (min-width: 600px) and (max-width: 960px)": {
      paddingLeft: 70,
      paddingRight: 70
    }
  }
 });
export const GrayDivider = styled(Divider)({
  color: Colors.mediumLightGray,
  borderColor: Colors.mediumLightGray,
});
export const VertGrayDivider = styled(Divider)({
  color: Colors.mediumLightGray,
  borderColor: Colors.mediumLightGray,
  margin: 'auto',
  height: '30px'
});
 export const ExtractCloseoutLogContainer = styled(Container)<ContainerProps>(() => {
  return {
    paddingLeft: 70,
    paddingRight: 70,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    "@media (min-width: 320px) and (max-width: 600px)": {
      paddingLeft: 70,
      paddingRight: 70
    },
    "@media (min-width: 600px) and (max-width: 960px)": {
      paddingLeft: 70,
      paddingRight: 70
    }
  }
 });
 export const StyledVerticalTab = styled(Tab)<TabProps>(() => {
  return {
    '&.MuiTab-root': {
      '&.Mui-selected': {
        fontWeight: '500',
      },
      minHeight: '24px',
      padding: '4px 18px',
      alignItems: 'start',
      fontWeight: '400',
      fontSize: '0.8125rem',
      color: Colors.darkerGray,
      '&.MuiButtonBase-root': {
        whiteSpace: 'nowrap'
      }
    },
  };
});
export const StyledVerticalTabs = styled(Tabs)<TabsProps>(()=>{
  return {
    '& .MuiTabs-indicator':{
      height: '100px',
      left: '0px',
      width: '6px',
    },
    '& .MuiTabs-flexContainerVertical': {
      gap: '8px'
    }
  }
});
export const HeaderViewControllerTab = styled(Tab)<TabProps>(() => {
  return {
    '&.MuiTab-root': {
      '&.Mui-selected': {
        color: Colors.darkerGray,
      },
      '&:hover': {
        color: Colors.darkerGray,
      },
      minHeight: '32px',
      minWidth: '40px',
      padding: '8px 12px',
      height: '100%',
      alignItems: 'center',
      fontWeight: '400',
      fontSize: '0.8125rem',
      borderRadius: '40px',
      color: Colors.darkishGray,
      '&.MuiButtonBase-root': {
        whiteSpace: 'nowrap',
      },
      zIndex: '1',
    },
  };
});
export const HeaderViewController = styled(Tabs)<TabsProps>(()=>{
  return {
    '& .MuiTabs-indicator':{
      height: '100%',
      left: '0px',
      top: '0px',
      width: '6px',
      backgroundColor: Colors.lightestGray,
      borderRadius: '40px',
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08) inset'
    },
    '& .MuiTabs-flexContainer': {
      height: '100%',
    },
    '& .MuiTabs-flexContainerHorizontal': {
      gap: '0px',
    },
    '&.MuiTabs-root': {
      minHeight: '32px',
    },
  }
});
export const DataGridCheckbox = styled(Checkbox)<CheckboxProps>(() => {
  return {
    padding: 0,
    margin: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked': {
      color: Colors.newAccentBlue,
    },
    '&.MuiCheckbox-indeterminate': {
      color: Colors.newAccentBlue
    },
    '& .MuiSvgIcon-root': {
      height: '16px',
      width: '16px',
      maxHeight: '16px',
      maxWidth: '16px',
    }
  };
});
export const ExtractsCheckbox = styled(Checkbox)<CheckboxProps>(() => {
  return {
    color: Colors.mediumDarkGray,
    '&:hover': {
      backgroundColor: 'transparent',
      color: Colors.darkishGray,
      '&.Mui-checked': {
        color: Colors.blue6,
      },
    },
    '&.Mui-checked': {
      color: Colors.newAccentBlue,
    },
    '&.MuiCheckbox-indeterminate': {
      color: Colors.newAccentBlue
    }
  };
});
export const MyDialog = styled(Dialog)<DialogProps>(() => {
  return {
    "& .MuiDialog-paper": {
      margin: '20px',
      padding: 0
    }
  };
});
export const DialogDivider = styled(Divider)(() => {
  return {
    borderColor: Colors.mediumLightGray,
  }
});
export const MyDialogActions = styled(DialogActions)<DialogActionsProps>(() => {
  return {
    margin: '20px',
    padding: 0
  }
});
export const PrimaryTypography = styled(Typography)<TypographyProps>(() => {
  return {
    color: Colors.darkerGray
  };
});
export const SecondaryTypography = styled(Typography)<TypographyProps>(() => {
  return {
    color: Colors.darkishGray
  };
});
export const OutlineWithLabelTextField = styled(TextField)<TextFieldProps>(() => {
  return {
    padding: 0,
    margin: 0,
    '& label.Mui-focused': {
      color: Colors.darkGray,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: Colors.darkerGray,
    },
    '& .MuiOutlinedInput-input': {
      padding: "15px 14px",
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
      '& .MuiButtonBase-root': {
        padding: 0,
        border: "none",
        '&:hover': {
          border: "none"
        }
      }
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Source: https://stackoverflow.com/a/58985002
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(14px, 16px) scale(1);"
    },
    '& .MuiOutlinedInput-root': {
      color: Colors.darkGray,
      fontSize: 15,
      fontWeight: 500,
      '& fieldset': {
        borderColor: Colors.mediumGray,
      },
      '&:hover fieldset': {
        borderColor: Colors.mediumGray,
      },
      '&.Mui-focused fieldset': {
        borderColor: '#89BFE8',
      },
    }
  };
});