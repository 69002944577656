import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCellProps,
  TableCell,
  TableBody,
  Box,
  styled,
} from '@mui/material';
import { PackageHistoryContext } from '../../contexts/PackageContextProvider';
import { CloseoutLog } from '../../types';
import idx from 'idx';
import Loading from '../shared/Loading';
import CloseoutLogListEmptyState from './CloseoutLogListEmptyState';
import { Colors } from '../../styles';
import { CloseoutLogContext } from '../../contexts/CloseoutLogContextProvider';
import useNavigationHook from '../../hooks/UseNavigationHook';
import { AccountProjectContext } from '../../contexts/AccountProjectContextProvider';
import { formatDate } from '../../utils/utils';
import { CloseoutLogListRow } from './CloseoutLogListRow';
import { useDeleteCloseoutLog } from '../../graphql/mutations/closeout/DeleteCloseoutLog';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import { SingleTextFieldModal } from '../shared/SingleTextFieldModal';
import { useUpdateCloseoutLog } from '../../graphql/mutations/closeout/UpdateCloseoutLog';
import { useDuplicateCloseoutLog } from '../../graphql/mutations/closeout/DuplicateCloseoutLog';

const TableHeaderCell = styled(TableCell)<TableCellProps>(() => {
  return {
    paddingTop: '12px',
    paddingBottom: '12px',
    borderBottom: `1px solid ${Colors.mediumLightGray}`,
    color: `${Colors.darkerGray}`,
    '&.MuiTableCell-stickyHeader': {
      backgroundColor: 'white',
    },
  };
});


export const CloseoutLogList = (props: {
  accountId: string;
  procoreProjectServerId: number;
}) => {
  const { state, dispatch } = React.useContext(PackageHistoryContext);
  const { dispatch: closeoutLogDispatch } = React.useContext(CloseoutLogContext);
  const { state: accountProjectState } = React.useContext(AccountProjectContext);
  const navigateTo = useNavigationHook();
  const { accountId, procoreProjectServerId } = props;
  const [deleteCloseoutLog, { loading: loadingDeleteCloseoutLog }] = useDeleteCloseoutLog({
    accountId: accountId,
    procoreProjectServerId: procoreProjectServerId,
    closeoutLogId: -1
  });

  const [deleteCloseoutLogModal, setDeleteCloseoutLogModal] = React.useState<{isOpen: boolean, log: CloseoutLog}>({isOpen: false, log: null});
  const [renameCloseoutLogModal, setRenameCloseoutLogModal] = React.useState<{isOpen: boolean, id: number, name: string}>({isOpen: false, id: undefined, name: ''});

  const [ updateCloseoutLog, {loading: loadingUpdateCloseoutLog} ]  = useUpdateCloseoutLog(
    {
      accountId: accountProjectState.accountId,
      procoreProjectServerId: accountProjectState.procoreProjectServerId,
      closeoutLogId: -1,
      attributes: {
        name: '',
      }
    }
  );

  const [duplicateCloseoutLog, { loading: loadingDuplicateCloseoutLog }] = useDuplicateCloseoutLog({
    accountId: accountId,
    procoreProjectServerId: procoreProjectServerId,
    id: -1,
  });

  const handleDeleteCloseoutLog = () => {
    {
      deleteCloseoutLog({
        variables: {
          accountId: accountId,
          procoreProjectServerId: procoreProjectServerId,
          closeoutLogId: deleteCloseoutLogModal.log.id,
        }
      }).then(({ data }) => {
        //remove closeout log from state
        dispatch({type: 'DELETE_CLOSEOUT_PACKAGE', closeoutLogId: deleteCloseoutLogModal.log.id})
        setDeleteCloseoutLogModal({isOpen: false, log: null})
      }).catch((err) => {
        console.log('err', err);
      });
    }
  }
  const handleRenameCloseoutLog = (text: string, id: number) => {
    updateCloseoutLog({
      variables: {
        accountId: accountProjectState.accountId,
        procoreProjectServerId: accountProjectState.procoreProjectServerId,
        closeoutLogId: id,
        attributes: {
          name: text,
        }
      }
    }).then( ({data}) => {
        dispatch({
          type: 'RENAME_CLOSEOUT_LOG',
          value: {
            name: data.updateCloseoutLog.closeoutLog.name,
            id: data.updateCloseoutLog.closeoutLog.id,
          }
        });
        setRenameCloseoutLogModal({isOpen: false, id: undefined, name: ''});
    }).catch((err) => {
      console.log('err', err);
    })
  }

  const closeoutLogs = (idx(state.data, (data) => data.closeoutLogs) || []) as CloseoutLog[];

  React.useEffect(() => {
    if (state.loading) { return; }

    if (accountProjectState.closeoutLogIdToSelect && closeoutLogs.length > 0) {
      const log = closeoutLogs.find((log) => log.id == accountProjectState.closeoutLogIdToSelect);

      if (log) {
        closeoutLogDispatch({
          type: 'SET_CLOSEOUT_LOG',
          value: log,
        });

        navigateTo(
          `/teams/${accountId}/projects/${procoreProjectServerId}/closeout_logs/${log.id}`
        );
      }
    }
  }, [state.loading, closeoutLogs]);

  return state.loading ? (
    <Loading loadingLabel={'Loading packages...'} />
  ) : (
    <>
      <ConfirmationModal
        open={deleteCloseoutLogModal.isOpen}
        headerText='Confirm Deletion'
        bodyText={`Are you sure you want to delete ${deleteCloseoutLogModal.log?.name}?`}
        submitText="Delete"
        onSubmit={handleDeleteCloseoutLog}
        onClose={ ()=> setDeleteCloseoutLogModal({isOpen: false, log: null})}
        submitLoading={loadingDeleteCloseoutLog}
      />
      <SingleTextFieldModal
        headerText='Rename'
        defaultText={renameCloseoutLogModal.name}
        submitText='Save'
        onClose={() => setRenameCloseoutLogModal({isOpen: false, name: '', id: undefined})}
        open={renameCloseoutLogModal.isOpen}
        submitLoading= {loadingUpdateCloseoutLog}
        onClickSubmit={ (text: string) => handleRenameCloseoutLog(text, renameCloseoutLogModal.id)}
      />
      <Box
        display="flex"
        width={1}
        height={1}
        flexDirection="column"
        borderRadius="4px"
        style={{ background: 'white' }}
      >
        <TableContainer
          style={{
            background: 'white',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            minHeight: '100px',
            flex: '1 1 1px',
          }}
        >
          <Table stickyHeader aria-label="simple table" size="medium">
            <TableHead>
              <TableRow>
                <TableHeaderCell component="th">Name</TableHeaderCell>
                <TableHeaderCell component="th" align="left">
                  Requirements
                </TableHeaderCell>
                <TableHeaderCell component="th" align="left">
                  Percentage complete
                </TableHeaderCell>
                <TableHeaderCell component="th" align="left">
                  Date created
                </TableHeaderCell>
                <TableHeaderCell component="th" align="left"/>
              </TableRow>
            </TableHead>
            <TableBody>
              {closeoutLogs.map((log) => (
                <CloseoutLogListRow
                  key={log.id}
                  onClick={ () => {
                    closeoutLogDispatch({
                      type: 'SET_CLOSEOUT_LOG',
                      value: log
                    });

                    navigateTo(
                      `/teams/${accountId}/projects/${procoreProjectServerId}/closeout_logs/${log.id}`
                    );
                  }}
                  name={log.name}
                  count={log.logRequirementsCount.toString()}
                  disabled={loadingDuplicateCloseoutLog || loadingDeleteCloseoutLog || loadingUpdateCloseoutLog}
                  percentComplete={`${log.logRequirementsCount > 0 ? Math.round((log.logRequirementsCompleteCount / log.logRequirementsCount) * 100) : 0}%`}
                  date={formatDate(log.createdAt, accountProjectState.locale, accountProjectState.timeZone)}
                  onClickDelete={ () => setDeleteCloseoutLogModal({isOpen: true, log: log})}
                  onClickRename={ () => setRenameCloseoutLogModal({isOpen: true, name: log.name, id: log.id}) }
                  onClickDuplicate={() => {
                    duplicateCloseoutLog({
                      variables: {
                        accountId: accountId,
                        procoreProjectServerId: procoreProjectServerId,
                        id: log.id,
                      }
                    }).then(( { data }) => {
                      if (data.duplicateCloseoutLog.success) {
                        dispatch({
                          type: 'APPEND_CLOSEOUT_PACKAGE',
                          value: data.duplicateCloseoutLog.closeoutLog,
                        });
                      }
                    })
                   }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {closeoutLogs.length === 0 && <CloseoutLogListEmptyState />}
      </Box>
    </>
  );
};
