import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface LogRequirementDeleteData {
  deleteLogRequirements: {
    success: boolean;
  }
}

export interface LogRequirementDeleteVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logRequirementIds: number[];
}

export const useDeleteLogRequirements = createMutationHook<
  LogRequirementDeleteData,
  LogRequirementDeleteVariables
>(gql`
  mutation DeleteLogRequirements(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $logRequirementIds: [ID!]!
  ) {
    deleteLogRequirements(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, logRequirementIds: $logRequirementIds }
    ) {
      success
    }
  }
`);
