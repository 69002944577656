import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { TemplateCloseoutLog } from "../../../types";

export interface TemplateCloseoutLogCreate {
  templateCloseoutLog: TemplateCloseoutLog | null;
  success: boolean;
}

export interface TemplateCloseoutLogCreateData {
  createTemplateCloseoutLog: TemplateCloseoutLogCreate | null;
}

export interface TemplateCloseoutLogCreateAttributes {
  name: string;
  accountId: string;
}

export interface TemplateCloseoutLogCreateVariables {
  templateCloseoutLogAttributes: TemplateCloseoutLogCreateAttributes;
}

export const useCreateTemplateCloseoutLog = createMutationHook<
  TemplateCloseoutLogCreateData,
  TemplateCloseoutLogCreateVariables
>(gql`
  mutation CreateTemplateCloseoutLog(
    $templateCloseoutLogAttributes: TemplateCloseoutLogCreateAttributes!
  ) {
    createTemplateCloseoutLog(
      input: { templateCloseoutLogAttributes: $templateCloseoutLogAttributes }
    ) {
      templateCloseoutLog {
        id
        name
        createdAt
        templateLogRequirementsCount
      }
      success
    }
  }
`);
