import React from "react";
import { VendorPortalContextState, initialState, rootReducer } from "./VendorPortalContext";

const VendorPortalContext = React.createContext<VendorPortalContextState>({
  dispatch: () => undefined,
  state: initialState,
});

const VendorPortalContextProvider = (props: { children: React.ReactNode }): JSX.Element => {
  const [state, dispatch] = React.useReducer(rootReducer, initialState);

  return (
    <VendorPortalContext.Provider value={{state, dispatch}}>
      {props.children}
    </VendorPortalContext.Provider>
  );
}

export { VendorPortalContext, VendorPortalContextProvider };