import { gql } from '@apollo/client';
import { NewPackageExtractOptions } from '../../contexts/NewPackageContext';
import { ExportToTypes, FilterOptionInput, GroupingsInput, Package } from '../../types';
import { CheckState } from '../../utils/multi-tier-grouping';
import { createMutationHook } from '../GraphqlHelpers';

export interface PackageUpdate {
  package: Package | null;
  success: boolean;
}

export interface PackageUpdateData {
  updatePackage: PackageUpdate | null
}

export interface PackageQueryOptionsInput {
  type: string;
  options: Record<string, any>;
}

export interface SelectedItem {
  itemId: number;
  itemType: string;
  state: CheckState;
}

export interface PackageQueryAttributes {
  procoreToolId: number;
  filters: FilterOptionInput[];
  groupings: GroupingsInput[];
  options: PackageQueryOptionsInput;
  selectedItems: SelectedItem[];
}

export interface PackageUpdateAttributes {
  name: string;
  procoreProjectServerId: number;
  packageQueries: PackageQueryAttributes[];
  options: NewPackageExtractOptions;
  exportTo: ExportToTypes;
}

export interface PackageUpdateVariables {
  id: number
  package: PackageUpdateAttributes
}

export const useUpdatePackage = createMutationHook<PackageUpdateData, PackageUpdateVariables>(gql`
mutation UpdatePackage($id: ID!, $package: PackageUpdateAttributes!) {
  updatePackage(input: { id: $id, package: $package }) {
    package {
      id
      name
      status
      createdAt
      exportTo
      downloadExportUrl
    }
    success
  }
}
`);