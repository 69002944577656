import { gql } from "@apollo/client";
import { createQueryHook } from "../../GraphqlHelpers";
import { ExtractAutomationRecord } from "../../../types";

export type ExtractAutomationRecordData = {
  extractAutomationRecords: ExtractAutomationRecord[];
  extractAutomationRecordsCount: {
    count: number
  }
};

export type ExtractAutomationRecordDataVariables = {
  accountId: string;
  extractAutomationId: number;
  limit: number;
  offset: number;
};

const GetExtractAutomationRecordsQuery = gql`
  query GetExtractAutomationRecords(
    $accountId: ID!,
    $extractAutomationId: ID!,
    $limit: Int!,
    $offset: Int!
  ) {
    extractAutomationRecords(accountId: $accountId, extractAutomationId: $extractAutomationId, limit: $limit, offset: $offset) {
      id
      procoreCompanyName
      procoreProjectName
      procoreItemType
      procoreItemTypeTranslation
      procoreServerId
      documentStorageSystem
      path
      filename
      status
      statusTranslation
      createdAt
      updatedAt
      procoreRecordUrl
      destinationUrl
    }

    extractAutomationRecordsCount(accountId: $accountId, extractAutomationId: $extractAutomationId) {
      count
    }
  }
`;

export const useGetExtractAutomationRecords = createQueryHook<
  ExtractAutomationRecordData,
  ExtractAutomationRecordDataVariables
>(GetExtractAutomationRecordsQuery);
