import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface AccountNameUpdate {
  success: boolean;
}

export interface UpdateAccountNameData {
  updateAccountName: AccountNameUpdate
}

export interface UpdateAccountNameVariables {
  accountId: string
  name: string
}

export const useUpdateAccountName = createMutationHook<UpdateAccountNameData, UpdateAccountNameVariables>(gql`
mutation UpdateAccountName($accountId: ID!, $name: String!) {
  updateAccountName(input: { accountId: $accountId, name: $name }) {
    success
  }
}
`);