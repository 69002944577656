import React, { useContext } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from './shared/Typography';
import Box from '@mui/material/Box';
import { LoadingButton as Button } from './shared/Button';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';
import { useDeleteLogFileRequests } from '../graphql/mutations/closeout/DeleteLogFileRequests';

interface IConfirmArchiveFileRequestModalProps {
  fileRequestIds: number[];
  onClose: () => void;
}

export const ConfirmArchiveFileRequestModal = (
  props: IConfirmArchiveFileRequestModalProps & DialogProps
): JSX.Element => {
  const { fileRequestIds, onClose, ...rest } = props;
  const { state, dispatch } = useContext(CloseoutLogContext);
  const { state: accountProjectState } = useContext(AccountProjectContext);

  const [ deleteLogFileRequests, { loading: loadingDeleteLogFileRequests }] = useDeleteLogFileRequests({
    accountId: accountProjectState.accountId,
    procoreProjectServerId: accountProjectState.procoreProjectServerId,
    closeoutLogId: state.id,
    logFileRequestIds: [],
  });

  const handleArchiveFileRequest = () => {
    deleteLogFileRequests({
      variables: {
        accountId: accountProjectState.accountId,
        procoreProjectServerId: accountProjectState.procoreProjectServerId,
        closeoutLogId: state.id,
        logFileRequestIds: fileRequestIds,
      },
    })
      .then(({ data }) => {
        if (data.deleteLogFileRequests.success) {
          dispatch({
            type: 'DELETE_LOG_FILE_REQUESTS',
            value: { logFileRequestIds: fileRequestIds },
          });
        }
        onClose();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <Dialog fullWidth disableScrollLock maxWidth="xs" {...rest}>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
      >
        <Typography typestyle="xl">Confirm Action</Typography>
        <Typography>{`Are you sure you want to delete ${fileRequestIds.length} file request(s) ?`}</Typography>
        <Box display="flex" justifyContent="end" gap="8px">
          <Button
            disabled={loadingDeleteLogFileRequests}
            variant="outlined"
            buttonborderstyle="pill"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            loading={loadingDeleteLogFileRequests}
            variant="contained"
            buttonborderstyle="pill"
            onClick={() => handleArchiveFileRequest()}
          >
            {loadingDeleteLogFileRequests ? '' : 'Delete'}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
