import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface BuyTrialProjectLicenseData {
  buyTrialProjectLicense: {
    success: boolean;
  }
}

export interface BuyTrialProjectLicenseVariables {
  accountId: string;
  procoreProjectServerId: number;
}

export const useBuyTrialProjectLicense = createMutationHook<BuyTrialProjectLicenseData, BuyTrialProjectLicenseVariables>(gql`
mutation BuyTrialProjectLicense($accountId: ID!, $procoreProjectServerId: ID!) {
  buyTrialProjectLicense(input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId }) {
    success
  }
}
`);