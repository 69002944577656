import { Box } from '@mui/material';
import React from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { ToolTabData } from '../../contexts/NewPackageContext';
import ExportOptionToggle from './ExportOptionToggle';
import { DailyLogExtractOptions } from '../../types/daily_log';

const DailyLogOptions = (props: { toolTabIndex: number, toolTabData: ToolTabData }): JSX.Element => {
  const { toolTabIndex, toolTabData } = props;
  const { dispatch } = React.useContext(NewPackageContext);

  const dailyLogExtractOptions = toolTabData.extractOptions as DailyLogExtractOptions;

  return (
    <Box display="flex" flexDirection="column" width={1} height={1} padding={'20px 40px'} style={{ overflowY: 'auto', gap: '24px' }}>
      <h3 style={{ padding: 0, margin: 0 }}>General</h3>
      <ExportOptionToggle
        label={'Include full-sized image files as well as thumbnails'}
        checked={dailyLogExtractOptions.image_attachment_option === 'thumbnails_and_full_size'}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...toolTabData.extractOptions,
                image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only'
              } as DailyLogExtractOptions
            }
          });
        }} />

    <ExportOptionToggle
      label={'Show Created By on log entries'}
      checked={dailyLogExtractOptions.show_created_by_on_log_entries}
      onChange={(event) => {
        dispatch({
          type: 'SET_TOOL_OPTIONS',
          value: {
            toolTabIndex: toolTabIndex,
            option: {
              ...toolTabData.extractOptions,
              show_created_by_on_log_entries: event.target.checked
            } as DailyLogExtractOptions
          }
        });
      }} />
    </Box>
  )
};

export default DailyLogOptions;