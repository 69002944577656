import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface RefreshCompanyAutomationMetadataData {
  refreshCompanyAutomationMetadata: {
    success: boolean;
  }
}

export interface RefreshCompanyAutomationMetadataVariables {
  accountId: string;
  configuredAutomations: boolean;
}

export const useRefreshCompanyAutomationMetadata = createMutationHook<RefreshCompanyAutomationMetadataData, RefreshCompanyAutomationMetadataVariables>(gql`
mutation RefreshCompanyAutomationMetadata($accountId: ID!, $configuredAutomations: Boolean) {
  refreshCompanyAutomationMetadata(input: { accountId: $accountId, configuredAutomations: $configuredAutomations }) {
    success
  }
}
`);