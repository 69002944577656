import React, { useContext } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  styled,
} from '@mui/material';
import {
  AddCircleOutline,
  BoltOutlined,
  TuneOutlined,
} from '@mui/icons-material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import ConditionRow from './ConditionRow';
import { NewAutomationContext } from '../../contexts/EditAutomationContextProvider';
import ModuleHeader from './AutomationModuleHeader';
import {
  Colors,
  FieldLabelGroup,
  OutlinedCard,
  StyledFormControlLabel,
} from '../../styles';
import { Typography } from '../shared/Typography';
import { TabContext, TabPanel } from '@mui/lab';
import { BlueSwitch } from '../package/styles';
import { RfiExtractOptions } from '../../types/rfi';
import {
  SubmittalExtractOptions,
} from '../../types/submittal';
import { ToolAutomation } from '../../contexts/EditAutomationContext';
import { useQuery } from '@apollo/client';
import {
  ExtractAutomationToolFilterData,
  ExtractAutomationToolFilterVariables,
  getExtractAutomationToolFiltersQuery,
} from '../../graphql/queries/automation/GetExtractAutomationToolFilters';
import { LoadingMessage } from '../shared/LoadingMessage';
import { CorrespondenceExtractOptions } from '../../types/correspondence';
import ExportOptionToggle from '../package/ExportOptionToggle';
import { ObservationExtractOptions } from '../../types/observation';
import { InspectionExtractOptions } from '../../types/inspection';
import { AutomationsGroupBy } from '../shared/AutomationsGroupBy';
import { AutomationAddToFolderPathModal } from '../AutomationAddToFolderPathModal';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const StyledTabPanel = styled(TabPanel)(() => {
  return {
    padding: '16px',
  };
});

interface IAutomationPanelProps {
  toolAutomation: ToolAutomation;
}

export default function AutomationPanel({
  toolAutomation,
}: IAutomationPanelProps) {
  const { state, dispatch } = useContext(NewAutomationContext);
  const [isAddToFolderPathModalOpen, setIsAddToFolderPathModalOpen] = React.useState({ isOpen: false, groupingIndex: -1 });

  const { loading: loadingFilterData, data: filterData } = useQuery<
    ExtractAutomationToolFilterData,
    ExtractAutomationToolFilterVariables
  >(getExtractAutomationToolFiltersQuery, {
    variables: {
      accountId: state.accountId,
      procoreToolEngineName: toolAutomation.procoreToolEngineName,
    },
  });

  const selectedFilterMap = React.useMemo(() => {
    return toolAutomation.selectedFilters.reduce((acc, curr) => {
      acc[curr.key] = true;
      return acc;
    }, {});
  }, [toolAutomation.selectedFilters]);

  const newEmptySelectedFilter = () => {
    const unUsedFilters = toolAutomation.filters.filter(
      (filter) => !selectedFilterMap[filter.key]
    );
    if (unUsedFilters.length !== 0) {
      dispatch({
        type: 'APPEND_SELECTED_FILTER',
        value: {
          selectedFilter: {
            ...unUsedFilters[0],
            values: [],
          },
          procoreToolEngineName: toolAutomation.procoreToolEngineName,
        },
      });
    }
  };

  React.useEffect(() => {
    if (loadingFilterData || !filterData) {
      return;
    }
    dispatch({
      type: 'SET_TOOL_DATA',
      value: {
        procoreToolEngineName: toolAutomation.procoreToolEngineName,
        filters: filterData.extractAutomationToolFilters,
        groupings: filterData.extractAutomationToolGroupings,
      },
    });
  }, [loadingFilterData, filterData]);

  const allGroupings = state.sharedGroupings.concat(toolAutomation.groupings || []);

  return (
    <Box
      sx={{
        flex: '1 1 0',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'start',
        gap: '20px',
        alignSelf: 'stretch',
        overflowY: 'auto',
        paddingRight: '24px',
        paddingBottom: '32px',
      }}
    >
      {filterData && !loadingFilterData ? (
        <>
          <Typography typestyle="l">{`${toolAutomation.title} Automation`}</Typography>
          <OutlinedCard
            sx={{
              flexDirection: 'column',
              alignSelf: 'stretch',
            }}
          >
            <ModuleHeader
              title="Triggers"
              subtitle={`When should we extract ${toolAutomation.title}?`}
              icon={<BoltOutlined sx={{ fontSize: '20px' }} />}
            />
            {toolAutomation.selectedFilters.length > 0 && (
              <Stack
                direction={'column'}
                gap="16px"
                padding="24px 24px 0px 24px"
              >
                {toolAutomation.filters.length > 0 && toolAutomation.selectedFilters.map((selectedFilter, i) => {
                  return (
                    <ConditionRow
                      toolAutomation={toolAutomation}
                      selectedFilter={selectedFilter}
                      key={`condition-row-${i}`}
                      index={i}
                    />
                  );
                })}
              </Stack>
            )}
            <Box
              sx={{
                display: 'flex',
                padding: '16px',
              }}
            >
              <Button
                size="small"
                disabled={
                  toolAutomation.filters.length ===
                  toolAutomation.selectedFilters.length
                }
                sx={{ color: Colors.newAccentBlue }}
                onClick={newEmptySelectedFilter}
                startIcon={<AddCircleOutline />}
              >
                Add Condition
              </Button>
            </Box>
          </OutlinedCard>
          <OutlinedCard
            sx={{
              flexDirection: 'column',
              alignSelf: 'stretch',
            }}
          >
            <ModuleHeader
              title="Destination"
              subtitle={`Where should we put the extracted ${toolAutomation.title}?`}
              icon={<FmdGoodOutlinedIcon sx={{ fontSize: '20px' }} />}
            />
            <Stack padding="20px 24px 28px 24px" gap="24px">
              <FieldLabelGroup>
                <Typography typestyle="m">Service</Typography>
                <TextField
                  select
                  sx={{ flex: 'none', width: '200px' }}
                  size="small"
                  value={toolAutomation.cloudStorageSystem.id}
                >
                  {
                    state.cloudStorageSystems.map((cloudStorageSystem) => {
                      return (
                        <MenuItem
                          key={cloudStorageSystem.id}
                          onClick={() => {
                            dispatch({
                              type: 'SET_DESTINATION',
                              value: {
                                procoreToolEngineName: toolAutomation.procoreToolEngineName,
                                cloudStorageSystem: cloudStorageSystem,
                                destination: {
                                  document_storage_system: cloudStorageSystem.documentStorageSystem,
                                },
                              },
                            });

                          }}
                          value={cloudStorageSystem.id}
                        >
                          {cloudStorageSystem.label}
                        </MenuItem>
                      );
                    })
                  }
                </TextField>
              </FieldLabelGroup>
              <FieldLabelGroup sx={{
                gap: '12px',
              }}>
                <Box display={'flex'} flexDirection={'row'} gap={'4px'} alignItems={'center'}>
                  <Typography typestyle="m">Folder Path</Typography>
                  <Tooltip title="If a folder with the specified name does not exist, a new one will be created.">
                    <InfoOutlinedIcon sx={{
                      height: '16px',
                      width: '16px',
                    }} />
                  </Tooltip>
                </Box>
                <AutomationsGroupBy
                  selectedGroupings={toolAutomation.selectedGroupings}
                  editSelectedGrouping={(groupingIndexToEdit) => {
                    setIsAddToFolderPathModalOpen({
                      isOpen: true,
                      groupingIndex: groupingIndexToEdit
                    });
                  }}
                  setSelectedGroupings={(selectedGroupings) => {
                    dispatch({
                      type: 'UPDATE_SELECTED_GROUPINGS',
                      value: {
                        procoreToolEngineName: toolAutomation.procoreToolEngineName,
                        selectedGroupings,
                      },
                    });
                  }} onClickAddToPath={() => {
                    setIsAddToFolderPathModalOpen({
                      isOpen: true,
                      groupingIndex: -1
                    });
                  }} />
              </FieldLabelGroup>
            </Stack>
          </OutlinedCard>
          <OutlinedCard
            sx={{
              flexDirection: 'column',
              alignSelf: 'stretch',
            }}
          >
            <ModuleHeader
              title="PDF Options"
              subtitle={`Let's make sure the extracted ${toolAutomation.title} look right`}
              icon={<TuneOutlined sx={{ fontSize: '20px' }} />}
            />
            <TabContext
              value={toolAutomation.procoreToolEngineName}
            ></TabContext>
            <StyledTabPanel value="rfi">
              <StyledFormControlLabel
                sx={{ gap: '8px' }}
                label={'Include official RFI responses only'}
                control={
                  <BlueSwitch
                    size="small"
                    onChange={(evt) => {
                      dispatch({
                        type: 'UPDATE_EXTRACT_OPTIONS',
                        value: {
                          procoreToolEngineName:
                            toolAutomation.procoreToolEngineName,
                          extractOptions: {
                            ...toolAutomation.extractOptions,
                            response_option: evt.target.checked
                              ? 'official_only'
                              : 'all',
                          } as RfiExtractOptions,
                        },
                      });
                    }}
                    checked={
                      (toolAutomation.extractOptions as RfiExtractOptions)
                        ?.response_option == 'official_only'
                    }
                  />
                }
              />
            </StyledTabPanel>
            <StyledTabPanel value="submittal_log">
              <StyledFormControlLabel
                sx={{ gap: '8px' }}
                label={'Include distributed attachments only'}
                control={
                  <BlueSwitch
                    size="small"
                    onChange={(evt) => {
                      dispatch({
                        type: 'UPDATE_EXTRACT_OPTIONS',
                        value: {
                          procoreToolEngineName:
                            toolAutomation.procoreToolEngineName,
                          extractOptions: {
                            ...toolAutomation.extractOptions,
                            single_pdf: true,
                            workflow_responses: [],
                            attachment_option: evt.target.checked
                              ? 'distributed'
                              : 'all',
                          } as SubmittalExtractOptions,
                        },
                      });
                    }}
                    checked={
                      (toolAutomation.extractOptions as SubmittalExtractOptions)
                        ?.attachment_option == 'distributed'
                    }
                  />
                }
              />
            </StyledTabPanel>
            <StyledTabPanel value="communication_types">
            <StyledFormControlLabel
                sx={{ gap: '8px' }}
                label={'Include official Correspondence responses only'}
                control={
                  <BlueSwitch
                    size="small"
                    onChange={(evt) => {
                      dispatch({
                        type: 'UPDATE_EXTRACT_OPTIONS',
                        value: {
                          procoreToolEngineName:
                            toolAutomation.procoreToolEngineName,
                          extractOptions: {
                            ...toolAutomation.extractOptions,
                            response_option: evt.target.checked
                              ? 'official_only'
                              : 'all',
                          } as CorrespondenceExtractOptions,
                        },
                      });
                    }}
                    checked={
                      (toolAutomation.extractOptions as RfiExtractOptions)
                        ?.response_option == 'official_only'
                    }
                  />
                }
              />
            </StyledTabPanel>
            <StyledTabPanel value="forms">
              <Typography typestyle="xs" sx={{color: Colors.darkishGray}}>{'No PDF Options available'}</Typography>
            </StyledTabPanel>
            <StyledTabPanel value="change_events">
              <Typography typestyle="xs" sx={{color: Colors.darkishGray}}>{'No PDF Options available'}</Typography>
            </StyledTabPanel>
            <StyledTabPanel value="punch_list">
              <Typography typestyle="xs" sx={{color: Colors.darkishGray}}>{'No PDF Options available'}</Typography>
            </StyledTabPanel>
            <StyledTabPanel value="images">
              <Typography typestyle="xs" sx={{color: Colors.darkishGray}}>{'No PDF Options available'}</Typography>
            </StyledTabPanel>
            <StyledTabPanel value="meetings">
              <Typography typestyle="xs" sx={{color: Colors.darkishGray}}>{'No PDF Options available'}</Typography>
            </StyledTabPanel>
            <StyledTabPanel value="daily_log">
              <Typography typestyle="xs" sx={{color: Colors.darkishGray}}>{'No PDF Options available'}</Typography>
            </StyledTabPanel>
            <StyledTabPanel value="observations">
              <Box display="flex" flexDirection="column" width={1} height={1} gap={'12px'}>
                <Typography typestyle="s bold">Image Attachments</Typography>
                <ExportOptionToggle
                  label={'Include thumbnails and full sized images.'}
                  checked={(toolAutomation.extractOptions as ObservationExtractOptions).image_attachment_option === 'thumbnails_and_full_size'}
                  onChange={(event) => {
                    dispatch({
                      type: 'UPDATE_EXTRACT_OPTIONS',
                      value: {
                        procoreToolEngineName:
                          toolAutomation.procoreToolEngineName,
                        extractOptions: {
                          ...toolAutomation.extractOptions,
                          image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only'
                        } as ObservationExtractOptions
                      }
                    });
                  }} />
                <Typography typestyle="s bold">Drawings</Typography>
                <ExportOptionToggle
                  label={'Include markup.'}
                  checked={(toolAutomation.extractOptions as ObservationExtractOptions).include_linked_drawing_markup}
                  onChange={(event) => {
                    dispatch({
                      type: 'UPDATE_EXTRACT_OPTIONS',
                      value: {
                        procoreToolEngineName:
                          toolAutomation.procoreToolEngineName,
                        extractOptions: {
                          ...toolAutomation.extractOptions,
                          include_linked_drawing_markup: event.target.checked
                        } as ObservationExtractOptions
                      }
                    });
                  }} />
              </Box>
            </StyledTabPanel>
            <StyledTabPanel value="checklists">
            <Box display="flex" flexDirection="column" width={1} height={1} style={{ gap: '24px' }}>
              <ExportOptionToggle
                label={'Show N/A'}
                checked={(toolAutomation.extractOptions as InspectionExtractOptions).show_not_applicable_items}
                onChange={(event) => {
                  dispatch({
                    type: 'UPDATE_EXTRACT_OPTIONS',
                    value: {
                      procoreToolEngineName:
                        toolAutomation.procoreToolEngineName,
                      extractOptions: {
                        ...toolAutomation.extractOptions,
                        show_not_applicable_items: event.target.checked,
                        collapse_not_applicable_sections: event.target.checked ? (toolAutomation.extractOptions as InspectionExtractOptions).collapse_not_applicable_sections : false
                      } as InspectionExtractOptions
                    }
                  });
                }} />
                {
                  (toolAutomation.extractOptions as InspectionExtractOptions).show_not_applicable_items && (
                    <Box display={"flex"} paddingLeft={'12px'}>
                      <ExportOptionToggle
                        label={'Collapse N/A Sections'}
                        labelFontSize='12px'
                        checked={(toolAutomation.extractOptions as InspectionExtractOptions).collapse_not_applicable_sections}
                        onChange={(event) => {
                          dispatch({
                            type: 'UPDATE_EXTRACT_OPTIONS',
                            value: {
                              procoreToolEngineName:
                                toolAutomation.procoreToolEngineName,
                              extractOptions: {
                                ...toolAutomation.extractOptions,
                                collapse_not_applicable_sections: event.target.checked
                              } as InspectionExtractOptions
                            }
                          });
                        }} />
                    </Box>
                  )
                }
                <ExportOptionToggle
                  label={'Include full-sized image files as well as thumbnails'}
                  checked={(toolAutomation.extractOptions as InspectionExtractOptions).image_attachment_option === 'thumbnails_and_full_size'}
                  onChange={(event) => {
                    dispatch({
                      type: 'UPDATE_EXTRACT_OPTIONS',
                      value: {
                        procoreToolEngineName:
                          toolAutomation.procoreToolEngineName,
                        extractOptions: {
                          ...toolAutomation.extractOptions,
                          image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only'
                        } as InspectionExtractOptions
                      }
                    });
                  }} />

                <ExportOptionToggle
                  label={'Include linked Drawings'}
                  checked={(toolAutomation.extractOptions as InspectionExtractOptions).include_linked_drawings}
                  onChange={(event) => {
                    dispatch({
                      type: 'UPDATE_EXTRACT_OPTIONS',
                      value: {
                        procoreToolEngineName:
                          toolAutomation.procoreToolEngineName,
                        extractOptions: {
                          ...(toolAutomation.extractOptions as InspectionExtractOptions),
                          include_linked_drawings: event.target.checked,
                          include_linked_drawing_markup: event.target.checked ? (toolAutomation.extractOptions as InspectionExtractOptions).include_linked_drawing_markup : false,
                          include_other_markup: event.target.checked ? (toolAutomation.extractOptions as InspectionExtractOptions).include_other_markup : false
                        } as InspectionExtractOptions
                      }
                    });
                  }} />

                {
                  (toolAutomation.extractOptions as InspectionExtractOptions).include_linked_drawings && (
                    <Box display={"flex"} paddingLeft={'12px'}>
                      <ExportOptionToggle
                        label={'Include markup'}
                        labelFontSize='12px'
                        checked={(toolAutomation.extractOptions as InspectionExtractOptions).include_linked_drawing_markup}
                        onChange={(event) => {
                          dispatch({
                            type: 'UPDATE_EXTRACT_OPTIONS',
                            value: {
                              procoreToolEngineName:
                                toolAutomation.procoreToolEngineName,
                              extractOptions: {
                                ...toolAutomation.extractOptions,
                                include_linked_drawing_markup: event.target.checked,
                                include_other_markup: event.target.checked ? (toolAutomation.extractOptions as InspectionExtractOptions).include_other_markup : false
                              } as InspectionExtractOptions
                            }
                          });
                        }} />
                    </Box>
                  )
                }

                {
                  (toolAutomation.extractOptions as InspectionExtractOptions).include_linked_drawing_markup && (
                    <Box display={"flex"} paddingLeft={'24px'}>
                      <ExportOptionToggle
                      label={'Include other markup'}
                      labelFontSize='12px'
                      checked={(toolAutomation.extractOptions as InspectionExtractOptions).include_other_markup}
                      onChange={(event) => {
                        dispatch({
                          type: 'UPDATE_EXTRACT_OPTIONS',
                          value: {
                            procoreToolEngineName:
                              toolAutomation.procoreToolEngineName,
                            extractOptions: {
                              ...toolAutomation.extractOptions,
                              include_other_markup: event.target.checked
                            } as InspectionExtractOptions
                          }
                        });
                      }}/>
                    </Box>
                  )
                }

                <ExportOptionToggle
                  label={'Include linked Observations'}
                  checked={(toolAutomation.extractOptions as InspectionExtractOptions).include_observations}
                  onChange={(event) => {
                    dispatch({
                      type: 'UPDATE_EXTRACT_OPTIONS',
                      value: {
                        procoreToolEngineName:
                          toolAutomation.procoreToolEngineName,
                        extractOptions: {
                          ...toolAutomation.extractOptions,
                          include_observations: event.target.checked,
                        } as InspectionExtractOptions
                      }
                    });
                  }}/>
              </Box>
            </StyledTabPanel>
            {/* TODO: Add more PDF export options here */}
          </OutlinedCard>
        </>
      ) : (
        <Stack alignSelf="stretch" flex="1 1 0" justifyContent="center" alignItems="center">
          <LoadingMessage title="Loading..." />
        </Stack>
      )}

      {
        isAddToFolderPathModalOpen.isOpen && (
          <AutomationAddToFolderPathModal
            usingBox={toolAutomation.documentStorageSystem === 'box'}
            groupingToEdit={toolAutomation.selectedGroupings[isAddToFolderPathModalOpen.groupingIndex]}
            isOpen={isAddToFolderPathModalOpen.isOpen}
            closeModal={() => {
              setIsAddToFolderPathModalOpen({
                isOpen: false,
                groupingIndex: -1
              });
            }}
            groupings={allGroupings.filter(g => !toolAutomation.selectedGroupings.map(sg => sg.id).includes(g.id))}
            updatePath={(grouping) => {
              dispatch({
                type: 'UPDATE_SELECTED_GROUPINGS',
                value: {
                  procoreToolEngineName: toolAutomation.procoreToolEngineName,
                  selectedGroupings: toolAutomation.selectedGroupings.reduce((acc, currentVal, index) => {
                    if (index === isAddToFolderPathModalOpen.groupingIndex) {
                      acc.push(grouping);
                    } else {
                      acc.push(currentVal);
                    }

                    return acc;
                  }, [])
                },
              });
              setIsAddToFolderPathModalOpen({
                isOpen: false,
                groupingIndex: -1
              });
            }}
            addToPath={(grouping) => {
              dispatch({
                type: 'UPDATE_SELECTED_GROUPINGS',
                value: {
                  procoreToolEngineName: toolAutomation.procoreToolEngineName,
                  selectedGroupings: [
                    ...toolAutomation.selectedGroupings,
                    grouping
                  ]
                },
              });
              setIsAddToFolderPathModalOpen({
                isOpen: false,
                groupingIndex: -1
              });
            }} />
        )
      }
    </Box>
  );
}
