import { gql } from "@apollo/client";
import { createQueryHook } from "../../GraphqlHelpers";
import { ProcoreToolGroupings, TemplateCloseoutLogDataGridView, TemplateCloseoutLogExportOption, TemplateCloseoutLogTool, TemplateLogRequirement, TemplateLogRequirementTrade, TemplateLogRequirementType } from "../../../types";

export type TemplateCloseoutLogData = {
  templateLogRequirementTypes: TemplateLogRequirementType[];
  templateLogRequirementTrades: TemplateLogRequirementTrade[];
  templateLogRequirements: TemplateLogRequirement[];
  templateCloseoutLogGroupings: ProcoreToolGroupings[];
  templateCloseoutLogToolTypes: TemplateCloseoutLogTool[];
  currentTemplateCloseoutLogDataGridView: TemplateCloseoutLogDataGridView;
  templateCloseoutLogExportOption: TemplateCloseoutLogExportOption;
};

export type TemplateCloseoutLogDataVariables = {
  accountId: string;
  templateCloseoutLogId: number;
};

export const GetTemplateCloseoutLogDataQuery = gql`
  query GetTemplateCloseoutLogData(
    $accountId: ID!
    $templateCloseoutLogId: ID!
  ) {
    templateLogRequirementTypes(
      accountId: $accountId
      templateCloseoutLogId: $templateCloseoutLogId
    ) {
      id
      name
    }

    templateLogRequirementTrades(
      accountId: $accountId
      templateCloseoutLogId: $templateCloseoutLogId
    ) {
      id
      name
    }

    templateCloseoutLogGroupings(
      accountId: $accountId
      templateCloseoutLogId: $templateCloseoutLogId
    ) {
      id
      label
      attribute
      attributeSource
      defaultValue
      groupingLabel
      operator
      conditional
    }

    templateLogRequirements(
      accountId: $accountId
      templateCloseoutLogId: $templateCloseoutLogId
    ) {
      id
      description
      templateLogRequirementType {
        id
        name
      }
      templateLogRequirementTrade {
        id
        name
      }
    }

    templateCloseoutLogToolTypes(accountId: $accountId) {
      id
      procoreToolEngineName
      title
    }

    currentTemplateCloseoutLogDataGridView(accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId) {
      id
      name
      personal
      columnDefinitions
      sortModel
      groupingColumnDefinition
      groupings
      filters
    }

    templateCloseoutLogExportOption(accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId) {
      id
      includeTableOfContents
      includeVendorList
      groupings
      toolExtractOptions
    }
  }
`;

export const useGetTemplateCloseoutLogData = createQueryHook<
  TemplateCloseoutLogData,
  TemplateCloseoutLogDataVariables
>(GetTemplateCloseoutLogDataQuery);
