import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface RefreshCompanyUsersData {
  refreshCompanyUsers: {
    success: boolean;
  }
}

export interface RefreshCompanyUsersDataVariables {
  accountId: string;
}

export const useRefreshCompanyUsers = createMutationHook<RefreshCompanyUsersData, RefreshCompanyUsersDataVariables>(gql`
mutation RefreshCompanyUsers($accountId: ID!) {
  refreshCompanyUsers(input: { accountId: $accountId }) {
    success
  }
}
`);