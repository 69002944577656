import { Box } from '@mui/material';
import React from 'react';
import { Colors } from '../../styles';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button } from './Button';

const RunExtractsFreeTrialBanner = (props: { primaryText: React.ReactNode, secondaryText: React.ReactNode }): JSX.Element => {
  const { primaryText, secondaryText } = props;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      style={{
        background: 'white',
        borderRadius: "6px",
        marginBottom: "16px",
        boxShadow: '0px 1px 10px rgba(26, 32, 36, 0.06), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)'
      }}
    >
      <div style={{
        height: '6px',
        width: '100%',
        background: '#F5E538',
        borderTopRightRadius: '4px',
        borderTopLeftRadius: '4px'
      }}></div>
      <Box
        display={"flex"}
        flexDirection={"row"}
        style={{
          padding: "18px 16px"
        }}
        alignItems={"center"}>
        <InfoOutlinedIcon />
        <Box display="flex" flexDirection={"row"} alignItems={"center"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            marginLeft={"10px"}
            paddingRight={"20px"}
          >
            <p
              style={{
                padding: 0,
                margin: 0,
                color: Colors.darkerGray,
                fontWeight: 700,
                fontSize: "13px",
              }}>{primaryText}</p>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  color: Colors.darkerGray,
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                {secondaryText}
              </p>
          </Box>
          <Button
            onClick={() => {
              window.open("https://trailb.tech/request-demo", "_blank");
            }}
            variant="outlined"
            style={{
              minWidth: '105px'
            }}
            disableElevation={true}
            size="medium"
          >Contact Us</Button>
        </Box>
      </Box>
    </Box>
  )
};

export default RunExtractsFreeTrialBanner;