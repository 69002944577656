import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogRequirementType } from "../../../types";

export interface LogRequirementTypeUpdate {
  logRequirementType?: LogRequirementType;
  success: boolean;
}

export interface LogRequirementTypeUpdateData {
  updateLogRequirementType: LogRequirementTypeUpdate | null;
}

export interface LogRequirementTypeUpdateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  id: number;
  name: string;
}

export const useUpdateLogRequirementType = createMutationHook<
  LogRequirementTypeUpdateData,
  LogRequirementTypeUpdateVariables
>(gql`
  mutation UpdateLogRequirementType(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $id: ID!,
    $name: String!
  ) {
    updateLogRequirementType(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, id: $id, name: $name }
    ) {
      logRequirementType {
        id
        name
      }
      success
    }
  }
`);
