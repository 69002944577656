import { gql, QueryResult, useQuery } from "@apollo/client";
import { RecordAttachment } from "../../types/record_attachment";

export type RecordAttachmentData = {
  recordAttachments: RecordAttachment[]
};

export type RecordAttachmentVariables = {
  accountId: string;
  procoreProjectServerId: number;
  resourceType: string;
  procoreServerIds: number[];
};

export const RecordAttachmentFragment = gql`
fragment RecordAttachmentFragment on RecordAttachment {
  id
  procoreServerId
  formattedTitle
  attachmentGroupings {
    id
    groupingTitle
    attachments {
      id
      filename
      key
    }
  }
}
`;

export const getRecordAttachmentsQuery = gql`
query GetRecordAttachments($accountId: ID!, $procoreProjectServerId: ID!, $resourceType: String!, $procoreServerIds: [ID!]!) {
  recordAttachments(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, resourceType: $resourceType, procoreServerIds: $procoreServerIds) {
    ...RecordAttachmentFragment
  }
}
${RecordAttachmentFragment}
`;

export const useGetRecordAttachments = (
  accountId: string,
  procoreProjectServerId: number,
  resourceType: string,
  procoreServerIds: number[]): QueryResult<RecordAttachmentData> => useQuery<RecordAttachmentData, RecordAttachmentVariables>(getRecordAttachmentsQuery, { variables: {
    accountId: accountId,
    procoreProjectServerId: procoreProjectServerId,
    resourceType: resourceType,
    procoreServerIds: procoreServerIds
  },
  fetchPolicy: 'network-only' });