import React, { useState, useRef } from "react";
import {
  Box,
  Dialog,
  IconButton,
} from "@mui/material";
import { Account } from "../../types";
import { useAddUsersToAccount } from "../../graphql/mutations/AddUsersToAccount";
import LinkProcoreCompanyUsersTable from "../LinkProcoreCompanyUsersTable";
import { Typography } from "../shared/Typography";
import Close from "@mui/icons-material/Close";
import { Button } from "../shared/Button";
import { ManageUsersContext } from "../../contexts/ManageUsersContextProvider";
import { checkedItemIds, checkedItems } from "../../utils/utils";

const ManageUsersDataGridModal = (props: {
  account: Account;
  handleOnClose: (refresh: boolean) => void;
  open: boolean;
}): JSX.Element => {
  const { account, handleOnClose, open } = props;
  const containerRef = useRef(null);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const { state } = React.useContext(ManageUsersContext);

  const [
    addUsersToAccount,
    { loading: addUsersToAccountLoading },
  ] = useAddUsersToAccount({
    accountId: account.id,
    procoreLoginInformationIds: checkedItemIds(state.selectedItems),
  });

  const disableAddButton = checkedItems(state.selectedItems).length === 0;

  return (
    <Dialog
      disableScrollLock
      ref={(ref) => {
        containerRef.current = ref;
        setIsMounted(true);
      }}
      PaperProps={{
        sx: {
          minWidth: "850px",
          padding: "24px 28px 28px 28px",
        },
      }}
      maxWidth={false}
      onClose={() => {
        if (addUsersToAccountLoading) { return; }
        handleOnClose(false)
      }}
      aria-labelledby="help-dialog-title"
      open={open}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '24px',
        justifyContent: 'space-between'
      }}>
        <Typography typestyle="xl">
          Add Users
        </Typography>
        <IconButton disabled={addUsersToAccountLoading} onClick={() => handleOnClose(false)}>
          <Close sx={{fontSize: '18px'}}/>
        </IconButton>
      </Box>

      <Box display="flex" height={'600px'} flexDirection={'column'} width={1}>
        {
          isMounted && (
            <LinkProcoreCompanyUsersTable
              popperContainer={containerRef.current}
              key={`link-company-users-table`}
              accountId={account.id}
            />
          )
        }
      </Box>

      <Box display="flex" flexDirection="column" height={1} width={1}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"8px"}
          paddingTop={"24px"}
        >
          <Button
            disabled={addUsersToAccountLoading}
            variant="outlined"
            buttonborderstyle="pill"
            size="medium"
            onClick={() => handleOnClose(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={disableAddButton || addUsersToAccountLoading}
            variant="contained"
            buttonborderstyle="pill"
            size="medium"
            onClick={() => {
              addUsersToAccount({
                variables: {
                  accountId: account.id,
                  procoreLoginInformationIds: checkedItemIds(state.selectedItems),
                }
              }).then(({ data }) => {
                if (data && data.addUsersToAccount.success) {
                  handleOnClose(true);
                }
              })
            }}>
            Add
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ManageUsersDataGridModal;
