import Box from '@mui/material/Box';
import { GridSlotsComponentsProps } from '@mui/x-data-grid-premium';
import React from 'react';
import { Typography } from './shared/Typography';
import { Colors } from '../styles';

export function CustomFooterStatusComponent(
  props: NonNullable<GridSlotsComponentsProps['footer']>,
) {
  return (
    <Box sx={{ p: 1, display: 'flex', alignContent: 'center', justifyContent: 'center', borderTopWidth: '1px', borderTopColor: Colors.mediumLightGray, borderTopStyle: 'solid' }}>
      <Typography typestyle='xs' sx={{ color: Colors.mediumDarkGray }}>{`Displaying ${props.itemCount} items`}</Typography>
    </Box>
  );
}