import React from 'react';
import { NewPackageContextProvider } from '../../contexts/NewPackageContextProvider';
import { NewPackageAppContainer } from '../../styles';
import NewPackage from './NewPackage';
import { Box } from '@mui/material';
import { AccountProjectContext } from '../../contexts/AccountProjectContextProvider';
import { getDaysRemaining } from '../../utils/utils';
import FreeTrialBanner from '../shared/FreeTrialBanner';

const NewPackageContainer = (): JSX.Element => {
  const { state } = React.useContext(AccountProjectContext);

  const isTrial = state.licenseType === 'trial';

  const daysRemaining = () => {
    return getDaysRemaining(state.licenseEndDate);
  };

  return (
    <Box display="flex" flexDirection="column" height={1} width={1}>
      {isTrial && <FreeTrialBanner daysRemaining={daysRemaining()} />}
      <NewPackageContextProvider>
        <NewPackageAppContainer maxWidth="lg">
          <NewPackage />
        </NewPackageAppContainer>
      </NewPackageContextProvider>
    </Box>
  );
};

export default NewPackageContainer;
