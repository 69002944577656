import {
  Typography as MuiTypography,
  styled,
  TypographyProps,
} from '@mui/material';

type OtherProps = {
  typestyle?:
    | 'xxl'
    | 'xl'
    | 'l'
    | 'ml'
    | 'm'
    | 'm bold'
    | 's'
    | 's bold'
    | 'xs'
    | 'xs bold'
    | 'xxs';
};

const smallStyles = {
  fontSize: '0.8125rem',
  lineHeight: '0.952rem',
  fontWeight: '400',
};

export const getTypeStyles = (typestyle: string) => {
  switch (typestyle) {
    case 'xxl':
      return {
        fontSize: '1.875rem',
        lineHeight: '2.198rem',
        fontWeight: '700',
      };
    case 'xl':
      return {
        fontSize: '1.375rem',
        lineHeight: '1.611rem',
        fontWeight: '700',
      };
    case 'l':
      return {
        fontSize: '1.125rem',
        lineHeight: '1.318rem',
        fontWeight: '700',
      };
    case 'ml':
      return {
        fontSize: '0.9375rem',
        lineHeight: '1.099rem',
        fontWeight: '500',
      };
    case 'm bold':
      return {
        fontSize: '0.875rem',
        lineHeight: '1.026rem',
        fontWeight: '700',
      };
    case 'm':
      return {
        fontSize: '0.875rem',
        lineHeight: '1.026rem',
        fontWeight: '400',
      };
    case 's bold':
      return {
        fontSize: '0.8125rem',
        lineHeight: '0.952rem',
        fontWeight: '700',
      };
    case 's':
      return smallStyles;
    case 'xs bold':
      return {
        fontSize: '0.75rem',
        lineHeight: '0.879rem',
        fontWeight: '700',
      };
    case 'xs':
      return {
        fontSize: '0.75rem',
        lineHeight: '0.879rem',
        fontWeight: '400',
      };
    case 'xxs':
      return {
        fontSize: '0.625rem',
        lineHeight: '0.732rem',
        fontWeight: '400',
      };
    default:
      return smallStyles;
  }
};
export const Typography = styled(MuiTypography)<TypographyProps & OtherProps>(
  ({ typestyle }) => getTypeStyles(typestyle)
);
