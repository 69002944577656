import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { TemplateCloseoutLog } from "../../../types";

export interface TemplateCloseoutLogDuplicate {
  templateCloseoutLog: TemplateCloseoutLog | null;
  success: boolean;
}

export interface TemplateCloseoutLogDuplicateData {
  duplicateTemplateCloseoutLog: TemplateCloseoutLogDuplicate | null;
}

export interface TemplateCloseoutLogDuplicateVariables {
  accountId: string;
  id: number;
}

export const useDuplicateTemplateCloseoutLog = createMutationHook<
  TemplateCloseoutLogDuplicateData,
  TemplateCloseoutLogDuplicateVariables
>(gql`
  mutation DuplicateTemplateCloseoutLog(
    $accountId: ID!,
    $id: ID!,
  ) {
    duplicateTemplateCloseoutLog(
      input: { accountId: $accountId, id: $id }
    ) {
      templateCloseoutLog {
        id
        name
        createdAt
        templateLogRequirementsCount
      }
      success
    }
  }
`);
