import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface LoadProjectsOnCompaniesData {
  loadProjectsOnCompanies: {
    successfulProcoreCompanyServerIds: string[];
    failedProcoreCompanyServerIds: string[];
  }
}

export interface LoadProjectsOnCompaniesVariables {
  accountId: string;
  procoreCompanyServerIds: number[];
}

export const useLoadProjectsOnCompanies = createMutationHook<LoadProjectsOnCompaniesData, LoadProjectsOnCompaniesVariables>(gql`
mutation LoadProjectsOnCompanies($accountId: ID!, $procoreCompanyServerIds: [ID!]!) {
  loadProjectsOnCompanies(input: { accountId: $accountId, procoreCompanyServerIds: $procoreCompanyServerIds }) {
    successfulProcoreCompanyServerIds
    failedProcoreCompanyServerIds
  }
}
`);