import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { CloseoutLog } from "../../../types";

export interface CloseoutLogCreate {
  closeoutLog: CloseoutLog | null;
  success: boolean;
}

export interface CloseoutLogCreateData {
  createCloseoutLog: CloseoutLogCreate | null;
}

export interface CloseoutLogCreateAttributes {
  name: string;
  accountId: string;
  procoreProjectServerId: number;
  templateCloseoutLogId?: number;
}

export interface CloseoutLogCreateVariables {
  closeoutLogAttributes: CloseoutLogCreateAttributes;
}

export const useCreateCloseoutLog = createMutationHook<
  CloseoutLogCreateData,
  CloseoutLogCreateVariables
>(gql`
  mutation CreateCloseoutLog(
    $closeoutLogAttributes: CloseoutLogCreateAttributes!
  ) {
    createCloseoutLog(
      input: { closeoutLogAttributes: $closeoutLogAttributes }
    ) {
      closeoutLog {
        id
        name
        logRequirementsCount
        logRequirementsCompleteCount
        createdAt
        substantialCompletionDate
      }
      success
    }
  }
`);
