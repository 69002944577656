import Box from "@mui/material/Box";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import React from "react";
import { Colors } from "../../styles";
import { Typography } from "./Typography";

export default function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number, progressColor?: string },
) {
  const {progressColor, ...rest} = props;
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{ color: Colors.lightishGray }}
          {...rest}
          value={100} />
        <CircularProgress
          variant="determinate"
          sx={{ color: progressColor ? progressColor : Colors.newAccentBlue, position: 'absolute', left: 0 }}
          {...rest} />
      </Box>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography typestyle="l">{`${Math.round(props.value)}%`}</Typography>
        <Typography typestyle="xxs" sx={{ color: Colors.darkishGray}}>{'complete'}</Typography>
      </Box>
    </Box>
  );
}
