import { gql } from "@apollo/client";
import { ProcoreDocument } from "../../types/document";
import { createLazyQueryHook } from "../GraphqlHelpers";

export type ProcoreFilterDocumentFolderData = {
  procoreDocumentFolders: ProcoreDocument[];
  procoreDocumentFolderServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreFilterDocumentFolderVariables = {
  accountId: string;
  procoreProjectServerId: number;
  procoreParentFolderServerId?: number;
  limit: number;
  offset: number;
};

export const DocumentFragment = gql`
fragment ProcoreDocumentFolderFragment on ProcoreDocument {
  id
  formattedTitle
  procoreServerId
  parentId
  documentType
  hasChildren
  hasChildrenFolders
}
`;

export const filterProcoreDocumentFoldersQuery = gql`
query FilterProcoreDocumentFolders($accountId: ID!, $procoreProjectServerId: ID!, $procoreParentFolderServerId: ID, $limit: Int!, $offset: Int!) {
  procoreDocumentFolders(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, procoreParentFolderServerId: $procoreParentFolderServerId, limit: $limit, offset: $offset) {
    ...ProcoreDocumentFolderFragment
  }

  procoreDocumentFolderServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, procoreParentFolderServerId: $procoreParentFolderServerId) {
    procoreServerIds
  }
}
${DocumentFragment}
`;

export const fetchPaginatedProcoreDocumentFoldersQuery = gql`
query FetchPaginatedProcoreDocumentFolders($accountId: ID!, $procoreProjectServerId: ID!, $procoreParentFolderServerId: ID, $limit: Int!, $offset: Int!) {
  procoreDocumentFolders(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, procoreParentFolderServerId: $procoreParentFolderServerId, limit: $limit, offset: $offset) {
    ...ProcoreDocumentFolderFragment
  }
}
${DocumentFragment}
`;

export const useGetProcoreDocumentFolders = createLazyQueryHook<ProcoreFilterDocumentFolderData, ProcoreFilterDocumentFolderVariables>(filterProcoreDocumentFoldersQuery);