import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface UpdateProjectLicenseData {
  updateProjectLicense: {
    success: boolean;
    redirectUrl?: string;
  }
}

export interface UpdateProjectLicenseVariables {
  accountId: string;
  sessionId: string;
}

export const useUpdateProjectLicense = createMutationHook<UpdateProjectLicenseData, UpdateProjectLicenseVariables>(gql`
mutation UpdateProjectLicense($accountId: ID!, $sessionId: ID!) {
  updateProjectLicense(input: { accountId: $accountId, sessionId: $sessionId }) {
    success
    redirectUrl
  }
}
`);