import React from 'react';
import { Box } from '@mui/material';
import { Typography } from './Typography';
import { Colors } from '../../styles';

interface IUnderlinedHeaderProps {
  children: React.ReactNode;
}

export const UnderlinedHeader = (props: IUnderlinedHeaderProps): JSX.Element => {
  return (
    <Box paddingY="16px" borderBottom={`1px solid ${Colors.mediumLightGray}`}>
      <Typography typestyle="l">{props.children}</Typography>
    </Box>
  );
};
