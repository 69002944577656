import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface DeactivateProjectLicenseData {
  deactivateProjectLicense: {
    success: boolean;
  }
}

export interface DeactivateProjectLicenseVariables {
  accountId: string;
  procoreProjectServerId: number;
}

export const useDeactivateProjectLicense = createMutationHook<DeactivateProjectLicenseData, DeactivateProjectLicenseVariables>(gql`
mutation DeactivateProjectLicense($accountId: ID!, $procoreProjectServerId: ID!) {
  deactivateProjectLicense(input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId }) {
    success
  }
}
`);