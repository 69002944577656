import { gql } from "@apollo/client";
import { FilterOptionInput } from "../../types";
import { ProcoreIncident } from "../../types/incident";

export type ProcoreFilterIncidentData = {
  incidents: ProcoreIncident[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcoreIncidentVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterIncidentVariables = ProcoreIncidentVariables & {
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const IncidentFragement = gql`
fragment IncidentFragement on ProcoreIncident {
  id
  procoreServerId
  formattedTitle
  locationName
  status
  description
}
`;

export const filterProcoreIncidentsQuery = gql`
query FilterProcoreIncidents($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  incidents(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...IncidentFragement
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${IncidentFragement}
`;

export const fetchPaginatedProcoreIncidentsQuery = gql`
query FilterPaginatedProcoreIncidents($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  incidents(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...IncidentFragement
  }
}
${IncidentFragement}
`;