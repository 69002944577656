import { gql } from "@apollo/client";
import { createQueryHook } from "../GraphqlHelpers";
import { VendorPortalCloseoutLog, VendorPortalCloseoutManager, VendorPortalDataGridView, VendorPortalLogFileRequest } from "../../types";

export type VendorPortalData = {
  logFileRequests: VendorPortalLogFileRequest[];
  closeoutLog: VendorPortalCloseoutLog;
  vendorPortalDataGridView: VendorPortalDataGridView;
  closeoutManagers: VendorPortalCloseoutManager[];
};

export type VendorPortalDataVariables = {
  vendorId: string;
};

export const GetCloseoutLogDataQuery = gql`
  query GetVendorPortalData(
    $vendorId: ID!
  ) {
    logFileRequests(
      vendorId: $vendorId
    ) {
      id
      title
      description
      dueDate
      complete
      status
      translatedStatus
      logRequirementTrade {
        id
        name
      }
      specSection {
        id
        name
        divisionName
        procoreServerId
      }
      logRequirementDescription
      vendorWorkflowRequest {
        id
        logFileRequestId
        status
        translatedStatus
        comment
        vendorWorkflowResponse {
          id
          logFileRequestId
          status
          translatedStatus
          comment
          vendorWorkflowRequestId
        }
      }
    }

    closeoutManagers(vendorId: $vendorId) {
      id
      name
      vendorName
      email
      jobTitle
      phone
    }

    vendorPortalDataGridView(vendorId: $vendorId) {
      id
      columnDefinitions
      sortModel
      groupingColumnDefinition
      groupings
      filters
    }

    closeoutLog(
      vendorId: $vendorId
    ) {
      id
      vendorName
      procoreProjectName
      substantialCompletionDate
      projectAddress
      formattedAddress
    }
  }
`;

export const useGetVendorPortalData = createQueryHook<
  VendorPortalData,
  VendorPortalDataVariables
>(GetCloseoutLogDataQuery);
