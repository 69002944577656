import { Typography } from "../shared/Typography";
import { Colors } from "../../styles";
import { Box } from "@mui/material";
import React from "react";

const ListEmptyState = (): JSX.Element => {
  return (
    <Box
      display="flex"
      height={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: '8px'
      }}
    >
      <Box sx={{
        height: '200px',
        width: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}>
        <Box
          minHeight="220px"
          minWidth="220px"
          sx={{
            background:
              'url("/images/box-of-documents.png") no-repeat center / contain',
          }}
        ></Box>
      </Box>
      <Typography typestyle="l">No Extracts</Typography>
      <Typography sx={{ color: Colors.darkishGray, width: '500px', textAlign: 'center', lineHeight: '1.2rem' }}>
        {`Any time you run an extract it will appear here for easy downloading or sharing. Run your first extract by clicking the "New" button above.`}
      </Typography>
    </Box>
  );
};

export default ListEmptyState;
