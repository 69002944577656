import React, { ReactElement } from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { Typography } from './Typography';
import { Colors } from '../../styles';
import Box from '@mui/material/Box'

interface ISnazzyMenuItem {
  icon: ReactElement;
  title: string;
  subtitle: string;
}

export const SnazzyMenuItem = (
  props: ISnazzyMenuItem & MenuItemProps
): JSX.Element => {
  const { icon, title, subtitle, ...other } = props;
  return (
    <MenuItem
      {...other}
      sx={{
        padding: '8px 12px 12px 12px',
        borderRadius: '6px',
        whiteSpace: 'normal',
        '&:hover': {
          backgroundColor: Colors.lightestGray2,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          alignItems: 'center',
          maxWidth: '100%',
        }}
      >
        <Box
          sx={{
            flex: '0 0 40px',
            height: '40px',
            borderRadius: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.lightGray,
            border: `1.5px solid ${Colors.white}`,
          }}
        >
          {icon}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            maxWidth: '200px',
          }}
        >
          <Typography typestyle="m bold">{title}</Typography>
          <Typography>
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  );
};
