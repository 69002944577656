import React from "react";
import { ProcoreToolFilter } from "../contexts/NewPackageContext";
import FilterProcoreItems from "./shared/filters/FilterProcoreItems";
import { Action } from "../contexts/ManageUsersContext";
import { PAGINATION, procoreFilterToInputFilters } from "../utils/utils";
import { useApolloClient } from "@apollo/client";
import { ManageUsersContext } from "../contexts/ManageUsersContextProvider";
import { useGetProcoreCompanyUserFilters } from "../graphql/queries/GetProcoreCompanyUserFilters";
import { fetchPaginatedProcoreCompanyUsersQuery, filterProcoreCompanyUsersQuery, ProcoreCompanyUserData } from "../graphql/queries/GetProcoreCompanyUsers";
import { User } from "../types";

interface ILinkProcoreCompanyUsersTable {
  accountId: string;
  popperContainer?: Element;
}

function useStartInitialDataSync(props: {
  accountId: string;
  dispatch: (action: Action) => void;
  onFilterOptionSelectionChange: ({
    selectedFilters,
  }: {
    selectedFilters?: ProcoreToolFilter[];
  }) => void;
}) {
  React.useEffect(() => {
    loadProcoreCompanyUserFilters();
  }, []);

  // TODO: Change this
  const [
    loadProcoreCompanyUserFilters,
    { loading: loadingProcoreCompanyUserFilters, data: procoreCompanyUserFiltersData },
  ] = useGetProcoreCompanyUserFilters({
    accountId: props.accountId,
  });

  React.useEffect(() => {
    if (!procoreCompanyUserFiltersData || loadingProcoreCompanyUserFilters) {
      return;
    }

    props.dispatch({
      type: "SET_FILTERS",
      value: {
        filters: procoreCompanyUserFiltersData.filters,
        selectedFilters: procoreCompanyUserFiltersData.defaultFilters,
      },
    });

    props.onFilterOptionSelectionChange({
      selectedFilters: procoreCompanyUserFiltersData.defaultFilters,
    });
  }, [procoreCompanyUserFiltersData]);

  return { procoreCompanyUserFiltersData };
}

const processInitialDataLoad = (data: ProcoreCompanyUserData): { procoreItems: User[]; filteredProcoreItemServerIds: number[] } => {
  // TODO:
  return {
    procoreItems: data.procoreCompanyUsers,
    filteredProcoreItemServerIds: data.procoreCompanyUserRecordServerIds.procoreServerIds
  };
};

const processPaginatedDataLoad = (data: ProcoreCompanyUserData): User[] => {
  return data.procoreCompanyUsers;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LinkProcoreCompanyUsersTable = (props: ILinkProcoreCompanyUsersTable): JSX.Element => {
  const { dispatch, state } = React.useContext(ManageUsersContext);
  const client = useApolloClient();

  const fetchMoreProcoreItems = () => {
    dispatch({
      type: "SET_FAILED_TO_FETCH_MORE",
      value: {
        failedToFetchMore: false
      },
    });

    dispatch({
      type: "SET_LOADING_MORE",
      value: {
        loadingMore: true
      }
    });

    client
      .query({
        query: fetchPaginatedProcoreCompanyUsersQuery,
        variables: {
          accountId: props.accountId,
          filters: procoreFilterToInputFilters(state.selectedFilters),
          searchValue: state.searchValue,
          withAccess: false,
          limit: PAGINATION.limit,
          offset: state.procoreItems.length
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        dispatch({
          type: "APPEND_PROCORE_ITEMS",
          value: {
            procoreItems: processPaginatedDataLoad(data),
          }
        });
        dispatch({
          type: "SET_LOADING_MORE",
          value: {
            loadingMore: false
          }
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_LOADING_MORE",
          value: {
            loadingMore: false
          }
        });

        dispatch({
          type: "SET_FAILED_TO_FETCH_MORE",
          value: {
            failedToFetchMore: true
          }
        });
      });
  }

  const fetchPaginatedProcoreItems = (selectedFilters: ProcoreToolFilter[] | undefined = undefined, searchValue: string | undefined) => {
    dispatch({
      type: "SET_LOADING",
      value: { loading: true },
    });

    const searchVariableValue = searchValue === undefined ? state.searchValue : searchValue;

    client
      .query({
        query: filterProcoreCompanyUsersQuery,
        variables: {
          accountId: props.accountId,
          searchValue: searchVariableValue,
          filters: procoreFilterToInputFilters(
            selectedFilters !== undefined
              ? selectedFilters
              : state.selectedFilters
          ),
          withAccess: false,
          limit: PAGINATION.limit,
          offset: PAGINATION.offset,
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        const { procoreItems, filteredProcoreItemServerIds } = processInitialDataLoad(data);

        dispatch({
          type: "SET_PROCORE_ITEMS",
          value: {
            procoreItems: procoreItems,
            filteredProcoreItemServerIds: filteredProcoreItemServerIds,
          },
        });
        dispatch({
          type: "SET_LOADING",
          value: { loading: false },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: "SET_LOADING",
          value: { loading: false },
        });
      });
  };

  const onFilterOptionSelectionChange = ({
    selectedFilters = undefined,
  }: {
    selectedFilters?: ProcoreToolFilter[];
  }) => {
    fetchPaginatedProcoreItems(selectedFilters, undefined);
  };

  useStartInitialDataSync({
    accountId: props.accountId,
    dispatch: dispatch,
    onFilterOptionSelectionChange: onFilterOptionSelectionChange
  });

  return (
    <FilterProcoreItems
      filterActionbBarProps={{
      filters: state.filters,
      selectedFilters: state.selectedFilters,
      searchValue: state.searchValue,
      filterOpenState: state.filterOpenState,
      popperContainer: props.popperContainer,
      onSearchValueChanged: (newSearchValue) => {
        dispatch({
          type: "SET_SEARCH_VALUE",
          value: { searchValue: newSearchValue },
        });
      },
      onSearchValueEntered: (searchValue) => {
        fetchPaginatedProcoreItems(state.selectedFilters, searchValue);
      },
      onSelectedFiltersChanged: (newSelectedFilters) => {
        dispatch({
          type: "SET_SELECTED_FILTERS",
          value: { selectedFilters: newSelectedFilters },
        })
      },
      onFilterOptionSelectionChange: onFilterOptionSelectionChange,
      onFilterOpenStateChanged: (newFilterOpenState) => {
        dispatch({
          type: "SET_FILTER_OPEN_STATE",
          value: { filterOpenState: newFilterOpenState },
        });
      }
    }}
    procoreToolEngineName={'directory'}
    procoreToolEngineTitle={'Company Directory Users'}
    directoryType={'users'}
    loading={state.loading}
    loadingMore={state.loadingMore}
    failedToFetchMore={state.failedToFetchMore}
    onFetchMoreProcoreItems={() => {
      fetchMoreProcoreItems();
    }}
    onSelectedItemsChanged={(newSelectedItems) => {
      dispatch({
        type: "SET_SELECTED_ITEMS",
        value: { selectedItems: newSelectedItems },
      });
    }}
    procoreItems={state.procoreItems}
    filteredProcoreItemServerIds={
      state.filteredProcoreItemServerIds
    }
    selectedItems={state.selectedItems}
  />
  );
};

export default LinkProcoreCompanyUsersTable;
