import { gql } from "@apollo/client";
import { FilterOptionInput } from "../../types";
import { Submittal, SubmittalResponse } from "../../types/submittal";

export type ProcoreFilterSubmittalData = {
  submittals: Submittal[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  },
  submittalResponses: SubmittalResponse[];
};

export type ProcoreFilterSubmittalVariables = {
  accountId: string;
  procoreProjectServerId: number;
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const SubmittalFragment = gql`
fragment SubmittalFragment on ProcoreSubmittal {
  id
  procoreServerId
  title
  formattedTitle
  submittalType
  status
  distributed
  specSection
  responsibleContractor
  locationName
  formattedNumber
  submittalPackageTitle
  specDivisionNumber
  specDivision
  description
}
`;

export const filterProcoreSubmittalsQuery = gql`
query FilterProcoreSubmittals($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  submittals(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...SubmittalFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }

  submittalResponses(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId) {
    id
    name
    considered
  }
}
${SubmittalFragment}
`;

export const fetchPaginatedProcoreSubmittalsQuery = gql`
query FilterPaginatedProcoreSubmittals($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  submittals(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...SubmittalFragment
  }
}
${SubmittalFragment}
`;