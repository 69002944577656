import { gql } from "@apollo/client";
import { FilterOptionInput } from "../../types";
import { ProcoreProjectVendor } from "../../types/project_vendor";

export type ProcoreFilterProjectVendorData = {
  projectVendors: ProcoreProjectVendor[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  },
};

export type ProcoreFilterProjectVendorVariables = {
  accountId: string;
  procoreProjectServerId: number;
  filters: FilterOptionInput[];
  searchValue?: string;
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const ProjectVendorFragment = gql`
fragment ProjectVendorFragment on ProcoreProjectVendor {
  id
  formattedTitle
  procoreServerId
  description
}
`;

export const filterProcoreProjectVendorsQuery = gql`
query FilterProcoreProjectVendors($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  projectVendors(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ProjectVendorFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId, directoryType: "vendors") {
    procoreServerIds
  }
}
${ProjectVendorFragment}
`;

export const fetchPaginatedProcoreProjectVendorsQuery = gql`
query FilterPaginatedProjectVendors($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  projectVendors(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...ProjectVendorFragment
  }
}
${ProjectVendorFragment}
`;