import React, { useState } from 'react';
import { Typography } from './Typography';
import Box from '@mui/material/Box';
import { LoadingButton as Button } from './Button';
import TextField from '@mui/material/TextField';
import { FieldContainer } from '../../styles';

interface IManageTemplateCloseoutLogDetails {
  defaultText: string;
  onClickSubmit: (text: string, newDate?: Date) => void;
  submitLoading: boolean;
}

export const ManageTemplateCloseoutLogDetails = (
  props: IManageTemplateCloseoutLogDetails
): JSX.Element => {
  const {
    defaultText,
    onClickSubmit,
    submitLoading,
  } = props;
  const [textValue, setTextValue] = useState<string>(defaultText);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'24px'} width={'50%'}>
      <FieldContainer>
        <Typography typestyle="m">{'Name'}</Typography>
        <TextField
          value={textValue}
          autoComplete="off"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            setTextValue(evt.target.value)
          }
        />
      </FieldContainer>

      <Box display="flex" justifyContent="start" gap="8px">
        <Button
          loading={submitLoading}
          variant="contained"
          disabled={textValue === ''}
          buttonborderstyle="pill"
          onClick={() => onClickSubmit(textValue)}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
