import React from 'react';
import { Box } from '@mui/material';
import { Typography } from './shared/Typography';
import { Colors } from '../styles';

export const ViewLinkedItemsEmptyState = (): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      alignItems="center"
      justifyContent="center"
      gap='12px'
      width='100%'
    >
      <Box
        height="140px"
        width="200px"
        sx={{
          background:
            'url("/images/view-linked-items-empty.png") no-repeat center / cover',
        }}
      ></Box>
      <Typography color={Colors.darkerGray} typestyle="l">No Linked Items</Typography>
      <Typography color={Colors.darkishGray} typestyle="xs" textAlign={'center'}>Get started by linking to an item in Procore or uploading a new file</Typography>
    </Box>
  );
};