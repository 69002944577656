import {
  Box,
  DialogContent,
  DialogTitle,
  DialogTitleProps,
  Divider,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemProps,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import Loading from '../shared/Loading';
import NewPackageActionBar from './NewPackageActionBar';
import ExtractToolTabs from './ExtractToolTabs';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { useEffect } from 'react';
import { useCreatePackage } from '../../graphql/mutations/CreatePackage';
import idx from 'idx';
import { getProcoreTools } from '../../graphql/queries/GetProcoreTools';
import { AccountProjectContext } from '../../contexts/AccountProjectContextProvider';
import {} from '@apollo/client/link/context';
import {
  PackageQueryAttributes,
  PackageQueryOptionsInput,
  useUpdatePackage,
} from '../../graphql/mutations/UpdatePackage';
import { useDeletePackage } from '../../graphql/mutations/DeletePackage';
import {
  checkedItems,
  procoreFilterToInputFilters,
  procoreGroupingsToInputGroupings,
  TOOL_ENGINE_NAMES,
} from '../../utils/utils';
import { Colors, DialogDivider, MyDialog, MyDialogActions } from '../../styles';
import CloseIcon from '@mui/icons-material/Close';
import useNavigationHook from '../../hooks/UseNavigationHook';
import { PackageHistoryContext } from '../../contexts/PackageContextProvider';
import { useLocation, useParams } from 'react-router';
import { BlueSwitch } from './styles';
import { ExportToTypes, Package } from '../../types';
import RunExtractsFreeTrialBanner from '../shared/RunExtractFreeTrialBanner';
import ProcoreIcon from '../shared/ProcoreIcon';
import SelectDocumentFolderModal from './SelectDocumentFolderModal';
import { NewPackageExtractOptions } from '../../contexts/NewPackageContext';
import EditAndRerunExportBanner from '../shared/EditAndRerunExportBanner';
import { Button } from '../shared/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const CloseDialogIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    position: 'absolute',
    right: '8px',
    top: '20px',
    color: '#323232',
  };
});

export const CustomDialogTitle = styled(DialogTitle)<DialogTitleProps>(() => {
  return {
    paddingBottom: '12px',
    paddingTop: '28px',
  };
});

export const CheckoutToolTabsListItem = styled(ListItem)<ListItemProps>(() => {
  return {
    paddingTop: '6px',
    paddingBottom: '6px',
    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: '8px',
    },
  };
});

export interface CustomDialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const MyDialogTitle = (props: CustomDialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <CustomDialogTitle {...other}>
      <Typography variant="h6" component="span">
        {children}
      </Typography>
      {onClose ? (
        <CloseDialogIconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseDialogIconButton>
      ) : null}
    </CustomDialogTitle>
  );
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const NewPackage = (): JSX.Element => {
  const { accountId, procoreProjectId } = useParams<{
    accountId: string;
    procoreProjectId: string;
  }>();
  const query = useQuery();
  const { state, dispatch } = useContext(NewPackageContext);
  const { state: accountProjectState } = React.useContext(
    AccountProjectContext
  );
  const {
    fetchPackages,
    state: packageHistoryState,
    dispatch: dispatchPackageHistory,
  } = React.useContext(PackageHistoryContext);
  const navigateTo = useNavigationHook();

  const [deletePackage] = useDeletePackage({
    id: state.packageId,
    accountId: accountProjectState.accountId,
    procoreProjectServerId: accountProjectState.procoreProjectServerId,
  });
  const [loadTools, { loading: queryLoading, data }] = getProcoreTools({
    accountId: accountProjectState.accountId,
    procoreProjectServerId: accountProjectState.procoreProjectServerId,
  });
  const [createPackage, { loading: createPackageLoading }] = useCreatePackage({
    package: {
      accountId: accountProjectState.accountId,
      name: state.name,
      procoreProjectServerId: accountProjectState.procoreProjectServerId,
      createdFromPackageId: query.get('packageId')
        ? Number.parseInt(query.get('packageId'))
        : null,
    },
  });

  const [
    updatePackage,
    { loading: updatePackageLoading, data: updatePackageData },
  ] = useUpdatePackage({
    id: state.packageId,
    package: {
      name: state.name,
      procoreProjectServerId: accountProjectState.procoreProjectServerId,
      packageQueries: state.toolTabs
        .filter((t) => t.procoreTool !== null)
        .filter((t) => {
          return (
            Object.values(t.selectedItems).filter(
              (item) =>
                item.state === 'checked' || item.state === 'indeterminate'
            ).length > 0
          );
        })
        .map((t) => {
          const selectedItems =
            t.procoreTool.engineName === TOOL_ENGINE_NAMES.DOCUMENTS
              ? Object.values(t.selectedItems)
              : checkedItems(t.selectedItems);

          return {
            procoreToolId: t.procoreTool.id,
            filters: procoreFilterToInputFilters(t.selectedFilters),
            groupings: procoreGroupingsToInputGroupings(t.selectedGroupings),
            options: {
              type: t.procoreTool.engineName,
              options: t.extractOptions,
            } as PackageQueryOptionsInput,
            selectedItems: selectedItems,
          } as PackageQueryAttributes;
        }),
        options: state.options,
      exportTo: 'zip_file',
    },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBusyWorkingModalOpen, setIsBusyWorkingModalOpen] = useState(false);
  const [isFolderSelectionModalOpen, setIsFolderSelectionModalOpen] =
    useState(false);

  function openModal() {
    setIsModalOpen(true);
  }
  function closeModal() {
    setIsModalOpen(false);
  }
  function closeAndNavigateBack() {
    deletePackage();
    closeModal();
    navigateTo(`/teams/${accountId}/projects/${procoreProjectId}/packages`);
  }
  function closeBusyWorkingModal() {
    setIsBusyWorkingModalOpen(false);
  }
  function openBusyWorkingModal() {
    setIsBusyWorkingModalOpen(true);
  }
  function runExtract(
    exportTo: ExportToTypes,
    options: NewPackageExtractOptions
  ) {
    if (state.toolTabs.some((t) => t.loading)) {
      openBusyWorkingModal();
    } else {
      setIsFolderSelectionModalOpen(false);

      updatePackage({
        variables: {
          id: state.packageId,
          package: {
            name: state.name,
            procoreProjectServerId: accountProjectState.procoreProjectServerId,
            packageQueries: state.toolTabs
              .filter((t) => t.procoreTool !== null)
              .filter((t) => {
                return (
                  Object.values(t.selectedItems).filter(
                    (item) =>
                      item.state === 'checked' || item.state === 'indeterminate'
                  ).length > 0
                );
              })
              .map((t) => {
                const selectedItems =
                  t.procoreTool.engineName === TOOL_ENGINE_NAMES.DOCUMENTS
                    ? Object.values(t.selectedItems)
                    : checkedItems(t.selectedItems);

                return {
                  procoreToolId: t.procoreTool.id,
                  filters: procoreFilterToInputFilters(t.selectedFilters),
                  groupings: procoreGroupingsToInputGroupings(
                    t.selectedGroupings
                  ),
                  options: {
                    type: t.procoreTool.engineName,
                    options: t.extractOptions,
                  } as PackageQueryOptionsInput,
                  selectedItems: selectedItems,
                } as PackageQueryAttributes;
              }),
            options: options,
            exportTo: exportTo,
          },
        },
      });
    }
  }

  function extractToProcoreDocuments() {
    if (state.toolTabs.some((t) => t.loading)) {
      openBusyWorkingModal();
    } else {
      setIsFolderSelectionModalOpen(true);
    }
  }

  useEffect(() => {
    createPackage().then((result) => {
      dispatch({
        type: 'SET_PACKAGE',
        package: result.data.createPackage.package,
      });

      loadTools();
    });
  }, []);

  useEffect(() => {
    const updatePackage = idx(
      updatePackageData,
      (updatePackageData) => updatePackageData.updatePackage.success
    );
    if (updatePackageLoading || !updatePackage) {
      return;
    }

    fetchPackages();

    dispatchPackageHistory({ type: 'SET_DISPLAY_TOAST', value: true });
    dispatchPackageHistory({type: 'SET_PACKAGES_TAB_VALUE', value: 'history'})

    navigateTo(`/teams/${accountId}/projects/${procoreProjectId}/packages`);
  }, [updatePackageLoading, updatePackageData]);

  React.useEffect(() => {
    const procoreTools = idx(data, (data) => data.procoreTools);
    if (queryLoading || !procoreTools) {
      return;
    }

    dispatch({
      type: 'SET_PROCORE_TOOLS',
      value: {
        procoreTools: data.procoreTools,
        exportOptions: data.exportOptions,
        packageExportOption: data.packageExportOption,
      },
    });
  }, [queryLoading]);

  const toolsAreSelected = state.toolTabs.some(
    (t) => t.procoreTool != undefined || t.procoreTool != null
  );
  const packages = (idx(packageHistoryState.data, (data) => data.packages) ||
    []) as Package[];

  const isTrialAndExceedsLimit =
    accountProjectState.licenseType === 'trial' &&
    toolsAreSelected &&
    ((packages.length >= 5) ||
      state.toolTabs
        .filter((t) => t.procoreTool !== null)
        .filter((t) => {
          return (
            Object.values(t.selectedItems).filter(
              (item) =>
                item.state === 'checked' || item.state === 'indeterminate'
            ).length > 0
          );
        })
        .map((t) => checkedItems(t.selectedItems).length)
        .some((t) => t > 30));

  return (
    <>
      <SelectDocumentFolderModal
        open={isFolderSelectionModalOpen}
        runExtract={(options: NewPackageExtractOptions) =>
          runExtract('procore', options)
        }
        handleOnClose={() => setIsFolderSelectionModalOpen(false)}
      />

      <MyDialog
        maxWidth={'sm'}
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={isModalOpen}
      >
        <MyDialogTitle id="customized-dialog-title" onClose={closeModal}>
          {'Just Checking...'}
        </MyDialogTitle>
        <DialogContent dividers={false} style={{ marginBottom: '20px' }}>
          <Typography gutterBottom>
            {
              "If you leave this page you'll lose any progress you've made building this package. Do you want to leave anyway?"
            }
          </Typography>
        </DialogContent>
        <DialogDivider />
        <MyDialogActions>
          <Button
            autoFocus
            onClick={closeModal}
            variant="outlined"
            disableElevation={true}
            size="large"
          >
            {"Nevermind, I'll Stay"}
          </Button>
          <Button
            onClick={closeAndNavigateBack}
            size="large"
            variant="contained"
            disableElevation={true}
          >
            {'Leave Anyway'}
          </Button>
        </MyDialogActions>
      </MyDialog>

      <MyDialog
        maxWidth={'sm'}
        onClose={closeBusyWorkingModal}
        open={isBusyWorkingModalOpen}
      >
        <MyDialogTitle id="" onClose={closeBusyWorkingModal}>
          {"Sorry, we're still working..."}
        </MyDialogTitle>
        <DialogContent dividers={false} style={{ marginBottom: '20px' }}>
          <Typography gutterBottom>
            {
              "An extract cannot run while there's an operation in progress. Please wait for it to finish before running the extract again. "
            }
          </Typography>
        </DialogContent>
        <DialogDivider />
        <MyDialogActions>
          <Button
            autoFocus
            onClick={closeBusyWorkingModal}
            size="large"
            variant="contained"
            disableElevation={true}
          >
            {'Okay'}
          </Button>
        </MyDialogActions>
      </MyDialog>

      <Box
        display="flex"
        flexDirection="column"
        width={1}
        height={1}
        overflow="hidden"
      >
        <Box display="flex" flexDirection="row" width={1} height={1}>
          <Box
            display="flex"
            flexDirection="column"
            width={1}
            height={1}
            overflow="hidden"
          >
            <NewPackageActionBar
              showModal={openModal}
              runExtract={() => {
                runExtract('zip_file', state.options);
              }}
            />
            {accountProjectState.licenseType === 'trial' &&
              !createPackageLoading &&
              !queryLoading && (
                <RunExtractsFreeTrialBanner
                  primaryText={'Free Trial Mode'}
                  secondaryText={
                    'During your 7-day trial period, you can perform up to 5 data extracts. Each extract is capped at 30 records per tool. To maximize your trial experience, we suggest using multiple tools per extract to get a more complete view of the extracted data.'
                  }
                />
              )}
            {accountProjectState.licenseType !== 'trial' &&
              !createPackageLoading &&
              !queryLoading &&
              state.createdFromPackage && (
                <EditAndRerunExportBanner
                  primaryText={'Rerun extract'}
                  secondaryText={
                    'Note that any new items added since the last extract will not be automatically selected, so you may want to double check the currently selected items.'
                  }
                />
              )}
            {createPackageLoading || queryLoading ? (
              <Loading loadingLabel={'Loading tools...'} />
            ) : (
              <ExtractToolTabs />
            )}
          </Box>
          {!createPackageLoading && !queryLoading && (
            <Box
              style={{
                height: 'fit-content',
                marginLeft: '20px',
                background: 'white',
                width: '300px',
                borderRadius: '8px',
                marginTop: '84px',
                padding: 20,
                overflow: 'hidden',
                boxShadow:
                  '0px 1px 10px rgba(26, 32, 36, 0.06), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)',
              }}
            >
              <Box display={'flex'} flexDirection="column" height={1} width={1}>
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: 700,
                    color: Colors.darkerGray,
                    marginBottom: '16px',
                  }}
                >
                  Summary
                </Typography>
                {toolsAreSelected && (
                  <List
                    style={{
                      maxHeight: '150px',
                      overflowY: 'auto',
                      padding: 0,
                      marginBottom: '16px',
                    }}
                  >
                    {state.toolTabs.map((toolTab) => {
                      if (toolTab.procoreTool) {
                        return (
                          <CheckoutToolTabsListItem
                            key={`checkout-tool-tabs-${toolTab.procoreTool.id}`}
                            disableGutters={false}
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              width={1}
                            >
                              <Typography
                                style={{
                                  fontSize: '13px',
                                  fontWeight: 400,
                                  color: Colors.darkerGray,
                                }}
                              >
                                {toolTab.procoreTool.title}
                              </Typography>
                              <Box
                                display="inline-block"
                                style={{
                                  fontSize: '10px',
                                  color: Colors.darkerGray,
                                  backgroundColor: Colors.lightishGray,
                                  borderRadius: '20px',
                                  padding: '4px 10px',
                                  textAlign: 'center',
                                  fontWeight: 500,
                                  margin: 0,
                                }}
                              >
                                {
                                  Object.values(toolTab.selectedItems).filter(
                                    (item) =>
                                      item.state === 'checked' ||
                                      item.state === 'indeterminate'
                                  ).length
                                }
                              </Box>
                            </Box>
                          </CheckoutToolTabsListItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </List>
                )}
                <Divider style={{ marginBottom: '16px' }} />
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: Colors.darkerGray,
                  }}
                >
                  Extras
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width={1}
                  marginTop={'16px'}
                  alignItems={'center'}
                >
                  <Box display={'flex'} flexDirection={'row'} gap={'4px'} alignItems={'center'}>
                    <Tooltip title="The final extract will include an automatically generated Table of contents with links to each selected record.">
                      <InfoOutlinedIcon sx={{
                        height: '14px',
                        width: '14px',
                      }} />
                    </Tooltip>
                    <Typography
                      style={{
                        fontSize: '13px',
                        fontWeight: 400,
                        color: Colors.darkerGray,
                      }}
                    >
                      {'Table of contents page'}
                    </Typography>
                  </Box>
                  <BlueSwitch
                    size="small"
                    checked={state.options.include_table_of_contents}
                    onChange={(event) => {
                      dispatch({
                        type: 'SET_NEW_PACKAGE_OPTIONS',
                        value: {
                          options: {
                            ...state.options,
                            include_table_of_contents: event.target.checked,
                            include_description_in_table_of_contents: event
                              .target.checked
                              ? state.options
                                  .include_description_in_table_of_contents
                              : false,
                          },
                        },
                      });
                    }}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Box>
                {state.options.include_table_of_contents && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width={1}
                    marginTop={'16px'}
                    paddingLeft={'12px'}
                    alignItems={'center'}
                  >
                    <Box display={'flex'} flexDirection={'row'} gap={'4px'} alignItems={'center'}>
                      <Tooltip title="Each record's Procore description field will be included on the Table of contents.">
                        <InfoOutlinedIcon sx={{
                          height: '14px',
                          width: '14px',
                        }} />
                      </Tooltip>
                      <Typography
                        style={{
                          fontSize: '11px',
                          fontWeight: 400,
                          color: Colors.darkerGray,
                        }}
                      >
                        {'Include item description'}
                      </Typography>
                    </Box>
                    <BlueSwitch
                      size="small"
                      checked={
                        state.options.include_description_in_table_of_contents
                      }
                      onChange={(event) => {
                        dispatch({
                          type: 'SET_NEW_PACKAGE_OPTIONS',
                          value: {
                            options: {
                              ...state.options,
                              include_description_in_table_of_contents:
                                event.target.checked,
                            },
                          },
                        });
                      }}
                      name="checkedA"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Box>
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width={1}
                  marginTop={'16px'}
                  alignItems={'center'}
                >
                  <Box display={'flex'} flexDirection={'row'} gap={'4px'} alignItems={'center'}>
                    <Tooltip title="The header of each PDF will include your Procore project image.">
                      <InfoOutlinedIcon sx={{
                        height: '14px',
                        width: '14px',
                      }} />
                    </Tooltip>
                    <Typography
                      style={{
                        fontSize: '13px',
                        fontWeight: 400,
                        color: Colors.darkerGray,
                      }}
                    >
                      {'Include project photo'}
                    </Typography>
                  </Box>
                  <BlueSwitch
                    size="small"
                    checked={state.options.include_project_photo_on_pdf_header}
                    onChange={(event) => {
                      dispatch({
                        type: 'SET_NEW_PACKAGE_OPTIONS',
                        value: {
                          options: {
                            ...state.options,
                            include_project_photo_on_pdf_header: event.target.checked,
                          },
                        },
                      });
                    }}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Box>
                <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  style={{ gap: '8px' }}
                >
                  {state.packageExportOption &&
                    state.packageExportOption.exportToProcoreDocuments && (
                      <Button
                        startIcon={<ProcoreIcon />}
                        style={{
                          marginTop: 'auto',
                        }}
                        fullWidth={true}
                        variant="outlined"
                        disableElevation={true}
                        size="medium"
                        onClick={extractToProcoreDocuments}
                        disabled={
                          isTrialAndExceedsLimit ||
                          state.extractDisabled ||
                          updatePackageLoading
                        }
                      >
                        {'Extract To Documents'}
                      </Button>
                    )}
                  <Button
                    style={{
                      marginTop: 'auto',
                    }}
                    size="medium"
                    fullWidth={true}
                    variant="contained"
                    disableElevation={true}
                    onClick={() => runExtract('zip_file', state.options)}
                    disabled={
                      isTrialAndExceedsLimit ||
                      state.extractDisabled ||
                      updatePackageLoading
                    }
                  >
                    {'Extract .ZIP File'}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default NewPackage;
