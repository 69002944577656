import React from 'react';
import { useHistory } from 'react-router';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';

const useNavigationHook = (): ((path: string, procoreProjectId?: number) => void) => {
  const history = useHistory();
  const { getQueryParams } = React.useContext(AccountProjectContext);

  return (path: string, projectServerId?: number) => {
    const getPathWithQueryParams = (): string => {
      const queryParams = getQueryParams(projectServerId);
      if (queryParams) {
        // `/teams?procoreProjectId=123
        return `${path}${queryParams}`;
      } else {
        return path;
      }
    }

    history.push(getPathWithQueryParams());
  };
}

export default useNavigationHook;