import { SelectedItem } from "../graphql/mutations/UpdatePackage";
import { User } from "../types";
import { ProcoreToolFilter } from "./NewPackageContext";

export type ManageUsersState = {
  procoreItems: User[];
  filters: ProcoreToolFilter[];
  selectedFilters: ProcoreToolFilter[];
  searchValue: string;
  filterOpenState: Record<string, boolean>
  // TODO: Maybe this should be a Record<number, SelectedItem> to ensure uniqueness
  selectedItems: Record<string, SelectedItem>;
  loading: boolean;
  loadingMore: boolean;
  failedToFetchMore: boolean;
  filteredProcoreItemServerIds: number[];
};

export const initialState: ManageUsersState = {
  procoreItems: [],
  filters: [],
  selectedFilters: [],
  searchValue: '',
  filterOpenState: {},
  selectedItems: {},
  loading: true,
  loadingMore: false,
  failedToFetchMore: false,
  filteredProcoreItemServerIds: [],
};

export type Action =
  | { type: 'SET_PROCORE_ITEMS'; value: { procoreItems: User[], filteredProcoreItemServerIds: number[] } }
  | { type: 'APPEND_PROCORE_ITEMS'; value: { procoreItems: User[] } }
  | { type: 'SET_LOADING'; value: { loading: boolean; } }
  | { type: 'SET_LOADING_MORE'; value: { loadingMore: boolean; } }
  | { type: 'SET_FAILED_TO_FETCH_MORE'; value: { failedToFetchMore: boolean; } }
  | { type: 'SET_SEARCH_VALUE'; value: { searchValue: string; } }
  | { type: 'SET_FILTER_OPEN_STATE'; value: { filterOpenState: Record<string, boolean>; } }
  | { type: 'SET_FILTERS'; value: { filters: ProcoreToolFilter[], selectedFilters: ProcoreToolFilter[] }; }
  | { type: 'SET_SELECTED_FILTERS'; value: { selectedFilters: ProcoreToolFilter[]; } }
  | { type: 'SET_SELECTED_ITEMS'; value: { selectedItems: Record<string, SelectedItem>; } }

export const rootReducer: React.Reducer<ManageUsersState, Action> = (
  state,
  action
) => {
  switch (action.type) {
    case 'SET_PROCORE_ITEMS': {
      return {
        ...state,
        procoreItems: action.value.procoreItems,
        filteredProcoreItemServerIds: action.value.filteredProcoreItemServerIds
      };
    }

    case 'APPEND_PROCORE_ITEMS': {
      return {
        ...state,
        procoreItems: [...state.procoreItems, ...action.value.procoreItems]
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.value.loading
      };
    }

    case 'SET_LOADING_MORE': {
      return {
        ...state,
        loadingMore: action.value.loadingMore
      };
    }

    case 'SET_FAILED_TO_FETCH_MORE': {
      return {
        ...state,
        failedToFetchMore: action.value.failedToFetchMore
      };
    }

    case 'SET_SEARCH_VALUE': {
      return {
        ...state,
        searchValue: action.value.searchValue
      };
    }

    case 'SET_FILTER_OPEN_STATE': {
      return {
        ...state,
        filterOpenState: action.value.filterOpenState
      };
    }

    case 'SET_FILTERS': {
      return {
        ...state,
        filters: action.value.filters,
        selectedFilters: action.value.selectedFilters
      };
    }

    case 'SET_SELECTED_FILTERS': {
      return {
        ...state,
        selectedFilters: action.value.selectedFilters
      };
    }

    case 'SET_SELECTED_ITEMS': {
      return {
        ...state,
        selectedItems: action.value.selectedItems
      };
    }

    default:
      return state;
  }
};

export type ManageUsersContextState = {
  state: ManageUsersState;
  dispatch: (action: Action) => void;
};
