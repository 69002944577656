import React from 'react';
import Box from '@mui/material/Box';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { Colors } from '../../styles';
import { Typography } from './Typography';
import { CheckBoxOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import Link from '@mui/material/Link';
import { Divider, Stack, styled, Tooltip } from '@mui/material';
import { Button } from './Button';

interface IAutomationListSelectionPopupProps {
  open: boolean;
  onDeselect: () => void;
  onDelete: () => void;
  rowSelectionModel: GridRowSelectionModel;
}

const BlueButton = styled(Button)(() => ({
  '&.MuiButton-contained': {
    backgroundColor: Colors.blue1,
    boxShadow: 'none',
    color: Colors.newAccentBlue,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Colors.blue2,
    },
    '&:focus': {
      boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
    },
    '&:disabled': {
      backgroundColor: Colors.lightestAccentBlue,
      color: Colors.blue5,
    },
  },
}));

export const AutomationListSelectionPopup = ({
  open,
  onDeselect,
  onDelete,
  rowSelectionModel,
}: IAutomationListSelectionPopupProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        bottom: open ? '44px' : '24px',
        transition: 'all 0.2s ease-out',
        opacity: open ? 1 : 0,
        left: 0,
        right: 0,
        width: 'fit-content',
        margin: '0 auto',
        zIndex: '2',
      }}
    >
      <Box
        sx={{
          display: open ? 'flex' : 'none',
          alignItems: 'center',
          gap: '20px',
          borderRadius: '8px',
          border: `1px solid ${Colors.lightishGray}`,
          backgroundColor: Colors.white,
          boxShadow:
            '0px 6px 16px 1px rgba(36, 105, 158, 0.20), 0px 4px 4px -1px rgba(0, 130, 229, 0.20)',
          padding: '12px 16px 12px 20px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <CheckBoxOutlined sx={{ fontSize: '20px' }} />
            <Typography typestyle="s bold">
              {`${rowSelectionModel.length} items selected`}
            </Typography>
          </Box>
          <Link
            underline="hover"
            onClick={onDeselect}
            sx={{ cursor: 'pointer', color: Colors.newAccentBlue }}
          >
            <Typography typestyle="xs">Deselect All</Typography>
          </Link>
        </Box>
        <Divider
          sx={{
            height: '20px',
            borderWidth: '1px',
            borderColor: Colors.mediumLightGray,
          }}
        />
        <Stack direction="row" gap="8px" alignItems={'center'}>
          <Tooltip title="Delete" placement='top'>
            <BlueButton
              sx={{
                minWidth: '40px',
              }}
              onClick={onDelete}
              variant="contained"
              size="small"
              buttonborderstyle="pill"
            >
              <DeleteOutlineOutlined fontSize='small' />
            </BlueButton>
          </Tooltip>
        </Stack>
      </Box>
    </Box>
  );
};
