import { gql } from "@apollo/client";
import { FilterOptionInput } from "../../types";
import { ProcorePunchItem } from "../../types/punch_item";

export type ProcoreFilterPunchItemData = {
  punchItems: ProcorePunchItem[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  }
};

export type ProcorePunchItemVariables = {
  accountId: string;
  procoreProjectServerId: number;
};

export type ProcoreFilterPunchItemVariables = ProcorePunchItemVariables & {
  filters: FilterOptionInput[];
  procoreToolId: number;
  searchValue?: string;
  limit: number;
  offset: number;
};

export const PunchItemFragment = gql`
fragment PunchItemFragment on ProcorePunchItem {
  id
  procoreServerId
  formattedTitle
  assignees
  ballInCourts
  closedByName
  createdByName
  finalApproverName
  locationName
  priority
  punchItemManagerName
  status
  tradeName
  punchItemTypeName
  description
}
`;

export const filterProcorePunchItemsQuery = gql`
query FilterProcorePunchItems($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $searchValue: String, $limit: Int!, $offset: Int!) {
  punchItems(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...PunchItemFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${PunchItemFragment}
`;

export const fetchPaginatedProcorePunchItemsQuery = gql`
query FilterPaginatedProcorePunchItems($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $searchValue: String, $limit: Int!, $offset: Int!) {
  punchItems(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, searchValue: $searchValue, limit: $limit, offset: $offset) {
    ...PunchItemFragment
  }
}
${PunchItemFragment}
`;