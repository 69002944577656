import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogRequirement } from "../../../types";

export interface LogRequirementsUpdate {
  logRequirements: LogRequirement[];
  logRequirementItems: LogRequirementItem[];
  success: boolean;
}

export interface LogRequirementsUpdateData {
  bulkUpdateLogRequirements: LogRequirementsUpdate | null;
}

export interface LogRequirementsUpdateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logRequirementIds: number[];
  attributes: Record<string, any>;
}

export const useBulkUpdateLogRequirements = createMutationHook<
LogRequirementsUpdateData,
  LogRequirementsUpdateVariables
>(gql`
  mutation BulkUpdateLogRequirements(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $logRequirementIds: [ID!]!,
    $attributes: JSON!
  ) {
    bulkUpdateLogRequirements(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, logRequirementIds: $logRequirementIds, attributes: $attributes }
    ) {
      logRequirements {
        id
        description
        status
        logRequirementType {
          id
          name
        }
        logRequirementTrade {
          id
          name
        }
        responsibleContractor {
          id
          name
          procoreServerId
        }
        specSection {
          id
          name
          divisionName
          procoreServerId
        }
        customFields {
          id
          key
          fieldType
          value {
            id
            value
          }
        }
      }
      logRequirementItems {
        id
        logRequirementId
        recordType
        recordServerId
        engineName
        payload
      }
      success
    }
  }
`);
