import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface LogFileRequestDeleteData {
  deleteLogFileRequests: {
    success: boolean;
  }
}

export interface LogFileRequestDeleteVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logFileRequestIds: number[];
}

export const useDeleteLogFileRequests = createMutationHook<
  LogFileRequestDeleteData,
  LogFileRequestDeleteVariables
>(gql`
  mutation DeleteLogFileRequests(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $logFileRequestIds: [ID!]!
  ) {
    deleteLogFileRequests(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, logFileRequestIds: $logFileRequestIds }
    ) {
      success
    }
  }
`);
