import React from 'react';
import { Colors } from '../../styles';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { BlueSwitch } from './styles';

const ExportOptionToggle = (props: { label: string, sx?: SxProps<Theme>, labelFontSize?: string, labelFontWeight?: number, checked: boolean, onChange: (event: React.ChangeEvent<HTMLInputElement>) => void }): JSX.Element => {
  const { checked, onChange, label, labelFontSize = '14px', labelFontWeight = 400, sx } = props;

  return (
    <Box display="flex" flexDirection="row" style={{ gap: '8px'}} sx={sx} alignItems={'center'}>
      <BlueSwitch
        size="small"
        checked={checked}
        onChange={(event) => onChange(event) }
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      <Typography style={{ fontSize: labelFontSize, fontWeight: labelFontWeight, color: Colors.darkerGray }}>{label}</Typography>
    </Box>
  )
}

export default ExportOptionToggle;
