import { Box } from '@mui/material';
import React from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { ToolTabData } from '../../contexts/NewPackageContext';
import { RfiExtractOptions } from '../../types/rfi';
import ExportOptionToggle from './ExportOptionToggle';

const RfiOptions = (props: { toolTabIndex: number, toolTabData: ToolTabData }): JSX.Element => {
  const { toolTabIndex, toolTabData } = props;

  const { dispatch } = React.useContext(NewPackageContext);

  return (
    <Box display="flex" flexDirection="column" width={1} height={1} padding={'20px 40px'} style={{ overflowY: 'auto' }}>
      <h3>Attachments Content</h3>
      <ExportOptionToggle
        label={'Include official RFI responses only'}
        checked={(toolTabData.extractOptions as RfiExtractOptions).response_option == 'official_only'}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...toolTabData.extractOptions,
                response_option: event.target.checked ? 'official_only' : 'all'
              }
            }
          });
        }} />
    </Box>
  )
};

export default RfiOptions;