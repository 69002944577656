import React, { useContext, useState } from 'react';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';
import { Typography } from './shared/Typography';
import { Box, Divider, IconButton } from '@mui/material';
import { Colors, HeaderViewController, HeaderViewControllerTab } from '../styles';
import Close from '@mui/icons-material/Close';
import { TabContext, TabPanel } from '@mui/lab';
import { ManageRequirementTypes } from './ManageRequirementTypes';
import { ManageCloseoutTrades } from './ManageCloseoutTrades';
import { ManageCloseoutLogDetails } from './shared/ManageCloseoutLogDetails';
import { ManageCloseoutLogCustomFields } from './ManageCloseoutLogCustomFields';

interface ICloseoutLogSettingsModal {
  defaultCloseoutLogName: string;
  substantialCompletionDate?: Date;
  submitLoading: boolean;
  onClickSubmit: (text: string, newDate?: Date) => void;
  isOpen: boolean;
  onCloseModal: () => void;
}

const CloseoutLogSettingsModal = (props: ICloseoutLogSettingsModal): JSX.Element => {
  const { state: closeoutLogState, dispatch } = useContext(CloseoutLogContext);
  const { state } = useContext(AccountProjectContext);
  const [selectedTab, setSelectedTab] = useState<'details' | 'types' | 'trades' | 'custom_fields'>('details');

  return (
    <TabContext value={selectedTab}>
      <Dialog
        maxWidth={false}
        fullScreen
        open={props.isOpen}
        PaperProps={{
          sx: {
            position: 'absolute',
            width: 850,
            bottom: '0px',
            top: '0px',
            right: '0px',
          },
        }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            overflow: 'hidden',
          }}
        >
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} padding={'20px 24px'} borderBottom={`1px solid ${Colors.mediumLightGray}`}>
            <Box display={'flex'} flexDirection={'row'} gap={'24px'} alignItems={'center'}>
              <Typography typestyle="xl">Log Settings</Typography>
              <HeaderViewController
                  orientation="horizontal"
                  value={selectedTab}
                  onChange={(evt, newValue) => setSelectedTab(newValue)}
                  disabled={true}
                >
                <HeaderViewControllerTab
                  wrapped={true}
                  label="Details"
                  value={"details"}
                  disabled={false}
                />
                <HeaderViewControllerTab
                  wrapped={true}
                  label="Requirement Types"
                  value={"types"}
                  disabled={false}
                />
                <HeaderViewControllerTab
                  wrapped={true}
                  label="Trades"
                  value={"trades"}
                  disabled={false}
                />
                <HeaderViewControllerTab
                  wrapped={true}
                  label="Custom Fields"
                  value={"custom_fields"}
                  disabled={false}
                />
              </HeaderViewController>
            </Box>

            <Box display={'flex'} flexDirection={'row'} gap={'20px'}>
              <Divider orientation='vertical' sx={{ height: '100%' }}/>
              <IconButton disabled={props.submitLoading} onClick={props.onCloseModal}>
                <Close sx={{fontSize: '18px'}}/>
              </IconButton>
            </Box>
          </Box>

          <Box display={'flex'} flexGrow={1} flexDirection={'column'} width={1} padding={'20px 24px'}>
            <TabPanel sx={{ padding: 0, display: 'flex', flexDirection: 'column', height: selectedTab === 'details' ? '100%' : '0px' }} value={'details'}>
              <ManageCloseoutLogDetails
                defaultText={props.defaultCloseoutLogName}
                substantialCompletionDate={props.substantialCompletionDate}
                onClickSubmit={props.onClickSubmit}
                submitLoading={props.submitLoading} />
            </TabPanel>
            <TabPanel sx={{ padding: 0, display: 'flex', flexDirection: 'column', height: selectedTab === 'types' ? '100%' : '0px' }} value={'types'}>
              <ManageRequirementTypes />
            </TabPanel>
            <TabPanel sx={{ padding: 0, display: 'flex', flexDirection: 'column', height: selectedTab === 'trades' ? '100%' : '0px' }} value={'trades'}>
              <ManageCloseoutTrades />
            </TabPanel>
            <TabPanel sx={{ padding: 0, display: 'flex', flexDirection: 'column', height: selectedTab === 'custom_fields' ? '100%' : '0px' }} value={'custom_fields'}>
              <ManageCloseoutLogCustomFields />
            </TabPanel>
          </Box>
        </DialogContent>
      </Dialog>
    </TabContext>
  );
};

export default CloseoutLogSettingsModal;
