import React from 'react';
import { DataGridBasicEmptyState } from './shared/DataGridBasicEmptyState';

export const VendorListEmptyState = (): JSX.Element => {
  return (
    <DataGridBasicEmptyState
      title="No Companies"
      subtitle="Make sure this project's directory isn't empty"
    />
  );
};
