import { gql } from '@apollo/client';
import { Role } from '../../types';
import { createMutationHook } from '../GraphqlHelpers';

export interface UpdateUserRolesData {
  updateUserRoles: {
    success: boolean;
  }
}

export interface UpdateUserRolesVariables {
  accountId: string;
  procoreLoginInformationIds: number[];
  role: Role;
}

export const userUpdateUserRoles = createMutationHook<UpdateUserRolesData, UpdateUserRolesVariables>(gql`
mutation UpdateUserRoles($accountId: ID!, $procoreLoginInformationIds: [ID!]!, $role: String!) {
  updateUserRoles(input: { accountId: $accountId, procoreLoginInformationIds: $procoreLoginInformationIds, role: $role }) {
    success
  }
}
`);