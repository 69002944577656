import { gql } from '@apollo/client';
import { ExportToTypes, Package, ProcoreToolGroupings } from '../../types';
import { createMutationHook } from '../GraphqlHelpers';
import { CloseoutExtractOptions } from '../../contexts/CloseoutLogContext';

export interface CreateCloseoutLogPackage {
  package: Package | null;
  success: boolean;
}

export interface CreateCloseoutLogPackageData {
  createCloseoutLogPackage: CreateCloseoutLogPackage
}

export interface CreateCloseoutPackageAttributes {
  groupings: ProcoreToolGroupings[];
  exportTo: ExportToTypes;
  options: CloseoutExtractOptions;
}

export interface CreateCloseoutLogPackageVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  attributes: CreateCloseoutPackageAttributes;
}

export const useCreateCloseoutLogPackage = createMutationHook<CreateCloseoutLogPackageData, CreateCloseoutLogPackageVariables>(gql`
mutation CreateCloseoutLogPackage($accountId: ID!, $procoreProjectServerId: ID!, $closeoutLogId: ID!, $attributes: CreateCloseoutPackageAttributes!) {
  createCloseoutLogPackage(input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, attributes: $attributes }) {
    package {
      id
      name
      status
      createdAt
      exportTo
      downloadExportUrl
    }
    success
  }
}
`);