import { SelectedItem } from "../graphql/mutations/UpdatePackage";
import { ProcoreTool } from "../types";
import { ProcoreItem, ProcoreToolFilter } from "./NewPackageContext";

export type LinkProcoreItemToolTab = {
  procoreTool: ProcoreTool;
  procoreItems: ProcoreItem[];
  filters: ProcoreToolFilter[];
  selectedFilters: ProcoreToolFilter[];
  searchValue: string;
  filterOpenState: Record<string, boolean>
  // TODO: Maybe this should be a Record<number, SelectedItem> to ensure uniqueness
  selectedItems: Record<string, SelectedItem>;
  loading: boolean;
  loadingMore: boolean;
  failedToFetchMore: boolean;
  filteredProcoreItemServerIds: number[];
}

export type LinkProcoreItemsState = {
  toolTabs: LinkProcoreItemToolTab[];
  procoreTools: ProcoreTool[];
};

export const initialState: LinkProcoreItemsState = {
  toolTabs: [],
  procoreTools: []
};

export type Action =
  | { type: 'SET_PROCORE_ITEMS'; value: { procoreToolId: number; procoreItems: ProcoreItem[], filteredProcoreItemServerIds: number[] } }
  | { type: 'APPEND_PROCORE_ITEMS'; value: { procoreToolId: number; procoreItems: ProcoreItem[] } }
  | { type: 'SET_LOADING'; value: { procoreToolId: number; loading: boolean; } }
  | { type: 'SET_LOADING_MORE'; value: { procoreToolId: number; loadingMore: boolean; } }
  | { type: 'SET_FAILED_TO_FETCH_MORE'; value: { procoreToolId: number; failedToFetchMore: boolean; } }
  | { type: 'SET_SEARCH_VALUE'; value: { procoreToolId: number; searchValue: string; } }
  | { type: 'SET_FILTER_OPEN_STATE'; value: { procoreToolId: number; filterOpenState: Record<string, boolean>; } }
  | { type: 'SET_FILTERS'; value: { procoreToolId: number; filters: ProcoreToolFilter[], selectedFilters: ProcoreToolFilter[] }; }
  | { type: 'SET_SELECTED_FILTERS'; value: { procoreToolId: number; selectedFilters: ProcoreToolFilter[]; } }
  | { type: 'SET_SELECTED_ITEMS'; value: { procoreToolId: number; selectedItems: Record<string, SelectedItem>; } }

const updateToolTab = (
  toolTabs: LinkProcoreItemToolTab[],
  procoreToolId: number,
  updateToolTab: (toolTabData: LinkProcoreItemToolTab) => LinkProcoreItemToolTab): LinkProcoreItemToolTab[] => {
  return toolTabs.map((toolTab) => {
    if (toolTab.procoreTool.id === procoreToolId) {
      return updateToolTab(toolTab);
    } else {
      return toolTab;
    }
  });
};

export const rootReducer: React.Reducer<LinkProcoreItemsState, Action> = (
  state,
  action
) => {
  switch (action.type) {
    case 'SET_PROCORE_ITEMS': {
      const newToolTabs = updateToolTab(
        state.toolTabs,
        action.value.procoreToolId,
        (toolTab) => ({
          ...toolTab,
          procoreItems: action.value.procoreItems,
          filteredProcoreItemServerIds: action.value.filteredProcoreItemServerIds,
        })
      );

      return {
        ...state,
        toolTabs: newToolTabs
      }
    }

    case 'APPEND_PROCORE_ITEMS': {
      const newToolTabs = updateToolTab(
        state.toolTabs,
        action.value.procoreToolId,
        (toolTab) => ({
          ...toolTab,
          procoreItems: [...toolTab.procoreItems, ...action.value.procoreItems]
        })
      );

      return {
        ...state,
        toolTabs: newToolTabs
      }
    }

    case 'SET_LOADING': {
      const newToolTabs = updateToolTab(
        state.toolTabs,
        action.value.procoreToolId,
        (toolTab) => ({
          ...toolTab,
          loading: action.value.loading
        })
      );

      return {
        ...state,
        toolTabs: newToolTabs
      }
    }

    case 'SET_LOADING_MORE': {
      const newToolTabs = updateToolTab(
        state.toolTabs,
        action.value.procoreToolId,
        (toolTab) => ({
          ...toolTab,
          loadingMore: action.value.loadingMore
        })
      );

      return {
        ...state,
        toolTabs: newToolTabs
      }
    }

    case 'SET_FAILED_TO_FETCH_MORE': {
      const newToolTabs = updateToolTab(
        state.toolTabs,
        action.value.procoreToolId,
        (toolTab) => ({
          ...toolTab,
          failedToFetchMore: action.value.failedToFetchMore
        })
      );

      return {
        ...state,
        toolTabs: newToolTabs
      }
    }

    case 'SET_SEARCH_VALUE': {
      const newToolTabs = updateToolTab(
        state.toolTabs,
        action.value.procoreToolId,
        (toolTab) => ({
          ...toolTab,
          searchValue: action.value.searchValue
        })
      );

      return {
        ...state,
        toolTabs: newToolTabs
      }
    }

    case 'SET_FILTER_OPEN_STATE': {
      const newToolTabs = updateToolTab(
        state.toolTabs,
        action.value.procoreToolId,
        (toolTab) => ({
          ...toolTab,
          filterOpenState: action.value.filterOpenState
        })
      );

      return {
        ...state,
        toolTabs: newToolTabs
      }
    }

    case 'SET_FILTERS': {
      const newToolTabs = updateToolTab(
        state.toolTabs,
        action.value.procoreToolId,
        (toolTab) => ({
          ...toolTab,
          filters: action.value.filters,
          selectedFilters: action.value.selectedFilters
        })
      );

      return {
        ...state,
        toolTabs: newToolTabs
      }
    }

    case 'SET_SELECTED_FILTERS': {
      const newToolTabs = updateToolTab(
        state.toolTabs,
        action.value.procoreToolId,
        (toolTab) => ({
          ...toolTab,
          selectedFilters: action.value.selectedFilters
        })
      );

      return {
        ...state,
        toolTabs: newToolTabs
      }
    }

    case 'SET_SELECTED_ITEMS': {
      const newToolTabs = updateToolTab(
        state.toolTabs,
        action.value.procoreToolId,
        (toolTab) => ({
          ...toolTab,
          selectedItems: action.value.selectedItems
        })
      );

      return {
        ...state,
        toolTabs: newToolTabs
      }
    }

    default:
      return state;
  }
};

export type LinkProcoreItemsContextState = {
  state: LinkProcoreItemsState;
  dispatch: (action: Action) => void;
};
