import { gql } from "@apollo/client";
import { createQueryHook } from "../GraphqlHelpers";
import { ProcoreProjectUser } from "../../types";

export type ProjectUserData = {
  projectUsers: ProcoreProjectUser[];
};

export type ProjectUserVariables = {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  vendorIds: number[];
};

export const GetProcoreProjectUsersQuery = gql`
query GetProcoreProjectUsers($accountId: ID!, $procoreProjectServerId: ID!, $closeoutLogId: ID!, $vendorIds: [ID!]!) {
  projectUsers(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, vendorIds: $vendorIds) {
    id
    name
    procoreServerId
    vendorId
    vendorName
    emailAddress
    jobTitle
    businessPhone
    mobilePhone
  }
}
`;

export const useGetProcoreProjectUsers = createQueryHook<ProjectUserData, ProjectUserVariables>(GetProcoreProjectUsersQuery);