import { Box, SvgIconProps, Tab, TabProps, Tabs, TabsProps, styled } from '@mui/material';
import { TabContext, TabPanel, TabPanelProps } from '@mui/lab';
import React, { useContext } from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import ExtractToolContainer from './ExtractToolContainer';
import { Colors } from '../../styles';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseIcon from "@mui/icons-material/Close";

const CustomTabPanel = styled(TabPanel)<TabPanelProps & { enabled: string }>(({ enabled }) => {
 if (enabled === "true") {
  return {
    padding: 0,
    width: "100%",
    height: "100%",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    fontWeight: 400,
  };
 } else {
  return {
    padding: 0,
    width: "100%",
    height: "100%",
    margin: 0,
    fontWeight: 400,
  };
 }
})


const RemoveToolTabCloseIcon = styled(CloseIcon)<SvgIconProps>(() => {
  return {
    height: "16px",
    width: "16px",
    color: Colors.mediumDarkGray,
    background: 'white',
    "&:hover": {
      color: Colors.darkGray,
      background: 'white'
    }
  }
});

const ToolPickerTabs = styled(Tabs)<TabsProps>(() => {
  return {
    overflow: 'unset !important',
    background: Colors.white,
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    boxShadow: '0px 1px 10px rgba(26, 32, 36, 0.06), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)',
    zIndex: 0,
    paddingLeft: '16px',
    paddingRight: '16px',
    '& .MuiTabs-flexContainer': {
      height: '100%'
    },

    '& .MuiTabs-indicator': {
      backgroundColor: Colors.darkerGray,
    }
  };
});

const NewTabToolPickerTab = styled(Tab)<TabProps>(() => {
  return {
    fontSize: '1rem',
    minWidth: 'auto',
    paddingLeft: '24px',
    paddingRight: '24px',
    '& .MuiTab-labelIcon' : {
      minHeight: 'unset',
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiTab-wrapped': {
      flexDirection: 'row-reverse'
    }
  };
});

const ToolPickerTab = styled(Tab)<TabProps>(() => {
 return {
   fontSize: '1rem',
   minWidth: 'auto',
   paddingLeft: 0,
   paddingRight: 0,
   '& .MuiTab-labelIcon': {
     minHeight: 'unset',
     paddingTop: 0,
     paddingBottom: 0,
   },
   '& .MuiTab-wrapped': {
     flexDirection: 'row-reverse'
   }
 }
});

const AddToolPickerTab = styled(Tab)<TabProps>(() => {
  return {
    fontSize: '1rem',
    minWidth: 'auto',
    '& .MuiTab-labelIcon': {
      minHeight: 'unset',
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiTab-wrapped': {
      flexDirection: 'row-reverse'
    }
  }
});

const ExtractToolTabs = (): JSX.Element => {
  const { state, dispatch } = useContext(NewPackageContext);
  const [selectedToolTab, setSelectedToolTab] = React.useState<number>(0);

  const handleOnSelectedToolTabChanged = (event: React.SyntheticEvent, newValue: string) => {
    const index = Number.parseInt(newValue);
    if (index === -1) {
      dispatch({
        type: 'APPEND_TOOL_TAB'
      });
      setSelectedToolTab((state.toolTabs.length - 1) + 1);
    } else {
      setSelectedToolTab(index);
    }
  };

  const renderToolTabs = (): JSX.Element[] => {
    const toolTabs = state.toolTabs.map((toolTab, index) => {
      if (toolTab.procoreTool === null) {
        return (
          <NewTabToolPickerTab
            wrapped={true}
            key={`tool-tab-${index}`}
            label={'New Tab'}
            value={index} />
        );
      } else {
        return (
          <ToolPickerTab
            wrapped={true}
            key={`tool-tab-${index}`}
            label={
              <Box
                display='flex'
                flexDirection='row'
                justifyContent={'center'}
                alignItems={'center'}
                style={{
                  gap: '8px',
                  paddingLeft: 24,
                  paddingRight: 16,
                  borderRight: `1px solid ${Colors.lightishGray}`,
                }}>
                <p style={{ margin: 0, padding: 0 }}>{toolTab.procoreTool.title}</p>
                <RemoveToolTabCloseIcon onClick={(evt) => {
                  evt.stopPropagation();
                  dispatch({
                    type: 'REMOVE_TOOL_TAB',
                    value: {
                      toolTabIndex: index
                    }
                  });
                  setSelectedToolTab(Math.max(selectedToolTab - 1, 0));
                }} />
              </Box>
            }
            value={index} />
        );
      }
    });

    if (state.toolTabs.length === 1 && state.toolTabs[0].procoreTool === null) {
      return toolTabs;
    } else {
      const last = state.toolTabs[state.toolTabs.length - 1];
      const usedTools = state.toolTabs.map(t => t.procoreTool);
      const allToolsUsed = state.procoreTools.every(t => usedTools.includes(t));
      if (last.procoreTool !== null && !allToolsUsed) {
        return toolTabs.concat([
          <AddToolPickerTab
            wrapped={true}
            key={`tool-tab-add-1`}
            icon={<AddCircleOutlineOutlinedIcon />}
            value={-1} />
        ])
      } else {
        return toolTabs;
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" height={1} width={1} overflow="hidden">
      <TabContext value={selectedToolTab.toString()}>
        <ToolPickerTabs
          variant="scrollable"
          key={'tool-tabs'}
          value={selectedToolTab}
          orientation="horizontal"
          onChange={handleOnSelectedToolTabChanged}>
          {
            renderToolTabs()
          }
        </ToolPickerTabs>
        <Box sx={{
            padding: 0,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            overflow: "hidden",
            margin: 0,
          }}>
          {
            state.toolTabs.map((toolTab, index) => {
              return (
                <CustomTabPanel
                  value={index.toString()}
                  style={{ padding: 0}}
                  enabled={(selectedToolTab === index).toString()}
                  key={`extract-tool-container-tab-panel-${index}`}>
                  <ExtractToolContainer
                    key={`extract-tool-container-${index}`}
                    toolTabIndex={index}
                    toolTabData={toolTab} />
                </CustomTabPanel>
              );
            })
          }
        </Box>
      </TabContext>
    </Box>
  )
}

export default ExtractToolTabs;