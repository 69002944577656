import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogRequirement, LogRequirementItem } from "../../../types";

export interface ImportProcoreSubmittals {
  logRequirements: LogRequirement[];
  logRequirementItems: LogRequirementItem[];
  success: boolean;
}

export interface ImportProcoreSubmittalsData {
  importProcoreSubmittals: ImportProcoreSubmittals | null;
}

export interface ImportProcoreSubmittalsVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  procoreServerIds: number[];
  options: Record<string, any>;
}

export const useImportProcoreSubmittals = createMutationHook<
ImportProcoreSubmittalsData,
ImportProcoreSubmittalsVariables
>(gql`
  mutation ImportProcoreSubmittals(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $procoreServerIds: [ID!]!,
    $options: JSON!
  ) {
    importProcoreSubmittals(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, procoreServerIds: $procoreServerIds, options: $options }
    ) {
      logRequirements {
        id
        description
        status
        logRequirementType {
          id
          name
        }
        logRequirementTrade {
          id
          name
        }
        responsibleContractor {
          id
          name
          procoreServerId
        }
        specSection {
          id
          name
          divisionName
          procoreServerId
        }
        customFields {
          id
          key
          fieldType
          value {
            id
            value
          }
        }
      }
      logRequirementItems {
        id
        logRequirementId
        recordType
        recordServerId
        engineName
        payload
      }
      success
    }
  }
`);
