import Box from "@mui/material/Box";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import React from "react";
import { Colors } from "../../styles";

export default function CircularProgressOverlay(
  props: CircularProgressProps & { value: number, progressColor?: string },
) {
  const {progressColor, ...rest} = props;
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <Box sx={{ position: 'relative', display: 'flex' }}>
        <CircularProgress
          variant="determinate"
          sx={{ color: Colors.lightishGray }}
          {...rest}
          value={100} />
        <CircularProgress
          variant="determinate"
          sx={{ color: progressColor ? progressColor : Colors.newAccentBlue, position: 'absolute', left: 0 }}
          {...rest} />
      </Box>
    </Box>
  );
}
