import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface AddUsersToAccountData {
  addUsersToAccount: {
    success: boolean;
  }
}

export interface AddUsersToAccountVariables {
  accountId: string;
  procoreLoginInformationIds: number[];
}

export const useAddUsersToAccount = createMutationHook<AddUsersToAccountData, AddUsersToAccountVariables>(gql`
mutation AddUsersToAccount($accountId: ID!, $procoreLoginInformationIds: [ID!]!) {
  addUsersToAccount(input: { accountId: $accountId, procoreLoginInformationIds: $procoreLoginInformationIds }) {
    success
  }
}
`);