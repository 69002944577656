import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { TemplateLogRequirementType } from "../../../types";

export interface TemplateLogRequirementTypeCreate {
  templateLogRequirementType?: TemplateLogRequirementType;
  success: boolean;
}

export interface TemplateLogRequirementTypeCreateData {
  createTemplateLogRequirementType: TemplateLogRequirementTypeCreate | null;
}

export interface TemplateLogRequirementTypeCreateVariables {
  accountId: string;
  templateCloseoutLogId: number;
  name: string;
}

export const useCreateTemplateLogRequirementType = createMutationHook<
  TemplateLogRequirementTypeCreateData,
  TemplateLogRequirementTypeCreateVariables
>(gql`
  mutation CreateTemplateLogRequirementType(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $name: String!
  ) {
    createTemplateLogRequirementType(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, name: $name }
    ) {
      templateLogRequirementType {
        id
        name
      }
      success
    }
  }
`);
