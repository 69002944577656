import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { CloseoutLogColumnDefinition, TemplateCloseoutLogDataGridView } from "../../../types";
import { GridFilterItem } from "@mui/x-data-grid-premium";

export interface TemplateCloseoutLogDataGridViewUpdateData {
  updateTemplateCloseoutLogDataGridView: {
    success: boolean;
    templateCloseoutLogDataGridView: TemplateCloseoutLogDataGridView;
  }
}

interface TemplateCloseoutLogDataGridViewUpdateAttributes {
  columnDefinitions: CloseoutLogColumnDefinition[];
  sortModel: { field: string; sort: string; }[];
  groupings: string[];
  groupingColumnDefinition: CloseoutLogColumnDefinition;
  filters: GridFilterItem[];
}

export interface TemplateCloseoutLogDataGridViewUpdateVariables {
  accountId: string;
  templateCloseoutLogId: number;
  id: number;
  attributes: TemplateCloseoutLogDataGridViewUpdateAttributes;
}

export const useUpdateTemplateCloseoutLogDataGridView = createMutationHook<
  TemplateCloseoutLogDataGridViewUpdateData,
  TemplateCloseoutLogDataGridViewUpdateVariables
>(gql`
  mutation UpdateTemplateCloseoutLogDataGridView(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $id: ID!,
    $attributes: TemplateCloseoutLogDataGridViewUpdateAttributes!
  ) {
    updateTemplateCloseoutLogDataGridView(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, id: $id, attributes: $attributes }
    ) {
      success
      templateCloseoutLogDataGridView {
        id
        name
        personal
        columnDefinitions
        sortModel
        groupingColumnDefinition
        groupings
        filters
      }
    }
  }
`);
