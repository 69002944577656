import { Colors } from "../../styles";
import React from 'react';
import { Box } from '@mui/material';

export interface StatusPillProps {
  status: string;
  color?: string;
  backgroundColor?: string;
}

const getBackgroundColorForPillStatus = (status: string): string => {
  switch (status) {
    case "pending":
      return Colors.lightBlue;
    case "fetching_api_records":
    case "in_progress":
      return Colors.lightBlue;
    case 'on':
      return Colors.lightGreen;
    case 'off':
      return '#FFE5E9';
    case "zipping":
      return Colors.lightBlue;
    case "uploading":
      return Colors.lightBlue;
    case "complete":
      return Colors.lightGreen;
    case "canceled":
      return '#FA5959';
    case 'failed_size_limit_exceeded':
      return '#FFE5E9';
    default:
      return "";
  }
};

const getColorForPillStatus = (status: string): string => {
  switch (status) {
    case "fetching_api_records":
    case "in_progress":
      return Colors.mediumBlue;
    case 'pending':
      return Colors.mediumBlue;
    case "zipping":
      return Colors.mediumBlue;
    case 'on':
      return Colors.mediumGreen;
    case 'off':
      return '#991A1B';
    case "uploading":
      return Colors.mediumBlue;
    case "complete":
      return Colors.mediumGreen;
    case "canceled":
      return Colors.white;
    case 'failed_size_limit_exceeded':
      return '#991A1B';
    default:
      return "";
  }
};

const StatusPill = (props: { status: string, color?: string, backgroundColor?: string, children: string | JSX.Element | JSX.Element[] }): JSX.Element => {
  const { status, color, backgroundColor } = props;

  return (
    <Box
      margin={0}
      fontWeight={'bold'}
      display="inline-block"
      style={{
        color: color ? color : getColorForPillStatus(status),
        backgroundColor: backgroundColor ? backgroundColor : getBackgroundColorForPillStatus(status),
        fontSize: '14px',
        textAlign: 'center',
        padding: '4px 10px',
        borderRadius: '6px'
      }}
    >
      {props.children}
    </Box>
  )
};

export default StatusPill;