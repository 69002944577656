import { gql, QueryResult, useQuery } from '@apollo/client';
import { AccountProjectData } from '../../types';

interface AccountProjectVariables {
  accountId: string;
  licensed: boolean;
  procoreCompanyAdmin: boolean;
}

export const GetAccountProjectsQuery = gql`
  query GetAccountProjects($accountId: ID!, $licensed: Boolean!, $procoreCompanyAdmin: Boolean!) {
    procoreProjects(accountId: $accountId, licensed: $licensed, procoreCompanyAdmin: $procoreCompanyAdmin) {
      procoreServerId
      procoreCompanyServerId
      name
      displayName
      projectNumber
      licensed
      licenseType
      licenseEndDate
      timeZone
      canFreeTrial
      procoreCompanyName
      dmsaEnabled
      stage
      active
    }
  }
`;

export const useGetAccountProjects = (
  accountId: string,
  licensed: boolean,
  procoreCompanyAdmin: boolean = false
): QueryResult<AccountProjectData> =>
  useQuery<AccountProjectData, AccountProjectVariables>(
    GetAccountProjectsQuery,
    {
      variables: {
        accountId: accountId,
        licensed: licensed,
        procoreCompanyAdmin: procoreCompanyAdmin
      },
      fetchPolicy: 'cache-and-network',
    }
  );
