import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from './shared/Typography';
import Box from '@mui/material/Box';
import { LoadingButton as Button } from './shared/Button';
import { useDeleteLogRequirements } from '../graphql/mutations/closeout/DeleteLogRequirements';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';

interface IConfirmDeleteRequirementModalProps {
  logRequirementIds: number[];
  onClose: () => void;
}

export const ConfirmDeleteRequirementModal = (
  props: IConfirmDeleteRequirementModalProps & DialogProps
): JSX.Element => {
  const { logRequirementIds, onClose, ...rest } = props;
  const { state, dispatch } = React.useContext(CloseoutLogContext);
  const { state: accountProjectState } = React.useContext(
    AccountProjectContext
  );

  const [deleteLogRequirement, { loading: loadingDeleteRequirement }] =
    useDeleteLogRequirements({
      accountId: accountProjectState.accountId,
      procoreProjectServerId: accountProjectState.procoreProjectServerId,
      closeoutLogId: state.id,
      logRequirementIds: [],
    });

  const handleDeleteLogRequirement = () => {
    deleteLogRequirement({
      variables: {
        accountId: accountProjectState.accountId,
        procoreProjectServerId: accountProjectState.procoreProjectServerId,
        closeoutLogId: state.id,
        logRequirementIds: logRequirementIds,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: 'DELETE_LOG_REQUIREMENTS',
          value: { logRequirementIds: logRequirementIds },
        });
        onClose();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <Dialog fullWidth disableScrollLock maxWidth="xs" {...rest}>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
      >
        <Typography typestyle="xl">Confirm Action</Typography>
        <Typography>{`Are you sure you want to delete ${
          logRequirementIds.length
        } requirement${
          logRequirementIds.length === 1 ? '' : 's'
        }?`}</Typography>
        <Box display="flex" justifyContent="end" gap="8px">
          <Button
            disabled={loadingDeleteRequirement}
            variant="outlined"
            buttonborderstyle="pill"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            loading={loadingDeleteRequirement}
            variant="contained"
            buttonborderstyle="pill"
            onClick={() => handleDeleteLogRequirement()}
          >
            {loadingDeleteRequirement ? '' : 'Delete'}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
