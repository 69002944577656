import {
  Box,
  CircularProgress,
  TableCell,
  Typography,
  Popover,
  MenuItem,
  styled,
  TableCellProps,
} from "@mui/material";
import React, { useState } from "react";
import useNavigationHook from "../../hooks/UseNavigationHook";
import { Colors, SecondarySettingsButton } from "../../styles";
import { Account, Project } from "../../types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AccountProjectContext } from "../../contexts/AccountProjectContextProvider";
import StatusPill from "../shared/StatusPill";
import { getDaysRemaining, isAnnualLicense, isOwnerOrAdmin } from "../../utils/utils";
import { Button } from "../shared/Button";

const TableRowCell = styled(TableCell)<TableCellProps>(() => {
  return {
    borderBottom: `1px solid ${Colors.lightishGray}`,
    color: Colors.darkerGray,
    padding: 0,
    paddingLeft: "12px",
    paddingRight: "12px",
    '&:hover': {
      background: Colors.lightestGray2,
      cursor: 'pointer',
    }
  }
});

export const LicensedTableRowCell = (props: {
  account: Account;
  project: Project;
  loading: boolean;
  setConfirmDeactivateProjectModalOpen: (value: boolean) => void;
  setCurrentProcoreProjectServerId: (value: number) => void;
  manageBilling: (procoreProjectServerId: number) => void;
}): JSX.Element => {
  const [showRowActions, setShowRowActions] = useState(false);
  const [
    licensedProjectMoreActionsAnchorEl,
    setLicensedProjectMoreActionsAnchorEl,
  ] = useState(null);
  const [moreProjectOptionsAnchorEl, setMoreProjectOptionsAnchorEl] =
    useState(null);
  const navigateTo = useNavigationHook();
  const { dispatch } = React.useContext(AccountProjectContext);

  const handleOnMoreOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLicensedProjectMoreActionsAnchorEl(event.currentTarget);
  };

  const handleOnProjectClick = () => {
    dispatch({
      type: "SET_ACCOUNT_PROJECT",
      value: {
        accountProject: {
          accountId: account.id,
          internal: account.internal,
          closeoutLogEnabled: account.closeoutLogEnabled && project.dmsaEnabled,
          closeoutLogFileRequestEnabled: account.closeoutLogFileRequestEnabled && project.dmsaEnabled,
          configuredAutomations: account.configuredAutomations,
          automaticExportsEnabled: account.automaticExportsEnabled && project.dmsaEnabled,
          procoreCompanyServerId: project.procoreCompanyServerId,
          procoreProjectServerId: project.procoreServerId,
          projectName: project.displayName || project.name,
          dateFormat: account.dateFormat,
          timeZone: project.timeZone,
          licensed: project.licensed,
          licenseType: project.licenseType,
          licenseEndDate: project.licenseEndDate,
          appBaseUrl: account.appBaseUrl,
        }
      },
    });
    navigateTo(
      `/teams/${account.id}/projects/${project.procoreServerId}/packages`
    );
  }

  const { project, account, manageBilling, loading, setConfirmDeactivateProjectModalOpen, setCurrentProcoreProjectServerId } = props;
  return (
    <TableRowCell
      component="td"
      scope="row"
      onClick={() => {
        handleOnProjectClick();
      }}
      onMouseEnter={() => setShowRowActions(true)}
      onMouseLeave={() => setShowRowActions(false)}
    >
      <Box
        width={1}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        minHeight={"52px"}
      >
        <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: "12px" }}>
          <Box display={"flex"} flexDirection="column" alignItems={"start"}>
            <Typography style={{ fontSize: '14px', fontWeight: '500', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '500px', color: '#171A1C' }}>{project.displayName || project.name}</Typography>
            {
              project.licenseEndDate && (
                <Typography style={{ fontSize: '12px', fontWeight: '400', color: '#596A75' }}>{`Licensed until ${new Date(project.licenseEndDate).toLocaleDateString()}`}</Typography>
              )
            }
          </Box>
          { project.licenseType === 'trial' && <StatusPill status="" color={Colors.darkGray} backgroundColor={"#E6F3FC"}>{`${getDaysRemaining(project.licenseEndDate)} days left`}</StatusPill>}
        </Box>
        {showRowActions && !isAnnualLicense(account) && (
          <Box
            display="flex"
            flexDirection={"row"}
            style={{ gap: "8px" }}
            alignItems="center"
          >
            <Button
              size="small"
              variant="contained"
              style={{
                maxHeight: "30px",
              }}
              onClick={(event) => {
                event.stopPropagation();

                handleOnProjectClick();
              }}
            >
              View
            </Button>

            {project.licenseType !== "trial" && (
              <SecondarySettingsButton
                variant="outlined"
                disableElevation={true}
                size="small"
                onClick={(event) => {
                  event.stopPropagation();

                  handleOnMoreOptionsClick(event);
                }}
                startIcon={<MoreVertIcon />}
              ></SecondarySettingsButton>
            )}
            <Popover
              open={Boolean(licensedProjectMoreActionsAnchorEl)}
              anchorEl={licensedProjectMoreActionsAnchorEl}
              onClose={(event) => {
                event?.stopPropagation();

                setLicensedProjectMoreActionsAnchorEl(null);
                setShowRowActions(false);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                key="manage-billing"
                onClick={(event) => {
                  event.stopPropagation();

                  manageBilling(project.procoreServerId);
                }}
              >
                {loading ? (
                  <Box
                    display={"flex"}
                    flexDirection="row"
                    alignItems={"center"}
                    justifyContent="center"
                  >
                    <CircularProgress size={12} style={{ margin: "5px" }} />
                    <Typography>Manage Billing</Typography>
                  </Box>
                ) : (
                  "Manage Billing"
                )}
              </MenuItem>
            </Popover>
          </Box>
        )}
        {showRowActions && isAnnualLicense(account) && (
          <Box
            display="flex"
            flexDirection={"row"}
            style={{ gap: "8px" }}
            alignItems="center"
          >
            <SecondarySettingsButton
              variant="outlined"
              disableElevation={true}
              size="small"
              style={{
                maxHeight: "30px",
              }}
              onClick={() => {
                dispatch({
                  type: "SET_ACCOUNT_PROJECT",
                  value: {
                    accountProject: {
                      accountId: account.id,
                      internal: account.internal,
                      closeoutLogEnabled: account.closeoutLogEnabled && project.dmsaEnabled,
                      closeoutLogFileRequestEnabled: account.closeoutLogFileRequestEnabled && project.dmsaEnabled,
                      configuredAutomations: account.configuredAutomations,
                      automaticExportsEnabled: account.automaticExportsEnabled && project.dmsaEnabled,
                      procoreCompanyServerId: project.procoreCompanyServerId,
                      procoreProjectServerId: project.procoreServerId,
                      dateFormat: account.dateFormat,
                      projectName: project.displayName || project.name,
                      timeZone: project.timeZone,
                      licensed: project.licensed,
                      licenseType: project.licenseType,
                      licenseEndDate: project.licenseEndDate,
                      appBaseUrl: account.appBaseUrl,
                    }
                  },
                });
                navigateTo(
                  `/teams/${account.id}/projects/${project.procoreServerId}/packages`
                );
              }}
            >
              View
            </SecondarySettingsButton>
            {isOwnerOrAdmin(account) && project.licenseType !== 'trial' && (
              <>
                <SecondarySettingsButton
                  variant="outlined"
                  disableElevation={true}
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();

                    setMoreProjectOptionsAnchorEl(event.currentTarget);
                  }}
                  startIcon={<MoreVertIcon />}
                ></SecondarySettingsButton>
                <Popover
                  open={Boolean(moreProjectOptionsAnchorEl)}
                  anchorEl={moreProjectOptionsAnchorEl}
                  onClose={(event) => {
                    event?.stopPropagation();

                    setMoreProjectOptionsAnchorEl(null);
                    setShowRowActions(false);
                    setCurrentProcoreProjectServerId(null);
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    key="archive-package"
                    onClick={(event) => {
                      event.stopPropagation();

                      setMoreProjectOptionsAnchorEl(null);
                      setShowRowActions(false);

                      setCurrentProcoreProjectServerId(project.procoreServerId);
                      setConfirmDeactivateProjectModalOpen(true);
                    }}
                  >
                    {"Deactivate"}
                  </MenuItem>
                </Popover>
              </>
            )}
          </Box>
        )}
      </Box>
    </TableRowCell>
  );
};
