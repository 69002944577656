import React from 'react';
import { IconButtonProps, Snackbar, styled } from '@mui/material';

import { Box, IconButton, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';
import { PackageHistoryContext } from '../contexts/PackageContextProvider';

const DismissSnackbarIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    width: "22px",
    height: "22px",
    background: 'transparent',
    marginLeft: '32px',
    zIndex: 1,
    "&:hover": {
      background: 'transparent',
    },
  };
});

const NewPackageSnackbar = () => {
  const { state, dispatch } = React.useContext(PackageHistoryContext);

  return (
    <Snackbar
      open={state.displayPackageInProgressToast}
      autoHideDuration={3_000}
      onClose={(event, reason) => {
        if (reason !== 'timeout') { return; }

        dispatch({ type: 'SET_DISPLAY_TOAST', value: false })
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Box
        display={"flex"}
        sx={{
          backgroundColor: '#E6F3FC',
          padding: "12px 18px",
          borderRadius: '3px',
          boxShadow: '0px 2px 4px 1px rgba(26, 32, 36, 0.04)',
          filter: 'drop-shadow(0px 1px 10px rgba(26, 32, 36, 0.06)) drop-shadow(0px 4px 5px rgba(26, 32, 36, 0.07))',
        }}
        alignItems={"center"}
        flexDirection={"row"}>
        <SyncIcon htmlColor='#0B2538' sx={{ marginRight: '12px'}} />
        <Box display={"flex"} flexDirection={"column"}>
          <Typography sx={{ color: "#0B2538", fontSize: '13px', fontWeight: 700 }}>Extracting your data</Typography>
          <Typography sx={{ color: "#0B2538", fontSize: '13px', fontWeight: 400 }}>We’ll email you a link when it’s ready.</Typography>
        </Box>
        <DismissSnackbarIconButton onClick={() => { dispatch({ type: 'SET_DISPLAY_TOAST', value: false }) }}>
          <CloseIcon htmlColor={'#A0B0BA'} />
        </DismissSnackbarIconButton>
      </Box>
    </Snackbar>
  );
}

export default NewPackageSnackbar;