import { Typography } from "../shared/Typography";
import { Box } from "@mui/material";
import { Colors } from "../../styles";
import React from "react";

const CloseoutLogListEmptyState = (): JSX.Element => {
  return (
    <Box
      display="flex"
      height={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: '8px'
      }}
    >
      <Box
        height="200px"
        width="200px"
        sx={{
          background:
            'url("/images/checklist_illustration.png") no-repeat center / contain',
        }}
      ></Box>
      <Typography typestyle="l">No Closeout Logs</Typography>
      <Typography sx={{ color: Colors.darkishGray, width: '500px', textAlign: 'center', lineHeight: '1.2rem' }}>
        {`Closeout logs are the best way to track, manage, and extract items required for closeout. Create your first log by clicking the "New" button above.`}
      </Typography>
    </Box>
  );
};

export default CloseoutLogListEmptyState;
