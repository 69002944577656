import { DocumentNode, gql, QueryResult, useQuery } from "@apollo/client";
import { ImportResourceSync } from "../../../types";

export interface ImportResourceSyncData {
  importResourceSync: ImportResourceSync;
}

export interface ImportResourceSyncVariables {
  id: number;
  accountId: string;
  procoreProjectServerId: number;
}

export const GetImportResourceSyncQuery = gql`
query GetImportResourceSync($accountId: ID!, $procoreProjectServerId: ID!, $id: ID!) {
  importResourceSync(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, id: $id) {
    id
    status
  }
}
`;

function createQueryHook<TData, TVariables>(
  query: DocumentNode
): (variables: TVariables, skip: boolean) => QueryResult<TData, TVariables> {
  return function(variables, skip) {
    return useQuery(query, { variables, skip, fetchPolicy: 'network-only' });
  };
}

export const useGetImportResourceSync = createQueryHook<ImportResourceSyncData, ImportResourceSyncVariables>(GetImportResourceSyncQuery);