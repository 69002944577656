import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface VendorPortalCreateProcoreProjectFile {
  success: boolean;
  procoreFileServerId?: number;
  filename?: string;
  createdAt?: string;
  createData?: Record<string, any>;
}

export interface VendorPortalCreateProcoreProjectFileData {
  createProcoreProjectFile: VendorPortalCreateProcoreProjectFile
}

export interface VendorPortalCreateProcoreProjectFileVariables {
  vendorId: string;
  logFileRequestId: number;
  filename: string;
  uploadUuid: string;
}

export const useVendorPortalCreateProcoreProjectFile = createMutationHook<VendorPortalCreateProcoreProjectFileData, VendorPortalCreateProcoreProjectFileVariables>(gql`
mutation CreateProcoreProjectFile($vendorId: ID!, $logFileRequestId: ID!, $filename: String!, $uploadUuid: String!) {
  createProcoreProjectFile(input: { vendorId: $vendorId, logFileRequestId: $logFileRequestId, filename: $filename, uploadUuid: $uploadUuid }) {
    success
    procoreFileServerId
    filename
    createdAt
    createData
  }
}
`);