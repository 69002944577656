import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface TemplateCloseoutLogDeleteData {
  deleteTemplateCloseoutLog: {
    success: boolean;
  }
}

export interface TemplateCloseoutLogDeleteVariable {
  accountId: string;
  templateCloseoutLogId: number;
}

export const useDeleteTemplateCloseoutLog = createMutationHook<
  TemplateCloseoutLogDeleteData,
  TemplateCloseoutLogDeleteVariable
>(gql`
  mutation DeleteTemplateCloseoutLog(
    $accountId: ID!,
    $templateCloseoutLogId: ID!
  ) {
    deleteTemplateCloseoutLog(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId }
    ) {
      success
    }
  }
`);
