import { ApolloError } from "@apollo/client";
import { CloseoutLog, PackageData } from "../types";

export type PackageHistoryState = {
  loading: boolean;
  data: PackageData;
  error: ApolloError;
  displayPackageInProgressToast: boolean;
  displaySuccessToast: {isOpen: boolean, message: string};
  tabValue: string;
  closeoutLogEnabled: boolean;
}

type Action =
  | { type: 'SET_PACKAGE_HISTORY'; value: PackageData }
  | { type: 'SET_LOADING'; value: boolean }
  | { type: 'SET_ERROR'; value: ApolloError }
  | { type: 'SET_DISPLAY_TOAST', value: boolean }
  | { type: 'SET_CLOSEOUT_LOG_ENABLED', value: boolean }
  | { type: 'SET_PACKAGES_TAB_VALUE', value: string }
  | { type: 'APPEND_CLOSEOUT_PACKAGE', value: CloseoutLog }
  | { type: 'DELETE_CLOSEOUT_PACKAGE', closeoutLogId: number }
  | { type: 'SET_DISPLAY_SUCCESS_TOAST', value: {isOpen: boolean, message: string}}
  | { type: 'RENAME_CLOSEOUT_LOG', value: {name: string, id: number}}

export const rootReducer: React.Reducer<PackageHistoryState, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_PACKAGE_HISTORY':
      return {
        ...state,
        data: action.value
      };

    case 'APPEND_CLOSEOUT_PACKAGE': {
      return {
        ...state,
        data: {
          ...state.data,
          closeoutLogs: [
            ...state.data.closeoutLogs,
            action.value
          ]
        }
      }
    }

    case 'DELETE_CLOSEOUT_PACKAGE': {
      return {
        ...state,
        data: {
          ...state.data,
          closeoutLogs: state.data.closeoutLogs.filter( log => log.id !== action.closeoutLogId)
        },
        displaySuccessToast: {isOpen: true, message: 'Closeout log successfully deleted'},
      }
    }

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.value
      }

    case 'SET_ERROR':
      return {
        ...state,
        error: action.value
      }

    case 'SET_DISPLAY_TOAST':
      return {
        ...state,
        displayPackageInProgressToast: action.value
      }

    case 'SET_DISPLAY_SUCCESS_TOAST':
      return {
        ...state,
        displaySuccessToast: action.value
      }

    case 'SET_PACKAGES_TAB_VALUE':
      return {
        ...state,
        tabValue: action.value
      }

    case 'SET_CLOSEOUT_LOG_ENABLED':
      return {
        ...state,
        closeoutLogEnabled: action.value,
        tabValue: action.value ? 'closeout_logs' : 'history'
      }

    case 'RENAME_CLOSEOUT_LOG':
      return {
        ...state,
        data: {
          ...state.data,
          closeoutLogs: state.data.closeoutLogs.map( log => {
            if (log.id === action.value.id) {
              return {
                ...log,
                name: action.value.name,
              }
            } else { return log }
          })
        }
      }

    default:
      return state;
  }
};

export type PackageHistoryContextType = {
  state: PackageHistoryState;
  dispatch: (action: Action) => void;
  fetchPackages: () => void;
};