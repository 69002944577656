import { gql } from "@apollo/client";
import { FilterOptionInput } from "../../types";
import { ProcoreDailyLog } from "../../types/daily_log";

export type ProcoreFilterDailyLogData = {
  dailyLogs: ProcoreDailyLog[];
  toolRecordServerIds: {
    procoreServerIds: number[]
  },
};

export type ProcoreFilterDailyLogVariables = {
  accountId: string;
  procoreProjectServerId: number;
  filters: FilterOptionInput[];
  procoreToolId: number;
  limit: number;
  offset: number;
};

export const DailyLogFragment = gql`
fragment DailyLogFragment on ProcoreDailyLog {
  id
  procoreServerId
  formattedTitle
}
`;

export const filterProcoreDailyLogsQuery = gql`
query FilterProcoreDailyLogs($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $procoreToolId: ID!, $limit: Int!, $offset: Int!) {
  dailyLogs(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, limit: $limit, offset: $offset) {
    ...DailyLogFragment
  }

  toolRecordServerIds(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, procoreToolId: $procoreToolId) {
    procoreServerIds
  }
}
${DailyLogFragment}
`;

export const fetchPaginatedProcoreDailyLogsQuery = gql`
query FilterPaginatedProcoreDailyLogs($accountId: ID!, $procoreProjectServerId: ID!, $filters: [FilterOptionInput!]!, $limit: Int!, $offset: Int!) {
  dailyLogs(accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, filters: $filters, limit: $limit, offset: $offset) {
    ...DailyLogFragment
  }
}
${DailyLogFragment}
`;