import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

export const ModalFooter = (props: { children } & BoxProps): JSX.Element => {
  const { children, ...rest } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '8px',
        padding: '24px 28px',
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
