import React, { useState } from 'react';
import {
  Box,
  Link,
  Breadcrumbs,
  DialogContent,
  styled,
  Popover,
  MenuItem,
  Dialog,
  DialogTitle,
  FormControl,
  TextField,
  Tooltip,
} from '@mui/material';
import { Typography } from './Typography';
import useNavigationHook from '../../hooks/UseNavigationHook';
import { AccountProjectContext } from '../../contexts/AccountProjectContextProvider';
import { AuthContext } from '../../contexts/AuthContext';
import { Colors, DialogDivider, MyDialogActions } from '../../styles';
import Home from '@mui/icons-material/Home';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { Button } from './Button';
import { isEmpty } from '../../utils/utils';
import { useCreateFeedback } from '../../graphql/mutations/CreateFeedback';
import { useLocation, useParams } from 'react-router';
import { getBreadcrumbsFromPath } from '../../utils/utils';

const StyledLink = styled(Link)(() => ({
  fontSize: '0.75rem',
  lineHeight: '0.879rem',
  fontWeight: '500',
  color: `${Colors.darkishGray}`,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    color: `${Colors.darkerGray}`,
  },
}));

export type Page = 'PROJECT_HOME' | 'CLOSEOUT_LOG' | 'EXTRACT_CLOSEOUT_LOG' | 'NEW_INSTANT_EXTRACT'

export type Breadcrumb = {
  displayName: string;
  path?: string;
  resetSelectedCloseoutLogId: boolean;
};

export const BreadcrumbNav = (): JSX.Element => {
  const navigateTo = useNavigationHook();
  const { state, getPath, dispatch } = React.useContext(AccountProjectContext);
  const { id } = useParams<{ id: string }>();
  const { setAuthenticated } = React.useContext(AuthContext);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState(null);
  const [feedbackType, setFeedbackType] = useState('');
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  //Account icon and menu
  const handleOnProfileClick = (event: React.MouseEvent<{}>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleOnHelpClick = (event: React.MouseEvent<{}>) => {
    setHelpMenuAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    const path = getPath('/logout');

    setAuthenticated(false);
    window.location.replace(path);
  };

  //Feedback icon and modal

  const [createFeedback, { loading: createFeedbackLoading }] =
    useCreateFeedback({
      feedbackType: feedbackType,
      message: message,
    });

  function closeAndResetState() {
    setIsModalOpen(false);
    setFeedbackType('');
    setMessage('');
  }

  function handleOnFeedbackSubmitted() {
    createFeedback();

    closeAndResetState();
  }

  //Home breadcrumb (always shown)
  const handleHomeNavigation = () => {
    dispatch({
      type: 'RESET_ACCOUNT_PROJECT',
      value: '',
    });
    navigateTo('/teams');
  };

  const breadcrumbs = getBreadcrumbsFromPath(
    location.pathname,
    state.selectedAccount,
    state.projectName,
    state.procoreProjectServerId,
    id
  );

  return (
    <>
      <Dialog
        maxWidth={'sm'}
        disableScrollLock
        onClose={closeAndResetState}
        aria-labelledby="help-dialog-title"
        open={isModalOpen}
        sx={{
          '& .MuiPaper-root': {
            width: '440px',
          },
        }}
      >
        <DialogContent
          dividers={false}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            marginBottom: '20px',
          }}
        >
          <DialogTitle
            sx={{
              padding: '16px 0px',
            }}
          >
            <Typography typestyle="xl">{'Submit Feedback to Extracts Pro'}</Typography>
          </DialogTitle>
          <FormControl variant="outlined">
            <TextField
              select
              id="demo-simple-select-outlined"
              value={feedbackType}
              onChange={(evt) => setFeedbackType(evt.target.value as string)}
              label="Type"
            >
              {['Bug', 'Feature', 'Other'].map((v) => (
                <MenuItem key={`feedback-menu-item-${v}`} value={v}>
                  {v}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <TextField
            id="outlined-multiline-static"
            label="Message"
            multiline
            minRows={2}
            maxRows={10}
            value={message}
            onChange={(evt) => setMessage(evt.target.value as string)}
            variant="outlined"
            sx={{
              '& .MuiInputBase-root': {
                display: 'flex',
              },
            }}
          />
        </DialogContent>
        <DialogDivider />
        <MyDialogActions>
          <Button
            onClick={handleOnFeedbackSubmitted}
            size="large"
            variant="contained"
            disableElevation={true}
            disabled={isEmpty(feedbackType) || isEmpty(message)}
            color="primary"
          >
            {'Submit'}
          </Button>
        </MyDialogActions>
      </Dialog>
      <Popover
        key={"profile-menu"}
        open={Boolean(profileMenuAnchorEl)}
        anchorEl={profileMenuAnchorEl}
        onClose={() => setProfileMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <MenuItem key="profile-logout" onClick={handleLogout}>
          {'Logout'}
        </MenuItem>
      </Popover>
      <Popover
        key={"help-menu"}
        open={Boolean(helpMenuAnchorEl)}
        anchorEl={helpMenuAnchorEl}
        onClose={() => setHelpMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <MenuItem key="feedback" onClick={() => setIsModalOpen(true)}>
          {'Submit Feedback'}
        </MenuItem>
        <MenuItem key="documentation" onClick={() => {
          window.open('https://help.extractspro.com', '_blank');
        }}>
          {'Help Center'}
        </MenuItem>
        <MenuItem key="contact-support" onClick={() => {
          window.open("mailto:support@extractspro.com", '_blank');
        }}>
          {'Contact Support'}
        </MenuItem>
      </Popover>
      <Box
        sx={{
          width: '100%',
          minHeight: '30px',
          height: '30px',
          padding: '4px 12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: `${Colors.white}`,
          boxShadow:
            '0px 1px 10px rgba(26, 32, 36, 0.06), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)',
        }}
      >
        <Breadcrumbs
          sx={{
            '& .MuiBreadcrumbs-separator': {
              color: `${Colors.reallyBlueGray}`,
              margin: '0px 10px',
            },
          }}
        >
          <StyledLink underline="hover" onClick={handleHomeNavigation}>
            <Home sx={{ mr: '6px', fontSize: '0.879rem' }} />
            Extracts Pro Home
          </StyledLink>
          {breadcrumbs.map((item, i) => {
            //if last breadcrumb, return text not link
            return i === breadcrumbs.length - 1 ? (
              <Typography
                key={i}
                sx={{ fontWeight: '500' }}
                typestyle="xs"
                color={Colors.darkerGray}
              >
                {item.displayName}
              </Typography>
            ) : (
              <StyledLink key={i} underline="hover" onClick={()=> {
                if (item.resetSelectedCloseoutLogId) {
                  dispatch({
                    type: "RESET_SELECTED_CLOSEOUT_LOG_ID"
                  });
                }
                navigateTo(item.path)
              }}>
                {item.displayName}
              </StyledLink>
            );
          })}
        </Breadcrumbs>
        <Box
          id="right actions"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
          }}
        >
          <Tooltip title="Account">
            <AccountCircleOutlined
              sx={{
                color: `${Colors.darkishGray}`,
                '&:hover': {
                  color: `${Colors.darkerGray}`,
                },
                cursor: 'pointer',
                fontSize: '1.2rem',
              }}
              onClick={handleOnProfileClick}
            />
          </Tooltip>
          <Tooltip title="Submit Feedback">
            <HelpOutline
              sx={{
                color: `${Colors.darkishGray}`,
                '&:hover': {
                  color: `${Colors.darkerGray}`,
                },
                cursor: 'pointer',
                fontSize: '1.2rem',
              }}
              onClick={handleOnHelpClick}
            ></HelpOutline>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};
